import { callApiServer } from "../utils/callApiServer";
import { industryPageSize } from "../constants/pageSize";
export const actGetVSICInformationApi = (body) => {
  const params = {
    searchText: body.q,
    vdl: body.vdl,
    tts: body.tts,
    ln: body.ln,
    dt: body.dt,
    kv: body.kv,
    type: body.type,
    pageIndex: body.pageIndex,
    size: industryPageSize,
    language: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/Company/GetChartByVsicId", { params: params });
};

export const actGetTopVSICApi = () => {
  return callApiServer.get("/Company/GetTop10Vsic");
};

export const actSendDataToCompareApi = (model) => {
  let modelx = {
    ...model,
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/Company/GetDataSXTT", modelx);
};

export const actSendDataFinacialToCompareApi = (model) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/Company/ChiTieuHeSo_Chart?lang=" + params.lang, model);
};

export const actAutocomplete = (model) => {
  return callApiServer.get("/Company/GetVsicAutoComplete", { params: model });
};

export const requestSoSanh_Nganh_TB = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/Company/SoSanh_Nganh_TB?lang=" + params.lang, body);
};
export const requestSoSanh_Nganh_TGT = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/Company/SoSanh_Nganh_TGT?lang=" + params.lang, body);
};

//----------------------------------------------------------------------
// IR-IF
// Tra cứu thông tin ngành
export const searchIndustry = (body) => {
  const params = {
    searchText: body.searchText,
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/IFReportStatistic/SearchCommonReportData", { params: params });
};

// DS báo cáo ngành
export const searchIndustryReport = (body) => {
  const params = {
    pageIndex: parseInt(body.page),
    pageSize: industryPageSize,
    categoryList: body.categoryListSelected,
    yearOfReportList: body.yearOfReportListSelected
  };
  return callApiServer.post(`/IFReportStatistic/SearchVsicReport`, params);
};

// chi tiết bc ngành
export const getIndustryReportDeyail = (reportId) => {
  return callApiServer.get(`/IFReportStatistic/ViewVsicReport?reportId=${reportId}`);
};

// bc liên quan - DN liên quan
export const getRelatedReportAndCompany = (reportId) => {
  return callApiServer.get(`/IFReportStatistic/GetReportRelateInformation?reportId=${reportId}`);
};

// Thống kê cơ bản - table
export const searchIndustryStatistic = (body) => {
  let params = {
    vsicCode: body.vsicCode,
    vsicStatisticKind: body.vsicStatisticKind,
    textSearch: body.textSearch,
    dataType: body.dataType,
    calculationType: body.calculationType,
    predicate: body.predicate,
    sortType: body.sortType,
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  if (body.vsicFilter) {
    params.vsicFilter = body.vsicFilter;
  }
  return callApiServer.post(`/IFReportStatistic/ViewVsicStatisticBaseText?pageIndex=${body.pageIndex}&pageSize=${industryPageSize}`, params);
};

// Thống kê nâng cao - table
export const searchIndustryAdvanceStatistic = (body) => {
  let params = {
    productCode: body.productCode,
    vsicStatisticKind: body.vsicStatisticKind,
    textSearch: body.textSearch,
    dataType: body.dataType,
    calculationType: body.calculationType,
    predicate: body.predicate,
    sortType: body.sortType,
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  if (body.productFilter) {
    params.productFilter = body.productFilter;
  }
  if (body.areaFilter) {
    params.areaFilter = body.areaFilter;
  }
  return callApiServer.post(`/IFReportStatistic/ViewVsicStatisticBaseText/Advanced?pageIndex=${body.pageIndex}&pageSize=${industryPageSize}`, params);
};

// ds năm để vẽ chart
export const actGetStatisticChartYear = () => {
  return callApiServer.get("/IFReportStatistic/VsicStatisticChartGetYear");
};

// Thống kê cơ bản - chart
export const searchIndustryStatisticChart = (body) => {
  return callApiServer.post("/IFReportStatistic/VsicStatisticBaseChart", body);
};

// Thống kê nâng cao - chart
export const searchIndustryAdvanceStatisticChart = (body) => {
  return callApiServer.post("/IFReportStatistic/VsicStatisticAdvancedChart", body);
};

// ds ngành vsic
export const getVsicList = () => {
  return callApiServer.get("/IFReportStatistic/GetListVsic");
};

// ds sản phẩm
export const getProductList = () => {
  return callApiServer.get("/IFReportStatistic/GetListProduct");
};
//----------------------------------------------------------------------
