import * as Types from "../actions";

const INIT_STATE = {
  subNavMacro: {
    loading: false,
    listData: [],
  },
  listCheckboxMacro: {
    loading: false,
    listData: [],
  },
  dataChartMacro: {
    loading: false,
    listChart: [],
    limitChart: false
  },
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_SUB_NAV_MARCRO:
      return {
        ...state,
        subNavMacro: {
          ...state.subNavMacro,
          loading: true
        }
      };
    case Types.GET_SUB_NAV_MARCRO_SUCCESS:
      return {
        ...state,
        subNavMacro: {
          ...state.subNavMacro,
          loading: false,
          listData: action.data
        }
      };
    case Types.GET_SUB_NAV_MARCRO_FAIL:
      return {
        ...state,
        subNavMacro: {
          ...state.subNavMacro,
          loading: false
        }
      };
    //listcheckbox
    case Types.GET_LIST_CHECKBOX_MARCRO:
      return {
        ...state,
        listCheckboxMacro: {
          ...state.listCheckboxMacro,
          loading: true
        }
      };
    case Types.GET_LIST_CHECKBOX_MARCRO_SUCCESS:
      return {
        ...state,
        listCheckboxMacro: {
          ...state.listCheckboxMacro,
          loading: false,
          listData: action.data
        }
      };
    case Types.GET_LIST_CHECKBOX_MARCRO_FAIL:
      return {
        ...state,
        listCheckboxMacro: {
          ...state.listCheckboxMacro,
          loading: false
        }
      };
    //listDataChart
    case Types.REFRESH_CHART:
      return {
        ...state,
        dataChartMacro: {
          ...state.dataChartMacro,
          limitChart: false,
        }
      };
    case Types.GET_DATA_CHART_MACRO:
      return {
        ...state,
        dataChartMacro: {
          ...state.dataChartMacro,
          loadingChart: true
        }
      };
    case Types.GET_DATA_CHART_MACRO_SUCCESS:

      let dataCustom = {
        activeTab: action.activeTab,
        ...action.data
      }
      let lenghtDataKTN = [dataCustom, ...state.dataChartMacro.listChart];
      const result = lenghtDataKTN.filter(item => item.activeTab === action.activeTab);
      if (result.length > 8) {
        return {
          ...state,
          dataChartMacro: {
            ...state.dataChartMacro,
            loadingChart: false,
            limitChart: true
          }
        };
      } else {
        return {
          ...state,
          dataChartMacro: {
            ...state.dataChartMacro,
            loadingChart: false,
            limitChart: false,
            listChart: [dataCustom, ...state.dataChartMacro.listChart]
          }
        }
      }
    case Types.GET_DATA_CHART_MACRO_FAIL:
      return {
        ...state,
        dataChartMacro: {
          ...state.dataChartMacro,
          loadingChart: false
        }
      };
    case Types.DELETE_DATA_CHART_MACRO:
      var { key } = action.payload;
      var checked = false;
      var arrx = [...state.dataChartMacro.listChart];
      arrx.splice(key, 1);
      if (arrx.length <= 8) {
        checked = false
      }
      return {
        ...state,
        dataChartMacro: {
          ...state.dataChartMacro,
          loadingChart: false,
          listChart: [...arrx],
          limitChart: checked,
        }
      };
    default:
      return state;
  }
};
