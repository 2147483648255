import React, { Suspense } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import IntlMessages from "../../../utils/IntlMessages";
import Data from "../../../components/Dummy/dataVSIC/data";
import { Table } from "reactstrap";
import { actGetVsicList } from "../../../redux/IndustryInformation/actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import LoaderTable from "../../LoaderTable";

class ModalListVSICPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: "",
        }
    }

    componentDidMount() {
        this.props.getVsicList();
    }

    handleCloseModal = () => {
        this.props.handleCloseForget(false);
    };

    handleHideAlert = () => {
        this.setState({
            alert: "",
        });
    };

    render() {
        const { toggleModal } = this.props;
        const closeBtn = (
            <span onClick={this.handleCloseModal} className="material-icons close-btn">close</span>
        );
        const { industryList, industryListLoading } = this.props.industryInformationReducer;
        var dataVSIC = [];
        dataVSIC = industryList.map((data, index) => {
            return (
                <tr key={index}>
                    <td>{data.code}</td>
                    <td>{data.enName}</td>
                    <td>{data.name}</td>
                </tr>
            );
        });

        return (
            <React.Fragment>
                <Modal
                    className="common-modal"
                    isOpen={toggleModal}
                    size="lg"
                    centered
                >
                    <ModalHeader
                        close={closeBtn}
                    >
                        <IntlMessages id="newWord.IndustrySystem" />
                    </ModalHeader>

                    <ModalBody>
                        <div className="table-custom">
                            {industryListLoading ? (<LoaderTable
                                isLoaded={!industryListLoading}
                                styleCustom={{ height: "250px" }}
                            />
                            ) :
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th><IntlMessages id="vsicList.code" /></th>
                                            <th><IntlMessages id="vsicList.industryEnName" /></th>
                                            <th><IntlMessages id="vsicList.industryName" /></th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                        {dataVSIC}
                                    </tbody>
                                </Table>
                            }
                        </div>
                    </ModalBody>

                </Modal>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        industryInformationReducer: state.industryInformationReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getVsicList: (body) => {
            dispatch(actGetVsicList(body));
        },
    };
};

const MyComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ModalListVSICPage));

export default function ModalListVSIC(props) {
    return (
        <Suspense fallback="loading">
            <MyComponent {...props} />
        </Suspense>
    );
}