import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Table } from "reactstrap";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { _Premium_ } from "../../../constants/rolesModel";
import NoData from "../../NoData";
import LoaderTable from "../../LoaderTable";
class ModalStructure extends React.Component {
  handleCloseModal = () => {
    this.props.handleCloseStructure(false);
  };
  render() {
    const { toggleStructure, structorDetailData, nameOwner, created_at, loadingStructor, handlePopupLaw, roles } = this.props;
    const closeBtn = (
      <span onClick={this.handleCloseModal} className="material-icons close-btn">close</span>
    );

    return (
      <div>
        <Modal
          isOpen={toggleStructure}
          size="lg"
          centered
          className="modal_owner_structure common-modal"
        >
          <ModalHeader
            close={closeBtn}
          >
            <IntlMessages id="newWord.owner" /> {nameOwner ? nameOwner : ""}
          </ModalHeader>
          <LoaderTable isLoaded={!loadingStructor} styleCustom={{ height: "200px" }} />
          {!loadingStructor &&
            <ModalBody style={{ display: "flex", justifyContent: "center" }}>
              {structorDetailData && structorDetailData.length > 0 ? (
                <div className="table-custom" style={{ width: "100%" }}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th >STT</th>
                        <th><IntlMessages id="submenu.Ownership" /></th>
                        <th><IntlMessages id="ownership.Ownershippercentage" /></th>
                        <th><IntlMessages id="ownership.Updateddate" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      {structorDetailData.length > 0 &&
                        structorDetailData.map((props, index) => {
                          return (
                            <tr key={index}>
                              <td><span>{index + 1}</span></td>
                              <td>
                                <a
                                  href="#"
                                  className="text-cyan"
                                  onClick={roles && roles === _Premium_ ? e => handlePopupLaw(e, props.id) : () => { return false }}
                                >
                                  {props.name ? props.name : ""}
                                </a>
                              </td>
                              <td><span>{props.shipRate ? props.shipRate : ""}%</span></td>
                              <td >
                                <span>
                                  {created_at
                                    ? created_at
                                    : ""}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoData />
              )}
            </ModalBody>
          }
        </Modal>
      </div>
    );
  }
}

export default injectIntl(ModalStructure);
