import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import IntlMessages from "../../../utils/IntlMessages";

class TabIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { imageUrl, chapter, id, changeTab, reportLang } = this.props;
        return (
            <div className="report-content">
                <img className="report-image" src={imageUrl} alt={imageUrl} />
                <Nav className="report-menu">
                    {
                        chapter && chapter.length ? chapter.map((props, key) => {
                            const name = reportLang === "en" && props.chapterEnName ? props.chapterEnName : props.chapterName;
                            return (
                                <NavItem key={key}>
                                    <Link className="text-cyan"
                                        onClick={() => changeTab(`chapter-${props.chapterNo}`, key)}
                                        to={`${id}?tab=chapter-${props.chapterNo}`}
                                    >
                                        {reportLang === "en" ? 'Chapter' : 'Chương'} {props.chapterNo}: {name}
                                    </Link>
                                </NavItem>
                            );
                        }) : ''
                    }
                </Nav>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(TabIndex));