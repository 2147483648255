export default {
  /* 01.General */
  "general.copyright": "Virace React © 2020 All Rights Reserved.",
  "menu.home": "Menu điều hướng",
  "menu.cr-home": "Trang chủ CR",
  "menu.if-ir-home": "Trang chủ IF-IR",
  "menu.companyinfoorigin": "Thông tin doanh nghiệp",
  "menu.companyinfo": "Thông tin",
  "menu.companyinfoplus": "doanh nghiệp",
  "menu.companycomparisonorigin": "So sánh doanh nghiệp",
  "menu.companycomparison": "So sánh",
  "menu.companycomparisonplus": "doanh nghiệp",
  "menu.industryinformationorigin": "Thông tin ngành",
  "menu.industryinformation": "Thông tin",
  "menu.industryinformationplus": "ngành",
  "menu.macroinformationorigin": "Thông tin vĩ mô",
  "menu.macroinformation": "Thông tin",
  "menu.macroinformationplus": "vĩ mô",
  "menu.newevent": "Tin tức",
  "menu.neweventdetail": "Chi tiết tin tức",
  "menu.faq": "FAQs",
  "menu.contact": "Liên hệ",
  "menu.report": "Báo cáo",
  "menu.reportIndustry": "Báo cáo DN & ngành",
  "menu.companyinformationsearch": "Tra cứu thông tin doanh nghiệp",
  "menu.industryinformationsearch": "Tra cứu thông tin ngành",
  "menu.accountsManagement": "Quản lý tài khoản",
  "menu.cartDataTitle": "Danh sách yêu cầu",

  "sidebar.info": "Thông Tin",
  "sidebar.menu": "Menu",
  "sidebar.Comparison": "So Sánh",
  "sidebar.Industry": "Ngành",
  "sidebar.companyType": "Loại hình doanh nghiệp",

  "submenu.Businessinformation": "Thông tin",
  "submenu.Basicgrowth": "Đánh giá tăng trưởng cơ bản",
  "submenu.Ownership": "Chủ sở hữu",
  "submenu.Ratios": "Chỉ số",
  "submenu.Accounts": "Chỉ tiêu",
  "submenu.Comparisons": "So sánh",
  "submenu.Industryaverages": "SS với trung bình",
  "submenu.Othercompanies": "Doanh nghiệp khác",
  "submenu.ExchangeRate": "Tỷ giá",
  "submenu.relatedIndustryReports": "Báo cáo liên quan",

  "page.hTitle": "Thông tin doanh nghiệp",
  "page.hAddress": "Địa chỉ - Điện thoại - Fax - Website",
  "page.MST": "MST",

  "detailCompany.h1Table": "Thông tin kinh doanh",
  "detailCompany.MainbusinessShortcut": "Hoạt động KD chính",
  "detailCompany.Mainbusiness": "Hoạt động kinh doanh chính",
  "detailCompany.Keyproduct": "Sản phẩm chính",
  "detailCompany.Keymarket": "Thị trường chính",
  "detailCompany.TaxcodeShortCut": "Mã số thuế (MST)",
  "detailCompany.Taxcode": "Mã số thuế",
  "detailCompany.Parentcompany": "Doanh nghiệp mẹ",
  "detailCompany.Accountingperiod": "Kỳ kế toán",
  "detailCompany.FinancialstatmentShortCut": "Bộ BCTC",
  "detailCompany.Financialstatment": "Bộ báo cáo tài chính",
  "detailCompany.FiscalperiodShortCut": "Niên độ BCTC",
  "detailCompany.Fiscalperiod": "Niên độ báo cáo tài chính",
  "detailCompany.Stocklisting": "Trạng thái niêm yết",
  "detailCompany.NoemployeesShortCut": "Số nhân viên (NV)",
  "detailCompany.Noemployees": "Số nhân viên",
  "detailCompany.LegalrepresentativeShortCut": "Đại diện pháp luật (ĐDPL)",
  "detailCompany.Legalrepresentative": "Đại diện theo pháp luật",
  "detailCompany.shareholders": "Thông tin cổ đông",
  "detailCompany.Datarequest": "Yêu cầu dữ liệu",
  "detailCompany.ActionInfo": "Thông tin hoạt động chính",
  "detailCompany.AreaBusiness": "Lĩnh vực hoạt động kinh doanh",
  "detailCompany.NetProfitGrowthRate": "Tỷ lệ tăng trưởng lợi nhuận thuần",
  "detailCompany.Downloadfinancialreportx": "Tải báo cáo",

  //TOOLTIP
  "detailCompany.MainBusiness": "Hoạt động kinh doanh chính",
  "detailCompany.KeyProduct": "Sản phẩm chính",
  "detailCompany.NetrevenueShortcut": "DT thuần",
  "detailCompany.Netrevenue": "Doanh thu thuần",
  "detailCompany.VsicCode": "Mã ngành VSIC",

  "detailCompany.NetprofitShortcut": "LN thuần",
  "detailCompany.Netprofit": "LN thuần",
  "detailCompany.noNetprofitShortcut": "LN gộp",
  "detailCompany.noNetprofit": "LN gộp",
  "detailCompany.Totalassets": "Tổng TS",
  "detailCompany.Netdebts": "Nợ ròng",
  "detailCompany.OwnerequityShortcut": "Vốn CSH",
  "detailCompany.Ownerequity": "Vốn CSH",
  "detailCompany.GoodsSoldShortcut": "Giá vốn",
  "detailCompany.GoodsSold": "Giá vốn hàng bán",
  "detailCompany.Marketcapitalization": "Vốn hóa thị trường",
  "detailCompany.Outstandingshares": "Cổ phần lưu hành",
  "detailCompany.Downloadfinancialreport": "MUA - DOWNLOAD BC",
  "detailCompany.Updated": "Cập nhật ",
  "detailCompany.Financialreports": "Báo cáo phân tích TC",
  "detailCompany.Choosefiscalyear": "Chọn năm tài chính",
  "detailCompany.Purchase": "Mua",
  "detailCompany.Consolidatedfinancial": "Tải Báo cáo TC hợp nhất",
  "detailCompany.Downloadfinancialreportbuy": "Báo cáo DN & ngành",
  "detailCompany.NetProfitMargin": "Biên lợi nhuận thuần",
  "detailCompany.table1.top5a": "Top 5 doanh nghiệp cùng ngành và cùng size với DN đang xem và có DT thuần cao nhất",
  "detailCompany.table1.top5b": "05 doanh nghiệp cùng ngành, cùng size và có DT tương đương với DN đang xem",
  "detailCompany.table2": "Doanh nghiệp cùng ngành được truy cập nhiều nhất trong vòng 3 tháng",
  "detailCompany.FinancialReportingType": "Loại báo cáo tài chính",
  "detailCompany.notCompare": "Khác loại hình doanh nghiệp",
  "detailCompany.maxCompare": "Số lượng doanh nghiệp so sánh đã đạt giá trị tối đa",
  "detailCompany.minCompare": "Chọn ít nhất 2 doanh nghiệp để so sánh",
  "detailCompany.reportType": "Loại hình doanh nghiệp",
  "detailCompany.minTargetCompare": "Chọn ít nhất 1 chỉ tiêu hoặc chỉ số để so sánh",
  "detailCompany.maxTargetCompare": "Chọn nhiều nhất 8 chỉ tiêu và/hoặc chỉ số để so sánh",

  "growthBasic.Compareaverage": "So sánh trung bình ngành",
  "growthBasic.Selectlocation": "Chọn khu vực",
  "growthBasic.SelectlocationTitle": "khu vực",
  "ownership.Legal": "Đại diện pháp luật",
  "ownership.Fullname": "Tên",
  "ownership.Position": "Chức vụ",
  "ownership.Ownershippercentage": "Cổ phần",
  "ownership.Updateddate": "Ngày cập nhật",
  "ownership.stt": "STT",
  "ownership.Ownershipstructure": "Cấu trúc chủ sở hữu",
  "ownership.Ownershiptype": "Chủ sở hữu",
  "ownership.Ownershippercentagex": "Tỷ lệ",
  "ownership.Privatehold": "Tư nhân",
  "ownership.EnterpriseName": "Doanh nghiệp",
  "ownership.Foreignhold": "Khác",
  "ownership.Others": "Khác",
  "ownership.Subsidiaryandaffiliate": "Danh sách doanh nghiệp con và liên kết",
  "ownership.Companyname": "Tên doanh nghiệp",
  "ownership.Taxcode": "MST",
  "ownership.Relationship": "Quan hệ",
  "ownership.Subsidiary": "Doanh nghiệp con",
  "ownership.Affiliate": "Doanh nghiệp liên quan",
  "ratio.h1list": "Danh sách chỉ số",

  "account.h1list": "Chỉ tiêu",
  "account.Otheraccounts": "Các chỉ tiêu quan trọng",
  "averages.h1Accounts": "Chỉ tiêu",
  "averages.h1Ratios": "Chỉ số",
  "averages.basicRatios": "Chỉ số tài chính cơ bản",
  "averages.peerComparison": "Trung bình các DN cùng ngành tương đương",
  "averages.peerComparisonTB": "TB các DN cùng ngành tương đương",
  "averages.titleColumn": "Tổng doanh thu thuần ngành",
  "compare.tabSame": "So sánh Cùng ngành",
  "compare.tabAny": "So sánh Bất kỳ",
  "compare.txtInput": "Nhập tên ngành hoặc mã ngành VSIC cần tìm kiếm",
  "compare.searchResult": "Kết quả tìm kiếm",
  "compare.businessComparison": "So sánh trực tiếp",
  "compare.groupComparison": "So sánh nhóm doanh nghiệp",
  "compare.group1": "Nhóm doanh nghiệp 1",
  "compare.group2": "Nhóm doanh nghiệp 2",
  "compare.companiesComparison": "Danh sách doanh nghiệp so sánh",
  "compare.businessGroup": "Nhóm doanh nghiệp",
  "comparett.h1Accounts": "Chỉ tiêu",
  "comparett.h1Ratios": "Chỉ số",
  "comparett.basicRatios": "Chỉ số tài chính cơ bản",
  "comparett.Otheraccounts": "Các chỉ tiêu quan trọng",
  "comparett.peerComparison": "Trung bình các doanh nghiệp cùng ngành tương đương",

  "comparett.directcomparison": "So sánh trực tiếp",
  "comparett.averages": "Trung Bình",
  "comparett.accountTotal": "Tổng giá trị",
  "comparett.accounts": "Chỉ tiêu",
  "comparett.ratios": "Chỉ số",
  "comparett.industryAverage": "Trung bình ngành",

  "industry.tab1": "Tổng quan ngành",
  "industry.x1input": "Nhập tên ngành/ mã VSIC cần tìm",
  "industry.x1h1": "Ngành",
  "industry.x1top": "Top 10 ngành đăng ký nhiều nhất",
  "industry.x1sutrix": "Doanh nghiệp",

  "industry.tab2": "Chỉ số kinh tế ngành",
  "industry.tab4": "So Sánh Ngành",

  "industry.tab3": "Sản xuất - Tiêu thụ - Tồn kho",
  "industry.tab3h1ShortCut": "Tình trạng SX - TT - TK của 1 SP",
  "industry.tab3h1": "Tình trạng Sản xuất, Tiêu thụ, Tồn kho của một sản phẩm",
  "industry.tab3h2ShortCut": "So sánh tình trạng SX - TT - TK  của các SP",
  "industry.tab3h2": "So sánh tình trạng Sản xuất, Tiêu thụ, Tồn kho của các sản phẩm",
  "industry.tab3h2sub1": "Sản phẩm",
  "industry.tab3h2sub2": "Điều kiện SS",
  "industry.locationComparison": "So sánh ngành",
  "industry.informationOfManufacture": "Thông tin sản xuất tiêu thụ tồn kho ngành",

  "filter.vcsh": "Vốn CSH (VNĐ)",
  "filter.tts": "Tổng TS (VNĐ)",
  "filter.lnt": "LN thuần (VNĐ)",
  "filter.dt": "DT thuần (VNĐ)",
  "filter.kvx": "Khu Vực",
  "Khu Vực": "Khu Vực",
  "filter.dsnn": "Danh sách ngành (VSIC)",
  "filter.textInput": "Nhập thông tin tìm kiếm (Tên doanh nghiệp/MST/VSIC)",
  "companyFilter.textInput.minLength": "Quý khách vui lòng tìm kiếm tối thiểu 3 ký tự",
  "filter.buttonSearch": "Tìm kiếm",
  "filter.Sortby": "Lọc theo:",
  "filter.vdl1": "< 3 tỷ ",
  "filter.vdl2": "3 tỷ  - 20 tỷ ",
  "filter.vdl3": "20 tỷ  - 100 tỷ ",
  "filter.vdl4": ">100 tỷ ",
  /*01.3 DNOrderByLN */
  "filter.ln1": "< 300 triệu",
  "filter.ln2": "300 triệu - 5 tỷ",
  "filter.ln3": "5 tỷ - 20 tỷ",
  "filter.ln4": "> 30 tỷ",
  /*01.4 DNOrderByDT */
  "filter.dt1": "< 3 tỷ /năm",
  "filter.dt2": "3 tỷ  - 50 tỷ",
  "filter.dt3": "50 tỷ  - 200 tỷ",
  "filter.dt4": "> 300 tỷ",
  "filter.advanceSearch": "Tìm kiếm nâng cao",
  "filter.compact": "Rút gọn",

  /* 01.1 Filter */
  "pagi.First": "Đầu",
  "pagi.Last": "Cuối",
  /* 01.3 Table */
  "table.sttTable": "STT",
  "table.businessName": "Tên doanh nghiệp",
  "table.companyName": "Tên doanh nghiệp",
  "table.companyList": "Danh sách doanh nghiệp",
  "table.tableMST": "MST",
  "table.tableDelete": "Xóa",
  "table.favoriteCompanies": "Lưu",
  "table.sort": "Sắp xếp",
  "table.group": "Nhóm",
  "table.UpdateTable": "Cập nhật lại",
  "table.statusx": "Trạng thái",

  "table.groupChosse": "Chọn nhóm",
  "table.group1": "Nhóm 1",
  "table.group2": "Nhóm 2",

  "common.Years": "Theo thời gian: 3 năm",
  "common.Apply": "Áp dụng",
  "common.cancelBtn": "Hủy",
  "common.refresh": "Cập nhật lại",
  "common.IndustryAverage": "So sánh với TB ngành",
  "common.IndustryAverageTB": "TB Ngành",
  "common.Selectlocation": "Chọn khu vực",
  "common.Selectlocationx": "Theo khu vực",
  "common.listProvince": "Danh sách tỉnh thành",
  "common.listProvinceSelected": "Danh sách đã chọn",
  "common.sort": "Sắp Xếp",
  "common.largest": "Lớn nhất",
  "common.smallest": "Nhỏ nhất",
  "common.totalValue": "Tổng ngành",

  "common.AccounstRatio": "Chỉ số - Chỉ tiêu thường",
  "common.AccounstCredit": "Chỉ số - Chỉ tiêu tín dụng",

  "common.target": "Chỉ tiêu cơ bản",
  "common.bcdkt": "Bảng cân đối kế toán",
  "common.bckqhd": "Báo cáo kết quả hoạt động kinh doanh",
  "common.bclctt": "Báo cáo lưu chuyển tiền tệ trực tiếp",
  "common.bclcttgt": "Báo cáo lưu chuyển tiền tệ gián tiếp",

  "common.sourceVirac": "Nguồn VIRAC",
  "common.successx": "Successful",

  "ratio.Capitalstructure": "Cấu trúc vốn",
  "ratio.Performanceratios": "Chỉ số hiệu quả hoạt động",
  "ratio.Liquidityratios": "Chỉ số thanh toán",
  "ratio.Profitabilityratios": "Chỉ số lợi nhuận",
  "ratio.Customizedratios": "Điều chỉnh công thức tính chỉ số",
  "averages.Otheraccounts": "Các chỉ tiêu quan trọng",

  /*Quản lý tài khoản */
  "user.Info": "Thông tin tài khoản",
  "user.lang": "Ngôn ngữ mặc định",
  "user.changpass": "Đổi mật khẩu",
  "user.changpassOld": "Nhập mật khẩu cũ",
  "user.changpassNews": "Nhập mật khẩu mới",
  "user.changpassConfirm": "Nhập lại mật khẩu mới",
  "user.validatePass": "Mật khẩu phải từ 6 ký tự và có chứa số, kí tự đặc biệt, chữ cái thường và chữ cái in Hoa.",

  "user.RequestHistory": "Lịch sử yêu cầu",
  "user.DataRequestHistory": "Lịch sử yêu cầu cung cấp data",
  "user.orderCode": "Mã đơn hàng",
  "user.requirement": "Nội dung yêu cầu",
  "user.companyandindustry": "Doanh nghiệp/Ngành",
  "user.taxandCode": "MST/Mã ngành",
  "user.DateRequested": "Ngày yêu cầu",
  "user.Status": "Tình trạng",
  "user.NoRequest": "Không tìm thấy lịch sử dữ liệu yêu cầu",
  "user.listReport": "Danh sách báo cáo",

  "user.LogHistory": "Lịch sử truy cập",
  "user.Action": "Thao tác",
  "user.LogActivity": "Nội dung thao tác, truy cập",
  "user.Date": "Thời gian",
  "user.Browser": "Trình duyệt",
  "user.langVN": "Tiếng Việt",
  "user.langEN": "Tiếng Anh",

  "user.Notification": "Thông báo",
  "user.NotificationContent": "Nội dung thông báo",
  "user.DateSend": "Ngày gửi",
  "user.NewsS": "Danh sách các tin mới nhất",
  "user.LogOff": "Đăng xuất",
  "user.Confirm": "Xác nhận",
  "user.LogOffB": "Bạn muốn đăng xuất khỏi hệ thống ?",
  "user.Cancel": "Hủy",
  "user.ViewAll": "Xem hết",
  "user.hello": "Chào",
  "user.profile": "Hồ sơ",

  /*Cart */
  "cart.listDataRequest": "Danh sách yêu cầu cung cấp dữ liệu doanh nghiệp",
  "cart.Request": "Yêu cầu",
  "cart.Note": "Ghi chú",
  "cart.NoteNo": "Không có dữ liệu nào được yêu cầu",
  "cart.NoteOrder": "Nội dung ghi chú đơn hàng",
  "cart.SendOrder": "Gửi yêu cầu",
  "cart.FailAlert": "Thất bại",
  "cart.FailAlertOrder": "Xin lỗi quý khách! Bạn chưa được cấp quyền đặt yêu cầu cung cấp dữ liệu. Vui lòng liên hệ Hotline: 0938 677 566!",
  "cart.Acceps": "Đồng ý",
  "cart.industryReport.title": "Danh sách thông tin và báo cáo ngành",
  "cart.industryReport.report": "Báo cáo/Thông tin",
  "cart.industryReport.price": "Đơn giá",
  "cart.industryReport.search": "Tìm kiếm thêm báo cáo ngành",
  "cart.company.title": "Danh sách dữ liệu doanh nghiệp",
  "cart.company.report": "Loại báo cáo/Dữ liệu",
  "cart.company.company": "Doanh nghiệp",
  "cart.company.taxCode": "MST",
  "cart.company.search": "Tìm kiếm thêm dữ liệu doanh nghiệp",
  "cart.customer": "Người mua hàng",
  "cart.promotionCode": "Mã giảm giá",
  "cart.termPart_1": "Tôi đã đọc và đồng ý với ",
  "cart.termPart_2": "điều khoản và điều kiện ",
  "cart.termPart_3": "của website.",
  "cart.orderInfor": "Thông tin đơn hàng",

  "cart.money": "Tạm tính",
  "cart.promotion": "Giảm giá",
  "cart.total": "Tổng cộng",
  "cart.buy": "Mua hàng",
  "cart.request": "Gửi yêu cầu",
  "cart.note": "Chỉ mua được những báo cáo có đơn giá sẵn",
  "cart.discountCodeWrong": "Mã giảm giá không hợp lệ.",

  "cart.provisionalMoney": "Tạm tính",
  "cart.discount": "Giảm giá",
  "cart.VAT": "VAT ",
  "cart.totalMoney": "Tổng cộng",
  "cart.confirmPay": "XÁC NHẬN THANH TOÁN",
  "cart.cancelPay": "HUỶ THANH TOÁN",

  /* new */
  "newWord.somthingWrong": "Có lỗi xảy ra. Bạn vui lòng thử lại sau",
  "newWord.overTurnDownload": "Quý khách quá số lượt tải trong tháng. Vui lòng liên hệ chăm sóc khách hàng!",
  "newWord.notPermision": "Quý khách không có quyền thực hiện chức năng này. Vui lòng liên hệ chăm sóc khách hàng!",

  "newWord.chooseOne": "Bạn chỉ có thể chọn so sánh trực tiếp hoặc so sánh trung bình",
  "newWord.oke": "Đồng ý",
  "newWord.find": "Tìm",
  "newWord.from": "Từ",
  "newWord.to": "Tới",
  "newWord.sufRange": "(Tỷ VNĐ)",
  "newWord.sufRangeInput": "tỷ VNĐ",
  "newWord.Range": "Phạm vi tùy chỉnh",
  "newWord.emptyInput": "Vui lòng không được để trống.",
  "newWord.validFormat": "Vui lòng nhập đúng định dạng!",
  "newWord.validFormatPassword": "*Mật khẩu chưa đúng định dạng!",
  "newWord.validFormatPasswordConfirm": "*Xác nhận mật khẩu không chính xác !",
  "newWord.validFormatPasswordleast": "*Mật khẩu phải có ít nhất 6 kí tự !",

  "newWord.registeredMainShortCut": "Ngành KD đăng ký chính",
  "newWord.registeredMain": "Ngành kinh doanh đăng ký chính",
  "newWord.financialInfo": "Thông tin tài chính",

  "newWord.incorporatedDateShortCut": "Ngày đăng ký KD",
  "newWord.incorporatedDate": "Ngày đăng ký kinh doanh",

  "newWord.charteredCapital": "Vốn điều lệ",
  "newWord.wait": "Vui lòng chờ...",
  "newWord.titleChartOwner": "Danh sách các doanh nghiệp người đại diện pháp luật nắm cổ phần",
  "newWord.foreign": "Danh sách chủ sở hữu nước ngoài",
  "newWord.owner": "Danh sách chủ sở hữu",
  "newWord.ownerPercentage": "Tỉ lệ sở hữu",
  "newWord.notAvailable": "Hiện tại chưa có dữ liệu",
  "newWord.chart": "Biểu đồ",
  "newWord.performanceReview": "Đánh giá hoạt động doanh nghiệp",
  "newWord.industryRevenueGSO": "Doanh thu theo GSO (VNĐ)",
  "newWord.industryRevenueVIRAC": "Doanh thu theo VIRAC (VNĐ)",
  "newWord.companyNameTaxCode": "Nhập tên doanh nghiệp / MST / VSIC bất kỳ cần tìm kiếm",
  "newWord.companyNoSelected": "Không có doanh nghiệp nào được chọn!",
  "newWord.typeFinancialStatement": "Bộ BCTC",
  "newWord.ficalPeriod": "Kỳ BCTC",
  "newWord.typeStatement": "Loại BCTC",
  "newWord.accountingPeriod": "Kỳ kế toán",
  "newWord.notice": "Lưu ý",
  "newWord.alertSelectedAccountRatioTBN": "Chọn chỉ tiêu/chỉ số để so sánh với trung bình ngành!",
  "newWord.alertSelectedAccountRatio": "Chọn chỉ số /chỉ tiêu để so sánh!",
  "newWord.alertSelectedxRatio": "Chọn chỉ số để so sánh!",
  "newWord.alertSelectedxAccount": "Chọn chỉ tiêu để so sánh!",
  "newWord.alertSelected8Chart": "Hệ thống chỉ biểu diễn 8 biểu đồ trên một trang. Vui lòng điều chỉnh số lượng biểu đồ để có thể xuất biểu đồ mới.",
  "newWord.alert8LineRatio": "Chọn nhiều nhất 8 chỉ số",
  "newWord.alert8Target": "Chọn nhiều nhất 8 chỉ tiêu",
  "newWord.alertValidateChart":
    "Vui lòng điều chỉnh số lượng đối tượng cần so sánh. Lưu ý: Để hệ thống xuất 1 biểu đồ thành công, số lượng các đối tượng có thể chọn tối đa là 6 đối tượng...",
  "newWord.compareCompaniesOne": "Chọn một nhóm doanh nghiệp để so sánh!",
  "newWord.compareCompaniesTWo": "Chọn hai nhóm doanh nghiệp để so sánh!",
  "newWord.compareCompaniesGroup": "Chọn nhóm doanh nghiệp để so sánh!",
  "newWord.compareCompaniesCompare": "Chọn doanh nghiệp để so sánh!",
  "newWord.compareCompaniesCompareGroup": "Chọn doanh nghiệp vào nhóm để so sánh",
  "newWord.compareCompanies": "Chọn doanh nghiệp cùng ngành!",
  "newWord.alertUpdate": "Bạn chắc chắn muốn cập nhật lại?",
  "newWord.selectedCompanies": "Các DN đã chọn trong nhóm",
  "newWord.AllcompanieInGroup": "Tất cả các DN trong nhóm",
  "newWord.selectedCompaniesAve": "Các DN đã chọn trong nhóm",
  "newWord.AllcompanieInGroupAve": "Tất cả các DN trong nhóm",
  "newWord.listCompanies": "Danh sách các doanh nghiệp",
  "newWord.companiesname": "Tên doanh nghiệp",
  "newWord.AlertRemoveChart": "Bạn chắc chắn muốn xóa biểu đồ này?",
  "newWord.AlertRemoveCompaniesList": "Bạn chắc chắn muốn xóa doanh nghiệp khỏi danh sách này?",
  "newWord.AlertDataNotAvailable": "Hiện tại chưa có dữ liệu !",
  "newWord.ContactViracInfo": "Vui lòng liên hệ VIRAC để được hỗ trợ thêm thông tin",
  "newWord.ChooseComparison": "Chọn điều kiện so sánh !",
  "newWord.AskQuestion": "Các câu hỏi thường gặp",
  "newWord.InputQuestion": "Nhập các câu hỏi cần hỏi...",
  "newWord.Headquater": "Trụ sở chính",
  "newWord.representativeOffice": "Văn phòng đại diện tại Hồ Chí Minh",
  "newWord.representativeOfficeSing": "Văn phòng đại diện tại Singapore",
  "newWord.researchStock": "Công ty cổ phần nghiên cứu ngành và tư vấn việt nam",
  "newWord.mostRead": "Đọc nhiều nhất",
  "newWord.dataRequested": "Không có dữ liệu nào được yêu cầu !",
  "newWord.pleaseInput": "*Mật khẩu không được để trống.",
  "newWord.confirmationPassMathch": "Xác nhận mật khẩu không chính xác !",
  "newWord.resetPass": "Đổi mật khẩu thành công",
  "newWord.registerSuccess":
    "Quý khách đã đăng ký thành công. Vui lòng kiểm tra email để nhận mật khẩu đăng nhập hệ thống. Quý khách sẽ được chuyển hướng tới trang đăng nhập sau ",
  "newWord.registerFail": "Đăng ký thất bại. Bạn vui lòng thử lại sau.",
  "newWord.agree": "Đồng ý",

  "newWord.orderSent": "Gửi yêu cầu mua dữ liệu thành công !",
  "newWord.newsVirac": "Tin VIRACE",
  "newWord.noteRequest": "Ghi chú cho yêu cầu số",
  "newWord.saveNote": "Lưu ghi chú",
  "newWord.InputContent": "Nhập nội dung",
  "newWord.listVSIC": "Danh sách mã ngành VSIC 2019",
  "newWord.IndustrySystem": "Hệ thống ngành kinh tế Việt Nam",
  "newWord.oldPassIncorrect": "Mật khẩu cũ không chính xác !",
  "newWord.deleteNotification": " Xóa thông báo không thành công. Vui lòng reload lại trang web !",
  "newWord.AlertNotification": "Hiện tại chưa có thông báo",
  "newWord.NoFinancial": "Hiện tại chưa có bộ báo cáo tài chính!",
  "newWord.SelectEquivalent": "Chọn một trong các tiêu chí tương đương!",
  "newWord.Alert6LineOnChart":
    "Hệ thống chỉ biểu diễn tối đa 6 đường trên một biểu đồ. Vui lòng điều chỉnh số lượng đối tượng so sánh như số lượng chỉ số hoặc chỉ tiêu ",
  "newWord.AlertSelectProduct": "Chọn sản phẩm để so sánh! ",
  "newWord.Changelanguage": "Thay đổi ngôn ngữ ? ",
  "newWord.IndustryFound": "Hiện tại chưa có mã ngành nào được tìm thấy!",
  "newWord.companyNotFound": "Không có doanh nghiệp nào được tìm kiếm !",
  "newWord.companyNotFoundVSIC": "Vui lòng nhập tên ngành hoặc mã ngành VSIC để tìm kiếm !",
  "newWord.financialGrowth": "BIỂU ĐỒ TĂNG TRƯỞNG CHỈ TIÊU TÀI CHÍNH",
  "newWord.listOwnerpercentage": "Danh sách các doanh nghiệp người chủ sở hữu nắm cổ phần",
  "newWord.notFindRequest": "Không tìm thấy lịch sử dữ liệu yêu cầu !",
  "newWord.deleteDataRequest": "Bạn chắc chắn muốn xóa yêu cầu dữ liệu này ?",
  "newWord.updatePeriod": "Kỳ cập nhật",
  "newWord.upgradeAccount": "Quý khách vui lòng nâng cấp tài khoản!",
  "newWord.loginAccountRequire": "Quý khách vui lòng đăng nhập để sử dụng chức năng này!",

  "textChart.unit": "Đơn vị: VND",
  "textChart.unitValue": "Giá trị tiêu thụ (Triệu đồng)",

  "newWord.addressHeadquater": "Tầng 5 tòa nhà Anh Minh, 36 Hoàng Cầu, Đống Đa, Hà Nội",
  "newWord.addressOffice": "Lầu 4, tòa nhà Tuấn Minh, 21 Huỳnh Tịnh Của, P. Võ Thị Sáu, Quận 3 Thành phố Hồ Chí Minh",

  "newWord.logoutSystem": "Hệ thống đang đăng xuất",
  "newWord.downloadReportSample": "Tải báo cáo mẫu",
  "newWord.chooseLang": "Chọn ngôn ngữ",
  "newWord.selectIndustry": "Chọn ngành",
  "newWord.selectYear": "Chọn năm",
  "newWord.selectTypeReport": "Chọn loại báo cáo",
  "newWord.selectQuarter": "Chọn quý",

  "newWord.selectQuarterTable": "Quý",
  "newWord.selectTypeReportTable": "Loại Báo cáo",
  "newWord.selectLangReportTable": "Ngôn ngữ",
  "newWord.selectYearTable": "Năm",

  "newWord.QuarterO": "Quý 1",
  "newWord.QuarterT": "Quý 2",
  "newWord.QuarterTh": "Quý 3",
  "newWord.QuarterF": "Quý 4",

  "newWord.Regular": "Thường",
  "newWord.Credit": "Tín dụng",
  "newWord.Undefined": "Không xác định",
  "newWord.Annual": "Hằng năm",
  "newWord.SemiAnnual": "Bán niên",
  "newWord.amountCompanies": "Kết quả tìm kiếm",
  "newWord.categoryFilter": "Nhóm ngành",
  "newWord.yearFilter": "Năm",
  "newWord.companies": "Doanh nghiệp",
  "newWord.noteCart": "Nhập nội dung ghi chú",
  "newWord.downloadSearchResult": "Tải xuống kết quả tìm kiếm",
  "newWord.downloadStatisticResult": "Tải xuống kết quả thống kê",

  "marcro.exchange.rate.currency": "Ngoại tệ",
  "marcro.exchange.rate.petroleum": "Xăng dầu",
  "marcro.exchange.rate.gold": "Giá Vàng",
  "marcro.exchange.rate.price.list": "Bảng giá",
  "marcro.exchange.rate.price.list.date": "Ngày",

  suffixD: " VNĐ",
  suffixK: " Nghìn VNĐ",
  suffixM: " Triệu VNĐ",
  suffixB: " Tỷ VNĐ",
  suffixT: " Nghìn Tỷ VNĐ",

  suffixKP: " Nghìn VNĐ",
  suffixMP: " Triệu VNĐ",
  suffixBP: " Tỷ VNĐ",
  suffixTP: " Nghìn Tỷ VNĐ",

  suffixK0: " Nghìn",
  suffixM0: " Triệu",
  suffixB0: " Tỷ",
  suffixT0: " Nghìn Tỷ",

  suffixBVND: " Tỷ VNĐ",
  suffixKBVND: " Nghìn Tỷ VNĐ",
  suffixMBVND: " Triệu Tỷ VNĐ",
  suffixBBVND: " Tỷ Tỷ VNĐ",
  suffixTBVND: " Nghìn Tỷ Tỷ VNĐ",

  suffixUSD: " USD",
  suffixKUSD: " Nghìn USD",
  suffixMUSD: " Triệu USD",
  suffixBUSD: " Tỷ USD",
  suffixTUSD: " Nghìn Tỷ USD",

  // footer
  "footer.confidentiality": "Bảo mật Thông tin và Dữ liệu",
  "footer.disclaimer": "Điều khoản miễn trừ ",
  "footer.hotline": "Hotline",
  "footer.confidentiality.text1": `BẢO MẬT – THÔNG TIN NÀY ĐƯỢC CÔNG TY CỔ PHẦN NGHIÊN CỨU NGÀNH VÀ TƯ VẤN VIỆT NAM (VIRAC) CUNG CẤP THEO CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN CỦA HỢP ĐỒNG ĐĂNG KÝ VÀ KHÔNG ĐƯỢC CÔNG BỐ.`,
  "footer.confidentiality.text2": `Quyền sử dụng và trích nguồn thông tin và dữ liệu: `,
  "footer.confidentiality.text3": `-	VIRAC đồng ý cho Khách hàng/Người dùng một quyền không độc quyền, không chuyển nhượng để trích dẫn, đăng tải, sao chép, sắp xếp, dịch thuật dữ liệu và thông tin, tạo ra các sản phẩm phái sinh từ dữ liệu và thông tin chỉ cho mục đích sử dụng nội bộ của Khách hàng bao gồm báo cáo phân tích thị trường, báo cáo phân tích ngành, báo cáo phân tích doanh nghiệp, ghi chú, biên soạn, nghiên cứu hoặc các sản phẩm tương tự của Khách hàng. Tuy nhiên, trong tất cả các trường hợp, Khách hàng/Người dùng cần trích nguồn khi sử dụng bất kỳ thông tin, dữ liệu được khai thác từ Báo cáo phân tích tổng hợp thông tin doanh nghiệp của VIRACE với tên nguồn cụ thể, ví dụ “Nguồn: VIRAC” hoặc "Nguồn: VIRACE".`,
  "footer.confidentiality.text4": `-	Khách hàng/Người dùng không được phép sử dụng, sao chép, chia sẻ, tiết lộ hoặc cung cấp bằng lời nói hoặc bằng văn bản hoặc bất cứ hình thức nào khác bất kỳ dữ liệu, thông tin, hình ảnh, nội dung, chức năng hệ thống, thông tin khuyến mại, hợp đồng, thương hiệu, nhãn hiệu, tên thương mại, dấu hiệu dịch vụ, nguồn cơ sở dữ liệu và toàn bộ hoặc một phần nội dung tài liệu hướng dẫn sử dụng và các tài liệu liên quan khác của VIRAC, Hệ thống VIRACE và BÁO CÁO PHÂN TÍCH TỔNG HỢP THÔNG TIN DOANH NGHIỆP của VIRACE cho các mục đích ngoài mục đích sử dụng nội bộ nêu trên, bao gồm phát tán thông tin trên mọi loại kênh thông tin, kênh truyền thông và bằng mọi hình thức, hoặc/và tạo ra nội dung cho các bài báo, bài nhận định, hoặc các xuất bản, phát hành, ấn phẩm khác về VIRAC, Hệ thống VIRACE và Báo cáo phân tích tổng hợp thông tin doanh nghiệp của VIRACE cho nội bộ hoặc cho các bên thứ ba khi chưa có sự chấp thuận trước bằng văn bản của VIRAC.`,
  "footer.disclaimer.text1": `Bản quyền 2020 của Công ty Cổ phần Nghiên Cứu Ngành và Tư Vấn Việt Nam (VIRAC). Đã đăng ký bản quyền.`,
  "footer.disclaimer.text2": `Tất cả thông tin và dữ liệu trên Hệ thống VIRACE và trong báo cáo này được chuẩn bị trên cơ sở rằng các thông tin và dữ liệu được cho là đáng tin cậy tại thời điểm được cung cấp. VIRAC không tuyên bố hay đảm bảo về tính đầy đủ và chính xác của các thông tin và dữ liệu đó. Chúng không nhất thiết phản ánh các kết quả và xu hướng thị trường hoặc ý kiến của VIRAC, và có thể thay đổi mà không cần báo trước.`,
  "footer.disclaimer.text3": `Tất cả thông tin và dữ liệu được cung cấp, với mục đích cung cấp thông tin, cho Khách hàng/Người sử dụng của VIRAC, và không cấu thành nội dung của bảng chào giá hoặc lời mời mua hoặc bán bất kỳ tài sản hoặc/và quyền sở hữu nào được thảo luận ở đây trong bất kỳ định chế pháp lý nào. Khách hàng/Người sử dụng phải đưa ra quyết định dựa trên sự tư vấn độc lập tùy thuộc vào tình hình tài chính và mục tiêu cụ thể của mình. Vui lòng trích dẫn nguồn khi lấy dẫn chứng.`,
  // Link api tygia.com
  "Api.Ngoaite":
    "https://tygia.com/api.php?column=1&title=0&chart=0&gold=0&rate=1&ngoaite=usd,jpy,chf,eur,gbp,hkd&expand=1&color=eeffee&titlecolor=111111&bgcolor=&upcolor=00aa00&downcolor=bb0000&textcolor=333333&nganhang=VIETCOM&fontsize=120&ngay=&column=2&locale=vn",
  "Api.Vang": "https://tygia.com/api.php?column=1&title=0&chart=0&gold=1&rate=0&expand=2&color=284C84&nganhang=VIETCOM&fontsize=120&change=0&locale=vi",
  "exchange.rate": "Tỷ Giá",

  // main menu
  "mainMenu.company1": "phân tích",
  "mainMenu.company2": "doanh nghiệp",
  "mainMenu.industry1": "phân tích",
  "mainMenu.industry2": "ngành",

  // auth
  "auth.login": "Đăng nhập",
  "auth.register": "Đăng ký",

  "auth.forgotPass": "Quên mật khẩu",
  "auth.changePass": "Đổi mật khẩu",
  "auth.remember": "Nhớ đăng nhập",
  "auth.password": "Mật khẩu",
  "auth.repassword": "Xác nhận mật khẩu",

  "auth.forgotPass.text": "Vui lòng nhập email đã đăng ký để hệ thống xác thực và cấp mật khẩu mới",
  "auth.forgotPass.send": "Gửi yêu cầu",
  "auth.enterEmail": "Email",
  "auth.enterPresenter": "Email người giới thiệu",
  "auth.existPresenter": "Email người giới thiệu không tồn tại",
  "auth.enterPhone": "Số điện thoại",
  "auth.enterFirstName": "Tên",
  "auth.enterLastName": "Họ",
  "auth.fullName": "Họ tên",

  "auth.enterCompany": "Công ty/ Nơi công tác",

  "auth.enterNewPass": "Mật khẩu",
  "auth.enterNewPassAgain": "Xác thực mật khẩu",
  "auth.forgotPass.checkMail": "VIRACE đã gửi email reset mật khẩu. Quý khách vui lòng truy cập email để hoàn tất việc đổi mật khẩu.",
  "auth.changePassFail": "Đổi mật khẩu không thành công",
  "auth.haveAcount": "Đã có tài khoản?",
  "auth.notHaveAcount": "Chưa có tài khoản?",
  "auth.wrongPassword": "Mật khẩu sai.",

  "auth.wrongAccount_p1": "Tài khoản ",
  "auth.wrongAccount_p2": " không tồn tại.",
  "auth.lockedAccount_p1": "Tài khoản bị khóa do sai mật khẩu 5 lần liên tiếp. Vui lòng liên hệ tổng đài ",
  "auth.lockedAccount_p2": " hoặc email ",
  // error
  "error.phoneRequired": "Số điện thoại không được để trống.",
  "error.phoneFormatted": "Số điện thoại không hợp lệ.",
  "error.firstNameFormatted": "Tên không đúng dịnh dạng. ",
  "error.firstNameRequired": "Tên không được để trống.",

  "error.lastNameFormatted": "Họ không đúng dịnh dạng.",
  "error.lastNameRequired": "Họ không được để trống.",
  "error.companyFormatted": "Công ty/Nơi công tác không hợp lệ ",

  "error.emailRequired": "Email không được để trống.",
  "error.emailFormatted": "Email không đúng định dạng",
  "error.presenterFormatted": "Email người giới thiệu không hợp lệ",

  "error.passRequired": "Mật khẩu không được để trống.",
  "error.passMinLength": "Mật khẩu phải có tối thiểu 8 ký tự",
  "error.passFormatted": "Mật khẩu phải bao gồm đồng thời ký tự chữ in hoa, ký tự chữ in thường, ký tự số, ký tự đặc biệt và không được chứa dấu cách",
  "error.confirmPassRequired": "Xác nhận mật khẩu không được để trống.",
  "error.confirmPassMatch": "Mật khẩu không trùng khớp.",
  "error.duplicateEmail": "Email đăng ký đã tồn tại.",
  "error.duplicateEmailWarning_p1": "Email đã được sử dụng để đăng ký tài khoản, bạn có thể ",
  "error.duplicateEmailWarning_p2": "đăng nhập ",
  "error.duplicateEmailWarning_p3": "hoặc ",
  "error.duplicateEmailWarning_p4": "khôi phục mật khẩu ",
  "error.duplicateEmailWarning_p5": "để tiếp tục sử dụng.",

  "error.wrongPresenter": "Email người giới thiệu không tồn tại.",

  // breadcrumb
  "breadcrumb.home": "Trang chủ",
  "breadcrumb.menu-dieu-huong": "Menu điều hướng",
  "breadcrumb.trang-chu-cr": "Trang chủ CR",
  "breadcrumb.trang-chu-if-ir": "Trang chủ IF-IR",
  "breadcrumb.chi-tiet-doanh-nghiep": "Chi tiết doanh nghiệp",
  "breadcrumb.ket-qua-tra-cuu-thong-tin-doanh-nghiep": "Kết quả tra cứu thông tin doanh nghiệp",
  "breadcrumb.tim-kiem-danh-sach-doanh-nghiep": "Tìm kiếm danh sách doanh nghiệp",
  "breadcrumb.ket-qua-tra-cuu-thong-tin-nganh": "Kết quả tra cứu thông tin ngành",
  "breadcrumb.danh-sach-bao-cao-nganh": "Danh sách báo cáo ngành",
  "breadcrumb.quan-li-tai-khoan": "Quản lý tài khoản",
  "breadcrumb.cart": "Báo cáo quan tâm",

  viewMap: "Xem bản đồ",
  goHomePage: "Về trang chủ",
  "search.notfound.title": "Lỗi tìm kiếm",
  "search.notfound.text": "Không tìm thấy kết quả phù hợp. Vui lòng thử lại!",
  "search.notfound.text-1": "Không tìm thấy kết quả phù hợp.",
  "search.notfound.text-2": "Vui lòng thử lại!",

  "notfound.text": "Không tìm thấy kết quả phù hợp",

  "company.downloadReport": "Tải báo cáo DN",
  copyright: "Bản quyền",
  company: "Doanh nghiệp",
  selected: "Đã chọn!",

  "report.company": "Báo cáo doanh nghiệp",
  "report.industry": "Báo cáo ngành",
  "report.CompanySnapshot.title": "Báo cáo tổng quan doanh nghiệp",
  "report.CompanySnapshot.content": "Thông tin cơ bản của một doanh nghiệp cùng với một số chỉ tiêu chủ yếu và cơ chế hoạt động của doanh nghiệp.",
  "report.CompanyExperditious.title": "Báo cáo doanh nghiệp giản lược",
  "report.CompanyExperditious.content":
    "Thông tin tổng quan của doanh nghiệp cùng với các dữ liệu đa chiều, bao gồm báo cáo phân tích tài chính, cấu trúc chủ sở hữu, tình hình xuất nhập khẩu và các chỉ số khác.",

  "report.StandardCompany.title": "Báo cáo doanh nghiệp tiêu chuẩn",
  "report.StandardCompany.content":
    "Đánh giá toàn diện về tình hình hoạt động, thị trường, rủi ro kinh doanh, tiềm năng và sức khỏe tài chính của doanh nghiệp.",
  "report.ComprehensiveCompany.title": "Báo cáo doanh nghiệp chuyên sâu",
  "report.ComprehensiveCompany.content":
    "Báo cáo toàn diện, nhận xét đa chiều về một doanh nghiệp, bao gồm quá trình phát triển, cấu trúc chủ sở hữu, nhận định ngành và nhiều dữ liệu quan trọng khác.",
  "report.template": "File mẫu",
  reportFromVirac: "Báo cáo tài chính nguồn Virac",

  // advance search
  "advanceSearch.title": "Tìm kiếm danh sách doanh nghiệp",
  "advanceSearch.area": "Khu vực và năm",
  "advanceSearch.industry": "Ngành",
  "advanceSearch.companyType": "Loại hình doanh nghiệp",
  "advanceSearch.ownership": "Tính chất sở hữu/quản lý",
  "advanceSearch.finance": "Tài chính",
  "advanceSearch.importExport": "Xuất/nhập khẩu",

  "advanceSearch.info": "Danh mục thông tin",
  "advanceSearch.selectSearchTerms": "Chọn điều kiện tìm kiếm",
  "advanceSearch.searchTerms": "Điều kiện tìm kiếm",
  "advanceSearch.addTerms": "Thêm điều kiện",
  "advanceSearch.area.addTermsCondition": "Khu vực không tồn tại hoặc giai đoạn dữ liệu trống",
  "advanceSearch.area.addTermsMax": "Quý khách chỉ được lựa chọn tối đa 10 điều kiện",
  "advanceSearch.area.addTermsMax2": "Quý khách chỉ được lựa chọn tối đa 2 điều kiện",
  "advanceSearch.area.addTermsMax5": "Quý khách chỉ được lựa chọn tối đa 5 điều kiện",
  "advanceSearch.area.dataPoint": "Giai đoạn dữ liệu",
  "advanceSearch.finance.dataPoint": "Năm tài chính",
  "advanceSearch.importExport.dataPoint": "Loại hình",
  "advanceSearch.importExport.import": "Nhập khẩu",
  "advanceSearch.importExport.export": "Xuất khẩu",
  "advanceSearch.importExport.addCriteria": "Thêm giá trị",

  "advanceSearch.importExport.value": "Giá trị",
  "advanceSearch.importExport.majorGoods": "Mặt hàng tiêu biểu",
  "advanceSearch.importExport.hsCode": "Mã HS",
  "advanceSearch.importExport.requiredType": "Chọn ít nhất 01 loại hình!",
  "advanceSearch.importExport.requiredValueOrHs": "Chưa chọn Giá trị hoặc Mặt hàng tiêu biểu.",

  "advanceSearch.area.year": "Năm",
  "advanceSearch.area.latestQuater": "Quý mới nhất",
  "advanceSearch.area.area": "Khu vực",
  "advanceSearch.area.add": "Thêm khu vực",
  "advanceSearch.companyType.companyType": "Loại hình doanh nghiệp",
  "advanceSearch.legalRepresentative": "Đại diện pháp luật",
  "advanceSearch.legalRepresentative.placeHoder": "Nhập thông tin tìm kiếm ( CMND/CCCD/Hộ chiếu/Tên người đại diện pháp luật)",
  "advanceSearch.owner": "Chủ sở hữu",
  "advanceSearch.owner.placeHoder": "Nhập thông tin tìm kiếm ( CMND/CCCD/Hộ chiếu/Mã số thuế/Tên chủ sở hữu)",
  "advanceSearch.province": "Tỉnh/Thành phố",
  "advanceSearch.district": "Quận/Huyện",
  "advanceSearch.commune": "Phường/Xã",
  "advanceSearch.term": "Điều kiện",
  "advanceSearch.downloadCompany": "Tải danh sách",
  "advanceSearch.industry.list": "Danh sách ngành",
  "advanceSearch.companyTypes.list": "Danh sách loại hình doanh nghiệp",

  "advanceSearch.industry.addTermsCondition": "Ngành không tồn tại hoặc giai đoạn dữ liệu trống",
  "advanceSearch.typeCompany.addTermsCondition": "Chọn loại hình doanh nghiệp không có trong danh sách hoặc chưa chọn giai đoạn dữ liệu",

  "advanceSearch.finance.from": "Từ",
  "advanceSearch.finance.to": "Đến",
  "advanceSearch.finance.criteria": "Chỉ tiêu",
  "advanceSearch.finance.addCriteria": "Thêm chỉ tiêu",
  "advanceSearch.finance.addTermsCondition": "Chỉ tiêu không tồn tại hoặc năm tài chính trống",
  "advanceSearch.finance.required": " Vui lòng nhập giá trị từ hoặc đến hợp lệ!",
  "advanceSearch.no-searching-criteria": "Danh sách điều kiện trống",

  "crhome.table1": "Doanh nghiệp truy cập gần nhất",
  "crhome.chart1": "Doanh thu của top doanh nghiệp thuộc các ngành được truy cập nhiều nhất trong 30 ngày",
  "crhome.chart2": "Tỷ lệ doanh nghiệp phân theo quy mô của 5 ngành được truy cập nhiều nhất trong 30 ngày",
  "crhome.chart3": "Tỷ lệ doanh nghiệp phân theo quy mô của 5 ngành được truy cập nhiều nhất trong 30 ngày bởi tất cả người dùng Virace",
  "crhome.netRevenue": "Doanh thu thuần",
  "crhome.netRevenueGrowthRate": "Tăng trưởng doanh thu thuần",
  "crhome.5yRevenueCAGR": "5y - CAGR doanh thu thuần",
  "crhome.3yRevenueCAGR": "3y - CAGR doanh thu thuần",
  "crhome.vsic": "Mã ngành VSIC",

  "vsicList.code": "Mã ngành",
  "vsicList.industryName": "Tên ngành tiếng Việt",
  "vsicList.industryEnName": "Tên ngành tiếng Anh",

  "virac.businessRegistration": "Giấy chứng nhận đăng ký kinh doanh số: 0106972372",
  "virac.businessRegistrationBy": "Do Sở kế hoạch và đầu tư TP.Hà Nội cấp ngày 31/08/2015",
  "virac.follow": "Theo dõi VIRAC:",

  companyReportFileName: "Bao_cao_doanh_nghiep.pdf",

  "industry.filter.all": "Tất cả",
  "industry.filter.statistic": "Thống kê",
  "industry.filter.report": "Báo cáo",
  "industry.filter.input": "Nhập từ khóa tìm kiếm",
  "industry.filter.input.minLength": "Quý khách vui lòng tìm kiếm tối thiểu 2 ký tự",
  "industry.view": "Tìm hiểu thêm",
  "industry.statistic.basic": "Thống kê cơ bản",
  "industry.statistic.SO_DOANH_NGHIEP": "Số doanh nghiệp",
  "industry.statistic.SO_LAO_DONG": "Số lao động",
  "industry.statistic.NGUON_VON_BINH_QUAN": "Nguồn vốn bình quân",
  "industry.statistic.TAI_SAN_CO_DINH_VA_DAU_TU_DAI_HAN_BINH_QUAN": "Tài sản cố định và đầu tư dài hạn bình quân",
  "industry.statistic.DOANH_THU_THUAN": "Doanh thu thuần",
  "industry.statistic.LOI_NHUAN_TRUOC_THUE": "Lợi nhuận trước thuế",
  "industry.statistic.THUE_VA_CAC_KHOAN_NOP_NGAN_SACH": "Thuế và các khoản đã nộp ngân sách",
  "industry.statistic.advance": "Thống kê nâng cao",
  "industry.statistic.SAN_XUAT": "Sản xuất",
  "industry.statistic.TIEU_THU": "Tiêu thụ",
  "industry.statistic.TON_KHO": "Tồn kho",
  "industry.report.more": "Thêm báo cáo",
  "industry.statistic.tab.data": "Dữ liệu",
  "industry.statistic.tab.relatedRatio": "Chỉ số liên quan",
  "industry.statistic.tab.relatedReport": "Báo cáo liên quan",
  "industry.statistic.tab.table": "Bảng",
  "industry.statistic.tab.chart": "Biểu đồ",
  "industry.statistic.tab.addVSIC": "Thêm mã VSIC",
  "industry.statistic.typeList.relative": "Tăng trưởng so với cùng kỳ",
  "industry.statistic.typeList.absolute": "Giá trị tuyệt đối",
  "industry.statistic.typeList.cagr": "CAGR 5 năm",
  "industry.statistic.total": "Tổng",
  "industry.statistic.average": "Trung bình",
  "industry.statistic.relative": "Tuyệt đối",

  "industry.statistic.table.type": "Loại dữ liệu",
  vsicId: "Mã VSIC",
  vsicName: "Tên ngành",
  unit: "Đơn vị",
  "industry.statistic.sortAZ": "Sắp xếp A-Z",
  "industry.statistic.sortZA": "Sắp xếp Z-A",
  "industry.statistic.clearFilter": "Xóa bộ lọc",
  "industry.statistic.input": "Tìm kiếm...",
  "industry.statistic.add-vsic-max": "Vượt quá số lượng mã VSIC cho phép thêm",
  "industry.statistic.add-product-max": "Vượt quá số lượng mã hàng hóa cho phép thêm",
  "industry.statistic.product-code": "Mã hàng hóa",
  "industry.statistic.product-des": "Mô tả hàng hóa",
  "industry.statistic.product": "Hàng hóa",
  "industry.statistic.chart.vsic-min": "Chọn tối thiểu 1 ngành",
  "industry.statistic.chart.vsic-max": "Chọn tối đa 3 ngành",
  "industry.statistic.chart.statistic-min": "Chọn tối thiểu 1 loại dữ liệu",
  "industry.statistic.chart.year-min": "Chọn tối thiểu 1 năm",
  "industry.statistic.chart.year-max": "Chọn tối đa 5 năm",
  "industry.report.menu": "Mục lục",
  "industry.report.chapter": "Chương",
  "industry.statistic-advance.chart.vsic-min": "Số lượng hàng hóa * số lượng khu vực tối thiểu là 1",
  "industry.statistic-advance.chart.vsic-max": "Số lượng hàng hóa * số lượng khu vực tối đa là 3",
  "industry.statistic.chart.product-min": "Chọn tối thiểu 1 hàng hóa",
  "industry.statistic.chart.area-min": "Chọn tối thiểu 1 khu vực",

  "industry.statistic.tabRatio.GDP": "GDP (Tỷ đồng)",
  "industry.statistic.tabRatio.Paddy": "Lúa cả năm",
  "industry.statistic.tabRatio.INDUSTRIAL_PRODUCTION": "CHỈ SỐ SẢN XUẤT TOÀN NGÀNH CÔNG NGHIỆP (%)",
  "industry.statistic.tabRatio.TOTAL_SALES": "TỔNG MỨC BÁN LẺ HÀNG HOÁ VÀ DOANH THU DỊCH VỤ TIÊU DÙNG (Tỷ đồng)",
  "industry.statistic.tabRatio.TRANSPORTATION": "VẬN TẢI HÀNH KHÁCH VÀ HÀNG HOÁ",
  "industry.statistic.tabRatio.INVESMENT": "VỐN ĐẦU TƯ (Nghìn tỷ đồng)",

  "industry.downloadReport": "Tải báo cáo",
  "industry.reportNoContent": "Báo cáo chưa có nội dung",
  "cart.no-data": "Danh sách trống",
  "cart.no-selected": "Vui lòng chọn đối tượng",
  "cart.is-company": "Chỉ mua được những báo cáo có đơn giá sẵn",
  "advance-search.company.tooltip.value": "Giá trị trường điều kiện",
  "industry.report.chapter-not-found": "Không tồn tại chương này. Vui lòng kiểm tra lại."
};
