import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import HomeHeadInfo from "../Header/HomeHeadInfo";
import { _ForeignAccount_ } from "../../constants/rolesModel";

export class HomeHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      checkPropsChildren: this.props.children ? true : false
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  back() {
    this.props.history.goBack();
  }
  forward() {
    this.props.history.goForward();
  }

  showBackAndForward() {
    const URL_HIDDEN = "https://viracresearch.com";
    let value = false;

    if (document && document.location && document.location.ancestorOrigins) {
      let ancestorOrigins = Array.from(document.location.ancestorOrigins);
      ancestorOrigins.map((item) => {
        if (item.includes(URL_HIDDEN)) {
          value = true;
        }
      });
    } else {
      value = false;
    }
    return value;
  }

  render() {
    let role;
    if (this.props && this.props.userAccount) {
      role = this.props.userAccount.roleClient;
    }
    // var mainUrl = localStorage.getItem("access_token") && role && role !== _ForeignAccount_ ? "/menu-dieu-huong" : "/trang-chu-if-ir";
    let mainUrl = "/company-search";
    return (
      <div className="view_header_home">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <HomeHeadInfo {...this.props} isLogged={this.props.isLooged} isPropsChildren={this.state.checkPropsChildren} />
      </div>
    );
  }
}

export default injectIntl(HomeHead);
