import React, { Component } from "react";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { Radar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { numberFormat } from "../../utils/numberFormat";
Chart.register(...registerables);

class Radarchart extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    render() {
        const { dataChart, labels } = this.props;
        const data = {
            labels: labels,
            datasets: [{
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: '#EC5657',
                pointRadius: 2,
                pointHoverRadius: 5,
                pointBackgroundColor: '#EC5657',
                pointBorderColor: '#EC5657',
                pointHoverBackgroundColor: '#EC5657',
                pointHoverBorderColor: '#EC5657',
                data: dataChart[0].data,
                label: dataChart[0].label,
            }]
        };
        const options = {
            elements: {
                line: {
                    "tension": 0,
                    "borderWidth": 2
                }
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    labels: {
                        color: '#B6C4DE'
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            return `${tooltipItem.dataset.label}: ${numberFormat(tooltipItem.raw)}%`
                        }
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        color: "rgba(144, 155, 175, 0.3)",
                    },
                    pointLabels: {
                        color: '#B6C4DE',
                        font: {
                            size: 12
                        }
                    },
                    ticks: {
                        stepSize: 30,
                        backdropColor: "rgba(0, 0, 0, 0)"
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
        }
        return (
            <React.Fragment>
                <div className="column-multiple-chart-wrapper">
                    <div className="radar">
                        <Radar data={data} options={options} />
                    </div>
                    <div className="cright"><IntlMessages id="common.sourceVirac" /></div>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(Radarchart);
