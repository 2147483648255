import { callApiServer } from "../utils/callApiServer";
import { HistoryAccessed, HistoryRequest, notification } from "../constants/pageSize";
// User account - thông tin tài khoản

export const actGetUserAccountApi = () => {
  return callApiServer.get("/Users/profile");
};

// History Request - lịch sử yêu cầu

export const actGetHistoryRequestedApi = (body) => {
  const params = {
    pageIndex: body.page,
    pageSize: HistoryRequest,
    status: body.status,
    searchText: body.searchText
  };
  return callApiServer.get("/Order/RequestHistory", { params: params });
};
export const actGetHistoryRequestedOrderAttachment = (orderID) => {
  const params = {
    OrderId: orderID
  };
  return callApiServer.get("/Order/Order_Attachment", { params: params });
};
export const actGetHistoryRequestedOrderDownload = (model) => {
  const params = {
    OrderId: model.OrderId,
    name: model.name
  };
  return callApiServer.get("/Order/Order_Download", { params: params });
};
export const actGetHistoryRequestedSearchApi = (txtSearch) => {
  const params = {
    search: txtSearch,
    pageIndex: 1,
    pageSize: HistoryRequest
  };
  return callApiServer.get("/RequestHistory_Search", { params: params });
};

//History Accessed - lịch sử truy cập

export const actGetHistoryAccessedApi = (page) => {
  const params = {
    pageIndex: page,
    pageSize: HistoryAccessed
  };
  return callApiServer.get("/AuditLog/AccessHistory", { params: params });
};

//Notification - thông báo

export const actGetNotificationsApi = (page) => {
  const params = {
    pageIndex: page,
    pageSize: notification
  };
  return callApiServer.get("/message/MessageList", { params: params });
};

export const actDeleteNotificationsApi = (id) => {
  return callApiServer.delete("/message/Delete/" + id);
};

export const actChangeStatusSeenMessageApi = (id) => {
  return callApiServer.get("/message/isSeenMessage/" + id);
};

//Change Password - Đổi mật khẩu

export const actChangePasswordApi = (body) => {
  return callApiServer.post("/Users/ChangePassword", body);
};

//Change LANG

export const actChangeLang = (body) => {
  return callApiServer.post("/Users/language", body);
};
