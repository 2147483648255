import React, { Component } from "react";
import Iframe from "react-iframe";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import Header from "../../components/Header/HomeHead";
import Footer from "../../components/Footer/Footer";
export class Contacts extends Component {
  componentDidMount() {
    document.title = "VIRACE | Contact";
  }
  render() {
    return (
      <div className="_view_contacts">
        <Header history={this.props.history} />
        <main className="contacts-container">
          <div className="card-item mb-4">
            <h1 className="company-name">
              <IntlMessages id="newWord.researchStock" />
            </h1>
            <div className="row">
              <div className="col-xl-6">
                <div className="contact-info">
                  <div className="contact-info-item">
                    <span className="material-icons">feed</span>
                    <IntlMessages id="virac.businessRegistration" />
                  </div>
                  <div className="contact-info-item">
                    <span className="material-icons">feed</span>
                    <IntlMessages id="virac.businessRegistrationBy" />
                  </div>
                  <div className="contact-info-item">
                    <span className="material-icons">email</span>
                    <a className="sendMail" href={"mailto:viracresearch@virac.com.vn?Subject=Hello%20Virac"} target="_top">
                      viracresearch@virac.com.vn
                    </a>
                  </div>
                  <div className="contact-info-item">
                    <span className="material-icons">public</span>
                    <a className="" href={"https://www.viracresearch.com/"} rel="noopener noreferrer" target="_blank">
                      viracresearch.com
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="contact-info">
                  <div className="contact-info-item social">
                    <span className="material-icons">link</span>
                    <IntlMessages id="virac.follow" />
                    <a href="https://www.facebook.com/VIRAC.Research/" target="_blank" rel="nofollow external noopener noreferrer">
                      <i className="fab fa-facebook-square"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/vietnam-industry-research-and-consultancy/"
                      target="_blank"
                      rel="nofollow external noopener noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="https://twitter.com/ViracResearch/" target="_blank" rel="nofollow external noopener noreferrer">
                      <i className="fab fa-twitter-square"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row equal-height">
            <div className="col-lg-6 mb-4">
              <div className="card-item">
                <div className="row">
                  <div className="col-12 pb-4">
                    <div className="contact-info">
                      <h2>
                        <IntlMessages id="newWord.Headquater" />
                      </h2>
                      <div className="contact-info-item">
                        <span className="material-icons">place</span>
                        <IntlMessages id="newWord.addressHeadquater" />
                      </div>
                      <div className="contact-info-item">
                        <span className="material-icons">phone</span>
                        <a href="tel:+842463289520">+84 24 6328 9520</a>
                        <span className="hotline">
                          <i className="fas fa-caret-right"></i>Hotline:
                          <a href="tel:+0938677566">0938 677 566</a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="contact-map">
                      <Iframe
                        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.3369319061308!2d105.8218025154835!3d21.01920039347924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab797c957f3d%3A0x1d2df1fd12007594!2sAnh%20Minh%20Building!5e0!3m2!1svi!2s!4v1568872982704!5m2!1svi!2s"
                        width="100%"
                        height="250px"
                        id="myId"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="card-item">
                <div className="row">
                  <div className="col-12 pb-4">
                    <div className="contact-info">
                      <h2>
                        <IntlMessages id="newWord.representativeOffice" />
                      </h2>
                      <div className="contact-info-item">
                        <span className="material-icons">place</span>
                        <IntlMessages id="newWord.addressOffice" />
                      </div>
                      <div className="contact-info-item">
                        <span className="material-icons">phone</span>
                        <a href="tel:+842866808523">+84 28 6680 8523</a>
                        <span className="hotline">
                          <i className="fas fa-caret-right"></i>Hotline:
                          <a href="tel:+0938677566">0938 677 566</a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="contact-map">
                      <Iframe
                        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.296914229829!2d106.6858608153343!3d10.788556161918484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f2d5f8cf859%3A0x51b5fe1e48c23bcd!2zMjEgSHXhu7NuaCBU4buLbmggQ-G7p2EsIFBoxrDhu51uZyA4LCBRdeG6rW4gMywgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1668066790925!5m2!1svi!2s"
                        width="100%"
                        height="250px"
                        id="myId"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default injectIntl(Contacts);
