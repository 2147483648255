import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import { Checkbox } from "semantic-ui-react";
import Linechart from "../../../components/ChartDemo/Linechart";
import LoaderTable from "../../../components/LoaderTable";
import AlertWarning from "../../../components/SweetAlert/AlertWarning";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { actGetTargetCODECheckbox, actRequestTarget, actRequestTargetDelete, actRequestTargetINIT } from "../../../redux/actions";
import Button from "../../../components/CustomButton/CustomButton";
import { _Standard_, _Premium_ } from "../../../constants/rolesModel";
import NoData from "../../../components/NoData";
export class TabTargets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      isCheckedTarget: true,
      selectedRadio: "radio-1",
      selectedInputChart: [],
      isExpand: true,
      alert: ""
    };
  }

  handleRefresh = () => {
    this.setState({
      isChecked: false,
      selectedInputChart: []
    });
  };

  componentDidMount() {
    if (this.props.companyDetailReducer.id !== null && this.props.companyDetailReducer.id !== undefined) {
      this.props.getTargetCODECheckbox(this.props.companyDetailReducer.id);
      const { id, set, roleName } = this.props.companyDetailReducer;
      this.props.actRequestTargetINIT(id, set, roleName);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.intl.locale !== nextProps.intl.locale) {
      if (this.props.companyDetailReducer.id !== null && this.props.companyDetailReducer.id !== undefined) {
        this.props.getTargetCODECheckbox(this.props.companyDetailReducer.id);
        const { id, set, roleName } = this.props.companyDetailReducer;
        this.props.actRequestTargetINIT(id, set, roleName);
      }
    }
    const { target, limitChartTG } = nextProps.dataReducer.dataTarget;
    if (target && target.length > 0) {
      if (limitChartTG) {
        this.alertNumChart();
      }
    }
  }

  alertNumChart = () => {
    const { messages } = this.props.intl;
    this.actAlert(messages[`newWord.alertSelected8Chart`]);
  };

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  };

  funcUntilFilter(arr) {
    const newSelectedInputChart = [...this.state.selectedInputChart];
    for (var i = 0; i < arr.length; i++) {
      const index = newSelectedInputChart.indexOf(arr[i].code);
      if (index >= 0) {
        newSelectedInputChart.splice(index, 1);
      }
    }
    return newSelectedInputChart;
  }

  toggleChangeChildRadio = (e) => {
    this.setState({ selectedRadio: e.target.value });
  };

  handleDeleteChart = (prams) => {
    this.props.actRequestTargetDelete(prams);
  };

  handleApplyChart = () => {
    const { selectedInputChart, isChecked } = this.state;
    const { messages } = this.props.intl;
    if (this.props.id !== null && this.props.id !== undefined) {
      if (selectedInputChart.length === 0) {
        this.actAlert();
      } else if (selectedInputChart.length > 8) {
        this.actAlert(messages[`newWord.alert8Target`]);
      } else {
        const model = {
          id: this.props.id,
          arrHeso: selectedInputChart,
          arrKv: [],
          isChecked: isChecked
        };
        this.props.requestTarget(model);
      }
    }
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params ? params : messages[`newWord.alertSelectedxAccount`]}
        </SweetAlert>
      )
    });
  };

  actDeleteChart = (prams) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <AlertWarning
          message={messages[`newWord.AlertRemoveChart`]}
          confirmBtnText={messages[`user.Confirm`]}
          cancelBtnText={messages[`user.Cancel`]}
          title={messages[`user.Confirm`]}
          confirmToFunc={() => {
            this.handleDeleteChart(prams);
            this._hideAlert();
          }}
          cancleToFunc={this._hideAlert}
        />
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  // click chọn chỉ tiêu
  handleChange = (codeInput) => {
    const index = this.state.selectedInputChart.indexOf(codeInput);
    if (index < 0) {
      this.state.selectedInputChart.push(codeInput);
    } else {
      this.state.selectedInputChart.splice(index, 1);
    }
    this.setState({ selectedInputChart: [...this.state.selectedInputChart] });
  };

  toggleExpand = () => {
    this.setState({
      ...this.state,
      isExpand: !this.state.isExpand
    });
  };

  render() {
    const { selectedInputChart } = this.state;
    const { loadingTGChart, loadingTGCheckBox, targetCode, target } = this.props.dataReducer.dataTarget;
    const { justView } = this.props;
    const roles = _Premium_;
    const { loadingDT } = this.props.dataReducer;
    return (
      <React.Fragment>
        {this.state.alert}
        {loadingTGCheckBox && loadingDT ? (
          <React.Fragment>
            <div ref={(el) => (this.componentRef = el)} className="custom-target">
              <div className="company_target_views">
                {!justView && (
                  <div className="card-item mb-4">
                    <div className="card-title card-title-line">
                      <IntlMessages id="submenu.Accounts" />
                      <div className="toogle-icon-container" onClick={this.toggleExpand}>
                        <span className="material-icons">{this.state.isExpand ? "keyboard_arrow_up" : "keyboard_arrow_down"}</span>
                      </div>
                    </div>
                    <Collapse isOpen={this.state.isExpand}>
                      {/* chỉ tiêu thường */}
                      <div className={roles && targetCode.chiTieu && targetCode.chiTieu.length > 0 ? "row mb-3" : ""}>
                        {roles && targetCode.chiTieu && targetCode.chiTieu.length > 0 && (
                          <React.Fragment>
                            {/* <div className="row-title col-12"><IntlMessages id="common.target" /></div> */}
                            {targetCode.chiTieu.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name}
                                    id={props.code}
                                    checked={selectedInputChart.includes(props.code)}
                                    onChange={() => this.handleChange(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* chỉ tiêu tín dụng */}
                      <div className={roles && targetCode.tinDung && targetCode.tinDung.length > 0 ? "row mb-3" : ""}>
                        {roles && targetCode.tinDung && targetCode.tinDung.length > 0 && (
                          <React.Fragment>
                            {/* <div className="row-title col-12"><IntlMessages id="common.target" /></div> */}
                            {targetCode.tinDung.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name}
                                    id={props.code}
                                    checked={selectedInputChart.includes(props.code)}
                                    onChange={() => this.handleChange(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* bảng cân đối kế toán */}
                      <div className={roles && roles === _Premium_ && targetCode.cacChiTieuKhac && targetCode.cacChiTieuKhac.length > 0 ? "row mb-3" : ""}>
                        {roles && roles === _Premium_ && targetCode.cacChiTieuKhac && targetCode.cacChiTieuKhac.length > 0 && (
                          <React.Fragment>
                            <div className="row-title col-12">
                              <IntlMessages id="common.bcdkt" />
                            </div>
                            {targetCode.cacChiTieuKhac.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name ? props.name : ""}
                                    id={props.code ? props.code : ""}
                                    checked={selectedInputChart.includes(props.code)}
                                    onChange={() => this.handleChange(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* báo cáo kết quả hoạt động kinh doanh */}
                      <div className={roles && roles === _Premium_ && targetCode.income_Statement && targetCode.income_Statement.length > 0 ? "row mb-3" : ""}>
                        {roles && roles === _Premium_ && targetCode.income_Statement && targetCode.income_Statement.length > 0 && (
                          <React.Fragment>
                            <div className="row-title col-12">
                              <IntlMessages id="common.bckqhd" />
                            </div>
                            {targetCode.income_Statement.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name ? props.name : ""}
                                    id={props.code ? props.code : ""}
                                    checked={selectedInputChart.includes(props.code)}
                                    onChange={() => this.handleChange(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* báo cáo lưu chuyển tiền tệ trực tiếp */}
                      <div
                        className={
                          roles && roles === _Premium_ && targetCode.direct_Cash_Flow_Statement && targetCode.direct_Cash_Flow_Statement.length > 0
                            ? "row mb-3"
                            : ""
                        }
                      >
                        {roles && roles === _Premium_ && targetCode.direct_Cash_Flow_Statement && targetCode.direct_Cash_Flow_Statement.length > 0 && (
                          <React.Fragment>
                            <div className="row-title col-12">
                              <IntlMessages id="common.bclctt" />
                            </div>
                            {targetCode.direct_Cash_Flow_Statement.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name ? props.name : ""}
                                    id={props.code ? props.code : ""}
                                    checked={selectedInputChart.includes(props.code)}
                                    onChange={() => this.handleChange(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      {/* báo cáo lưu chuyển tiền tệ gián tiếp */}
                      <div
                        className={
                          roles && roles === _Premium_ && targetCode.indirect_Cash_Flow_Statement && targetCode.indirect_Cash_Flow_Statement.length > 0
                            ? "row mb-3"
                            : ""
                        }
                      >
                        {roles && roles === _Premium_ && targetCode.indirect_Cash_Flow_Statement && targetCode.indirect_Cash_Flow_Statement.length > 0 && (
                          <React.Fragment>
                            <div className="row-title col-12">
                              <IntlMessages id="common.bclcttgt" />
                            </div>
                            {targetCode.indirect_Cash_Flow_Statement.map((props, key) => {
                              return (
                                <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                  <Checkbox
                                    label={props.name ? props.name : ""}
                                    id={props.code ? props.code : ""}
                                    checked={selectedInputChart.includes(props.code)}
                                    onChange={() => this.handleChange(props.code)}
                                  />
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                      <div className="company-area-head region-submit mt-4">
                        <Button className="refresh-btn common-button-2" onClick={() => this.handleRefresh()} disabled={selectedInputChart.length === 0}>
                          <IntlMessages id="common.refresh" />
                        </Button>
                        <Button className="aplly-btn common-button ml-3" onClick={this.handleApplyChart} disabled={loadingTGChart} isLoading={loadingTGChart}>
                          <IntlMessages id="common.Apply" />
                        </Button>
                      </div>
                    </Collapse>
                  </div>
                )}

                {loadingTGChart && <LoaderTable isLoaded={!loadingTGChart} styleCustom={{ height: "500px" }} />}
                <div className="company-chart row equal-height" style={loadingTGChart ? { display: "none" } : {}}>
                  {target &&
                    target.length > 0 &&
                    target.map((props, key) => {
                      return (
                        <div className="col-md-6 col-lg-4 col-xl-3 pb-4" key={key}>
                          <div className="line-chart card-item">
                            <span className="material-icons close-icon" onClick={() => this.actDeleteChart(key)}>
                              close
                            </span>
                            <Linechart dataChart={props} enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)} />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {target && target.length === 0 && (
                  <div className="card-item mb-4">
                    <NoData />
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <LoaderTable isLoaded={loadingTGCheckBox && loadingDT} styleCustom={{ height: "50vh" }} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataStorageReducer: state.dataStorageReducer,
    dataReducer: state.companyReducer,
    commonReducer: state.CommonReducer,
    companyDetailReducer: state.companyReducer.companyDetail
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // DS chỉ tiêu
    getTargetCODECheckbox: (id) => {
      dispatch(actGetTargetCODECheckbox(id));
    },
    // Vẽ chart mặc định
    actRequestTargetINIT: (id, set, roleName) => {
      dispatch(actRequestTargetINIT(id, set, roleName));
    },
    // Vẽ chart
    requestTarget: (body) => {
      dispatch(actRequestTarget(body));
    },
    // Xóa chart
    actRequestTargetDelete: (id) => {
      dispatch(actRequestTargetDelete(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabTargets));
