import * as Types from "../actions";

//GET - Sample Report
export const actGetSampleReport = () => {
    return {
        type: Types.GET_SAMPLE_REPORT,
    }
}
//GET - Select Industry
export const actGetListIndustry = () => {
    return {
        type: Types.GET_LIST_SELECT_INDUSTRY,
    }
}
//GET - Select Year
export const actGetListIndustryYear = () => {
    return {
        type: Types.GET_LIST_SELECT_INDUSTRY_YEAR,
    }
}
