import * as Types from "../actions";
import _ from "lodash";

// const getDataLocal = !localStorage.getItem("carts") ? [] : null ? [] : JSON.parse(localStorage.getItem("carts"));
const INIT_STATE = {
  dataRequired: {
    dataRequest: [],
    userID: null
  },
  total: 0,
  message: null,
  loadingCart: true,
  loadingSend: true,

  createOrderLoading: false,
  createRequestLoading: false,
  getOrderLoading: false,
  orderInfo: {}
};

var findIndex = (carts, arrToDel) => {
  let result = -1;
  carts.forEach((carts, index) => {
    if (carts.requestData === arrToDel.requestData && carts.id === arrToDel.id && carts.lang === arrToDel.lang) {
      result = index;
    }
  });
  return result;
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_DATA_FROM_LOCALSTORAGE:
      // let { objLocal } = action.payload;
      var temp = !localStorage.getItem("carts") ? [] : JSON.parse(localStorage.getItem("carts"));
      let data = [];
      // let b = _.findIndex(temp, function(e) {
      //   // return e.userID === objLocal.userID;
      // });
      // if (b === -1) {
      //   data = objLocal.dataRequest;
      // } else {
      //   data = temp[b].dataRequest;
      // }
      if (temp && temp.length && temp[0].dataRequest) {
        data = temp[0].dataRequest;
      }
      return {
        ...state,
        dataRequired: {
          dataRequest: [...data],
          // userID: objLocal.userID
          userID: null
        },
        loadingCart: false
      };

    case Types.ADD_TO_CART:
      const { model } = action.payload;
      var tempx = !localStorage.getItem("carts") ? [] : JSON.parse(localStorage.getItem("carts"));
      _.filter(tempx, (item) => {
        // if (item.userID === state.dataRequired.userID) {
        //   return (state.dataRequired.dataRequest = item.dataRequest);
        // }
        if (item.userID === null || item.userID === undefined) {
          return (state.dataRequired.dataRequest = item.dataRequest);
        }
      });
      var arr = [...state.dataRequired.dataRequest];
      if (arr.length === 0) {
        arr.push(model);
      } else {
        let index = arr.findIndex((e) => e.requestData === model.requestData && e.id === model.id && e.lang === model.lang);
        if (index === -1) {
          arr.push(model);
        } else {
          arr[index] = model;
        }
      }

      // var a = tempx.findIndex((e) => e.userID === state.dataRequired.userID);
      var a = tempx.findIndex((e) => e.userID === null || e.userID === undefined);

      if (a === -1) {
        tempx.push({ dataRequest: arr });
        localStorage.setItem("carts", JSON.stringify(tempx));
      } else {
        tempx[a].dataRequest = arr;
        localStorage.setItem("carts", JSON.stringify(tempx));
      }
      return {
        dataRequired: {
          dataRequest: [...arr],
          // userID: state.dataRequired.userID
          userID: null
        }
      };

    // remove from cart
    case Types.REMOVE_FROM_CART:
      let { arrToDel } = action.payload;
      let i = findIndex(state.dataRequired.dataRequest, arrToDel);
      var tempxx = !localStorage.getItem("carts") ? [] : JSON.parse(localStorage.getItem("carts"));
      state.dataRequired.dataRequest.splice(i, 1);

      _.filter(tempxx, (e) => {
        // if (e.userID === state.dataRequired.userID) {
        //   return (e.dataRequest = state.dataRequired.dataRequest);
        // }
        if (e.userID === null || e.userID === undefined) {
          return (e.dataRequest = state.dataRequired.dataRequest);
        }
      });
      localStorage.setItem("carts", JSON.stringify(tempxx));
      return {
        dataRequired: {
          dataRequest: [...state.dataRequired.dataRequest],
          // userID: state.dataRequired.userID
          userID: null
        }
      };

    // SEND - DATA - REQUEST
    case Types.SEND_DATA_REQUEST: {
      return {
        ...state,
        loadingCart: true,
        loadingSend: true,
        createRequestLoading: true
      };
    }

    case Types.SEND_DATA_REQUEST_MESSAGE:
      let { message, listRequested } = action.payload;
      let tempDR = !localStorage.getItem("carts") ? [] : JSON.parse(localStorage.getItem("carts"));

      if (message === "Success") {
        let indexUser;
        tempDR.map((e, index) => {
          // if (e.userID === state.dataRequired.userID) {
          if (e.userID === null || e.userID === undefined) {
            indexUser = index;
            listRequested.forEach((itemRequested) => {
              if (itemRequested.viracServiceId === 1) {
                // e.dataRequest = e.dataRequest.filter((data) => data.id === itemRequested.id && data.requestData !== itemRequested.requestData);
                const position = e.dataRequest.findIndex((data) => data.id === itemRequested.id && data.requestData === itemRequested.requestData);
                e.dataRequest.splice(position, 1);
              } else if (itemRequested.viracServiceId === 2 || itemRequested.viracServiceId === 3 || itemRequested.viracServiceId === 4) {
                // e.dataRequest = e.dataRequest.filter((data) => data.id !== itemRequested.id);

                const position = e.dataRequest.findIndex((data) => data.id === itemRequested.id);
                e.dataRequest.splice(position, 1);
              }
            });
          }
        });

        localStorage.removeItem("carts");
        localStorage.setItem("carts", JSON.stringify(tempDR));

        return {
          ...state,

          dataRequired: {
            dataRequest: [...tempDR[indexUser].dataRequest],
            // userID: state.dataRequired.userID
            userID: null
          },
          unCheckItemWhenSuccess: true,
          loadingSend: false,
          loadingCart: false,
          createRequestLoading: false
        };
      } else {
        return {
          ...state,
          dataRequired: { ...state.dataRequired },
          loadingSend: false,
          loadingCart: false,
          createRequestLoading: false
        };
      }
    case Types.SEND_DATA_REQUEST_FAIL: {
      return {
        ...state,
        loadingSend: false,
        loadingCart: false,
        createRequestLoading: false
      };
    }

    case Types.UPDATE_NOTE_DATA_REQUESTED:
      let { arrUpdateNote } = action.payload;
      let tempDQ = !localStorage.getItem("carts") ? [] : JSON.parse(localStorage.getItem("carts"));

      _.filter(state.dataRequired.dataRequest, (item) => {
        if (item.id === arrUpdateNote.id && item.requestData === arrUpdateNote.requestData) {
          item.comment = arrUpdateNote.comment;
        }
      });

      _.filter(tempDQ, (e) => {
        // if (e.userID === state.dataRequired.userID) {
        //   e.dataRequest = state.dataRequired.dataRequest;
        // }
        if (e.userID === null) {
          e.dataRequest = state.dataRequired.dataRequest;
        }
      });
      localStorage.setItem("carts", JSON.stringify(tempDQ));
      return {
        ...state,
        dataRequired: { ...state.dataRequired }
      };

    // mua hàng
    case Types.CREATE_ORDER: {
      return {
        ...state,
        createOrderLoading: true
      };
    }

    case Types.CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        createOrderLoading: false
      };
    }

    case Types.CREATE_ORDER_FAIL: {
      return {
        ...state,
        createOrderLoading: false
      };
    }

    // lấy thông tin đơn hàng
    case Types.GET_ORDER: {
      return {
        ...state,
        getOrderLoading: true
      };
    }

    case Types.GET_ORDER_SUCCESS: {
      let carts = !localStorage.getItem("carts") ? [] : JSON.parse(localStorage.getItem("carts"));
      let productList = [];
      if (carts.length > 0) {
        productList = carts[0].dataRequest;
        action.data.forEach((i) => {
          productList = productList.filter((j) => j.id !== i.productId);
        });
        const dt = [];
        // dt.push({ userID: carts[0].userID, dataRequest: productList });
        dt.push({ dataRequest: productList });

        localStorage.setItem("carts", JSON.stringify(dt));
      }
      return {
        ...state,
        dataRequired: {
          dataRequest: [...productList],
          userID: null
        },
        getOrderLoading: false
      };
    }

    case Types.GET_ORDER_FAIL: {
      return {
        ...state,
        getOrderLoading: false
      };
    }

    default:
      return {
        ...state
      };
  }
};
