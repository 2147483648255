import * as Types from "../actions";
const INIT_STATE = {
  //company detail
  companyData: [],
  searchAutoComplete: {
    dataAutoComplete: [],
    isLoading: false
  },
  companyDetail: {},
  totalItems: 0,
  totalPage: 1,
  pageIndex: 1,
  loading: false,
  loadingDT: false,

  // bao cao tai chinh
  listYear: [],
  listYearDown: [],
  // chart
  dataBasicGrowth: {
    dataChartGrowth: [],
    dataChartGrowthType: [],
    growthRateCode: [],
    loadingGRChart: false,
    loadingGRCheckBox: false
  },
  dataBasicGrowthHomepage: {
    dataChartGrowth: [],
    dataChartGrowthType: [],
    loadingGRChart: false
  },
  //So sanh trung binh nganh,
  revColumnChart: {},
  model: {},
  message: null,
  loadingChart: false,
  dataChartCompareAverage: [],
  loadingNAVTBN: false,
  // owner
  owner: {
    companyRef: [],
    laws: [],
    lawDetail: {},
    loadingDetailLaw: false,
    loadingStructor: false,
    structorDetail: {},
    structors: [],
    loadingLawTB: false,
    loadingREFTB: false,
    loadingStructorTB: false
  },
  dataFinacial: {
    finalcial: [],
    finacialCode: [],
    dataChartFinance: [],
    loadingFNChart: false,
    loadingFNCheckBox: false,
    loadingFNChartInView: false
  },
  dataTarget: {
    target: [],
    targetCode: [],
    loadingTGChart: true,

    loadingTGCheckBox: false,
    limitChartTG: false
  },
  //so sanh
  companyDNOrther: [],
  DtaChartDNK: [],
  DtaChartDNKGROUP: [],
  limitChartGR: false,
  companyFinancialInfo: [],
  companyFinancialSearchList: [], // ds tiêu chí tài chính tìm kiếm nâng cao
  dataPointList: [], // ds năm dữ liệu tìm kiếm nâng cao
  selectedAdvanceSearchingCriteria: [], // ds điều kiện tìm kiếm nâng cao đã chọn
  advanceSearchLoading: null,

  // Tab báo cáo liên quan
  companyRelatedData: [],
  loadingRelatedData: true,

  // Data Doanh thu top doanh nghiep truy cap
  dataTopRevenua: [],
  loadingDataTopRevenua: [false]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_LIST_COMPANYINFO:
      const actionx = action.payload.model;
      return {
        ...state,
        loading: true,
        pageIndex: actionx.pageIndex,
        companyData: []
      };
    case Types.GET_LIST_COMPANYINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        totalItems: action.totalItems,
        totalPage: action.totalPage,
        companyData: action.companyData,
        pageIndex: action.pageIndex
      };
    case Types.GET_LIST_COMPANYINFO_FAIL:
      return {
        ...state,
        loading: false,
        companyData: []
      };

    case Types.GET_LIST_COMPANY_AUTOCOMPLETE:
      return {
        ...state,
        searchAutoComplete: { isLoading: true, dataAutoComplete: [] }
      };
    case Types.GET_LIST_COMPANY_AUTOCOMPLETE_SUCCESS:
      let customx = action.dataAutoComplete.map((props) => {
        return {
          title: props.name,
          description: props.address,
          id: props.id,
          vsiccode: props.vsicId
        };
      });
      return {
        ...state,
        searchAutoComplete: {
          isLoading: false,
          dataAutoComplete: customx
        }
      };
    case Types.GET_LIST_COMPANY_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        searchAutoComplete: { isLoading: false, dataAutoComplete: [] }
      };
    // end auto complete
    case Types.GET_TOP_REVENUA:
      return {
        ...state,
        loadingDataTopRevenua: true
      };
    case Types.GET_TOP_REVENUA_SUCCESS:
      return {
        ...state,
        loadingDataTopRevenua: false,
        dataTopRevenua: action.data
      };
    case Types.GET_TOP_REVENUA_FAIL:
      return {
        ...state,
        loadingDataTopRevenua: false,
        dataTopRevenua: []
      };
    case Types.GET_COMPANY_DETAIL:
      return {
        ...state,
        loadingDT: false,
        companyDetail: []
      };
    //HieuHV8
    case Types.GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDT: true,
        companyDetail: action.detail
        // revColumnChart: action.detail.chartComparision.length < 1 ? {} : action.detail.chartComparision[0],
        // dataBasicGrowthHomepage: {
        //   ...state.dataBasicGrowthHomepage,
        //   loadingGRChart: false,
        //   dataChartGrowth: action.detail.chartBinding
        // },
        // dataOwner: {
        //   law: {
        //     ...state.owner,
        //     laws: action.detail.chartCircle.law,
        //     loadingLawTB: true
        //   },
        //   structors: {
        //     ...state.owner,
        //     structors: action.detail.chartCircle.structor,
        //     loadingStructorTB: true
        //   },
        //   companyRef: {
        //     ...state.owner,
        //     companyRef: action.detail.chartCircle.companyRef,
        //     loadingREFTB: true
        //   }
        // },
        // dataFinacial: {
        //   ...state.dataFinacial,
        //   loadingFNChart: true,
        //   dataChartFinance: action.detail.chartFinance,
        //   finalcial: action.detail.finalcial
        // },
        // dataTarget: {
        //   ...state.dataTarget,
        //   loadingTGChart: true,
        //   target: action.detail.target
        // },
        // dataChartCompareAverage: []
      };
    case Types.RESET_FINANCIAL:
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          loadingFNChart: false,
          dataChartFinance: [],
          finalcial: []
        }
      };
    case Types.GET_FINANCIAL_INIT_SUCCESS:
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          loadingFNChart: false,
          dataChartFinance: action.detail.chartFinance,
          finalcial: action.detail.finalcial
        }
      };
    case Types.REQUEST_FINANCIAL_INIT:
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          loadingFNChart: true,
          loadingFNChartInView: true,
          limitChartFN: false
        }
      };
    case Types.REQUEST_FINANCIAL_INIT_SUCCESS:
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          loadingFNChart: false,
          loadingFNChartInView: false,

          finalcial: action.detail.finalcial,
          limitChartFN: false
        }
      };
    case Types.GET_TARGET_INIT_SUCCESS:
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          loadingTGChart: false,
          limitChartTG: false,
          target: action.detail.target
        }
      };
    case Types.GET_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        loadingDT: true
      };
    //bao cao tai chinh
    case Types.GET_REPORT_YEAR_SUCCESS:
      return {
        ...state,
        listYear: action.listYear
      };
    case Types.GET_REPORT_DOWNLOAD_SUCCESS:
      let popi =
        action.listYearDown &&
        action.listYearDown.map((props) => {
          return { year: props.year, name: props.name };
        });
      return {
        ...state,
        listYearDown: popi
      };
    //chart
    case Types.CODE_CHECKBOX_GROWTHRATE:
      return {
        ...state,
        dataBasicGrowth: {
          ...state.dataBasicGrowth,
          loadingGRCheckBox: false
        }
      };
    case Types.CODE_CHECKBOX_GROWTHRATE_SUCCESS:
      return {
        ...state,
        dataBasicGrowth: {
          ...state.dataBasicGrowth,
          growthRateCode: action.data,
          loadingGRCheckBox: true
        }
      };
    case Types.GET_GROWTHRATE_CHART:
      return {
        ...state,
        dataBasicGrowth: {
          dataChartGrowth: [],
          dataChartGrowthType: [],
          loadingGRChart: true
        }
      };
    case Types.GET_GROWTHRATE_CHART_SUCCESS:
      return {
        ...state,
        dataBasicGrowth: {
          ...state.dataBasicGrowth,
          dataChartGrowth: action.dataChart,
          loadingGRChart: false
        }
      };
    case Types.GET_GROWTHRATE_CHART_FAIL:
      return {
        ...state,
        dataBasicGrowth: {
          ...state.dataBasicGrowth,
          dataChartGrowth: []
        }
      };
    case Types.GET_CHART_BY_TYPE:
      return {
        ...state,
        dataBasicGrowth: {
          ...state.dataBasicGrowth,
          loadingGRChart: true
        }
      };
    case Types.GET_CHART_BY_TYPE_SUCCESS:
      return {
        ...state,
        dataBasicGrowth: {
          ...state.dataBasicGrowth,
          dataChartGrowthType: action.dataChartType,
          loadingGRChart: false
        }
      };
    case Types.GET_CHART_BY_TYPE_FAIL:
      return {
        ...state,
        dataBasicGrowth: {
          ...state.dataBasicGrowth,
          dataChartGrowthType: [],
          loadingGRChart: false
        }
      };
    case Types.GET_CHART_BY_TYPE_RESET:
      return {
        ...state,
        dataBasicGrowth: {
          ...state.dataBasicGrowth,
          dataChartGrowthType: [],
          loadingGRChart: false
        }
      };
    //So sanh trung binh nganh
    case Types.GET_DATA_NET_PROFIT_COLUMN_CHART:
      return {
        ...state,
        loadingChart: true
      };
    case Types.GET_DATA_NET_PROFIT_COLUMN_CHART_SUCCESS:
      const { dataPoints } = action.payload;
      return {
        ...state,
        loadingChart: false,
        revColumnChart: dataPoints
      };

    case Types.GET_MENU_DATA_TO_COMPARE:
      return {
        ...state,
        loadingNAVTBN: false,
        loadingMenuCompare: true
      };
    case Types.GET_MENU_DATA_TO_COMPARE_SUCCESS:
      let { model } = action.payload;
      return {
        ...state,
        loadingNAVTBN: true,
        loadingMenuCompare: false,
        model
      };
    case Types.REQUEST_DATA_TO_COMPARE_FAIL:
      const { message } = action.payload;
      return {
        ...state,
        message,
        loadingChart: false
      };
    case Types.REQUEST_DATA_TO_COMPARE:
      return {
        ...state,
        loadingChart: true
      };
    case Types.REQUEST_DATA_TO_COMPARE_SUCCESS:
      let { modelx } = action.payload;
      return {
        ...state,
        loadingChart: false,
        dataChartCompareAverage: [...modelx, ...state.dataChartCompareAverage]
      };
    case Types.DELETE_CHART_COMPARE_AVERAGE:
      var { key } = action.payload;
      var arr = [...state.dataChartCompareAverage];
      arr.splice(key, 1);
      return {
        ...state,
        dataChartCompareAverage: [...arr]
      };
    //--------------------------
    // owner
    case Types.GET_OWNER_LAW:
      return {
        ...state,
        owner: {
          ...state.owner,
          laws: [],
          loadingLawTB: false
        }
      };
    case Types.GET_OWNER_LAW_SUCCESS:
      return {
        ...state,
        owner: {
          ...state.owner,
          laws: action.data,
          loadingLawTB: true
        }
      };
    case Types.GET_OWNER_LAW_FAIL:
      return {
        ...state,
        owner: {
          ...state.owner,
          loadingLawTB: true
        }
      };
    case Types.GET_OWNER_LAW_DETAIL:
      return {
        ...state,
        owner: {
          ...state.owner,
          loadingDetailLaw: true,

          lawDetail: []
        }
      };
    case Types.GET_OWNER_LAW_DETAIL_SUCCESS:
      return {
        ...state,
        owner: {
          ...state.owner,
          loadingDetailLaw: false,

          lawDetail: action.data
        }
      };
    case Types.GET_OWNER_LAW_DETAIL_FAIL:
      return {
        ...state,
        owner: {
          ...state.owner,
          loadingDetailLaw: false,

          lawDetail: []
        }
      };
    //structor
    case Types.GET_OWNER_STRUCTOR:
      return {
        ...state,
        owner: {
          ...state.owner,
          structors: [],
          loadingStructorTB: false
        }
      };
    case Types.GET_OWNER_STRUCTOR_SUCCESS:
      return {
        ...state,
        owner: {
          ...state.owner,
          structors: action.data,
          loadingStructorTB: true
        }
      };
    case Types.GET_OWNER_STRUCTOR_FAIL:
      return {
        ...state,
        owner: {
          ...state.owner,
          loadingStructorTB: true
        }
      };
    case Types.GET_OWNER_STRUCTOR_DETAIL:
      return {
        ...state,
        owner: {
          ...state.owner,
          loadingStructor: true,

          structorDetail: []
        }
      };
    case Types.GET_OWNER_STRUCTOR_DETAIL_SUCCESS:
      return {
        ...state,
        owner: {
          ...state.owner,
          loadingStructor: false,

          structorDetail: action.data
        }
      };
    case Types.GET_OWNER_STRUCTOR_DETAIL_FAIL:
      return {
        ...state,
        owner: {
          ...state.owner,
          loadingStructor: false,

          structorDetail: []
        }
      };
    // /companyRef
    //structor
    case Types.GET_OWNER_COMPANYREF:
      return {
        ...state,
        owner: {
          ...state.owner,
          companyRef: [],
          loadingREFTB: false
        }
      };
    case Types.GET_OWNER_COMPANYREF_SUCCESS:
      return {
        ...state,
        owner: {
          ...state.owner,
          companyRef: action.data,
          loadingREFTB: true
        }
      };
    case Types.GET_OWNER_COMPANYREF_FAIL:
      return {
        ...state,
        owner: {
          ...state.owner,
          loadingREFTB: true
        }
      };
    //financial
    case Types.REQUEST_FINANCIAL:
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          loadingFNChart: true,
          limitChartFN: false
        }
      };
    case Types.REQUEST_FINANCIAL_RESET:
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          finalcial: []
        }
      };
    case Types.REQUEST_FINANCIAL_SUCCESS:
      let lenghtDataFN = [[...action.data], ...state.dataFinacial.finalcial];
      if (lenghtDataFN.length > 8) {
        return {
          ...state,
          dataFinacial: {
            ...state.dataFinacial,
            loadingFNChart: false,
            limitChartFN: true
          }
        };
      } else {
        return {
          ...state,
          dataFinacial: {
            ...state.dataFinacial,
            loadingFNChart: false,
            limitChartFN: false,
            finalcial: [[...action.data], ...state.dataFinacial.finalcial]
          }
        };
      }
    case Types.REQUEST_FINANCIAL_DELETE_CHART:
      var checked = false;
      var arrx = [...state.dataFinacial.finalcial];
      arrx.splice(action.id, 1);
      if (arrx.length <= 8) {
        checked = false;
      }
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          loadingFNChart: false,
          limitChartFN: checked,
          finalcial: [...arrx]
        }
      };
    //fix
    case Types.STORAGE_CHART_FINANCIAL_RESET:
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          target: []
        },
        dataFinacial: {
          ...state.dataFinacial,
          finalcial: []
        }
      };
    case Types.REQUEST_FINANCIAL_FAIL:
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          loadingFNChart: false
        }
      };
    case Types.CODE_CHECKBOX_FINANCIAL:
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          loadingFNCheckBox: false,
          finacialCode: []
        }
      };
    case Types.CODE_CHECKBOX_FINANCIAL_SUCCESS:
      return {
        ...state,
        dataFinacial: {
          ...state.dataFinacial,
          loadingFNCheckBox: true,
          finacialCode: action.data
        }
      };
    //target
    case Types.REQUEST_TARGET:
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          loadingTGChart: true,
          limitChartTG: false
        }
      };
    case Types.REQUEST_TARGET_RESET:
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          loadingTGChart: false,
          target: []
        }
      };
    case Types.REFRESH_CHART:
      return {
        ...state,
        limitChartGR: false,
        dataTarget: {
          ...state.dataTarget,
          limitChartTG: false
        },
        dataFinacial: {
          ...state.dataFinacial,
          limitChartFN: false
        }
      };
    case Types.REQUEST_TARGET_SUCCESS:
      let lenghtDataxz = [[...action.data], ...state.dataTarget.target];
      if (lenghtDataxz.length > 8) {
        return {
          ...state,
          dataTarget: {
            ...state.dataTarget,
            loadingTGChart: false,
            limitChartTG: true
          }
        };
      } else {
        return {
          ...state,
          dataTarget: {
            ...state.dataTarget,
            loadingTGChart: false,
            limitChartTG: false,
            target: [[...action.data], ...state.dataTarget.target]
          }
        };
      }
    case Types.REQUEST_TARGET_INIT:
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          loadingTGChart: true,
          limitChartTG: false
        }
      };
    case Types.REQUEST_TARGET_INIT_SUCCESS:
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          loadingTGChart: false,
          limitChartTG: false,
          target: action.detail.target
        }
      };
    case Types.REQUEST_TARGET_DELETE:
      let checkedTG = false;
      let arrTG = [...state.dataTarget.target];
      arrTG.splice(action.id, 1);
      if (arrTG.length <= 8) {
        checkedTG = false;
      }
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          loadingTGChart: false,
          limitChartTG: checkedTG,
          target: [...arrTG]
        }
      };
    case Types.REQUEST_TARGET_FAIL:
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          loadingTGChart: false
        }
      };
    case Types.CODE_CHECKBOX_TARGET:
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          loadingTGCheckBox: false
        }
      };
    case Types.CODE_CHECKBOX_TARGET_SUCCESS:
      return {
        ...state,
        dataTarget: {
          ...state.dataTarget,
          loadingTGCheckBox: true,
          targetCode: action.data
        }
      };
    //so sanh - doanh nghiep khac
    case Types.REQUEST_COMPANY_INDUSTRY:
      return {
        ...state,
        loading: true
      };
    case Types.REQUEST_COMPANY_INDUSTRY_SUCCESS:
      return {
        ...state,
        totalItems: action.totalItems,
        totalPage: action.totalPage,
        companyDNOrther: action.companyDNOrther,
        pageIndex: action.pageIndex,
        loading: false
      };
    case Types.REQUEST_COMPANY_INDUSTRY_FAIL:
      return {
        ...state,
        loading: false,
        companyDNOrther: []
      };
    case Types.REQUEST_COMPANY_INDUSTRYBK:
      return {
        ...state,
        loading: true
      };
    case Types.REQUEST_COMPANY_INDUSTRYBK_SUCCESS:
      return {
        ...state,
        totalItems: action.totalItems,
        totalPage: action.totalPage,
        companyDNOrther: action.companyDNOrtherBK,
        pageIndex: action.pageIndex,
        loading: false
      };
    case Types.REQUEST_COMPANY_INDUSTRYBK_FAIL:
      return {
        ...state,
        loading: false,
        companyDNOrther: []
      };
    case Types.REQUEST_COMPANY_INDUSTRYDNBK:
      return {
        ...state,
        loading: true
      };
    case Types.REQUEST_COMPANY_INDUSTRYDNBK_SUCCESS:
      return {
        ...state,
        totalItems: action.totalItems,
        totalPage: action.totalPage,
        companyDNOrther: action.companyDNOrtherBK,
        pageIndex: action.pageIndex,
        loading: false
      };
    case Types.REQUEST_COMPANY_INDUSTRYDNBK_FAIL:
      return {
        ...state,
        companyDNOrther: [],
        loading: false
      };
    // Doanh nghiep khac - so sanh truc tiep
    case Types.REQUEST_LIVE_CHARTDNK_DELETE:
      let arrLC = [...state.DtaChartDNK];
      arrLC.splice(action.id, 1);
      return {
        ...state,
        loading: false,
        DtaChartDNK: [...arrLC]
      };
    case Types.REQUEST_LIVE_CHARTDNK_RESET:
      return {
        ...state,
        loading: false,
        DtaChartDNK: []
      };
    case Types.REQUEST_LIVE_CHARTDNK:
      return {
        ...state,
        loading: true
      };
    case Types.REQUEST_LIVE_CHARTDNK_SUCCESS:
      return {
        ...state,
        loading: false,
        DtaChartDNK: [...action.data, ...state.DtaChartDNK]
      };
    case Types.REQUEST_LIVE_CHARTDNK_FAIL:
      return {
        ...state,
        loading: false
      };
    //ss Trung Binh
    case Types.REQUEST_LIVE_CHARTDNK_TB:
      return {
        ...state,
        loading: true
      };
    case Types.REQUEST_LIVE_CHARTDNK_TB_SUCCESS:
      return {
        ...state,
        loading: false,
        DtaChartDNK: [...action.data, ...state.DtaChartDNK]
      };
    case Types.REQUEST_LIVE_CHARTDNK_TB_FAIL:
      return {
        ...state,
        loading: false
      };
    //ss Tong gia tri
    case Types.REQUEST_LIVE_CHARTDNK_TGT:
      return {
        ...state,
        loading: true
      };
    case Types.REQUEST_LIVE_CHARTDNK_TGT_SUCCESS:
      return {
        ...state,
        loading: false,
        DtaChartDNK: [...action.data, ...state.DtaChartDNK]
      };
    case Types.REQUEST_LIVE_CHARTDNK_TGT_FAIL:
      return {
        ...state,
        loading: false
      };
    //ss Trung Binh group
    case Types.REQUEST_LIVE_CHARTDNK_TB_GROUP:
      return {
        ...state,
        loading: true,
        limitChartGR: false
      };
    case Types.REQUEST_LIVE_CHARTDNK_TB_GROUP_SUCCESS:
      let lenghtData = [...action.data, ...state.DtaChartDNKGROUP];
      if (lenghtData.length > 8) {
        return {
          ...state,
          loading: false,
          limitChartGR: true
        };
      } else {
        return {
          ...state,
          loading: false,
          limitChartGR: false,
          DtaChartDNKGROUP: [...action.data, ...state.DtaChartDNKGROUP]
        };
      }
    case Types.REQUEST_LIVE_CHART_GROUP_RESET:
      return {
        ...state,
        loading: false,
        DtaChartDNKGROUP: []
      };
    case Types.REQUEST_LIVE_CHARTDNK_TB_GROUP_FAIL:
      return {
        ...state,
        loading: false
      };
    //ss Tong gia tri group
    case Types.REQUEST_LIVE_CHARTDNK_TGT_GROUP:
      return {
        ...state,
        loading: true,
        limitChartGR: false
      };
    case Types.REQUEST_LIVE_CHARTDNK_TGT_GROUP_SUCCESS:
      let lenghtData2 = [...action.data, ...state.DtaChartDNKGROUP];
      if (lenghtData2.length > 8) {
        return {
          ...state,
          loading: false,
          limitChartGR: true
        };
      } else {
        return {
          ...state,
          loading: false,
          limitChartGR: false,
          DtaChartDNKGROUP: [...action.data, ...state.DtaChartDNKGROUP]
        };
      }
    case Types.REQUEST_LIVE_CHARTDNK_TGT_GROUP_FAIL:
      return {
        ...state,
        loading: false
      };
    // Doanh nghiep khac - so sanh truc tiep
    case Types.REQUEST_LIVE_CHARTDNK_DELETE_GROUP:
      let checkedLC = false;
      var arrLCx = [...state.DtaChartDNKGROUP];
      arrLCx.splice(action.id, 1);
      if (arrLCx.length <= 8) {
        checkedLC = false;
      }
      return {
        ...state,
        loading: false,
        limitChartGR: checkedLC,
        DtaChartDNKGROUP: [...arrLCx]
      };
    case Types.REQUEST_LIVE_CHARTDNK_RESET_GROUP:
      return {
        ...state,
        loading: false,
        DtaChartDNKGROUP: []
      };
    case Types.DOWNLOAD_COMPANY_REPORT_SUCCESS:
      return {
        ...state,
        exportCompanyData: action.data
      };
    case Types.DOWNLOAD_COMPANY_REPORT_FAIL:
      return {
        ...state,
        message: action.message
      };
    case Types.GET_COMPANY_FINANCIAL_INFO_SUCCESS:
      return {
        ...state,
        companyFinancialInfo: action.data
      };
    case Types.GET_COMPANY_FINANCIAL_INFO_FAIL:
      return {
        ...state,
        companyFinancialInfo: []
      };
    // Dữ liệu tab Báo cáo liên quan / Chi tiết daonh nghiệp
    case Types.GET_DATA_RELATED_REPORT_IN_COMPANY:
      return {
        ...state,
        loadingRelatedData: true
      };
    case Types.GET_DATA_RELATED_REPORT_IN_COMPANY_SUCCESS:
      return {
        ...state,
        companyRelatedData: action.data,
        loadingRelatedData: false
      };
    case Types.GET_DATA_RELATED_REPORT_IN_COMPANY_FAIL:
      return {
        ...state,
        companyRelatedData: [],
        loadingRelatedData: false
      };
    // advance search
    case Types.ADVANCE_SEARCH_COMPANY_BY_AREA:
      return {
        ...state,
        loading: true,
        pageIndex: action.payload.pageIndex,
        companyData: [],
        advanceSearchLoading: true
      };
    case Types.ADVANCE_SEARCH_COMPANY_BY_AREA_SUCCESS:
      const area = action.data;
      return {
        ...state,
        loading: false,
        totalItems: area.totalItem,
        totalPage: Math.ceil(area.totalItem / area.pageSize),
        companyData: area.results,
        pageIndex: area.pageIndex,
        advanceSearchLoading: false
      };
    case Types.ADVANCE_SEARCH_COMPANY_BY_AREA_FAIL:
      return {
        ...state,
        loading: false,
        companyData: [],
        advanceSearchLoading: false
      };
    case Types.ADVANCE_SEARCH_COMPANY_BY_INDUSTRY:
      return {
        ...state,
        loading: true,
        pageIndex: action.payload.pageIndex,
        companyData: [],
        advanceSearchLoading: true
      };
    case Types.ADVANCE_SEARCH_COMPANY_BY_INDUSTRY_SUCCESS:
      const industry = action.data;
      return {
        ...state,
        loading: false,
        totalItems: industry.totalItem,
        totalPage: Math.ceil(industry.totalItem / industry.pageSize),
        companyData: industry.results,
        pageIndex: industry.pageIndex,
        advanceSearchLoading: false
      };
    case Types.ADVANCE_SEARCH_COMPANY_BY_INDUSTRY_FAIL:
      return {
        ...state,
        loading: false,
        companyData: [],
        advanceSearchLoading: false
      };
    case Types.ADVANCE_SEARCH_COMPANY_BY_FINANCE:
      return {
        ...state,
        loading: true,
        pageIndex: action.payload.pageIndex,
        companyData: [],
        advanceSearchLoading: true
      };
    case Types.ADVANCE_SEARCH_COMPANY_BY_FINANCE_SUCCESS:
      const finance = action.data;
      return {
        ...state,
        loading: false,
        totalItems: finance.totalItem,
        totalPage: Math.ceil(finance.totalItem / finance.pageSize),
        companyData: finance.results,
        pageIndex: finance.pageIndex,
        advanceSearchLoading: false
      };
    case Types.ADVANCE_SEARCH_COMPANY_BY_FINANCE_FAIL:
      return {
        ...state,
        loading: false,
        companyData: [],
        advanceSearchLoading: false
      };
    case Types.GET_FINANCIAL_CRITERIA_LIST_SUCCESS:
      return {
        ...state,
        companyFinancialSearchList: [...action.data]
      };
    case Types.GET_FINANCIAL_CRITERIA_LIST_FAIL:
      return {
        ...state,
        companyFinancialSearchList: []
      };
    case Types.GET_DATA_POINT_LIST_SUCCESS:
      return {
        ...state,
        dataPointList: [...action.data]
      };
    case Types.GET_DATA_POINT_LIST_FAIL:
      return {
        ...state,
        dataPointList: []
      };
    default:
      return { ...state };
  }
};
