import { Formik } from "formik";
import { ConnectedFocusError } from "focus-formik-error";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { actLogout } from "../../../redux/Authentication/actions";
import { actChangePassword } from "../../../redux/AccountManagement/actions";
import Button from "../../../components/CustomButton/CustomButton";
import IntlMessages from "../../../utils/IntlMessages";
import { injectIntl } from "react-intl";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteCookie } from "../../../utils/cookies";

class TabChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCheck: false,
            toggleForgetPass: false,
            success: false,
            alert: "",
        }
    }

    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.userAccount && nextProps.userAccount.message) {
            this.handleShowAlert(nextProps.userAccount.message);
        }
    }

    handleShowAlert = message => {
        const { messages } = this.props.intl;
        if (message === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block" }}
                        title={"Success"}
                        // showCancel
                        confirmBtnText={messages[`newWord.oke`]}
                        customClass="swal-wide"
                        confirmBtnCssClass="confirmBtn swal2-styled"
                        onConfirm={() => {
                            this.redirectURL();
                        }}
                    >
                        <p style={{ fontSize: "15px" }}>
                            <IntlMessages id="newWord.resetPass" />
                        </p>
                    </SweetAlert>
                )
            });
        }
        if (message === "Can't Change Password") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block" }}
                        title={"Fail"}
                        // showCancel
                        confirmBtnText={messages[`newWord.oke`]}
                        customClass="swal-wide"
                        confirmBtnCssClass="confirmBtn swal2-styled"
                        onConfirm={() => {
                            this.handleHideAlert();
                        }}
                    >
                        <p style={{ fontSize: "15px" }}>
                            <IntlMessages id="newWord.oldPassIncorrect" />
                        </p>
                    </SweetAlert>
                )
            });
        }
    };

    handleHideAlert = () => {
        this.setState({
            alert: "",
        });
    };

    redirectURL = () => {
        this.props.logout();
        localStorage.removeItem("access_token");
        deleteCookie("access_token");
        this.handleHideAlert();
        // window.location.href = "/dang-nhap";
        this.props.history.push("/dang-nhap");
      };

    render() {
        const { loadingUser } = this.props.userAccount;
        const { messages } = this.props.intl;
        const { alert } = this.state;
        return (
            <React.Fragment>
                {alert}
                <div className="login-page tab-change-password">
                    <div className="login-form-container form-container">
                        <div className="login-form">
                            <Formik
                                initialValues={{
                                    oldPassword: "",
                                    newPassword: "",
                                    confirmPassword: ""
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    setTimeout(() => {
                                        this.props.handleChangePassword(
                                            values.oldPassword,
                                            values.newPassword,
                                        );
                                        this.setState({
                                            isCheck: true,
                                        })
                                        setSubmitting(false);
                                    }, 500);
                                }}
                                validationSchema={Yup.object().shape({
                                    oldPassword: Yup.string()
                                        .required(messages[`error.passRequired`])
                                        .min(8, messages[`error.passMinLength`])
                                        .matches(
                                            /^.*((?=.*[!~/*^$&@]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).[^\s]*$/,
                                            messages[`error.passFormatted`]
                                        ),
                                    newPassword: Yup.string()
                                        .required(messages[`error.passRequired`])
                                        .min(8, messages[`error.passMinLength`])
                                        .matches(
                                            /^.*((?=.*[!~/*^$&@]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).[^\s]*$/,
                                            messages[`error.passFormatted`]
                                        ),
                                    confirmPassword: Yup.string()
                                        .required(messages[`error.confirmPassRequired`])
                                        .oneOf(
                                            [Yup.ref("newPassword"), null],
                                            messages[`error.confirmPassMatch`]
                                        )
                                })}
                            >
                                {props => {
                                    const {
                                        values,
                                        errors,
                                        handleChange,
                                        handleSubmit,
                                        handleBlur
                                    } = props;
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <ConnectedFocusError />
                                            <h1><IntlMessages id="auth.changePass" /></h1>
                                            <div className="form-input form-icon">
                                                <label>
                                                    <div className="icon">
                                                        <span className="material-icons">lock</span>
                                                    </div>
                                                    <input
                                                        type="password"
                                                        name="oldPassword"
                                                        autoComplete="off"
                                                        placeholder={messages[`user.changpassOld`]}
                                                        value={values.oldPassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </label>
                                                {errors.oldPassword && (
                                                    <div className="form-error">{errors.oldPassword}</div>
                                                )}
                                            </div>
                                            <div className="form-input form-icon">
                                                <label>
                                                    <div className="icon">
                                                        <span className="material-icons">lock</span>
                                                    </div>
                                                    <input
                                                        type="password"
                                                        name="newPassword"
                                                        autoComplete="off"
                                                        placeholder={messages[`auth.enterNewPass`]}
                                                        value={values.newPassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </label>
                                                {errors.newPassword && (
                                                    <div className="form-error">{errors.newPassword}</div>
                                                )}
                                            </div>
                                            <div className="form-input form-icon">
                                                <label>
                                                    <div className="icon">
                                                        <span className="material-icons">lock</span>
                                                    </div>
                                                    <input
                                                        type="password"
                                                        name="confirmPassword"
                                                        autoComplete="off"
                                                        placeholder={messages[`auth.enterNewPassAgain`]}
                                                        value={values.confirmPassword}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </label>
                                                {errors.confirmPassword && (
                                                    <div className="form-error">{errors.confirmPassword}</div>
                                                )}
                                            </div>
                                            <div className="form-submit login-submit">
                                                <Button
                                                    disabled={loadingUser}
                                                    isLoading={loadingUser}
                                                    className="common-button w-100"
                                                >
                                                    <IntlMessages id="auth.changePass" />
                                                </Button>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

TabChangePassword.propTypes = {
    state: PropTypes.object
};
const mapStateToProps = state => {
    return {
        userAccount: state.accountReducer,
    };
};
const mapDispatchToProps = (dispatch, props) => {
    return {
        handleChangePassword: (oldPass, newPass) =>
            dispatch(actChangePassword(oldPass, newPass)),
        logout: () => dispatch(actLogout()),
    };
};

TabChangePassword.defaultProps = {};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(TabChangePassword));
