import * as Types from "../actions";

const INIT_STATE = {
  listFAQ: [],
  listFAQFormat: [],
  loading: false,
  province: [],
  userAccount: [],
  bannerData: [],
  searchAutoComplete: {
    dataAutoComplete: [],
    isLoading: false
  },
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.GET_BANNER_SUCCESS:
      return {
        ...state,
        userAccount: action.userId,
        province: action.province,
        bannerData: action.data,
        searchAutoComplete: { isLoading: false, dataAutoComplete: [] }
      };
    case Types.GET_BANNER_FAIL:
      return {
        ...state,
        bannerData: [],
      };
    case Types.GET_LIST_FAQ:
      return {
        ...state,
        loading: true
      };
    case Types.GET_LIST_FAQ_SUCCESS:
      let arr = action.data;
      var newArr = arr.reduce(function (prev, currentVl) {
        return [...prev, ...currentVl.faq];
      }, []);
      return {
        ...state,
        loading: false,
        listFAQ: action.data,
        listFAQFormat: newArr
      };
    case Types.GET_LIST_FAQ_FAIL:
      return {
        ...state,
        loading: false
      };
    case Types.GET_LIST_COMPANY_AUTOCOMPLETE:
      return {
        ...state,
        searchAutoComplete: { isLoading: true, dataAutoComplete: [] }
      };
    case Types.GET_LIST_COMPANY_AUTOCOMPLETE_SUCCESS:
      let customx = action.dataAutoComplete.map(props => {
        return {
          title: props.name,
          description: props.address,
          id: props.id,
          vsiccode: props.vsicId
        };
      });
      return {
        ...state,
        searchAutoComplete: {
          isLoading: false,
          dataAutoComplete: customx
        }
      };
    case Types.GET_LIST_COMPANY_AUTOCOMPLETE_FAIL:
      return {
        ...state,
        searchAutoComplete: { isLoading: false, dataAutoComplete: [] }
      };
    default:
      return state;
  }
};
