import * as Types from "../actions";

const INIT_STATE = {
  dataSampleReport: [],
  dataSelectIndustry: [],
  dataSelectIndustryYear: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // SAMPLE_REPORT
    case Types.GET_SAMPLE_REPORT_SUCCESS:
      return {
        ...state,
        dataSampleReport: action.data
      };
    case Types.GET_SAMPLE_REPORT_FAIL:
      return {
        ...state,
        dataSampleReport: []
      };
    // SelectIndustry
    case Types.GET_LIST_SELECT_INDUSTRY_SUCCESS:
      const { listNganh } = action.data;
      let popix =
        listNganh &&
        listNganh.map(props => {
          return {
            key: props.vsic,
            value: props.vsic,
            text: props.vsic
          };
        });
      return {
        ...state,
        dataSelectIndustry: popix
      };
    case Types.GET_LIST_SELECT_INDUSTRY_FAIL:
      return {
        ...state,
        dataSelectIndustry: []
      };
    // SelectIndustryYear
    case Types.GET_LIST_SELECT_INDUSTRY_YEAR_SUCCESS:
      const resData = action.data;
      let customData =
        resData &&
        resData.map(props => {
          return {
            key: props,
            value: props,
            text: props
          };
        });
      return {
        ...state,
        dataSelectIndustryYear: customData
      };
    case Types.GET_LIST_SELECT_INDUSTRY_YEAR_FAIL:
      return {
        ...state,
        dataSelectIndustryYear: []
      };

    default:
      return state;
  }
};
