import * as Types from "../actions";
import _ from "lodash";

const INIT_STATE = {
  loadingPage: false,
  data: {},
  dataProduct: {},
  dataReturn: [],
  dataBarCharts: [],
  dataChartsName: [],
  dataChartToShow: [],
  typeOne: null,
  typeTwo: null,
  loadingChart: false,
  dataFinancial: [],
  dataChartFinancial: [],
  dataCompare: {},
  dataCompareAutoComplete: [],
  chartCPMCompare: [],
  loading: false,
  loadingPageTop: false,
  limitChart: false,
  limitChartKTN: false,
  limitChartTK: false,

  // search industry
  searchLoading: false,
  industryReport: [],  // top báo cáo liên quan
  industryRelatedCompany: [],  // top doanh nghiệp liên quan
  totalRelatedReport: 0, // tổng số bc liên quan

  // statistic industry
  statisticLoading: false,
  statisticData: [],
  statisticLoadingChart: false,
  statisticDataChart: [],
  dataPointList: [],   // ds năm để vẽ chart
  statisticAreaList: [],  // ds ngành khu vực được thống kê
  filterVsicList: [],  // ds ngành/sản phẩm filter
  initFilterVsic: [], // ds ngành/sản phẩm được thống kê
  filterAreaList: [],  // ds khu vực filter
  initFilterArea: [], // ds khu vực thống kê
  filterTypeList: [], // ds loại dữ liệu filter

  // industry report
  industryReportLoading: false,
  totalPage: 0,
  totalItems: 0,
  industryReportList: [],  // ds báo cáo ngành
  industryList: [], // ds ngành (VSIC)
  industryListLoading: false,
  productList: [], // ds sản phẩm
  loadingIndustryReportDetail: false,
  industryReportDetail: {},
  vsicRelatedCompany: [], // DN liên quan bc
  vsicRelatedReport: [],  // bc liên quan bc
  loadingRelatedReport: false,
};

var findIndex = (arr, key) => {
  let result = -1;
  arr.forEach((arr, index) => {
    if (arr.id === key) {
      result = index;
    }
  });
  return result;
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //Autocomplete
    case Types.SEARCH_AUTOCOMPLETE_INDUSTRY_SUCCESS:
      let customx = action.data.map(props => {
        return { title: props.name, id: props.id, vsiccode: props.vsicCode };
      });
      let uniq = _.uniqBy(customx, "id");
      return {
        ...state,
        loadingAutocomplete: false,
        dataCompareAutoComplete: uniq
      };
    case Types.SEARCH_AUTOCOMPLETE_INDUSTRY_FAIL:
      return {
        ...state,
        loadingAutocomplete: false,
        dataCompareAutoComplete: []
      };
    case Types.SEARCH_AUTOCOMPLETE_INDUSTRY:
      return {
        ...state,
        loadingAutocomplete: true,
        dataCompareAutoComplete: []
      };
    //TỔNG QUAN NGÀNH
    case Types.GET_TOP_VSIC:
      return {
        ...state,
        loadingPageTop: true
      };

    case Types.GET_TOP_VSIC_SUCCESS:
      const { chartDAta } = action.payload;

      let res = chartDAta.listTopVsic ? chartDAta.listTopVsic : [];
      let res1 = chartDAta.listName ? chartDAta.listName : [];
      return {
        ...state,
        dataBarCharts: [...res],
        dataChartsName: [...res1],
        loadingPageTop: false
      };

    case Types.GET_TOP_VSIC_FAIL:
      return {
        ...state,
        loadingPageTop: false
      };

    case Types.GET_INDUSTRY_INFORMATION:
      return {
        ...state,
        loadingPage: true,
        data: {}
      };
    case Types.GET_INDUSTRY_INFORMATION_SUCCESS:
      var { dataVSIC } = action.payload;
      return {
        ...state,
        data: { ...dataVSIC },
        loadingPage: false
      };

    case Types.GET_INDUSTRY_INFORMATION_FAIL:
      return {
        ...state,
        loadingPage: false,
        loadingChart: false,
        data: {}
      };

    //CHỈ SỐ KINH TẾ NGÀNH

    case Types.GET_DATA_FINANCIAL_TO_COMPARE:
      return {
        ...state,
        loadingPage: true
      };

    case Types.GET_DATA_FINANCIAL_TO_COMPARE_SUCCESS:
      var { dataFinancial } = action.payload;
      return {
        ...state,
        dataFinancial: { ...dataFinancial },
        loadingPage: false
      };

    case Types.SEND_DATA_FINANCIAL_TO_COMPARE:
      return {
        ...state,
        loadingChart: true
      };
    case Types.SEND_DATA_FINANCIAL_TO_COMPARE_RESET:
      return {
        ...state,
        dataChartFinancial: [],
        dataChartToShow: [],
        chartCPMCompare: []
      };

    case Types.GET_DATA_CHART_FINANCIAL_TO_COMPARE_SUCCESS:
      var { dataFinancialChart } = action.payload;
      const result = dataFinancialChart.filter(
        item => item.dataChart.length > 0
      );
      let lenghtDataKTN = [...result, ...state.dataChartFinancial];
      if (lenghtDataKTN.length > 8) {
        return {
          ...state,
          loadingChart: false,
          limitChartKTN: true
        };
      } else {
        return {
          ...state,
          loadingChart: false,
          limitChartKTN: false,
          dataChartFinancial: [...result, ...state.dataChartFinancial]
        };
      }

    case Types.DELETE_FINANCIAL_CHART:
      var keyx = action.payload.key;

      var checked = false;
      var arrx1 = [...state.dataChartFinancial];
      arrx1.splice(keyx, 1);
      if (arrx1.length <= 8) {
        checked = false;
      }
      return {
        ...state,
        loadingChart: false,
        limitChartKTN: checked,
        dataChartFinancial: [...arrx1]
      };
    // SẢN XUẤT - TIÊU THỤ  - TỒN KHO

    case Types.GET_DATA_PRODUCT_TO_COMPARE:
      return {
        ...state,
        loadingPage: true
      };

    case Types.GET_DATA_PRODUCT_TO_COMPARE_SUCCESS:
      var { dataProduct } = action.payload;
      return {
        ...state,
        dataProduct: { ...dataProduct },
        loadingPage: false
      };

    case Types.SEND_DATA_TO_COMPARE:
      return {
        ...state,
        loadingChart: true,
        limitChartTK: false
      };

    case Types.GET_DATA_CHART_EXPORT_TO_SHOW_SUCCESS:
      var { data } = action.payload;
      if (data.dataChart && data.dataChart.length > 0) {
        let dataCurrent = state.dataChartToShow;
        let result = dataCurrent.reduce(function (prev, vlue) {
          return [...prev, ...vlue.dataChart];
        }, []);

        let lengthData = [...data.dataChart, ...result];
        if (lengthData.length > 8) {
          return {
            ...state,
            loadingChart: false,
            limitChartTK: true
          };
        } else {
          return {
            ...state,
            loadingChart: false,
            limitChartTK: false,
            dataChartToShow: [data, ...state.dataChartToShow]
          };
        }
      } else {
        return {
          ...state,
          loadingChart: false,
          limitChartTK: false
        };
      }
    case Types.DELETE_CHART_INDUSTRY_INFORMATION:
      var checkedq = false;
      var { key, id } = action.payload;
      var arr = [];
      var tempType = [];
      arr = [...state.dataChartToShow];
      if (arr.length <= 8) {
        checkedq = false;
      }
      tempType = _.filter(arr, item => item.id === id);
      var i = findIndex(tempType[0].dataChart, key);
      tempType[0].dataChart.splice(i, 1);
      var index = findIndex(arr, id);
      if (tempType[0].dataChart.length === 0) {
        arr.splice(index, 1);
      } else {
        arr[index] = tempType[0];
      }
      return {
        ...state,
        limitChartTK: checkedq,
        dataChartToShow: arr
      };

    //SO SANH NGANH

    case Types.GET_DATA_COMPARE_INDUSTRY_INFORMATION:
      return {
        ...state,
        loadingPage: true
      };

    case Types.GET_DATA_COMPARE_INDUSTRY_INFORMATION_SUCCESS:
      const { dataCompare } = action.payload;
      return {
        ...state,
        loadingPage: false,
        dataCompare: { ...dataCompare }
      };
    //ss Trung binh
    case Types.REFRESH_CHART:
      return {
        ...state,
        limitChart: false,
        limitChartKTN: false,
        limitChartTK: false
      };
    case Types.REQUEST_CHART_SSNGANH_TB:
      return {
        ...state,
        loading: true,
        limitChart: false
      };
    case Types.REQUEST_CHART_SSNGANH_TB_SUCCESS:
      let lenghtDatax = [...action.data, ...state.chartCPMCompare];
      if (lenghtDatax.length > 8) {
        return {
          ...state,
          loading: false,
          limitChart: true
        };
      } else {
        return {
          ...state,
          loading: false,
          limitChart: false,
          chartCPMCompare: [...action.data, ...state.chartCPMCompare]
        };
      }
    case Types.REQUEST_CHART_SSNGANH_TB_FAIL:
      return {
        ...state,
        loading: false
      };
    //ss Tong gia tri
    case Types.REQUEST_CHART_SSNGANH_TGT:
      return {
        ...state,
        loading: true,
        limitChart: false
      };
    case Types.REQUEST_CHART_SSNGANH_TGT_SUCCESS:
      let lenghtDatax1 = [...action.data, ...state.chartCPMCompare];
      if (lenghtDatax1.length > 8) {
        return {
          ...state,
          loading: false,
          limitChart: true
        };
      } else {
        return {
          ...state,
          loading: false,
          limitChart: false,
          chartCPMCompare: [...action.data, ...state.chartCPMCompare]
        };
      }
    case Types.REQUEST_CHART_SSNGANH_TGT_FAIL:
      return {
        ...state,
        loading: false
      };
    case Types.REQUEST_CHART_SSNGANH_DELETE:
      let checkedSS = false;
      let arrSS = [...state.chartCPMCompare];
      arrSS.splice(action.id, 1);
      if (arrSS.length <= 8) {
        checkedSS = false;
      }
      return {
        ...state,
        loading: false,
        limitChart: checkedSS,
        chartCPMCompare: [...arrSS]
      };

    // search industry
    case Types.SEARCH_INDUSTRY:
      return {
        ...state,
        searchLoading: true,
      };
    case Types.SEARCH_INDUSTRY_SUCCESS:
      return {
        ...state,
        industryReport: action.data.vsicReportData,
        industryRelatedCompany: action.data.vsicRelatedCompany,
        totalRelatedReport: action.data.totalRelatedReport,
        searchLoading: false
      };
    case Types.SEARCH_INDUSTRY_FAIL:
      return {
        ...state,
        searchLoading: false,
        industryReport: [],
        industryRelatedCompany: [],
        totalRelatedReport: 0,
      };
    case Types.SEARCH_INDUSTRY_REPORT:
      return {
        ...state,
        industryReportLoading: true,
      };
    case Types.SEARCH_INDUSTRY_REPORT_SUCCESS:
      const dt = action.data;
      return {
        ...state,
        industryReportList: dt.ifReports,
        industryReportLoading: false,
        totalItems: dt.totalItem,
        totalPage: Math.ceil(dt.totalItem / dt.pageSize),
        pageIndex: dt.pageIndex,
      };
    case Types.SEARCH_INDUSTRY_REPORT_FAIL:
      return {
        ...state,
        industryReportLoading: false,
      };
    case Types.STATISTIC_INDUSTRY:
      return {
        ...state,
        statisticLoading: true,
      };
    case Types.STATISTIC_INDUSTRY_SUCCESS:
      const dt1 = action.data;
      return {
        ...state,
        statisticData: dt1.ifStatisticViewModels,
        statisticLoading: false,
        totalItems: dt1.totalItem,
        totalPage: Math.ceil(dt1.totalItem / dt1.pageSize),
        pageIndex: dt1.pageIndex,
        filterVsicList: dt1.vsicFilter && dt1.vsicFilter.length ? dt1.vsicFilter : dt1.vsicItems,
        filterTypeList: dt1.dataKind,
        initFilterVsic: dt1.vsicItems
      };
    case Types.STATISTIC_INDUSTRY_FAIL:
      return {
        ...state,
        statisticLoading: false,
        statisticData: []
      };
    case Types.ADVANCE_STATISTIC_INDUSTRY:
      return {
        ...state,
        statisticLoading: true,
      };
    case Types.ADVANCE_STATISTIC_INDUSTRY_SUCCESS:
      const dt2 = action.data;
      return {
        ...state,
        statisticData: dt2.ifAdvancedStatisticViewModels,
        statisticLoading: false,
        totalItems: dt2.totalItem,
        totalPage: Math.ceil(dt2.totalItem / dt2.pageSize),
        pageIndex: dt2.pageIndex,
        filterVsicList: dt2.productFilter && dt2.productFilter.length ? dt2.productFilter : dt2.productItems,
        filterTypeList: dt2.dataKind,
        filterAreaList: dt2.areaFilter && dt2.areaFilter.length ? dt2.areaFilter : dt2.areaItems,
        initFilterVsic: dt2.productItems,
        initFilterArea: dt2.areaItems
      };
    case Types.ADVANCE_STATISTIC_INDUSTRY_FAIL:
      return {
        ...state,
        statisticLoading: false,
        statisticData: []
      };
    case Types.STATISTIC_INDUSTRY_CHART:
      return {
        ...state,
        statisticLoadingChart: true,
      };
    case Types.STATISTIC_INDUSTRY_SUCCESS_CHART:
      return {
        ...state,
        statisticDataChart: action.data.vsicStatisticBasicChartData,
        statisticLoadingChart: false,
      };
    case Types.STATISTIC_INDUSTRY_FAIL_CHART:
      return {
        ...state,
        statisticLoadingChart: false,
        statisticDataChart: []
      };
    case Types.ADVANCE_STATISTIC_INDUSTRY_CHART:
      return {
        ...state,
        statisticLoadingChart: true,
      };
    case Types.ADVANCE_STATISTIC_INDUSTRY_SUCCESS_CHART:
      return {
        ...state,
        statisticDataChart: action.data.vsicStatisticAdvancedChartData,
        statisticLoadingChart: false,
      };
    case Types.ADVANCE_STATISTIC_INDUSTRY_FAIL_CHART:
      return {
        ...state,
        statisticLoadingChart: false,
        statisticDataChart: []
      };
    case Types.STATISTIC_INDUSTRY_CHART_YEAR:
      return {
        ...state,
      };
    case Types.STATISTIC_INDUSTRY_SUCCESS_CHART_YEAR:
      return {
        ...state,
        dataPointList: action.data,
      };
    case Types.STATISTIC_INDUSTRY_FAIL_CHART_YEAR:
      return {
        ...state,
      };
    case Types.VSIC_LIST:
      return {
        ...state,
        industryListLoading: true,
      };
    case Types.VSIC_LIST_SUCCESS:
      return {
        ...state,
        industryList: action.data,
        industryListLoading: false
      };
    case Types.VSIC_LIST_FAIL:
      return {
        ...state,
        industryListLoading: false,
        industryList: []
      };
    case Types.PRODUCT_LIST:
      return {
        ...state,
      };
    case Types.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: action.data,
      };
    case Types.PRODUCT_LIST_FAIL:
      return {
        ...state,
      };
    case Types.INDUSTRY_REPORT_DETAIL:
      return {
        ...state,
        loadingIndustryReportDetail: true,
      };
    case Types.INDUSTRY_REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        industryReportDetail: action.data,
        loadingIndustryReportDetail: false,
      };
    case Types.INDUSTRY_REPORT_DETAIL_FAIL:
      return {
        ...state,
        loadingIndustryReportDetail: false,
      };
    case Types.INDUSTRY_RELATED_REPORT:
      return {
        ...state,
        loadingRelatedReport: true
      };
    case Types.INDUSTRY_RELATED_REPORT_SUCCESS:
      return {
        ...state,
        vsicRelatedCompany: action.data.vsicRelatedCompany,
        vsicRelatedReport: action.data.vsicReportData,
        loadingRelatedReport: false
      };
    case Types.INDUSTRY_RELATED_REPORT_FAIL:
      return {
        ...state,
        loadingRelatedReport: false
      };
    default:
      return {
        ...state
      };
  }
};
