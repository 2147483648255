const addSymbols = (e, CanvasJS, message = {}, percent = false) => {
  try {
    var suffixes = ["VNĐ", " Nghìn VNĐ", " Triệu VNĐ", " Tỷ VNĐ"];
    if (!isEmpty(message) && percent) {
      suffixes = ["", message["suffixKP"], message["suffixMP"], message["suffixBP"]];
    } else if (!isEmpty(message) && !percent) {
      suffixes = [message["suffixD"], message["suffixK"], message["suffixM"], message["suffixB"]];
    } else if (isEmpty(message)) {
      suffixes = ["VNĐ", " Nghìn VNĐ", " Triệu VNĐ", " Tỷ VNĐ"];
    } else {
      suffixes = [message["suffixD"], message["suffixK"], message["suffixM"], message["suffixB"]];
    }

    if (e.value >= 0) {
      var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
      if (order > suffixes.length - 1) order = suffixes.length - 1;
      var suffix = suffixes[order];
      const newVal = e.value / Math.pow(1000, order);
      return numberFormat(+newVal.toFixed(2)) + suffix;
    } else {
      let value = Math.abs(e.value);
      var order2 = Math.max(Math.floor(Math.log(value) / Math.log(1000)), 0);
      if (order2 > suffixes.length - 1) order2 = suffixes.length - 1;
      var suffixes2 = suffixes[order2];
      const newVal = value / Math.pow(1000, order2);
      return "-" + numberFormat(+newVal.toFixed(2)) + suffixes2;
    }
  } catch (err) {
    return CanvasJS.formatNumber(e.value);
  }
};
export default addSymbols;

export const addSymbols_USD = (e, CanvasJS, message = {}, percent = false) => {
  try {
    let suffixes = [message["suffixUSD"], message["suffixKUSD"], message["suffixMUSD"], message["suffixBUSD"]];

    if (e.value >= 0) {
      var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
      if (order > suffixes.length - 1) order = suffixes.length - 1;
      var suffix = suffixes[order];
      const newVal = e.value / Math.pow(1000, order);
      return numberFormat(+newVal.toFixed(2)) + suffix;
    } else {
      let value = Math.abs(e.value);
      var order2 = Math.max(Math.floor(Math.log(value) / Math.log(1000)), 0);
      if (order2 > suffixes.length - 1) order2 = suffixes.length - 1;
      var suffixes2 = suffixes[order2];
      const newVal = value / Math.pow(1000, order2);
      return "-" + numberFormat(+newVal.toFixed(2)) + suffixes2;
    }
  } catch (err) {
    return CanvasJS.formatNumber(e.value);
  }
};

export const renderSuf = (suf, type) => {
  if (suf) {
    switch (type) {
      case "2":
        return localStorage.getItem("currentLanguage") === "en" ? "VND" : "VNĐ";
      case "3":
        return "";
      case "4":
        return "";
      default:
        return "%";
    }
  } else {
    return "";
  }
};

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

function numberFormat(amount, decimal = ",", thousands = ".") {
  try {
    return amount
      .toString()
      .replace(thousands, decimal)
      .replace(/\B(?=(\d{3})+(?!\d))/g, thousands);
  } catch (e) {
    // Todo err
  }
}
