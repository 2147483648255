import React, { Component } from "react";
import { isEmpty } from "lodash";
import ParetoChart from "../../../components/CompanyInfo/Chart/ParetoChart";
import MultiColumnImportExport from "../../../components/CompanyInfo/Chart/MultiColumnImportExport";

import { connect } from "react-redux";
import Linechart from "../../../components/ChartDemo/Linechart";
import { defaultPageConpanyInfo } from "../../../constants/defaultValues";
import {
  actAddToCart,
  actGetChartGrowthRate,
  actGetOwnerStructor,
  actGetDataNetProfitColumnChart,
  actRequestFinancial,
  actResetFinancial
} from "../../../redux/actions";
import { requestFinancial } from "../../../services/companyInfo.service";
import PieChartOwner from "../../../components/CompanyInfo/Chart/PieChartOwner";
import PieChartImportExport from "../../../components/CompanyInfo/Chart/PieChartImportExport";

import ColumnComparisonChart from "../../../components/CompanyInfo/Chart/ColumnComparisonChart";
import { handleFlyBuyCart } from "../../../assets/js/handleflybuycart.js";
import { formatMoney } from "../../../utils/formatMoney";
import { numberFormat } from "../../../utils/numberFormat";
import LoaderTable from "../Loader/LoaderTable";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { _Basic_, _Standard_, _Premium_ } from "../../../constants/rolesModel";
import { Table, Input, Label } from "reactstrap";
import Radarchart from "../../../components/ChartDemo/Radarchart";
import { Popup } from "semantic-ui-react";
import NoData from "../../../components/NoData";
import { Link } from "react-router-dom";
import { callApiServer } from "../../../utils/callApiServer";
import { getDataColumnImportAndExport, getDataPieImportAndExport } from "../../../services/companyInfo.service";
import Button from "../../../components/CustomButton/CustomButton";

class TabInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreditDN: this.props.checkCreditDN,
      selectedOptionYear: "",
      selectedOptionID: "",
      selectedYearDownload: "",
      selectedOptionYearDown: "",
      isChooseToBuy: true,
      isChooseDownload: true,
      dataChartStructure: [],
      chartComparision: {},
      valueFinancial: this.props.valueFinancial, // set (check tín dụng - thường)
      table1Type: "table1Type1",
      dataTable_1: [],
      dataTable_2: [],
      dataTable_3: [],
      dataChartFinance: [], // ROA/ROE
      isLoadingDataChartFinance: false,
      dataProfitMargin: [], // DT Thuần
      isLoadingDataProfitMargin: false,

      //xuất nhập khẩu
      dataImportAndExport_Column: [],
      isLoadingDataImportAndExport_Column: false,
      dataImportAndExport_Pie: [],
      isLoadingDataImportAndExport_Pie: false,
      dataPieActive: "import",
      dataPieImport: [],
      dataPieExport: []
    };
  }
  formatDataChart = (data) => {
    var flattened =
      data.length > 0 &&
      data.reduce(function(prev, props) {
        return [
          ...prev,
          {
            y: props.percent ? props.percent : 0,
            name: props.nameOwner ? props.nameOwner : ""
          }
        ];
      }, []);
    this.setState({
      dataChartStructure: flattened
    });
  };
  handleBuyItem = (params) => {
    const { detail } = this.props;
    const model = {
      id: detail.id,
      name: detail.name,
      englishName: detail.englishName,
      vsicId: detail.vsicId,
      taxNumber: detail.taxNumber,
      requestData: params,
      viracServiceId: 1, // dữ liệu doanh nghiệp
      type: 1,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  componentDidMount() {
    setTimeout(() => {
      handleFlyBuyCart();
    }, 500);
    this.resetData();
    this.initialData();

    // Số liệu xuất nhập khẩu
    this.getDataColumnImportAndExport();
    this.getDataPieImportAndExport();
  }
  // ROA/ROE
  async getDataChartFinance(body) {
    this.setState({
      isLoadingDataChartFinance: true
    });
    try {
      const response = await requestFinancial(body);
      if (response && response.data && response.data.data) {
        this.setState({
          dataChartFinance: response.data.data,
          isLoadingDataChartFinance: false
        });
      } else {
        this.setState({
          dataChartFinance: [],
          isLoadingDataChartFinance: false
        });
      }
    } catch (error) {
      this.setState({
        dataChartFinance: [],
        isLoadingDataChartFinance: false
      });
    }
  }
  // ROA/ROE
  async getDataProfitMargin(body) {
    this.setState({
      isLoadingDataProfitMargin: true
    });
    try {
      const response = await requestFinancial(body);
      if (response && response.data && response.data.data) {
        this.setState({
          dataProfitMargin: response.data.data,
          isLoadingDataProfitMargin: false
        });
      } else {
        this.setState({
          dataProfitMargin: [],
          isLoadingDataProfitMargin: false
        });
      }
    } catch (error) {
      this.setState({
        dataProfitMargin: [],
        isLoadingDataProfitMargin: false
      });
    }
  }

  // Số liệu xuất nhật khẩu
  // Biểu đồ cột
  async getDataColumnImportAndExport() {
    this.setState({
      isLoadingDataImportAndExport_Column: true
    });
    try {
      const response = await getDataColumnImportAndExport(this.props.id);
      if (response && response.data && response.data.data) {
        this.setState({
          dataImportAndExport_Column: response.data.data,
          isLoadingDataImportAndExport_Column: false
        });
      } else {
        this.setState({
          dataImportAndExport_Column: [],
          isLoadingDataImportAndExport_Column: false
        });
      }
    } catch (error) {
      this.setState({
        dataImportAndExport_Column: [],
        isLoadingDataImportAndExport_Column: false
      });
    }
  }
  //Biểu đồ tròn
  async getDataPieImportAndExport() {
    this.setState({
      isLoadingDataImportAndExport_Pie: true
    });
    try {
      const response = await getDataPieImportAndExport(this.props.id);
      if (response && response.data && response.data.data) {
        this.setState({
          dataImportAndExport_Pie: response.data.data,
          dataPieImport: response.data.data.importData,
          dataPieExport: response.data.data.exportData,

          isLoadingDataImportAndExport_Pie: false
        });
      } else {
        this.setState({
          dataImportAndExport_Pie: [],
          dataPieImport: [],
          dataPieExport: [],
          isLoadingDataImportAndExport_Pie: false
        });
      }
    } catch (error) {
      this.setState({
        dataImportAndExport_Pie: [],
        dataPieImport: [],
        dataPieExport: [],
        isLoadingDataImportAndExport_Pie: false
      });
    }
  }

  changeDataPie(type) {
    if (type === "import") {
      this.setState({
        dataPieActive: "import"
      });
    } else if (type === "export") {
      this.setState({
        dataPieActive: "export"
      });
    }
  }

  resetData() {
    this.props.resetFinancial();
  }
  initialData = () => {
    const { valueFinancial } = this.props;
    if (this.props.id !== null || this.props.id !== undefined) {
      this.props.getOwnerStructor(this.props.id);
      this.props.getChartGrowthRate(this.props.id);

      if (this.props.detail.set) {
        // roa/roe - tỷ lệ tăng trưởng LN thuần
        const set = this.props.detail.set;
        const setThuong = defaultPageConpanyInfo.tabRatio[0].set;
        const setTindung = defaultPageConpanyInfo.tabRatio[1].set;
        let arrHeso1 = [];
        let arrHeso2 = [];
        if (setThuong.includes(set)) {
          this.setState({ isThuong: true });
          arrHeso1 = ["34", "35"];
          arrHeso2 = ["31"];
        } else if (setTindung.includes(set)) {
          this.setState({ isThuong: false });
          arrHeso1 = ["16", "17"];
          arrHeso2 = ["20"];
        }
        const model1 = {
          arrHeso: [...arrHeso1],
          arrKv: [],
          id: this.props.id,
          isCheck: false
        };
        const model2 = {
          arrHeso: [...arrHeso2],
          arrKv: [],
          id: this.props.id,
          isCheck: false
        };
        // this.props.requestFinancial(model1);
        // this.props.requestFinancial(model2);

        // ROA/ROE
        this.getDataChartFinance(model1);
        // DT Thuần
        this.getDataProfitMargin(model2);
      }

      if (valueFinancial !== undefined && valueFinancial !== null) {
        this.props.handleGetDataNetProfitColumnChart(this.props.id, valueFinancial);
      }
    }
  };

  handleDownloadContract = (name, id) => {
    if (name !== "") {
      this.setState({
        selectedOptionYearDown: name,
        selectedYearDownload: id,
        isChooseDownload: false
      });
    } else {
      this.setState({
        isChooseDownload: true
      });
    }
  };
  funcCustomDataKv(data) {
    if (data.length > 0) {
      return data.reduce(function(prev, vlue) {
        return [...prev, vlue.key];
      }, []);
    } else {
      return [""];
    }
  }

  handleChangeYearFinancial = (params, id) => {
    this.setState({
      selectedOptionYear: params,
      selectedOptionId: id,
      isChooseToBuy: false
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { structors } = nextProps.dataOwner;
    if (structors) {
      this.formatDataChart(structors);
    }

    if (nextProps.accountManagement && nextProps.accountManagement.userAccount) {
      if (nextProps.accountManagement.userAccount.id) {
        this.setState({
          userId: nextProps.accountManagement.userAccount.id
        });
      } else {
        this.setState({
          ...this.state
        });
      }
    }
    const { revColumnChart } = nextProps.revColumnChart;
    //Get data Chart Comparision
    if (revColumnChart !== undefined && revColumnChart && isEmpty(revColumnChart) === false) {
      this.setState({
        chartComparision: { ...revColumnChart }
      });
    }
    if (this.props.intl.locale !== nextProps.intl.locale) {
      this.initialData();
    }
  }
  denyClick = () => {};
  renderWebsite = (param) => {
    if (param) {
      return param.replace("https://", "");
    }
    return "";
  };
  returnDataChart = (arr, num) => {
    let index = arr.findIndex((item) => parseInt(item.type) === num);
    if (index < 0) {
      return [];
    } else {
      return arr[index];
    }
  };

  onTable1TypeChanged = (e) => {
    const { value } = e.target;

    this.setState({
      table1Type: value
    });
  };

  render() {
    const {
      isCreditDN,
      nameKv,
      dataChartStructure,

      isLoadingDataImportAndExport_Column,
      dataImportAndExport_Column,
      dataImportAndExport_Pie,
      isLoadingDataImportAndExport_Pie,
      dataPieActive,
      dataChartFinance,
      isLoadingDataChartFinance,
      dataProfitMargin,
      isLoadingDataProfitMargin
    } = this.state;

    const { detail, justView } = this.props;
    const roles = _Premium_;

    const { companyInfo, companyFinancialInfo } = this.props.detail;
    const { loadingDT } = this.props.reducer;
    const { messages } = this.props.intl;
    const { loadingGRChart, dataChartGrowth, dataChartGrowthType } = this.props.dataReducer.dataBasicGrowth;
    const { finalcial, loadingFNChart } = this.props.dataReducer.dataFinacial;
    const { financialStatements } = this.props.detail.companyFinancialInfo;

    const radarChartProfitMargin = [];
    const radarChartLabel = [];
    const radarChartData = [{}];
    dataProfitMargin.forEach((e) => {
      radarChartData[0].label = e.title;
      e.dataChart.forEach((item) => {
        radarChartProfitMargin.push(item.y);
        radarChartLabel.push(item.x);
      });
      radarChartData[0].data = radarChartProfitMargin;
    });
    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");

    return (
      <React.Fragment>
        <LoaderTable isLoaded={loadingDT} styleCustom={{ height: "300px" }} />
        <div className="company_info_views " hidden={!loadingDT} ref={(el) => (this.componentRef = el)}>
          <div className="row equal-height">
            <div className="col-lg-6 col-xl-3 pb-4">
              <div className="card-item info_view_h_">
                <div className="card-title card-title-line">
                  <IntlMessages id="detailCompany.h1Table" />
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <IntlMessages id="detailCompany.LegalrepresentativeShortCut" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {companyInfo && companyInfo.legalRepresentativesProfile ? companyInfo.legalRepresentativesProfile : ""}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <IntlMessages id="newWord.incorporatedDateShortCut" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {companyInfo && companyInfo.incorporatedDate ? companyInfo.incorporatedDate.replace(/-/g, "/") : ""}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <IntlMessages id="detailCompany.Accountingperiod" />
                  </div>
                  <div className="info_tbody_l_table_item_r">{detail && detail.accountingPeriod ? detail.accountingPeriod : ""}</div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <IntlMessages id="detailCompany.FiscalperiodShortCut" />
                  </div>
                  <div className="info_tbody_l_table_item_r">{companyInfo && companyInfo.fiscalPeriod ? companyInfo.fiscalPeriod : ""}</div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <IntlMessages id="detailCompany.NoemployeesShortCut" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {companyInfo && companyInfo.noOfEmployee ? formatMoney(parseInt(companyInfo.noOfEmployee, 10)) : ""}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <IntlMessages id="detailCompany.AreaBusiness" />
                  </div>
                  <div className="info_tbody_l_table_item_r">{companyInfo && companyInfo.mainBusiness ? companyInfo.mainBusiness : ""}</div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <IntlMessages id="detailCompany.Parentcompany" />
                  </div>
                  <div className="info_tbody_l_table_item_r">{companyInfo && companyInfo.parentCompanyName ? companyInfo.parentCompanyName : ""}</div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <span>
                      <IntlMessages id="detailCompany.ActionInfo" />
                    </span>
                    <Popup
                      trigger={<span className="material-icons text-cyan tooltip-icon cursor-default ml-3">info</span>}
                      className="custom-popup"
                      position={"right center"}
                    >
                      <Popup.Content>
                        <ul className="pl-0 mb-0">
                          <li>
                            - <IntlMessages id="detailCompany.MainBusiness" />
                          </li>
                          <li>
                            - <IntlMessages id="detailCompany.KeyProduct" />
                          </li>
                          <li>
                            - <IntlMessages id="detailCompany.Keymarket" />
                          </li>
                        </ul>
                      </Popup.Content>
                    </Popup>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-3 pb-4">
              <div className="card-item info_view_h_">
                <div className="card-title card-title-line finance-info">
                  <IntlMessages id="newWord.financialInfo" />
                  {financialStatements && financialStatements.length > 0 && (
                    <Popup
                      trigger={<span className="material-icons text-cyan tooltip-icon cursor-default ml-3">info</span>}
                      className="custom-popup"
                      position={"right center"}
                    >
                      <Popup.Header>
                        <IntlMessages id="detailCompany.FinancialReportingType" />
                      </Popup.Header>
                      <Popup.Content>
                        <ul className="pl-0 mb-0">
                          {financialStatements.map((props, index) => {
                            return (
                              <li key={index}>
                                -{props.year}: {language === "en" ? props.enType : props.type}
                              </li>
                            );
                          })}
                        </ul>
                      </Popup.Content>
                    </Popup>
                  )}
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_r_table_item_l">
                    <IntlMessages id="newWord.charteredCapital" />
                  </div>
                  <div className="info_tbody_r_table_item_r">
                    {companyFinancialInfo && companyFinancialInfo.charterdCapital
                      ? formatMoney(parseInt(companyFinancialInfo.charterdCapital, 10)) + messages["suffixD"]
                      : ""}
                  </div>
                </div>

                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_r_table_item_l">
                    <IntlMessages id="detailCompany.NetrevenueShortcut" />
                  </div>
                  <div className="info_tbody_r_table_item_r">
                    {companyFinancialInfo && companyFinancialInfo.netRevenue
                      ? formatMoney(parseInt(companyFinancialInfo.netRevenue, 10)) + messages["suffixD"]
                      : ""}
                  </div>
                </div>

                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_r_table_item_l" title={messages["detailCompany.Netprofit"]}>
                    <IntlMessages id="detailCompany.NetprofitShortcut" />
                  </div>
                  <div className="info_tbody_r_table_item_r">
                    {companyFinancialInfo && companyFinancialInfo.netProfit
                      ? formatMoney(parseInt(companyFinancialInfo.netProfit, 10)) + messages["suffixD"]
                      : ""}
                  </div>
                </div>

                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_r_table_item_l">
                    {" "}
                    <IntlMessages id="detailCompany.Totalassets" />
                  </div>
                  <div className="info_tbody_r_table_item_r">
                    {companyFinancialInfo && companyFinancialInfo.totalAssets
                      ? formatMoney(parseInt(companyFinancialInfo.totalAssets, 10)) + messages["suffixD"]
                      : ""}
                  </div>
                </div>

                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_r_table_item_l">
                    <IntlMessages id="detailCompany.OwnerequityShortcut" />
                  </div>
                  <div className="info_tbody_r_table_item_r">
                    {companyFinancialInfo && companyFinancialInfo.ownerEquity
                      ? formatMoney(parseInt(companyFinancialInfo.ownerEquity, 10)) + messages["suffixD"]
                      : ""}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l"> ROE</div>
                  {companyFinancialInfo && companyFinancialInfo.roe && (
                    <div className={`info_tbody_l_table_item_r ` + (Number(companyFinancialInfo.roe) < 0 ? "text-danger" : "text-success")}>
                      {numberFormat(companyFinancialInfo.roe) + "%"}
                    </div>
                  )}
                </div>

                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l"> ROA</div>
                  {companyFinancialInfo && companyFinancialInfo.roa && (
                    <div className={`info_tbody_l_table_item_r ` + (Number(companyFinancialInfo.roa) < 0 ? "text-danger" : "text-success")}>
                      {numberFormat(companyFinancialInfo.roa) + "%"}
                    </div>
                  )}
                </div>

                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    {" "}
                    <IntlMessages id="newWord.updatePeriod" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {companyFinancialInfo && companyFinancialInfo.dateUpdate ? companyFinancialInfo.dateUpdate : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-3 pb-4">
              <div className="finance-group card-item">
                <div className="card-title card-title-line">ROA/ROE</div>
                <div className="chart-info chart-line flex-column align-items-stretch">
                  {isLoadingDataChartFinance ? (
                    <LoaderTable isLoaded={!isLoadingDataChartFinance} styleCustom={{ height: "500px" }} />
                  ) : dataChartFinance && dataChartFinance.length ? (
                    <Linechart title={""} suf={true} percent={true} dataChart={dataChartFinance} dataSuf="%" enabledToolTip={true} />
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-3 pb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  {this.state.isThuong ? <IntlMessages id="detailCompany.NetProfitMargin" /> : <IntlMessages id="detailCompany.NetProfitGrowthRate" />}
                </div>
                <div className="chart-info chart-line flex-column align-items-stretch">
                  {isLoadingDataProfitMargin ? (
                    <LoaderTable isLoaded={!isLoadingDataProfitMargin} styleCustom={{ height: "500px" }} />
                  ) : dataProfitMargin && dataProfitMargin.length > 0 ? (
                    <Radarchart dataChart={radarChartData} labels={radarChartLabel} />
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row equal-height">
            <div className="col-xl-4 pb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="newWord.performanceReview" />
                </div>
                <div className="chart-info chart-multi">
                  {loadingGRChart ? (
                    <LoaderTable isLoaded={!loadingGRChart} styleCustom={{ height: "500px" }} />
                  ) : dataChartGrowth && dataChartGrowth.length > 0 ? (
                    <ParetoChart
                      dataColumn_2={this.returnDataChart(dataChartGrowthType, 2)}
                      nameKv={nameKv}
                      dataSpline_1={this.returnDataChart(dataChartGrowthType, 4)}
                      dataColumn_1={this.returnDataChart(dataChartGrowthType, 1)}
                      dataColumn_3={this.returnDataChart(dataChartGrowthType, 3)}
                      backgroundColor={"#02164d"}
                      tickColor={"rgba(144, 155, 175, 0.3)"}
                      isCreditDN={isCreditDN && isCreditDN}
                      dataChartDefault={dataChartGrowth}
                      enabledToolTip={true}
                      roles={roles}
                      hiddenAll={this.state.hiddenAll}
                      hiddenLegend={this.state.hiddenLegend}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-4 pb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="averages.titleColumn" />
                </div>
                <ColumnComparisonChart backgroundColor={"#02164d"} vsicName="aa" enabledToolTip={roles && roles === _Premium_} />
              </div>
            </div>

            <div className="col-xl-4 pb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="ownership.Ownershipstructure" />
                </div>
                {dataChartStructure && dataChartStructure.length > 0 ? (
                  <div className="chart-info chart-circle">
                    <div className="container-chart">
                      <PieChartOwner dataChartStructure={dataChartStructure} backgroundColor={"#02164d"} />
                    </div>
                  </div>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>

          {/* Thông tin xuất nhập khẩu */}
          <div className="row equal-height">
            <div className="col-xl-6 pb-4">
              <div className="card-item">
                {isLoadingDataImportAndExport_Column ? (
                  <LoaderTable isLoaded={!isLoadingDataImportAndExport_Column} styleCustom={{ height: "500px" }} />
                ) : dataImportAndExport_Column ? (
                  <div>
                    <div className="card-title card-title-line">
                      <span> {language === "vi" ? dataImportAndExport_Column.title : dataImportAndExport_Column.enTitle}</span>
                    </div>
                    <div className="chart-info chart-multi">
                      <MultiColumnImportExport
                        dataImport={dataImportAndExport_Column.importTurnover}
                        dataExport={dataImportAndExport_Column.exportTurnover}
                        dataChartRevenue={this.returnDataChart(dataChartGrowthType, 1)}
                        backgroundColor={"#02164d"}
                        tickColor={"rgba(144, 155, 175, 0.3)"}
                        isCreditDN={isCreditDN && isCreditDN}
                        dataChartDefault={dataChartGrowth}
                        enabledToolTip={roles && (roles === _Standard_ || roles === _Premium_)}
                        roles={roles}
                      />
                    </div>
                  </div>
                ) : (
                  <NoData></NoData>
                )}
              </div>
            </div>

            <div className="col-xl-6 pb-4">
              <div className="card-item">
                {isLoadingDataImportAndExport_Pie ? (
                  <LoaderTable isLoaded={!isLoadingDataImportAndExport_Pie} styleCustom={{ height: "500px" }} />
                ) : dataImportAndExport_Pie ? (
                  <div>
                    <div className="card-title card-title-line">
                      {dataPieActive === "import" && (
                        <span>{language === "vi" ? dataImportAndExport_Pie.importTitle : dataImportAndExport_Pie.importEnTitle}</span>
                      )}
                      {dataPieActive === "export" && (
                        <span>{language === "vi" ? dataImportAndExport_Pie.exportTitle : dataImportAndExport_Pie.exportEnTitle}</span>
                      )}
                    </div>
                    <div className="chart-info chart-circle">
                      <div className="col-xl-2 mb-5 btn-container">
                        <button
                          onClick={() => this.changeDataPie("import")}
                          className={dataPieActive === "import" ? "btn-import btn-active mr-2" : "btn-import mr-2"}
                        >
                          {language === "vi" ? "Nhập khẩu" : "Import"}
                        </button>
                        <button
                          onClick={() => this.changeDataPie("export")}
                          className={dataPieActive === "export" ? "btn-import btn-active mr-2" : "btn-import mr-2"}
                        >
                          {language === "vi" ? "Xuất khẩu" : "Export"}
                        </button>
                      </div>
                      <div className="col-xl-10">
                        <div className="container-chart">
                          <PieChartImportExport dataChart={dataImportAndExport_Pie} dataActive={dataPieActive} backgroundColor={"#02164d"} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userAccount: state.accountReducer.userAccount,
    dataOwner: state.companyReducer.owner,
    dataChartFinance: state.companyReducer.dataFinacial,
    revColumnChart: state.companyReducer.revColumnChart,
    detail: state.companyReducer.companyDetail,
    reducer: state.companyReducer,
    carts: state.cartReducer,
    dataReducer: state.companyReducer,
    CommonReducer: state.CommonReducer,
    companyDetailReducer: state.companyReducer.companyDetail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Thêm vào giỏ
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    },
    // Chart ROA/ROE - Tỷ lệ TTLN/Biên LNT
    requestFinancial: (body) => {
      dispatch(actRequestFinancial(body));
    },
    // Reset Chart ROA/ROE - Tỷ lệ TTLN/Biên LNT
    resetFinancial: () => {
      dispatch(actResetFinancial());
    },
    // Đánh giá hoạt động DN
    getChartGrowthRate: (id) => {
      dispatch(actGetChartGrowthRate(id));
    },
    // Tổng doanh thu thuần ngành
    handleGetDataNetProfitColumnChart: (id, valueFinancial) => {
      dispatch(actGetDataNetProfitColumnChart(id, valueFinancial));
    },
    // Cấu trúc chủ sở hữu
    getOwnerStructor: (id) => {
      dispatch(actGetOwnerStructor(id));
    }
    // Tooltip ở thông tin tài chính
    // getCompanyFinancialInfo: (id) => {
    //   dispatch(actGetCompanyFinancialInfo(id));
    // }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabInfo));
