import React, { Component } from "react";
import { Search } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { debounce } from "lodash";
import { totalValueFilter } from "../../constants/models";
import Swiper from "swiper";
import ModalListVSIC from "./CompanyChild/ListVSIC";
import Button from "../../components/CustomButton/CustomButton";
import { _Standard_, _Premium_, _Basic_ } from "../../constants/rolesModel";
import SweetAlert from "react-bootstrap-sweetalert";

const resultRenderer = ({ description, title, id, vsiccode }) => (
  <div key={id}>
    <p style={{ textTransform: "uppercase", fontWeight: "bold" }}>{title}</p>
    <p style={{ color: "gray" }}>
      {description} - {vsiccode}
    </p>
  </div>
);
class CompanyInfoFillter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleAdvanceSearch: false,
      toggleModalListVSIC: false,
      alert: ""
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.swiper();
    }, 500);
  }

  swiper = () => {
    new Swiper(".swiper-container", {
      grabCursor: true,
      autoplay: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      slidesPerView: 2
    });
  };

  renderLabel(params) {
    const { messages } = this.props.intl;
    let index = totalValueFilter.findIndex((x) => x.label === params);
    if (index > -1) {
      return messages[`${params}`];
    } else {
      return params;
    }
  }

  toggleAdvanceSearch = () => {
    this.setState({
      ...this.state,
      isToggleAdvanceSearch: !this.state.isToggleAdvanceSearch
    });
  };

  handleModalListVSIC = () => {
    this.setState((state) => ({
      toggleModalListVSIC: !state.toggleModalListVSIC
    }));
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  render() {
    const {
      txtsearch,
      handleSubmit,
      onKeyDown,
      listSuggest,
      loading,
      results,
      handleSearchChange,
      handleResultSelect,
      isRedirect,
      txtsearchError,
      roles
    } = this.props;

    const { messages } = this.props.intl;
    var expand = this.state.isToggleAdvanceSearch ? "expand" : "";
    this.swiper();

    // Các item có roles Basic sẽ không thể click để xem chi tiết
    const itemNotPermisionToView = (textDisplay) => {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return (
        <a className="keyword-item" href="#" onClick={() => this.actAlert(messages[`newWord.upgradeAccount`])}>
          {textDisplay}
        </a>
      );
    };
    return (
      <React.Fragment>
        {this.state.alert}
        <div className={!isRedirect ? "company-info-fillter custom-companyinfo" : "company-info-fillter"}>
          <div className="content-fillter">
            {this.state.toggleModalListVSIC && <ModalListVSIC toggleModal={this.state.toggleModalListVSIC} handleCloseForget={this.handleModalListVSIC} />}
            <div className="company-search-bar">
              <div className="heading">
                <div className="section-title">
                  <IntlMessages id="menu.companyinformationsearch" />
                </div>
                <div className="visc-list">
                  <a href="#" className="text-cyan" onClick={this.handleModalListVSIC}>
                    <em className="material-icons">featured_play_list</em>
                    <IntlMessages id="filter.dsnn" />
                  </a>
                </div>
              </div>
              <div className="ui action input fluid customFilter">
                <Search
                  className="searchFilter"
                  loading={loading}
                  onResultSelect={handleResultSelect}
                  onSearchChange={debounce(handleSearchChange, 500, {
                    leading: true
                  })}
                  onKeyDown={onKeyDown}
                  placeholder={messages["filter.textInput"]}
                  results={results}
                  resultRenderer={resultRenderer}
                  defaultValue={txtsearch}
                  minCharacters={3}
                  noResultsMessage={messages["search.notfound.text"]}
                />
                <Button disabled={loading} isLoading={loading} className={`common-button ml-3 ${loading ? "disabled" : ""}`} onClick={() => handleSubmit()}>
                  <IntlMessages id="filter.buttonSearch" />
                  <em className="material-icons">search</em>
                </Button>
              </div>
              {txtsearchError && (
                <div className="form-error">
                  <IntlMessages id={txtsearchError} />
                </div>
              )}
            </div>
            <div className="company-search-option">
              <div className={`search-bottom ${expand}`}>
                <div className="search-keyword">
                  {listSuggest && listSuggest.length > 0 && (
                    <div className="swiper-container">
                      <div className="swiper-wrapper">
                        {listSuggest.map((props, key) => {
                          return (
                            <div className="swiper-slide" key={key}>
                              {roles && (roles === _Basic_ || roles === _Standard_) ? (
                                itemNotPermisionToView(props.name ? props.name : "")
                              ) : (
                                <Link className="keyword-item" to={`chi-tiet-doanh-nghiep/${props.id}`}>
                                  {props.name ? props.name : ""}
                                </Link>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {listSuggest && listSuggest.length > 0 && (
                    <div className="swiper-navigation">
                      <div className="swiper-btn btn-prev swiper-button-prev">
                        <span className="material-icons">chevron_left</span>
                      </div>
                      <div className="swiper-btn btn-next swiper-button-next">
                        <span className="material-icons">chevron_right</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="search-advance-toggle">
                  <Link className="text-cyan" to="/tim-kiem-danh-sach-doanh-nghiep">
                    <IntlMessages id="filter.advanceSearch" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </React.Fragment>
    );
  }
}

export default injectIntl(CompanyInfoFillter);
