import React, { Component } from "react";
import { Table } from "reactstrap";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../../utils/IntlMessages";
import NoData from "../../../NoData";
export class TableAffiliates extends Component {
    render() {
        const { companyRefData } = this.props;
        return (
            <div className="">
                <div className="card-title card-title-line">
                    <IntlMessages id="ownership.Subsidiaryandaffiliate" />
                </div>
                {companyRefData && companyRefData.length > 0 ? (
                    <div className="table-custom">
                        <Table responsive>
                            <thead>
                                <tr className="owner-affiliates-title">
                                    <th style={{ width: "70px" }}><IntlMessages id="ownership.stt" /></th>
                                    <th><IntlMessages id="ownership.Companyname" /></th>
                                    <th><IntlMessages id="ownership.Taxcode" /></th>
                                    <th style={{ textAlign: "left" }}>VSIC</th>
                                    <th><IntlMessages id="ownership.Relationship" /></th>
                                    <th><IntlMessages id="ownership.Updateddate" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {companyRefData.length > 0 &&
                                    companyRefData.map((props, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>
                                                    <a
                                                        className="text-cyan"
                                                        href={
                                                            props.companyChildId ? `/chi-tiet-doanh-nghiep/${props.companyChildId}` : "#a"
                                                        }
                                                    >
                                                        {props.companyName ? props.companyName : ""}
                                                    </a>

                                                </td>
                                                <td>
                                                    {props.mst ? props.mst : ""}
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                    {props.vsic ? props.vsic : ""}
                                                </td>
                                                <td>
                                                    {props.statusName
                                                        ? props.statusName
                                                        : ""}
                                                </td>
                                                <td>
                                                    {props.dateUpdate
                                                        ? props.dateUpdate
                                                        : ""}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <NoData />
                )}
            </div>
        );
    }
}

export default injectIntl(TableAffiliates);
