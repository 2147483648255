export function setCookie(cname, cvalue) {
  var CookieDate = new Date();
    CookieDate.setFullYear(CookieDate.getFullYear() +10);
  var expires = "expires="+ CookieDate.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

export function deleteCookie(cname){
    document.cookie = cname +'=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}