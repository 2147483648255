import React, { Component } from "react";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import ModalConfidentiality from "./ModalConfidentiality";
import ModalCopyright from "./ModalCopyright";
import ModalDisclaimer from "./ModalDisclaimer";
import LiveChat from "react-livechat";
import img from "../../assets/images/logoSaleNoti.png";
export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "",
      toggleModalConfidentiality: false,
      toggleModalDisclaimer: false,
      toggleModalCopyright: false
    };
    this.handleModalConfidentiality = this.handleModalConfidentiality.bind(this);
  }

  // setLang() {
  //   let language = localStorage.getItem("currentLanguage");
  //   if (language !== this.state.lang) {
  //     if (language === "vi") {
  //       this.setState({
  //         lang: language,
  //         source: PdfVi
  //       });
  //       return;
  //     } else {
  //       this.setState({
  //         lang: language,
  //         source: PdfEn
  //       });
  //     }
  //   }
  // }

  hiddenLiveChat() {
    const URL_HIDDEN = "https://viracresearch.com";
    let value = false;

    if (document && document.location && document.location.ancestorOrigins) {
      let ancestorOrigins = Array.from(document.location.ancestorOrigins);
      ancestorOrigins.map((item) => {
        if (item.includes(URL_HIDDEN)) {
          value = true;
        }
      });
    } else {
      value = false;
    }
    return value;
  }

  handleModalConfidentiality() {
    this.setState((state) => ({
      toggleModalConfidentiality: !state.toggleModalConfidentiality
    }));
  }

  handleModalDisclaimer = () => {
    this.setState((state) => ({
      toggleModalDisclaimer: !state.toggleModalDisclaimer
    }));
  };

  handleModalCopyright = () => {
    this.setState((state) => ({
      toggleModalCopyright: !state.toggleModalCopyright
    }));
  };

  goToConfirmPage() {
    window.open("http://online.gov.vn/Home/WebDetails/86982?AspxAutoDetectCookieSupport=1", "_blank");
  }

  render() {
    var d = new Date();
    var n = d.getFullYear();
    var addClass = window.location.href.includes("menu-dieu-huong") ? "is-main-menu" : "";
    return (
      <footer>
        {this.state.toggleModalConfidentiality && (
          <ModalConfidentiality toggleModal={this.state.toggleModalConfidentiality} handleCloseForget={this.handleModalConfidentiality} />
        )}
        {this.state.toggleModalDisclaimer && <ModalDisclaimer toggleModal={this.state.toggleModalDisclaimer} handleCloseForget={this.handleModalDisclaimer} />}
        {this.state.toggleModalCopyright && <ModalCopyright toggleModal={this.state.toggleModalCopyright} handleCloseForget={this.handleModalCopyright} />}
        <div className="hotline text-center">
          <span className="text-bold">
            <IntlMessages id="footer.hotline" />:{" "}
          </span>
          <a href="tel:0938 677 566">0938 677 566</a>
          {!this.hiddenLiveChat() && (
            <div className="livechat">
              <LiveChat license={11287482} />
            </div>
          )}
        </div>
        <div className={`footer-wrapper flex items-center justify-between ${addClass}`}>
          <div className="footer-term">
            <p>
              {" "}
              <span onClick={() => this.goToConfirmPage()} className="img-noti">
                <img src={img} alt="" />
              </span>
              <span>|</span>
              <a href="#" onClick={this.handleModalConfidentiality}>
                <IntlMessages id="footer.confidentiality" />
              </a>
              <span>|</span>
              <a href="#" onClick={() => this.handleModalDisclaimer()}>
                <IntlMessages id="footer.disclaimer" />
              </a>
            </p>
          </div>
          <div className="footer-copyright">
            <p>
              <a href="#" onClick={() => this.handleModalCopyright()}>
                COPYRIGHT &copy; {n} VIRAC
              </a>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
export default injectIntl(Footer);
