import React, { Component } from "react";
import { Link } from "react-router-dom";
import { subPhone } from "../../../utils/func.util";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { Popup } from "semantic-ui-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { formatMoney } from "../../../utils/formatMoney";

import { _Standard_, _Basic_ } from "../../../constants/rolesModel";

export class CompanyItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: false,
      alert: ""
    };
  }

  renderWebsite = (param) => {
    if (param) {
      return param.replace("https://", "");
    }
    return "";
  };

  replaceLocation() {
    if (this.props && this.props.address) {
      var location = this.props.address;
      location = location.split(" ").join("+");
      return `https://www.google.com/maps/search/?api=1&query=${location}`;
    } else {
      // todo
    }
  }

  toggleExpand = () => {
    this.setState({
      ...this.state,
      isExpand: !this.state.isExpand
    });
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  render() {
    const props = this.props;
    const { roles } = this.props;
    const { messages } = this.props.intl;

    const language = localStorage.getItem("currentLanguage");

    const itemNotPermisionToView = (textDisplay) => {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return (
        <a href="#" onClick={() => this.actAlert(messages[`newWord.upgradeAccount`])}>
          {textDisplay}
        </a>
      );
    };
    return (
      <React.Fragment>
        {this.state.alert}

        <div className="col-lg-6 pb-4">
          <div className="result-item">
            <div className="vietnamese-name">
              <Link to={`/chi-tiet-doanh-nghiep/${props.id ? props.id : ""}`}>{props.name ? props.name : ""}</Link>
            </div>
            <div className="english-name">
              <Link to={`/chi-tiet-doanh-nghiep/${props.id ? props.id : ""}`}>{props.nameEnglish ? props.nameEnglish : ""}</Link>
            </div>
            <div className="address has-icon">
              <span className="material-icons">place</span>
              {props.address ? props.address : ""}
            </div>

            <div className="info-wrap">
              <ul className="info">
                <li className="has-icon">
                  <span className="material-icons">tag</span>
                  <IntlMessages id="page.MST" />: {props.taxNumber ? props.taxNumber : "N/A"}
                </li>
                <li className="has-icon">
                  <span className="material-icons">business_center</span>
                  VSIC: {props.vsic ? " " + props.vsic : "...."}
                </li>
              </ul>
              <a className="view-map" target="_blank" rel="noopener noreferrer" href={this.replaceLocation()}>
                {" "}
                <span className="text">
                  <IntlMessages id="viewMap" />
                </span>
                <span className="material-icons">map</span>
              </a>
            </div>
            {/* <Collapse isOpen={this.state.isExpand}> */}
            <div className="result-more-content">
              <ul className="info">
                <li className="has-icon">
                  <span className="material-icons">phone</span>
                  {props.phoneNumber ? subPhone(props.phoneNumber) : "N/A"}
                </li>
                <li className="has-icon">
                  <span className="material-icons">print</span>
                  {props.fax ? props.fax : "N/A"}
                </li>
                <li className="has-icon">
                  <span className="material-icons">public</span>
                  {props.webSite ? (
                    <a
                      className="text-cyan"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={props.webSite ? "https://" + this.renderWebsite(props.webSite) : ""}
                    >
                      {props.webSite}
                    </a>
                  ) : (
                    <span>N/A</span>
                  )}
                </li>
              </ul>
            </div>
            {/* </Collapse> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default injectIntl(CompanyItem);
