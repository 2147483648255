import { isEmpty, forEach } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import AlertWarning from "../../components/SweetAlert/AlertWarning";
import { actCreateOrder, actGetOrder, actRemoveFromCart, actSendRequestData } from "../../redux/Cart/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "../../components/CustomButton/CustomButton";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { formatMoney } from "../../utils/formatMoney";
import { Checkbox } from "semantic-ui-react";
import NoData from "../NoData";
import { Link } from "react-router-dom";
import LoaderTable from "../LoaderTable";
import Findnotfound from "../Findnotfound";
import getUrlParam from "../../utils/getUrlParam";
import { callApiServer } from "../../utils/callApiServer";

class Cart extends Component {
  constructor(props) {
    super(props);
    const { dataRequest } = this.props.carts.dataRequired;
    const industryReportData = dataRequest.filter((i) => i.type === 2 || i.type === 3 || i.type === 4);
    const companyReportData = dataRequest.filter((i) => i.type === 1);
    industryReportData.forEach((e) => {
      e.isChecked = false;
    });
    companyReportData.forEach((e) => {
      e.isChecked = false;
    });
    this.state = {
      openModal: false,
      stt: "",
      comment: "",
      orderNote: "",
      requestData: "",
      message: "",
      isDisabled: true,
      taxNumber: null,
      industryReportData: industryReportData,
      companyReportData: companyReportData,
      checkedAllIndustry: false,
      checkedAllCompany: false,
      indeterminateIndustry: false, // dấu trừ ở check all
      indeterminateCompany: false, // dấu trừ ở check all
      selectedIndustryList: [],
      selectedCompanyList: [],
      isDisabledBuyButton: true,
      isDisabledRequestButton: true,
      money: 0,
      initLoading: true,
      sessionId: getUrlParam()["session_id"] ? getUrlParam()["session_id"] : "",
      agreedTerms: false,
      isLoading: false,
      discountValue: "",
      discountError: false,
      billPayment: ""
    };
  }

  componentDidMount() {
    document.title = "VIRACE | Cart";

    const { sessionId } = this.state;
    if (sessionId) {
      this.props.getOrder(sessionId);
    }
    setTimeout(() => {
      this.setState({
        initLoading: false
      });
    }, 5000);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.carts.dataRequired && isEmpty(nextProps.carts.dataRequired) === false) {
      const { dataRequest } = nextProps.carts.dataRequired;
      const { selectedCompanyList, selectedIndustryList } = this.state;
      const industryReportData = dataRequest.filter((i) => !!i.id && (i.viracServiceId === 2 || i.viracServiceId === 3 || i.viracServiceId === 4));
      const companyReportData = dataRequest.filter((i) => !!i.id && i.viracServiceId === 1);
      // industryReportData.forEach((e) => {
      //   const x = selectedIndustryList.filter((i) => i.id === e.id);
      //   if (x.length > 0) {
      //     e.isChecked = x[0].isChecked;
      //   } else {
      //     e.isChecked = false;
      //   }
      // });

      // companyReportData.forEach((e) => {
      //   const y = selectedCompanyList.filter((i) => i.id === e.id);
      //   if (y.length > 0) {
      //     e.isChecked = y[0].isChecked;
      //   } else {
      //     e.isChecked = false;
      //   }
      // });

      this.setState({
        comment: nextProps.carts.dataRequired.dataRequest.comment,
        orderNote: this.state.orderNote,
        taxNumber: nextProps.carts.dataRequired.dataRequest.taxNumber,
        isDisabled: false,
        industryReportData: industryReportData,
        companyReportData: companyReportData
      });
    } else {
      this.setState({
        isDisabled: true,
        orderNote: ""
      });
    }
  }

  onChange = (e) => {
    var target = e.target;
    var value = target.value;
    var name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleShowAlert = (arrToDel) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <AlertWarning
          title={messages[`user.Confirm`]}
          confirmBtnText={messages[`user.Confirm`]}
          cancelBtnText={messages[`user.Cancel`]}
          message={messages[`newWord.deleteDataRequest`]}
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          confirmToFunc={() => {
            this.handleRemoveFromCart(arrToDel);
            this.handleHideAlert();
          }}
          cancleToFunc={this.handleHideAlert}
        />
      )
    });
  };

  handleShowAlertSuccess = (message) => {
    const { messages } = this.props.intl;
    if (message === "Success") {
      this.setState({
        ...this.state,
        orderNote: null,
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title={messages[`common.successx`]}
            // showCancel
            confirmBtnText={messages[`newWord.oke`]}
            customClass="swal-wide"
            confirmBtnCssClass="confirmBtn swal2-styled"
            onConfirm={() => {
              this.handleHideAlert();
            }}
          >
            <p style={{ fontSize: "15px" }}>
              <IntlMessages id="newWord.orderSent" />
            </p>
          </SweetAlert>
        )
      });
    } else {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title={messages[`cart.FailAlert`]}
            // showCancel
            confirmBtnText={messages[`newWord.oke`]}
            customClass="swal-wide"
            confirmBtnCssClass="confirmBtn swal2-styled"
            onConfirm={() => {
              this.handleHideAlert();
            }}
          >
            <p style={{ fontSize: "15px" }}>
              <IntlMessages id="cart.FailAlertOrder" />
            </p>
          </SweetAlert>
        )
      });
    }
  };

  actAlert = (message) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages["newWord.notice"]}
          // showCancel
          confirmBtnText={messages["newWord.oke"]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this.handleHideAlert();
          }}
        >
          {message}
        </SweetAlert>
      )
    });
  };

  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  actAlertLogin = (message) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages["newWord.notice"]}
          // showCancel
          confirmBtnText={messages["newWord.oke"]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this.handleHideAlertLogin();
          }}
        >
          {message}
        </SweetAlert>
      )
    });
  };
  handleHideAlertLogin = () => {
    this.setState({
      alert: ""
    });

    this.props.history.push("/dang-nhap");
  };

  // gửi yêu cầu
  hanldeSendRequest() {
    const { messages } = this.props.intl;

    let isLogged = localStorage.getItem("access_token") ? true : false;
    if (isLogged) {
      this.sendRequestData();
    } else {
      this.actAlertLogin(messages[`newWord.loginAccountRequire`]);
    }
  }
  sendRequestData = () => {
    const { messages } = this.props.intl;
    const { selectedIndustryList, selectedCompanyList } = this.state;
    const selectedList = [...selectedIndustryList, ...selectedCompanyList];
    var arrTemp = [];
    if (selectedList.length === 0) {
      this.actAlert(messages[`cart.no-selected`]);
    } else {
      forEach(selectedList, (item) => {
        arrTemp.push({
          comment: "",
          companyId: item.id ? item.id : "",
          requestData: item.requestData ? item.requestData : "",
          viracServiceId: item.viracServiceId ? item.viracServiceId : ""
        });
      });
      let arrToSend = {
        orderAPI: arrTemp,
        comment: this.state.orderNote
      };

      this.props.handleSendRequestData(arrToSend, selectedList, this.sendSuccess);
    }
  };

  sendSuccess = () => {
    this.setState(
      {
        checkedAllCompany: false,
        checkedAllIndustry: false,
        indeterminateCompany: false,
        indeterminateIndustry: false,
        selectedCompanyList: [],
        selectedIndustryList: []
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList);
      }
    );
  };

  // check all bc ngành
  handleChangeCheckAllIndustry = () => {
    let { industryReportData, checkedAllIndustry } = this.state;
    industryReportData.forEach((e) => {
      e.isChecked = !checkedAllIndustry ? true : false;
    });
    this.setState(
      {
        checkedAllIndustry: !checkedAllIndustry,
        industryReportData,
        indeterminateIndustry: false,
        selectedIndustryList: !checkedAllIndustry ? industryReportData : []
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList);
      }
    );
  };

  // check 1 bc ngành
  handleCheckedIndustry = (data) => {
    let { industryReportData, selectedIndustryList } = this.state;
    // nếu đang chọn thì xóa ra khỏi list
    if (data.isChecked) {
      selectedIndustryList = selectedIndustryList.filter((i) => i.id !== data.id || (i.id === data.id && i.lang !== data.lang));
    } else {
      selectedIndustryList.push(data);
    }
    industryReportData.forEach((e) => {
      if (e.id === data.id && e.lang === data.lang) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState(
      {
        checkedAllIndustry: industryReportData.length === selectedIndustryList.length,
        indeterminateIndustry: industryReportData.length !== selectedIndustryList.length && selectedIndustryList.length !== 0,
        industryReportData,
        selectedIndustryList
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList);
      }
    );
  };

  // check all DN
  handleChangeCheckAllCompany = () => {
    let { companyReportData, checkedAllCompany } = this.state;
    companyReportData.forEach((e) => {
      e.isChecked = !checkedAllCompany ? true : false;
    });
    this.setState(
      {
        checkedAllCompany: !checkedAllCompany,
        indeterminateCompany: false,
        companyReportData,
        selectedCompanyList: !checkedAllCompany ? companyReportData : []
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList);
      }
    );
  };

  // check 1 DN
  handleCheckedCompany = (data) => {
    let { companyReportData, selectedCompanyList } = this.state;
    // nếu đang chọn thì xóa ra khỏi list
    if (data.isChecked) {
      selectedCompanyList = selectedCompanyList.filter((i) => i.id !== data.id || (i.id === data.id && i.requestData !== data.requestData));
    } else {
      selectedCompanyList.push(data);
    }
    companyReportData.forEach((e) => {
      if (e.requestData === data.requestData && e.id === data.id && e.lang === data.lang) {
        e.isChecked = !e.isChecked;
      }
    });
    this.setState(
      {
        checkedAllCompany: companyReportData.length === selectedCompanyList.length,
        indeterminateCompany: companyReportData.length !== selectedCompanyList.length && selectedCompanyList.length !== 0,
        companyReportData,
        selectedCompanyList
      },
      () => {
        this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList);
      }
    );
  };

  // trạng thái của các button: disable?
  handleButtonStatus = (selectedIndustryList, selectedCompanyList) => {
    const selectedList = [...selectedIndustryList, ...selectedCompanyList];
    // không thể mua
    if (selectedList.length === 0 || selectedCompanyList.length > 0) {
      this.setState({
        isDisabledBuyButton: true,
        money: 0
      });
    } else {
      // có thể mua
      let money = 0;
      selectedIndustryList.forEach((e) => {
        money += Number(e.price);
      });
      this.setState({
        isDisabledBuyButton: false,
        money
      });
    }

    // không thể gửi yêu cầu
    if (selectedList.length === 0) {
      this.setState({
        isDisabledRequestButton: true
      });
    } else {
      this.setState({
        isDisabledRequestButton: false
      });
    }
  };

  // xóa khỏi cart
  handleDeleteData = (data) => {
    let arrToDel = {
      requestData: data.requestData,
      id: data.id,
      lang: data.lang
    };
    this.handleRemoveFromCart(arrToDel);
    if ((data.price !== null) & (data.price !== undefined)) {
      const selectedIndustryList = this.state.selectedIndustryList.filter((i) => i.requestData !== data.requestData);
      this.setState(
        {
          selectedIndustryList
        },
        () => {
          this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList);
        }
      );
    } else {
      const selectedCompanyList = this.state.selectedCompanyList.filter((i) => i.requestData !== data.requestData && i.name !== data.name);
      this.setState(
        {
          selectedCompanyList
        },
        () => {
          this.handleButtonStatus(this.state.selectedIndustryList, this.state.selectedCompanyList);
        }
      );
    }
  };

  handleRemoveFromCart = (arrToDel) => {
    this.props.removeFromCart(arrToDel);
  };

  // mua hàng
  hanldePayment() {
    const { messages } = this.props.intl;

    let isLogged = localStorage.getItem("access_token") ? true : false;
    if (isLogged) {
      this.payment();
    } else {
      this.actAlertLogin(messages[`newWord.loginAccountRequire`]);
    }
  }
  payment = () => {
    const { messages } = this.props.intl;
    const { selectedIndustryList, selectedCompanyList } = this.state;
    const selectedList = [...selectedIndustryList, ...selectedCompanyList];
    if (selectedList.length === 0) {
      this.actAlert(messages[`cart.no-selected`]);
    } else if (selectedCompanyList.length > 0) {
      this.actAlert(messages[`cart.is-company`]);
    } else {
      const reports = selectedList.filter((i) => i.viracServiceId === 2 || i.viracServiceId === 3 || i.viracServiceId === 4);
      const body = {
        products: reports.map((i) => {
          let type = "";
          // Kiểm tra type của báo cáo để gửi payload tương ứng
          if (i.viracServiceId === 2) {
            type = "Report";
          } else if (i.viracServiceId === 3) {
            type = "Vsic";
          } else if (i.viracServiceId === 4) {
            type = "VsicProduct";
          }
          return {
            lang: i.lang,
            productId: i.id,
            productType: type
          };
        }),
        discountCode: this.state.discountValue
      };
      this.checkDiscountAndShowBill(body);
    }
  };

  async checkDiscountAndShowBill(body) {
    this.setState({
      isLoading: true
    });
    const { messages } = this.props.intl;

    try {
      const response = await callApiServer.post("/Order/VerifyOrder", body);

      if (response && response.data.success && response.data.data) {
        const data = response.data.data;

        if (data.validateSuccess) {
          this.showBill(body, data);
          this.setState({
            discountError: false,
            isLoading: false
          });
        } else {
          this.setState({
            discountError: true,
            isLoading: false
          });
        }
      } else {
        this.setState({
          isLoading: false
        });

        this.showCommonErr();
      }
    } catch (err) {
      this.setState({
        discountError: false,
        isLoading: false
      });
      this.showCommonErr();
    }
  }

  showCommonErr() {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages["newWord.notice"]}
          // showCancel
          confirmBtnText={messages["newWord.oke"]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {messages["newWord.somthingWrong"]}
        </SweetAlert>
      )
    });
  }
  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };
  handleChangeDiscount = (event) => {
    const value = event.target.value;
    this.setState({
      discountValue: value,
      discountError: false
    });
  };
  showBill(body, bodyVerify) {
    let dataOrder = body;

    Object.assign(dataOrder, {
      discountKey: bodyVerify.discountKey ? bodyVerify.discountKey : "",
      discountCode: bodyVerify.discountCode ? bodyVerify.discountCode : ""
    });

    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    const { products, discountCode, totalAmount, vatLabel, vatValue, totalAmountAfterDiscount, totalDiscountValue } = bodyVerify;
    this.setState({
      billPayment: (
        <SweetAlert
          default
          hideOverlay={false}
          title={messages["cart.orderInfor"]}
          confirmBtnText={messages["cart.confirmPay"]}
          customClass="payment-popup"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnText={messages["cart.cancelPay"]}
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this.setState({
              billPayment: ""
            });
            this.props.createOrder(dataOrder);
          }}
          showCancel={true}
          onCancel={() => {
            this.setState({
              billPayment: ""
            });
          }}
        >
          <div className="order-infor">
            {products.map((props, index) => {
              return (
                <div className="order-item mb-2" key={index}>
                  <div className="col-7 order-item-label ">
                    {index + 1}. {language === "vi" ? props.productName : props.productEnName}
                  </div>
                  <div className="col-5 order-item-value font-w-550">
                    <div className="main-value">
                      <span>{formatMoney(parseInt(props.priceAfterDiscount, 10))} VND</span>
                    </div>
                    {props.isDiscount && (
                      <div className="sub-value">
                        <span>{formatMoney(parseInt(props.price, 10))} VND</span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="order-item mb-2 font-s-large ">
              <div className="col-7 order-item-label font-w-600">
                <IntlMessages id="cart.provisionalMoney"></IntlMessages>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>{formatMoney(parseInt(totalAmount, 10))} VND</span>
                </div>
              </div>
            </div>
            <div className="order-item mb-2 font-s-large ">
              <div className="col-7 order-item-label font-w-600">
                <IntlMessages id="cart.promotionCode"></IntlMessages>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>{discountCode}</span>
                </div>
              </div>
            </div>
            <div className="order-item mb-2 font-s-large ">
              <div className="col-7 order-item-label font-w-600">
                <IntlMessages id="cart.discount"></IntlMessages>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>-{formatMoney(parseInt(totalDiscountValue, 10))} VND</span>
                </div>
              </div>
            </div>
            <div className="order-item mb-2 font-s-large ">
              <div className="col-7 order-item-label font-w-600 ">
                <IntlMessages id="cart.VAT"></IntlMessages> <span>({vatLabel})</span>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>{formatMoney(parseInt(vatValue, 10))} VND</span>
                </div>
              </div>
            </div>
            <div className="order-item mb-2 font-s-large" style={{ borderTop: "0.5px solid #cdd5d9", paddingTop: "15px" }}>
              <div className="col-7 order-item-label font-w-600">
                <IntlMessages id="cart.totalMoney"></IntlMessages>
              </div>
              <div className="col-5 order-item-value font-w-600">
                <div className="main-value">
                  <span>{formatMoney(parseInt(totalAmountAfterDiscount, 10))} VND</span>
                </div>
              </div>
            </div>
          </div>
        </SweetAlert>
      )
    });
  }

  // gửi yêu cầu
  sendRequest = () => {};

  handleChangeCheckTerm = () => {
    const { agreedTerms } = this.state;

    this.setState({
      agreedTerms: !agreedTerms
    });
  };

  render() {
    const {
      initLoading,
      industryReportData,
      companyReportData,
      checkedAllCompany,
      checkedAllIndustry,
      indeterminateCompany,
      indeterminateIndustry,
      agreedTerms,
      isLoading
    } = this.state;
    const { userAccount } = this.props;
    const { createOrderLoading, createRequestLoading } = this.props.carts;
    const language = localStorage.getItem("currentLanguage");
    const isLogged = localStorage.getItem("access_token") ? true : false;

    return (
      <React.Fragment>
        {this.state.alert}
        {this.state.billPayment}

        {initLoading ? (
          <LoaderTable isLoaded={!initLoading} styleCustom={{ height: "500px" }} />
        ) : (
          <div className="cart-main-wrapper">
            {industryReportData.length > 0 || companyReportData.length > 0 ? (
              <div className="row">
                <div className="col-lg-8 col-xl-9">
                  <div className="card-item mb-4">
                    <div className="card-title card-title-line">
                      <IntlMessages id="cart.industryReport.title" />
                    </div>
                    <div className="table-custom">
                      {industryReportData && industryReportData.length > 0 && (
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="col-no">
                                <div className="input-list-Checkbox">
                                  <Checkbox
                                    checked={checkedAllIndustry}
                                    onChange={() => this.handleChangeCheckAllIndustry()}
                                    indeterminate={indeterminateIndustry}
                                  />
                                </div>
                              </th>
                              <th>
                                <IntlMessages id="cart.industryReport.report" />
                              </th>
                              <th>
                                <IntlMessages id="cart.industryReport.price" />
                              </th>
                              <th>
                                <IntlMessages id="newWord.selectLangReportTable" />
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {industryReportData.map((props, index) => {
                              return (
                                <tr key={index}>
                                  <td className="col-no">
                                    <div className="input-list-Checkbox">
                                      <Checkbox checked={!!props.isChecked} onChange={() => this.handleCheckedIndustry(props)} />
                                    </div>
                                  </td>
                                  <td>
                                    {props.type === 4 || props.type === 3 ? (
                                      <span className="text-cyan">{language === "en" && props.englishName ? props.englishName : props.name}</span>
                                    ) : (
                                      <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${props.id ? props.id : ""}`}>
                                        {language === "en" && props.englishName ? props.englishName : props.name}
                                      </Link>
                                    )}
                                  </td>
                                  <td>
                                    <span>{formatMoney(parseInt(props.price, 10))} VND</span>
                                  </td>
                                  <td>{props.lang === "vi" ? <IntlMessages id="user.langVN" /> : <IntlMessages id="user.langEN" />}</td>
                                  <td>
                                    <span className="material-icons close-icon" onClick={() => this.handleDeleteData(props)}>
                                      cancel
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                      {industryReportData && industryReportData.length === 0 && <NoData />}
                    </div>
                    <div className="search">
                      <Link className="text-cyan" to="/trang-chu-if-ir">
                        <em className="material-icons">search</em>
                        <IntlMessages id="cart.industryReport.search" />
                      </Link>
                    </div>
                  </div>
                  {isLogged && (
                    <div className="card-item mb-4">
                      <div className="card-title card-title-line">
                        <IntlMessages id="cart.company.title" />
                      </div>
                      <div className="table-custom">
                        {companyReportData && companyReportData.length > 0 && (
                          <Table responsive>
                            <thead>
                              <tr>
                                <th className="col-no">
                                  <div className="input-list-Checkbox">
                                    <Checkbox
                                      checked={checkedAllCompany}
                                      onChange={() => this.handleChangeCheckAllCompany()}
                                      indeterminate={indeterminateCompany}
                                    />
                                  </div>
                                </th>
                                {/* <th className="col-no">
                            <IntlMessages id="table.sttTable" />
                          </th> */}
                                <th>
                                  <IntlMessages id="cart.company.report" />
                                </th>
                                <th>
                                  <IntlMessages id="cart.company.company" />
                                </th>
                                <th>
                                  <IntlMessages id="cart.company.taxCode" />
                                </th>
                                <th>
                                  <IntlMessages id="newWord.selectLangReportTable" />
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {companyReportData.map((props, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="col-no">
                                      <div className="input-list-Checkbox">
                                        <Checkbox checked={!!props.isChecked} onChange={() => this.handleCheckedCompany(props)} />
                                      </div>
                                    </td>
                                    {/* <td className="col-no">
                                <span>{index + 1}</span>
                              </td> */}
                                    <td>
                                      <span>{props.requestData ? props.requestData : ""}</span>
                                    </td>
                                    <td>
                                      {props.name && (
                                        <Link className="text-cyan" to={`/chi-tiet-doanh-nghiep/${props.id ? props.id : ""}`}>
                                          {language === "en" && props.englishName ? props.englishName : props.name}
                                        </Link>
                                      )}
                                    </td>
                                    <td>
                                      <span>{props.taxNumber ? props.taxNumber : ""}</span>
                                    </td>
                                    <td>{props.lang === "vi" ? <IntlMessages id="user.langVN" /> : <IntlMessages id="user.langEN" />}</td>
                                    <td>
                                      <span className="material-icons close-icon" onClick={() => this.handleDeleteData(props)}>
                                        cancel
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        )}
                        {companyReportData && companyReportData.length === 0 && <NoData />}
                      </div>
                      <div className="search">
                        <Link className="text-cyan" to="/trang-chu-cr">
                          <em className="material-icons">search</em>
                          <IntlMessages id="cart.company.search" />
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-4 col-xl-3">
                  <div className="card-item payment-info">
                    <div className="payment-item">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.customer" />
                      </div>
                      <div className="payment-item-content">{userAccount.fullName ? userAccount.fullName : ""}</div>
                    </div>

                    {/* Tạm tính */}
                    <div className="payment-item">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.money" />
                      </div>
                      <div className="payment-item-content">
                        <span>{formatMoney(parseInt(this.state.money, 10))} VND</span>
                      </div>
                    </div>

                    {/* Mã gairm giá */}
                    <div className="payment-item">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.promotionCode" />
                      </div>
                      <div className="form-input form-icon">
                        <label>
                          <input
                            id="discount"
                            type="text"
                            name="discount"
                            value={this.state.discountValue}
                            maxLength="256"
                            autoComplete="off"
                            onChange={(e) => this.handleChangeDiscount(e)}
                            style={{ padding: 0 }}
                          />
                        </label>
                      </div>
                    </div>
                    {this.state.discountError && (
                      <div className="form-error mb-4">
                        <IntlMessages id="cart.discountCodeWrong" />
                      </div>
                    )}

                    {/* <div className="payment-item">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.promotion" />
                      </div>
                      <div className="payment-item-content">0 VND</div>
                    </div>

                    <div className="payment-item total">
                      <div className="payment-item-title">
                        <IntlMessages id="cart.total" />
                      </div>
                      <div className="payment-item-content">
                        <span>{formatMoney(parseInt(this.state.money, 10))} VND</span>
                      </div>
                    </div> */}

                    <div className="payment-item term">
                      <div className="input-list-Checkbox mr-2">
                        <Checkbox checked={agreedTerms} onChange={() => this.handleChangeCheckTerm()} />
                      </div>

                      <div className="term-description">
                        <IntlMessages id="cart.termPart_1" />
                        <a
                          className="text-cyan"
                          href={
                            language === "vi" ? "https://viracresearch.com/chinh-sach-cung-cap-dich-vu/" : "https://viracresearch.com/service-provision-policy/"
                          }
                          rel="noreferrer"
                          target="_blank"
                        >
                          <IntlMessages id="cart.termPart_2" />
                        </a>
                        <IntlMessages id="cart.termPart_3" />
                      </div>
                    </div>
                    <div className="payment-button mt-5">
                      <Button
                        className={this.state.isDisabledBuyButton ? "compare-btn common-button btn-disabled" : "compare-btn common-button"}
                        disabled={createOrderLoading || !agreedTerms || isLoading}
                        isLoading={createOrderLoading || isLoading}
                        onClick={() => this.hanldePayment()}
                      >
                        <IntlMessages id="cart.buy" />
                      </Button>
                      <Button
                        className={`compare-btn common-button mt-3`}
                        disabled={this.state.isDisabledRequestButton || createRequestLoading || !agreedTerms || isLoading}
                        isLoading={createRequestLoading || isLoading}
                        onClick={() => this.hanldeSendRequest()}
                      >
                        <IntlMessages id="cart.request" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-item">
                <Findnotfound isCartNoData={true} />
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    carts: state.cartReducer,
    userAccount: state.accountReducer.userAccount
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    // xóa khỏi cart
    removeFromCart: (arrToDel) => dispatch(actRemoveFromCart(arrToDel)),

    // gửi yêu cầu
    handleSendRequestData: (arrToSend, selectedList, cbSuccess) => dispatch(actSendRequestData(arrToSend, selectedList, cbSuccess)),

    // mua hàng
    createOrder: (body) => dispatch(actCreateOrder(body)),

    // lấy thông tin đơn hàng
    getOrder: (sessionId) => dispatch(actGetOrder(sessionId))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart));
