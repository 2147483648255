import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as Types from "../actions";
import * as actions from "./actions";
import BASE_API_URL from "../../constants/hostSever";
import { totalPage } from "../../utils/function.util";
import { HistoryAccessed, notification } from "../../constants/pageSize";
import { actNotify } from "./../Fetch/actions";
// USER ACCOUNT - Thông tin tài khoản
import {
  actGetUserAccountApi,
  actGetHistoryRequestedApi,
  actGetHistoryAccessedApi,
  actGetNotificationsApi,
  actChangePasswordApi,
  actDeleteNotificationsApi,
  actChangeStatusSeenMessageApi,
  actChangeLang,
  actGetHistoryRequestedOrderAttachment,
  actGetHistoryRequestedOrderDownload
} from "../../services/accountManagement.service";
const language = localStorage.getItem("currentLanguage");

function* watchGetUserAccount() {
  yield takeEvery(Types.GET_USER_ACCOUNT, getUserAccount);
}

function* getUserAccount() {
  try {
    var response = yield call(actGetUserAccountApi);
    if (response.data.data) {
      yield put(actions.getUserAccountSuccess(response.data.data));
      const { lang } = response.data.data;
      if (lang) {
        if (localStorage.getItem("currentLanguage") !== lang) {
          localStorage.setItem("currentLanguage", lang);
          yield put({
            type: Types.CHANGE_LOCALE,
            payload: lang
          });
        }
      }
    } else {
      yield put(actions.getUserAccountFail("err"));
    }
  } catch (err) {
    yield put(actions.getUserAccountFail(err.message));
  }
}

//  HISTORY REQUESTED - Lịch sử yêu cầu

function* watchGetHistoryRequested() {
  yield takeEvery(Types.GET_HISTORY_REQUESTED, getHistoryRequested);
}

function* getHistoryRequested(action) {
  var body = {
    page: action.page,
    status: action.status,
    searchText: action.searchText
  };
  try {
    var response = yield call(actGetHistoryRequestedApi, body);
    if (response.data.data) {
      var { requestsAPI, totalPages } = response.data.data;
      yield put(actions.getHistoryRequestedSuccess(requestsAPI, totalPages));
    } else {
      yield put(actions.getHistoryRequestedFail("fail"));
    }
  } catch (err) {
    yield put(actions.getHistoryRequestedFail(err.message));
  }
}
// function* watchGetHistoryRequestedSearch() {
//   yield takeEvery(
//     Types.GET_HISTORY_REQUESTED_SEARCH,
//     getHistoryRequestedSearch
//   );
// }

// function* getHistoryRequestedSearch(action) {
//   var { txtSearch } = action.payload;
//   try {
//     var response = yield call(actGetHistoryRequestedSearchApi, txtSearch);
//     if (response.data.data) {
//       var { requestsAPI } = response.data.data;
//       yield put({
//         type: Types.GET_HISTORY_REQUESTED_SEARCH_SUCCESS,
//         payload: { requestsAPI }
//       });
//     } else {
//       yield put({
//         type: Types.GET_HISTORY_REQUESTED_SEARCH_FAIL
//       });
//     }
//   } catch (err) {
//     yield put({
//       type: Types.GET_HISTORY_REQUESTED_SEARCH_FAIL
//     });
//   }
// }
function* watchGetHistoryOrderAttachment() {
  yield takeEvery(Types.GET_HISTORY_ORDER_ATTACHMENT, getHistoryOrderAttachment);
}

function* getHistoryOrderAttachment(action) {
  var { OrderId } = action.payload;

  try {
    var response = yield call(actGetHistoryRequestedOrderAttachment, OrderId);
    if (response.data.data) {
      yield put({
        type: Types.GET_HISTORY_ORDER_ATTACHMENT_SUCCESS,
        payload: { resData: response.data.data }
      });
    } else {
      yield put({
        type: Types.GET_HISTORY_ORDER_ATTACHMENT_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_HISTORY_ORDER_ATTACHMENT_FAIL
    });
  }
}
function* watchGetHistoryOrderDownload() {
  yield takeEvery(Types.GET_HISTORY_ORDER_DOWNLOAD, getHistoryOrderDownload);
}

function* getHistoryOrderDownload(action) {
  var { OrderId, name } = action.payload;
  let model = {
    OrderId: OrderId,
    name: name
  };
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  var linkDownLoad = `${BASE_API_URL}/Order/Order_Download?OrderId=${OrderId}&name=${name}`;
  //var linkDownLoad = `${window.config.api_url}/Order_Download?OrderId=${OrderId}&name=${name}`;
  try {
    var response = yield call(actGetHistoryRequestedOrderDownload, model);
    if (response.status === 200) {
      window.open(linkDownLoad);
      yield put({
        type: Types.GET_HISTORY_ORDER_DOWNLOAD_SUCCESS,
        payload: { resData: response.data.data }
      });
    } else {
      yield put({
        type: Types.GET_HISTORY_ORDER_DOWNLOAD_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_HISTORY_ORDER_DOWNLOAD_FAIL
    });
    yield put(actNotify(notify_data));
  }
}
// HISTORY ACCESSED - Lịch sử truy cập

function* watchGetHistoryAccessed() {
  yield takeEvery(Types.GET_HISTORY_ACCESSED, getHistoryAccessed);
}

function* getHistoryAccessed(action) {
  try {
    var response = yield call(actGetHistoryAccessedApi, action.page);
    if (response.data.data) {
      var data = response.data.data.auditLogs;
      var totalItems = response.data.data.totalItem;
      var pageIndex = response.data.data.pageIndex;
      var pageCount = response.data.data.pageCount;
      var totalPages = totalPage(totalItems, HistoryAccessed);
      yield put(actions.getHistoryAccessedSuccess(data, totalItems, totalPages, pageIndex, pageCount));
    } else {
      yield put(actions.getHistoryAccessedFail("Fail"));
    }
  } catch (err) {
    yield put(actions.getHistoryAccessedFail(err.message));
  }
}

// NOTIFICATION - Thông báo

function* watchGetNotifications() {
  yield takeEvery(Types.GET_NOTIFICATIONS, getNotifications);
}

function* getNotifications(action) {
  var { page } = action.payload;

  try {
    var response = yield call(actGetNotificationsApi, page);
    if (response.data) {
      var { messages, totalItem, pageIndex, pageSize } = response.data.data;
      var totalPages = totalPage(totalItem, notification);
      yield put(actions.getNotificationsSuccess(messages, totalItem, totalPages, pageIndex, pageSize));
    } else {
      yield put(actions.getNotificationsFail(messages));
    }
  } catch (err) {
    yield put(actions.getNotificationsFail(err.message));
  }
}

function* watchDeleteNotification() {
  yield takeEvery(Types.DELETE_NOTIFICATION, deleteNotification);
}

function* deleteNotification(action) {
  try {
    var response = yield call(actDeleteNotificationsApi, action.payload.id);
    if (response.data) {
      var { message } = response.data;
      if (message === "Success") {
        action.payload.success(message);
      } else {
        action.payload.success(message);
      }
    }
  } catch (err) {
    action.payload.success(err.message);
  }
}

function* watchChangeStatusSeenMessage() {
  yield takeEvery(Types.CHANGE_STATUS_SEEN_MESSAGE, changeStatusSeenMessage);
}

function* changeStatusSeenMessage(action) {
  var { id, success } = action.payload;
  try {
    var response = yield call(actChangeStatusSeenMessageApi, id);
    if (response.data) {
      var { message } = response.data;
      if (message === "Success") {
        success(message);
      } else {
        success(message);
      }
    }
  } catch (err) {
    success(err.message);
  }
}

// CHANGE PASSWORD - Thay đổi mật khẩu

function* watchChangePassword() {
  yield takeEvery(Types.CHANGE_PASSWORD, changePasswordRequest);
}

function* changePasswordRequest(action) {
  const model = {
    oldPassword: action.payload.oldPass,
    newPassword: action.payload.newPass
  };
  try {
    var response = yield call(actChangePasswordApi, model);
    if (response.data) {
      var { message } = response.data;
      yield put(actions.changePasswordMessage(message));
    } else {
      yield put(actions.changePasswordMessage(message));
    }
  } catch (err) {
    yield put(actions.changePasswordMessage(err));
  }
}
//  Change LANG

function* watchGetLangRequested() {
  yield takeEvery(Types.GET_LANG_REQUESTED, requestedLANG);
}

function* requestedLANG(action) {
  var { lang } = action.payload;
  var body = {
    lang
  };
  try {
    var response = yield call(actChangeLang, body);
    if (response.data.data) {
      yield put({
        type: Types.GET_LANG_REQUESTED_SUCCESS,
        data: response.data.data.lang
      });
      yield put({
        type: Types.GET_USER_ACCOUNT
      });
    } else {
      yield put({
        type: Types.GET_LANG_REQUESTED_FAIL
      });
      yield put({
        type: Types.GET_USER_ACCOUNT
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_LANG_REQUESTED_FAIL
    });
    yield put({
      type: Types.GET_USER_ACCOUNT
    });
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchGetUserAccount),
    fork(watchGetHistoryRequested),
    fork(watchGetNotifications),
    fork(watchDeleteNotification),
    fork(watchChangeStatusSeenMessage),
    fork(watchChangePassword),
    fork(watchGetLangRequested),
    // fork(watchGetHistoryRequestedSearch)
    fork(watchGetHistoryOrderAttachment),
    fork(watchGetHistoryOrderDownload),
    fork(watchGetHistoryAccessed)
  ]);
}
