import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import IntlMessages from "../../../utils/IntlMessages";

import { Dropdown, Menu, Checkbox } from "semantic-ui-react";
import Button from "../../../components/CustomButton/CustomButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Table } from "reactstrap";
import { actAdvanceSearchCompanyByIndustry } from "../../../redux/CompanyInfo/actions";
import { CompanyItem } from "../../../components/CompanyInfo/CompanyChild/CompanyItem";
import Pagination from "../../../components/Pagination/PaginationSimple";
import { formatMoney } from "../../../utils/formatMoney";
import LoaderTable from "../../../components/LoaderTable";
import Findnotfound from "../../../components/Findnotfound";
// import { _Demo_, _Basic_, _Standard_, _Premium_ } from "../../../constants/rolesModel";
import { copyArray } from "../../../utils/copyArray";
import { callApiServer } from "../../../utils/callApiServer";
import moment from "moment";
import { Spinner } from "reactstrap";
import { saveAs as importedSaveAs } from "file-saver";
export class TabCompanyType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listYear: [],
      selectedDataPoint: [], // giai đoạn dữ liệu
      selectedType: [], // ds loại doanh nghiệp đã chọn,
      selectedTypeId: [],
      selectedTerms: [], // điều kiện tìm kiếm được thêm
      pageIndex: 1,
      pageSize: 10,
      activePage: 1,
      isSearch: false,
      dataSearch: {},
      isDownloading: false,
      listCompanyType: [] // ds loại hình doanh nghiệp
    };
  }

  componentDidMount() {
    this.getListCompanyType();
    this.getListYear();
  }

  // List năm
  async getListYear() {
    try {
      const response = await callApiServer.get("/Company/ListYear");
      if (response && response.data && response.data.data) {
        const data = response.data.data;
        this.setState({ listYear: data });
      } else {
        this.setState({
          listYear: []
        });
      }
    } catch (error) {
      this.setState({
        listYear: []
      });
    }
  }

  //Get list Loại hình doanh nghiệp
  async getListCompanyType() {
    const response = await callApiServer.get("/Company/GetListCompanyType");
    if (response && response.data && response.data.data) {
      this.setState({
        listCompanyType: response.data.data
      });
    } else {
      this.setState({
        listCompanyType: []
      });
    }
  }

  // list giai đoạn dữ liệu
  handleChangeDataPoint = (year) => {
    const index = this.state.selectedDataPoint.indexOf(year);
    if (index < 0) {
      this.state.selectedDataPoint.push(year);
    } else {
      this.state.selectedDataPoint.splice(index, 1);
    }
    this.setState({
      selectedDataPoint: [...this.state.selectedDataPoint]
    });
  };

  handleChangeCompanyType = (value, list) => {
    let types = [];
    value.forEach((e) => {
      const arr = list.filter((i) => i.companyTypeId === e)[0];
      types.push(arr);
    });
    this.setState({
      selectedType: types,
      selectedTypeId: value
    });
  };

  // Tìm kiếm trong list Vsic

  handleSearchListType = (options, data) => {
    if (data) {
      return options.filter((item) => item.text.toLowerCase().includes(data.toLowerCase()));
    }
  };

  // thêm điều kiện
  addTerms = () => {
    const { roles } = this.props;
    const { messages } = this.props.intl;
    let { selectedDataPoint, selectedType } = this.state;
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;

    if (selectedDataPoint.length === 0 || selectedType.length === 0) {
      this.actAlert(messages[`advanceSearch.typeCompany.addTermsCondition`]);
    } else {
      const x = selectedDataPoint.length * selectedType.length;

      const y = x + selectedAdvanceSearchingCriteria.length;
      if (y > 10) {
        this.actAlert(messages[`advanceSearch.area.addTermsMax`]);
      } else {
        let selectedTerms = [];
        selectedDataPoint.forEach((dataPoint) => {
          selectedType.forEach((typeCompany) => {
            const z = selectedAdvanceSearchingCriteria.filter(
              (i) => i.type === "companyType" && dataPoint === i.dataPoint && i.typeCompany.companyTypeId === typeCompany.companyTypeId
            );
            if (z.length === 0) {
              selectedTerms.push({
                dataPoint,
                typeCompany,
                type: "companyType"
              });
            }
          });
        });

        this.props.companyReducer.selectedAdvanceSearchingCriteria = copyArray([...selectedAdvanceSearchingCriteria, ...selectedTerms]);
        this.setState({
          selectedTerms,
          selectedTypeId: [],
          selectedType: [],
          selectedDataPoint: []
        });
      }
    }
  };

  // xóa điều kiện
  removeTerm = (index) => {
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    this.props.companyReducer.selectedAdvanceSearchingCriteria = selectedAdvanceSearchingCriteria.filter((item, i) => i !== index);
    let selectedTerms = this.state.selectedTerms.filter((item, i) => i !== index);
    this.setState({
      selectedTerms
    });
  };

  // tìm kiếm
  searchTerms = (page) => {
    const { messages } = this.props.intl;
    const { pageSize } = this.state;
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    const areas = [];
    const vsics = [];
    const financials = [];
    const companyTypes = [];
    const legals = [];
    const owners = [];

    // Xuất nhập khẩu
    let importExportTurnover;
    let importExportYearValue = [];
    let importExportHsValue = [];

    if (selectedAdvanceSearchingCriteria.length > 0) {
      this.setState({ isSearch: true });
      selectedAdvanceSearchingCriteria.forEach((e) => {
        if (e.type === "area") {
          areas.push({
            year: e.dataPoint ? e.dataPoint : "",
            provinceId: e.area.provinceidrequest ? e.area.provinceidrequest.toString() : "",
            districtCode: e.area.districtCode ? e.area.districtCode.toString() : "",
            communeId: e.area.communeCode ? e.area.communeCode.toString() : ""
          });
        } else if (e.type === "industry") {
          vsics.push({
            year: e.dataPoint,
            vsicCode: e.industry.code
          });
        } else if (e.type === "companyType") {
          companyTypes.push({
            year: e.dataPoint,
            companyTypeId: e.typeCompany.companyTypeId
          });
        } else if (e.type === "legal") {
          legals.push({
            userId: e.userId
          });
        } else if (e.type === "owner") {
          owners.push({
            id: e.userId,
            ownerShipType: e.ownerShipType
          });
        } else if (e.type === "finance") {
          financials.push({
            year: e.dataPoint,
            financialItemId: e.criteria.criteriaId,
            fromValue: parseFloat(e.criteria.from),
            toValue: parseFloat(e.criteria.to)
          });
        } else if (e.type === "importExport_year" || e.type === "importExport_hs") {
          if (e.type === "importExport_year") {
            importExportYearValue.push({
              type: e.dataPoint,
              year: e.criteria.year,
              from: e.criteria.from ? parseFloat(e.criteria.from) : null,
              to: e.criteria.to ? parseFloat(e.criteria.to) : null
            });
          }
          if (e.type === "importExport_hs") {
            importExportHsValue.push({
              type: e.dataPoint,
              value: e.hs.shortCode
            });
          }
        }
      });
      importExportTurnover = {
        importExportYearValue,
        importExportHsValue
      };

      var body = {
        pageIndex: page,
        pageSize: pageSize,
        areas,
        vsics,
        financials,
        companyTypes,
        legals,
        owners,
        importExportTurnover
      };
      this.props.advanceSearchCompanyByIndustry(body);
      this.setState({
        dataSearch: body
      });
    } else {
      this.actAlert(messages[`advanceSearch.no-searching-criteria`]);
    }
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => this.searchTerms(activePage));
  };

  handleDownLoad(dataSearch) {
    const { messages } = this.props.intl;
    this.setState({
      isDownloading: true
    });
    const body = {
      areas: dataSearch.areas,
      financials: dataSearch.financials,
      vsics: dataSearch.vsics,
      companyTypes: dataSearch.companyTypes,
      legals: dataSearch.legals,
      owners: dataSearch.owners,
      importExportTurnover: dataSearch.importExportTurnover
    };
    callApiServer
      .post("Company/CompanyReportAdvancedSearch/export", body)
      .then((res) => {
        if (res && res.data.data && res.data.statusCode === 200) {
          this.processDownloadData(res.data.data);
        } else if (res && res.data.statusCode === 429) {
          this.actAlert(messages[`newWord.overTurnDownload`]);
          this.setState({
            isDownloading: false
          });
        } else if (res && res.data.statusCode === 403) {
          this.actAlert(messages[`newWord.notPermision`]);
          this.setState({
            isDownloading: false
          });
        } else {
          this.actAlert(messages[`newWord.somthingWrong`]);
          this.setState({
            isDownloading: false
          });
        }
      })
      .catch((err) => {
        this.actAlert(messages[`newWord.somthingWrong`]);
        this.setState({
          isDownloading: false
        });
      })
      .finally();
  }

  processDownloadData(urlFile) {
    const dateNow = Date.now();
    const dateNowFomated = moment(dateNow).format("YYMMDD");
    const fileName = `Result_${dateNowFomated}.xlsx`;
    const urlDownload = `/Company/SearchCompany/download-file-export?pathFile=${urlFile}`;
    callApiServer
      .get(urlDownload, {
        responseType: "blob"
      })
      .then((res) => {
        if (res && res.data) {
          importedSaveAs(res.data, fileName);
        }

        this.setState({
          isDownloading: false
        });
      });
  }

  render() {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    const { totalItems, totalPage, loading, companyData, dataPointList, selectedAdvanceSearchingCriteria, advanceSearchLoading } = this.props.companyReducer;
    const { activePage, isSearch, dataSearch, isDownloading, listCompanyType, selectedTypeId, listYear } = this.state;

    const companyTypes = [];
    listCompanyType.forEach((e, i) => {
      companyTypes.push({
        key: e.companyTypeId,
        value: e.companyTypeId,
        text: language === "en" && e.enName ? e.enName : e.name
      });
    });

    return (
      <React.Fragment>
        {this.state.alert}
        <div className="tab_area">
          <div className="row equal-height">
            <div className="col-lg-7 col-xl-8 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.selectSearchTerms" />
                </div>
                <div className="mb-4">
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.area.dataPoint" />
                  </div>
                  <div className="row">
                    {listYear &&
                      listYear.length > 0 &&
                      listYear.map((prop, key) => {
                        return (
                          <div className={`input-list-Checkbox col-md-3 col-lg-4 col-xl-3`} key={key}>
                            <Checkbox
                              label={language === "en" && prop.enName ? prop.enName : prop.name}
                              checked={this.state.selectedDataPoint.includes(prop.value)}
                              onChange={() => this.handleChangeDataPoint(prop.value)}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div>
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.companyTypes.list" />
                  </div>
                  <div className="area mb-3">
                    <div className="selected-custom-select w-100">
                      <Menu compact className="w-100 dropdown-vsic-list">
                        <Dropdown
                          placeholder={messages["sidebar.companyType"]}
                          fluid
                          multiple
                          search={this.handleSearchListType}
                          selection
                          options={companyTypes}
                          value={selectedTypeId}
                          noResultsMessage={messages["search.notfound.text"]}
                          onChange={(e, { value }) => this.handleChangeCompanyType(value, listCompanyType)}
                        />
                      </Menu>
                    </div>
                  </div>
                </div>
                <div className="mt-5 d-flex justify-content-center">
                  <Button className={`common-button`} onClick={() => this.addTerms()}>
                    <IntlMessages id="advanceSearch.addTerms" />
                    {/* <em className="material-icons">add</em> */}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.searchTerms" />
                </div>
                <div>
                  {selectedAdvanceSearchingCriteria.length > 0 && (
                    <div className="table-custom ">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <IntlMessages id="table.sttTable" />
                            </th>
                            <th>
                              <IntlMessages id="advanceSearch.term" />
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAdvanceSearchingCriteria.map((prop, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                {prop.type === "area" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.area.area" /> ({prop.dataPoint}):
                                    <span>{` ${prop.area.communeName ? prop.area.communeName + ", " : ""}${
                                      prop.area.districtName ? prop.area.districtName + ", " : ""
                                    }${prop.area.provinceName}`}</span>
                                  </td>
                                )}
                                {prop.type === "industry" && (
                                  <td>
                                    <IntlMessages id="sidebar.Industry" />{" "}
                                    {language === "en" && prop.industry.enName ? prop.industry.enName : prop.industry.name} ({prop.dataPoint}):{" "}
                                    {prop.industry.code}
                                  </td>
                                )}
                                {prop.type === "companyType" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.companyType.companyType" /> :
                                    {language === "en" && prop.typeCompany.enName ? prop.typeCompany.enName : prop.typeCompany.name} ({prop.dataPoint}):{" "}
                                  </td>
                                )}
                                {prop.type === "legal" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.legalRepresentative" /> :{language === "en" && prop.enName ? prop.enName : prop.name}{" "}
                                    <br></br> ID: {prop.idPerson}{" "}
                                  </td>
                                )}
                                {prop.type === "owner" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.owner" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID:{" "}
                                    {prop.showId}{" "}
                                  </td>
                                )}
                                {prop.type === "finance" && (
                                  <td>
                                    {prop.criteria.criteriaText} ({prop.dataPoint}):
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()}  ${formatMoney(
                                            prop.criteria.from.replace(/,/g, "."),
                                            2
                                          )} (${prop.criteria.criteriaUnit}) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${
                                            prop.criteria.criteriaUnit
                                          })`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_year" && (
                                  <td>
                                    {prop.dataPoint === "import" ? (
                                      <IntlMessages id="advanceSearch.importExport.import" />
                                    ) : (
                                      <IntlMessages id="advanceSearch.importExport.export" />
                                    )}
                                    <span> ({prop.criteria.year}):</span>
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()} ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${
                                            prop.criteria.unit
                                          }) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${
                                            prop.criteria.unit
                                          })`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_hs" && (
                                  <td>
                                    {prop.dataPoint === "import" ? (
                                      <IntlMessages id="advanceSearch.importExport.import" />
                                    ) : (
                                      <IntlMessages id="advanceSearch.importExport.export" />
                                    )}
                                    <span>{`: ${prop.hs.shortCode} - ${language === "vi" ? prop.hs.name : prop.hs.enName}`}</span>
                                  </td>
                                )}
                                <td>
                                  <span className="material-icons check" onClick={() => this.removeTerm(index)}>
                                    cancel
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  <div className="mt-5 d-flex justify-content-center">
                    <Button className={`common-button`} onClick={() => this.searchTerms(1)} disabled={loading} isLoading={loading}>
                      <IntlMessages id="filter.buttonSearch" />
                      <em className="material-icons">search</em>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {advanceSearchLoading === true && <LoaderTable isLoaded={!loading} styleCustom={{ height: "500px" }} />}
            {advanceSearchLoading === false && (
              <div>
                {companyData && companyData.length > 0 ? (
                  <div className="card-item mb-4 mt-0 company-info-content">
                    <div className="company-info-content-main w-100">
                      <div className="search-result-heading pb-3">
                        <p className="search-result-title">
                          {`${messages["newWord.amountCompanies"]} (${formatMoney(totalItems)} ${messages["newWord.companies"]})`}
                        </p>
                        {isDownloading ? (
                          <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                        ) : (
                          <div
                            className="btn-export-data text-cyan"
                            onClick={() => {
                              this.handleDownLoad(dataSearch);
                            }}
                          >
                            <em className="material-icons">download</em>
                            <IntlMessages id="newWord.downloadSearchResult" />
                          </div>
                        )}{" "}
                      </div>
                      <div className="search-result-list">
                        <div className="row">
                          {companyData.map((props, index) => {
                            return (
                              <CompanyItem
                                {...this.props}
                                key={index}
                                name={props.companyName}
                                nameEnglish={props.companyEnName}
                                id={props.id}
                                taxNumber={props.companyTax}
                                vsic={props.vsic}
                                address={props.companyAddress}
                                addressEnglish={props.companyEnAddress}
                                phoneNumber={props.companyPhone}
                                fax={props.companyFax}
                                webSite={props.companyWebsite}
                                vsicID={props.vsic}
                                isShowValueTooltip={false}
                                enNetProfitLabel={props.enNetProfitLabel}
                                enNetRevenueLabel={props.enNetRevenueLabel}
                                netProfitLabel={props.netProfitLabel}
                                netRevenueLabel={props.netRevenueLabel}
                                netProfitValue={props.netProfitValue}
                                netRevenueValue={props.netRevenueValue}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="foot-pagination">
                        <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-item">
                    <Findnotfound goHome={"/trang-chu-cr"} isSearch={true} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    commonReducer: state.CommonReducer,
    companyReducer: state.companyReducer,
    industryInformationReducer: state.industryInformationReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    advanceSearchCompanyByIndustry: (body) => {
      dispatch(actAdvanceSearchCompanyByIndustry(body));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabCompanyType));
