import React, { Component } from "react";
import CanvasJSReact from "../../../assets/js/canvasjs.react";
import XLSX from "xlsx";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import addSymbols from "../../../utils/addSymbols.js";
import { numberFormat } from "../../../utils/numberFormat";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
var arrayColor1 = [
  "#4661EE",
  // "#EC5657",
  "#1BCDD1",
  "#8FAABB",
  "#B08BEB",
  "#3EA0DD",
  "#F5A52A",
  "#23BFAA",
  "#FAA586",
  "#EB8CC6",
  "#4661EE",
  "#EC5657",
  "#1BCDD1",
  "#8FAABB",
  "#B08BEB",
  "#3EA0DD",
  "#F5A52A",
  "#23BFAA",
  "#FAA586",
  "#EB8CC6"
];
var index = 0;

class ParetoChartTabRatio extends Component {
  toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  };
  downloadAsExcel = (args) => {
    var dataPoints, filename;
    var newArr = [];
    filename = args.filename || "chart-data";

    dataPoints = args.chart.data;
    for (var i = 0; i < dataPoints.length; i++) {
      newArr.push({ x: dataPoints[i].name, y: "" }, ...dataPoints[i].dataPoints);
    }

    var ws = XLSX.utils.json_to_sheet(newArr, { skipHeader: true, dateNF: "YYYYMMDD HH:mm:ss" });
    if (!ws["!cols"]) ws["!cols"] = [];
    ws["!cols"][0] = { wch: 17 };
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  genColor(isValue) {
    if (isValue) {
      var color = arrayColor1[index];
      index += 1;
      return color;
    }
    return "grey";
  }
  render() {
    index = 0;
    const {
      dataColumn_2,
      dataColumn_1,
      dataColumn_3,
      dataLine_1,
      backgroundColor,
      colorCustom,
      dataChartCustom,
      // hiddenGrossProfitChart,
      // hiddenRevenueChart,
      // hiddenGoodsSold,
      // hiddenProfitMargin,
      //********dataDefault
      dataChartDefault,
      hiddenAll,
      hiddenLegend,
      enabledToolTip,
      roles,
      xType,
      ySuffix
    } = this.props;

    const { messages } = this.props.intl;
    const options = {
      animationEnabled: true,
      exportEnabled: false,
      backgroundColor: backgroundColor !== undefined ? backgroundColor : "#02164d",
      title: {
        fontSize: 20,
        fontWeight: "bold",
        fontFamily: "Roboto",
        margin: 20
      },
      axisX: {
        valueFormatString: "####",
        interval: 1,
        labelFontSize: 12,
        labelFontWeight: 400,
        labelFontColor: "#B6C4DE",
        lineColor: "rgba(144, 155, 175, 0.3)"
      },

      toolTip: {
        enabled: enabledToolTip,
        //animationEnabled: true,
        shared: true,
        contentFormatter: function(e) {
          var content = "";
          if (xType === "label" && e.entries[0].dataPoint.label) {
            content += e.entries[0].dataPoint.label;
            content += "<br/>";

            if (e.entries[0].dataPoint.vsicName) {
              content += e.entries[0].dataPoint.vsicName;
              content += "<br/>";
            }
          } else if (e.entries[0].dataPoint.x) {
            content += e.entries[0].dataPoint.x;
            content += "<br/>";

            if (e.entries[0].dataPoint.vsicName) {
              content += e.entries[0].dataPoint.vsicName;
              content += "<br/>";
            }
          }
          for (var i = 0; i < e.entries.length; i++) {
            content +=
              "<span style='color: " +
              e.entries[i].dataSeries.color +
              ";' >" +
              e.entries[i].dataSeries.name +
              "</span>" +
              ": " +
              numberFormat(e.entries[i].dataPoint.y);
            if (e.entries[i].dataSeries.type !== "line") {
              // content += messages["suffixD"];
              content += "";
            } else {
              content += "%";
            }
            content += "<br/>";
          }
          return content;
        }
      },
      axisY: {
        gridThickness: 1,
        gridColor: "rgba(144, 155, 175, 0.3)",
        includeZero: false,
        lineColor: "rgba(144, 155, 175, 0.3)",
        // labelFormatter: (e) => addSymbols_custom(e, CanvasJS, messages),
        labelFormatter: (e) => numberFormat(e.value),

        titleFontColor: "#B6C4DE",
        labelFontColor: "#B6C4DE",
        labelFontSize: 12,
        tickColor: "rgba(144, 155, 175, 0.3)",
        grindLine: "rgba(144, 155, 175, 0.3)",
        // title: messages["textChart.unit"],
        titleFontSize: 16,
        titleFontWeight: "lighter",
        labelFontFamily: "Roboto"
      },
      axisY2: {
        // title: "Percent",
        labelFontSize: 12,
        suffix: "%",
        titleFontColor: "#EC5657",
        lineColor: "#EC5657",
        labelFontColor: "#EC5657",
        tickColor: "rgba(144, 155, 175, 0.3)"
      },
      data: [],
      legend: {
        cursor: "pointer",
        fontFamily: "Roboto",
        fontColor: "#B6C4DE",
        fontSize: 12,
        itemclick: this.toggleDataSeries,
        fontWeight: 400,
        markerMargin: 4,
        itemWrap: true,
        itemMaxWidth: 300,
        horizontalAlign: "center"
      }
    };
    if (dataColumn_1 !== undefined) {
      options.data.push(
        {
          type: "column",
          showInLegend: true,
          name: dataColumn_1 && dataColumn_1.title ? dataColumn_1.title : "",

          // toolTipContent: `${dataChartDefault[0] && dataChartDefault[0].title && dataChartDefault[0].title ? dataChartDefault[0].title
          //   : ""} <strong>{y}</strong> <br/> {vsicName}`,
          color: colorCustom !== undefined ? colorCustom : this.genColor(dataColumn_1),
          xValueFormatString: "####",
          yValueFormatString: `##,##0.## ${ySuffix ? ySuffix : ""}`,
          // dataPoints: dataColumn_1 ? dataColumn_1.dataChart : []
          dataPoints: []
        },
        {
          type: "column",
          showInLegend: true,
          name: dataColumn_1.title ? dataColumn_1.title : "",
          // toolTipContent: `${dataColumn_1.title ? dataColumn_1.title
          //   : ""} <strong>{y} </strong><br/> {vsicName}`,
          visible: true,
          //color: "rgb(72, 187, 183, .5)",
          color: colorCustom !== undefined ? colorCustom : this.genColor(dataColumn_1.dataChart),
          xValueFormatString: "####",
          yValueFormatString: `##,##0.## ${ySuffix ? ySuffix : ""}`,
          dataPoints: dataColumn_1.dataChart ? dataColumn_1.dataChart : []
        }
      );
    }
    if (dataColumn_2 !== undefined) {
      options.data.push(
        {
          type: "column",
          showInLegend: true,
          name: dataColumn_2 && dataColumn_2.title ? dataColumn_2.title : "",
          // toolTipContent: `${dataChartDefault[1] && dataChartDefault[1].title
          //   ? dataChartDefault[1].title
          //   : ""} <strong>{y} </strong><br/> {vsicName}`,
          //color: "#3ab803",
          color: colorCustom !== undefined ? colorCustom : this.genColor(roles && (roles === "Standard" || roles === "Premium") && dataChartDefault[1]),
          xValueFormatString: "####",
          yValueFormatString: `##,##0.## ${ySuffix ? ySuffix : ""}`,
          dataPoints: roles && (roles === "Standard" || roles === "Premium") && []
        },
        {
          type: "column",
          showInLegend: true,
          name: dataColumn_2.title && dataColumn_2.title ? dataColumn_2.title : "",
          // toolTipContent: `${dataColumn_2.title && dataColumn_2.title ? dataColumn_2.title
          //   : ""} <strong>{y}</strong> <br/> {vsicName}`,
          //color: "rgb(58, 184, 3, .5)",
          color: colorCustom !== undefined ? colorCustom : this.genColor(dataColumn_2.dataChart),
          visible: true,
          xValueFormatString: "####",
          yValueFormatString: `##,##0.## ${ySuffix ? ySuffix : ""}`,
          dataPoints: dataColumn_2.dataChart ? dataColumn_2.dataChart : []
        }
      );
    }
    if (dataColumn_3 !== undefined) {
      options.data.push(
        {
          type: "column",
          showInLegend: true,
          name: dataChartDefault[2] && dataChartDefault[2].title ? dataChartDefault[2].title : "",
          // toolTipContent: `${dataChartDefault[2] && dataChartDefault[2].title
          //   ? dataChartDefault[2].title
          //   : ""} <strong>{y}</strong> <br/> {vsicName}`,
          //color: "#ea2e69",
          color: colorCustom !== undefined ? colorCustom : this.genColor(roles && roles === "Premium" && dataChartDefault[2]),
          xValueFormatString: "####",
          yValueFormatString: `##,##0.## ${ySuffix ? ySuffix : ""}`,
          dataPoints: roles && roles === "Premium" && []
        },
        {
          type: "column",
          showInLegend: true,
          name: dataColumn_3.title && dataColumn_3.title ? dataColumn_3.title : "",
          // toolTipContent: `${dataColumn_3.title && dataColumn_3.title ? dataColumn_3.title
          //   : ""} <strong>{y} </strong><br/> {vsicName}`,
          //color: "rgb(234, 46, 105, .5)",
          color: colorCustom !== undefined ? colorCustom : this.genColor(dataColumn_3.dataChart),
          visible: true,
          xValueFormatString: "####",
          yValueFormatString: `##,##0.## ${ySuffix ? ySuffix : ""}`,
          dataPoints: dataColumn_3.dataChart ? dataColumn_3.dataChart : []
        }
      );
    }
    if (dataChartCustom !== undefined) {
      options.data.push(
        {
          type: "column",
          showInLegend: hiddenLegend,
          name: dataChartCustom.title && dataChartCustom.title ? dataChartCustom.title : "",
          // toolTipContent: `${dataChartCustom.title && dataChartCustom.title ? dataChartCustom.title
          //   : ""} <strong>{y}</strong> <br/> {vsicName}`,
          //color: "rgb(58, 184, 3, .5)",
          color: colorCustom !== undefined ? colorCustom : this.genColor(dataChartCustom.dataChart),
          visible: hiddenAll,
          xValueFormatString: "####",
          yValueFormatString: `##,##0.## ${ySuffix ? ySuffix : ""}`,
          dataPoints: dataChartCustom.dataChart ? dataChartCustom.dataChart : []
        },
        {
          type: "column",
          showInLegend: true,
          name: dataChartDefault[1] && dataChartDefault[1].title ? dataChartDefault[1].title : "",
          // toolTipContent: `${dataChartDefault[1] && dataChartDefault[1].title
          //   ? dataChartDefault[1].title
          //   : ""} <strong>{y} </strong><br/> {vsicName}`,
          //color: "#3ab803",
          color: colorCustom !== undefined ? colorCustom : this.genColor(roles && (roles === "Standard" || roles === "Premium") && dataChartDefault[1]),
          xValueFormatString: "####",
          yValueFormatString: `##,##0.## ${ySuffix ? ySuffix : ""}`,
          dataPoints: roles && (roles === "Standard" || roles === "Premium") && []
        }
      );
    }

    if (dataLine_1 !== undefined) {
      options.data.push(
        {
          type: "line",
          connectNullData: true,
          showInLegend: true,
          axisYType: "secondary",
          axisYIndex: 1,
          lineThickness: 3,
          markerSize: 6,
          name: dataLine_1 && dataLine_1.title ? dataLine_1.title : "",
          color: colorCustom !== undefined ? colorCustom : "#C62806",
          // visible: hiddenProfitMargin,
          // toolTipContent: `${dataChartDefault[3] && dataChartDefault[3].title
          //   ? dataChartDefault[3].title
          //   : ""} <strong>{y}%</strong> <br/> {vsicName}`,
          xValueFormatString: "####",
          // yValueFormatString: "##,##0.## %",
          dataPoints: roles && roles === "Premium" && dataLine_1 ? dataLine_1.dataChart : []
          // dataLine_1Default
        },
        {
          type: "line",
          connectNullData: true,
          axisYType: "secondary",
          axisYIndex: 1,
          showInLegend: true,
          lineThickness: 3,
          markerSize: 6,
          name: dataLine_1 && dataLine_1.title ? dataLine_1.title : "",
          color: colorCustom !== undefined ? colorCustom : "#C106C6",
          visible: hiddenAll,
          // toolTipContent: `${dataLine_1 && dataLine_1.title
          //   ? dataLine_1.title
          //   : ""} <strong>{y}%</strong> <br/> {vsicName}`,
          xValueFormatString: "####",
          // yValueFormatString: "##,##0.## %",
          dataPoints: []
        }
      );
    }
    return (
      <div className="pareto-chart-views column-multiple-chart-wrapper">
        {/* <button id="downloadExcel" onClick={() => this.downloadAsExcel({ filename: "chart-data", chart: options })}>Download Chart Data as Excel</button> */}
        <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
        <div className="cright">
          <IntlMessages id="common.sourceVirac" />
        </div>
      </div>
    );
  }
}

export default injectIntl(ParetoChartTabRatio);
