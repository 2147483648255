import * as Types from "../actions";
import _ from "lodash";

const INIT_STATE = {
  userAccount: {},
  requestedHistoryData: {
    requestedHistory: [],
    listOrderID: [],
    listDataReport: [],
    totalPage: 0,
    loading: false,
    loadingReport: false,
    loadingDownload: false
  },
  historyAccData: {
    historyAccList: [],
    totalItems: 0,
    totalPage: 0,
    pageIndex: 1,
    pageCount: 1,
    loadingAccess: false
  },
  notificationListData: {
    notificationList: [],
    notiUnSeen: [],
    totalItems: 0,
    totalPage: 0,
    pageIndex: 1,
    pageCount: 1,
    loadingNoti: false
  },
  
  message: null,
  loadingUser: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    //USER ACCOUNT - Thông tin tài khoản
    case Types.GET_USER_ACCOUNT:
      return {
        ...state,
        loadingUser: true
      };
    case Types.GET_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        userAccount:action.payload.userAccData,
        loadingUser: false
      };
    case Types.GET_LANG_REQUESTED:
      return {
        ...state,
        loadingUser: true
      };
    case Types.GET_LANG_REQUESTED_SUCCESS:
      return {
        ...state,
        loadingUser: false
      };
    case Types.GET_LANG_REQUESTED_FAIL:
      return {
        ...state,
        loadingUser: false
      };

    // HISTORY REQUESTED - Lịch sử yêu cầu
    case Types.GET_HISTORY_ORDER_DOWNLOAD:
      return {
        ...state,
        requestedHistoryData: {
          ...state.requestedHistoryData,
          loadingDownload: true
        }
      };
    case Types.GET_HISTORY_ORDER_DOWNLOAD_SUCCESS:
      return {
        ...state,
        requestedHistoryData: {
          ...state.requestedHistoryData,
          loadingDownload: false
        }
      };
    case Types.GET_HISTORY_ORDER_DOWNLOAD_FAIL:
      return {
        ...state,
        requestedHistoryData: {
          ...state.requestedHistoryData,
          loadingDownload: false
        }
      };
    case Types.GET_HISTORY_ORDER_ATTACHMENT:
      return {
        ...state,
        requestedHistoryData: {
          ...state.requestedHistoryData,
          loadingReport: true
        }
      };
    case Types.GET_HISTORY_ORDER_ATTACHMENT_FAIL:
      return {
        ...state,
        requestedHistoryData: {
          ...state.requestedHistoryData,
          loadingReport: false,
          listDataReport: []
        }
      };
    case Types.GET_HISTORY_ORDER_ATTACHMENT_SUCCESS:
      const { resData } = action.payload;
      let popix =
        resData &&
        resData.map(props => {
          return {
            key: props.name,
            value: props.name,
            text: props.name
          };
        });
      return {
        ...state,
        requestedHistoryData: {
          ...state.requestedHistoryData,
          listDataReport: popix,
          loadingReport: false
        }
      };
    case Types.GET_HISTORY_REQUESTED:
      return {
        ...state,
        requestedHistoryData: {
          ...state.requestedHistoryData,
          loading: true
        }
      };
    // case Types.GET_HISTORY_REQUESTED_SEARCH:
    //     return {
    //         ...state,
    //         requestedHistoryData: {
    //             ...state.requestedHistoryData,
    //             loading: true,
    //         }
    //     };

    case Types.GET_HISTORY_REQUESTED_SUCCESS:
      var { reqHisData, totalPage } = action.payload;
      let popi =
        reqHisData &&
        reqHisData
          .filter(item => item.status === "Completed")
          .map(props => {
            return {
              key: props.orderId,
              value: props.orderId,
              text: props.code
            };
          });
      let uniq = _.uniqBy(popi, "key");
      return {
        ...state,
        requestedHistoryData: {
          ...state.requestedHistoryData,
          requestedHistory: reqHisData,
          totalPage: totalPage,
          loading: false,
          listOrderID: uniq
        }
      };
    // case Types.GET_HISTORY_REQUESTED_SEARCH_SUCCESS:
    //     var { requestsAPI } = action.payload;
    //     return {
    //         ...state,
    //         requestedHistoryData: {
    //             ...state.requestedHistoryData,
    //             requestedHistory: requestsAPI,
    //             loading: false,
    //         }
    //     };
    // case Types.GET_HISTORY_REQUESTED_SEARCH_FAIL:
    //     return {
    //         ...state,
    //         requestedHistoryData: {
    //             ...state.requestedHistoryData,
    //             requestedHistory: [],
    //             loading: false,
    //         }
    //     };
    case Types.GET_HISTORY_REQUESTED_FAIL:
      const { reqHisErr } = action.payload;
      return {
        ...state,
        requestedHistoryData: {
          ...state.requestedHistoryData,
          reqHisErr,
          loading: false
        }
      };

    //HISTORY ACCESSED - Lịch sử truy cập

    case Types.GET_HISTORY_ACCESSED:
      return {
        ...state,
        historyAccData: {
          ...state.historyAccData,
          loadingAccess: true
        }
      };

    case Types.GET_HISTORY_ACCESSED_FAIL:
      const { accHisErr } = action.payload;
      return {
        ...state,
        historyAccData: {
          ...state.historyAccData,
          loadingAccess: false,
          accHisErr
        }
      };
    case Types.GET_HISTORY_ACCESSED_SUCCESS:
      var {
        accHisData,
        totalItems,
        totalPagex,
        pageIndex,
        pageCount
      } = action.payload;
      return {
        ...state,
        historyAccData: {
          ...state.historyAccData,
          historyAccList: accHisData,
          totalItems: totalItems,
          totalPage: totalPagex,
          pageIndex: pageIndex,
          pageCount: pageCount,
          loadingAccess: false
        }
      };
    //NOTIFICATION - Thông báo

    case Types.GET_NOTIFICATIONS_SUCCESS:
      var notificationList = [];
      var {
        notiList,
        totalItem:totalItemNt,
        totalPages:totalPagesNt,
        pageIndex:pageIndexNt,
        pageSize
      } = action.payload;
      
      notificationList = [...notiList];
      var notiUnSeen = _.filter(
        notificationList,
        item => item.isSeen === false
      );
      return {
        ...state,
        notificationListData:{
          ...state.notificationList,
          notificationList:notificationList,
          notiUnSeen:notiUnSeen,
          totalItems: totalItemNt,
          totalPage: totalPagesNt,
          pageIndex: pageIndexNt,
          pageCount: pageSize,
          loadingNoti: false
        }
      };

    case Types.GET_NOTIFICATIONS:
      return {
        ...state,
        notificationListData:{
          ...state.notificationListData,
          loadingNoti: true
        }
      };
    case Types.GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        notificationListData:{
          ...state.notificationListData,
          loadingNoti: false
        }
      };

    case Types.DELETE_NOTIFICATION_SUCCESS:
      var { cbSuccess } = action.payload;
      return {
        ...state,
        cbSuccess
      };

    //CHANGE PASSWORD - Thay đổi mật khẩu
    case Types.CHANGE_PASSWORD:
      return {
        ...state,
        message: null,
        loadingUser: true
      };

    case Types.CHANGE_PASSWORD_MESSAGE:
      const { message } = action.payload;
      return {
        ...state,
        message: message,
        loadingUser: false
      };

    default:
      return { ...state };
  }
};
