import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { actSearchCompanyInfo, actSearchCompanyAutocomplete } from "../../redux/actions";
import CompanyInfoFillter from "../../components/CompanyInfo/CompanyInfoFillter";
import CompanyInfoContent from "../../components/CompanyInfo/CompanyInfoContent";
import paramFromData from "../../utils/paramFromData";
import getUrlParam from "../../utils/getUrlParam";
import queryString from "query-string";
import { DNOrderByVDL, DNOrderByTTS, DNOrderByLN, DNOrderByDT } from "../../constants/models";
import { debounce, some } from "lodash";
import LoaderTable from "../../components/LoaderTable";
import Findnotfound from "../../components/Findnotfound";
import { injectIntl } from "react-intl";
import Breadcrumbs from "../../constants/Breadcrumbs";
import SweetAlert from "react-bootstrap-sweetalert";

import { _Standard_, _Premium_, _Basic_, _ForeignAccount_ } from "../../constants/rolesModel";
import { Redirect } from "react-router-dom";
import { callApiServer } from "../../utils/callApiServer";
import { CompanyItem } from "../../components/CompanyInfo/CompanyChild/CompanyItem";
import { formatMoney } from "../../utils/formatMoney";
import NoData from "../../components/NoData";

class CompanyInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tax_code: getUrlParam()["tax_code"] ? getUrlParam()["tax_code"] : "",
      access_token: getUrlParam()["access_token"] ? getUrlParam()["access_token"] : "",

      isLoading: true,
      dataResults: [],

      alert: ""
    };
  }
  componentDidMount() {
    document.title = "VIRACE | Company Detail";
    this.setState({
      isLoading: true
    });
    const { tax_code, access_token } = this.state;
    if (access_token && tax_code) {
      localStorage.setItem("access_token", access_token);
      this.getListCompanyByTaxcode(tax_code);
    }
  }

  async getListCompanyByTaxcode(taxCode) {
    this.setState({
      isLoading: true
    });
    let body = {
      taxCode: taxCode
    };
    try {
      const response = await callApiServer.post("/Company/mm-company-search-by-tax", body);
      if (response && response.data && response.data.data && response.data.data.companies && response.data.data.companies.length) {
        this.setState({
          isLoading: false,
          dataResults: response.data.data.companies
        });
      } else {
        this.setState({
          isLoading: false,
          dataResults: []
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        dataResults: []
      });
    }
  }

  pushDetailCompany = (id) => {
    if (id !== null && id !== undefined) {
      this.props.history.push("/chi-tiet-doanh-nghiep/" + id);
    }
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  render() {
    const { isLoading, dataResults } = this.state;
    const { messages } = this.props.intl;

    return (
      <React.Fragment>
        {this.state.alert}

        {isLoading ? (
          <LoaderTable isLoaded={!isLoading} styleCustom={{ height: "500px" }} />
        ) : dataResults && dataResults.length ? (
          <div className="views_company_info">
            <div className="companyinfo-panel-content">
              <div className="company-info-content mb-4">
                <div className="company-info-content-main card-item">
                  {
                    <div className="search-result-heading pb-3">
                      <p className="search-result-title">{`${messages["newWord.amountCompanies"]} (${formatMoney(dataResults.length)} ${
                        messages["newWord.companies"]
                      })`}</p>
                    </div>
                  }

                  <div className="search-result-list">
                    <div className="row">
                      {dataResults &&
                        dataResults.length &&
                        dataResults.map((props, index) => {
                          return (
                            <CompanyItem
                              {...this.props}
                              key={index}
                              name={props.name}
                              nameEnglish={props.enName}
                              id={props.companyId}
                              taxNumber={props.taxCode}
                              vsic={props.vsicCode}
                              address={props.address}
                              phoneNumber={props.phoneNumber}
                              fax={props.fax}
                              webSite={props.website}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Findnotfound></Findnotfound>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // userAccount: state.accountReducer.userAccount,
    // companyReducer: state.companyReducer,
    // CommonReducer: state.CommonReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actSearchCompany: (body) => {
      dispatch(actSearchCompanyInfo(body));
    },
    actSearchAutocomplete: (body) => {
      dispatch(actSearchCompanyAutocomplete(body));
    }
  };
};

const MyComponent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompanyInfoPage));
export default function CompanyInfo(props) {
  return (
    <Suspense fallback="loading">
      <MyComponent {...props} />
    </Suspense>
  );
}
