import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { Dropdown, Menu, Checkbox, Input, Label } from "semantic-ui-react";
import Button from "../../../components/CustomButton/CustomButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Table } from "reactstrap";
import { actAdvanceSearchCompanyByFinance, actGetDataPointList, actGetFinancialCriteriaList } from "../../../redux/CompanyInfo/actions";
import { CompanyItem } from "../../../components/CompanyInfo/CompanyChild/CompanyItem";
import Pagination from "../../../components/Pagination/PaginationSimple";
import { formatMoney } from "../../../utils/formatMoney";
import LoaderTable from "../../../components/LoaderTable";
import Findnotfound from "../../../components/Findnotfound";
import { copyArray } from "../../../utils/copyArray";
import { callApiServer } from "../../../utils/callApiServer";
import moment from "moment";
import { Spinner } from "reactstrap";

import { saveAs as importedSaveAs } from "file-saver";
import CurrencyInput from "react-currency-input-field";

export class TabImportExport extends Component {
  dropdownRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      selectedDataPoint: [], // giai đoạn dữ liệu
      selectedCriteria: [
        // tiêu chí
        {
          year: "",
          from: "",
          to: ""
        }
      ],
      selectedTerms: [], // điều kiện tìm kiếm được thêm
      pageIndex: 1,
      pageSize: 10,
      activePage: 1,
      isSearch: false,
      criteriaData: [], // ds tiêu chí dropdown
      dropdownValue: [""], // giá trị dropdown
      dropdownText: [""], // text hiển thị ở dropdown
      filteredList: [], // list dropdown
      fromData: [""], // list from
      toData: [""], // list to,

      importExportType: [
        {
          value: "import",
          name: "Nhập khẩu",
          enName: "Import"
        },
        {
          value: "export",
          name: "Xuất khẩu",
          enName: "Export"
        }
      ],
      listYear: [],

      listHSCode: [],

      selectedHS: [],

      selectedHSCode: [],

      dataSearch: {},
      isDownloading: false
    };
  }

  componentDidMount() {
    // this.props.getDataPointList();
    this.getListHSCode();
    this.getListYear();
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const data = nextProps.companyReducer.dataPointList;
  //   let dataFormat = [];
  //   if (data && data.length > 0) {
  //     data.forEach((e) => {
  //       return { key: e.value, value: e.value, name: e.name, enName: e.enName, unit: "USD" };
  //     });
  //     dataFormat.push({
  //       text: "Năm",
  //       enText: "Year",
  //       data
  //     });
  //     this.setState({ filteredList: [dataFormat], criteriaData: dataFormat });
  //   }
  // }
  // List năm
  async getListYear() {
    try {
      const response = await callApiServer.get("/Company/ListYear");
      if (response && response.data && response.data.data) {
        const data = response.data.data;
        let dataFormat = [];
        if (data && data.length > 0) {
          data.forEach((e) => {
            return { key: e.value, value: e.value, name: e.name, enName: e.enName, unit: "USD" };
          });

          dataFormat.push({
            text: "Năm",
            enText: "Year",
            data
          });
          this.setState({ filteredList: [dataFormat], criteriaData: dataFormat });
        }
      } else {
        this.setState({
          filteredList: [],
          criteriaData: []
        });
      }
    } catch (error) {
      this.setState({
        filteredList: [],
        criteriaData: []
      });
    }
  }
  //Get list mặt hàng tiêu biểu
  async getListHSCode() {
    try {
      const response = await callApiServer.get("/Company/GetHsCode");
      if (response && response.data && response.data.data) {
        this.setState({
          listHSCode: response.data.data
        });
      } else {
        this.setState({
          listHSCode: []
        });
      }
    } catch (error) {
      this.setState({
        listHSCode: []
      });
    }
  }
  handleChangeHSCode = (value, list) => {
    let hsCodes = [];
    value.forEach((e) => {
      const arr = list.filter((i) => i.shortCode === e)[0];
      hsCodes.push(arr);
    });
    this.setState({
      selectedHS: hsCodes,
      selectedHSCode: value
    });
  };
  // Tìm kiếm trong list HS
  handleSearchListHS = (options, data) => {
    if (data) {
      return options.filter((item) => item.value.toLowerCase().includes(data.toLowerCase()) || item.label.toLowerCase().includes(data.toLowerCase()));
    }
  };

  // list loại hình
  handleChangeDataPoint = (type) => {
    const index = this.state.selectedDataPoint.indexOf(type);
    if (index < 0) {
      this.state.selectedDataPoint.push(type);
    } else {
      this.state.selectedDataPoint.splice(index, 1);
    }
    this.setState({
      selectedDataPoint: [...this.state.selectedDataPoint]
    });
  };

  // thêm tiêu chí
  addCriteria = () => {
    let { selectedCriteria, filteredList, fromData, toData, dropdownValue, dropdownText } = this.state;
    selectedCriteria.push({
      year: "",
      from: "",
      to: ""
    });
    filteredList.push(this.state.criteriaData);
    fromData.push("");
    toData.push("");
    dropdownValue.push("");
    dropdownText.push("");
    this.setState({
      selectedCriteria,
      filteredList,
      fromData,
      toData,
      dropdownValue,
      dropdownText
    });
  };

  // xóa tiêu chí
  removeCriteria = (index) => {
    let { selectedCriteria, filteredList, fromData, toData, dropdownValue, dropdownText } = this.state;
    selectedCriteria = selectedCriteria.filter((value, i) => i !== index);
    filteredList = filteredList.filter((value, i) => i !== index);
    fromData = fromData.filter((value, i) => i !== index);
    toData = toData.filter((value, i) => i !== index);
    dropdownValue = dropdownValue.filter((value, i) => i !== index);
    dropdownText = dropdownText.filter((value, i) => i !== index);
    this.setState({
      selectedCriteria,
      filteredList,
      fromData,
      toData,
      dropdownValue,
      dropdownText
    });
  };

  // thêm điều kiện
  addTerms = () => {
    const { roles } = this.props;
    const { messages } = this.props.intl;
    let { selectedDataPoint, selectedCriteria, selectedHSCode, selectedHS, filteredList, fromData, toData, dropdownValue, dropdownText } = this.state;
    let removeList = [];
    selectedCriteria.forEach((item, i) => {
      if (item.year === "") {
        removeList.push(i);
      }
    });

    // xóa tất cả tiêu chí trống, giữ lại 1 cái mặc định
    selectedCriteria = selectedCriteria.filter((item, i) => item.year !== "");
    if (selectedCriteria.length === 0) {
      selectedCriteria.push({
        year: "",
        from: "",
        to: ""
      });
    }
    filteredList = filteredList.filter((item, i) => removeList.includes(i) === false);
    if (filteredList.length === 0) {
      filteredList.push(this.state.criteriaData);
    }
    fromData = fromData.filter((item, i) => removeList.includes(i) === false);
    if (fromData.length === 0) {
      fromData.push("");
    }
    toData = toData.filter((item, i) => removeList.includes(i) === false);
    if (toData.length === 0) {
      toData.push("");
    }
    dropdownValue = dropdownValue.filter((item, i) => removeList.includes(i) === false);
    if (dropdownValue.length === 0) {
      dropdownValue.push("");
    }
    dropdownText = dropdownText.filter((item, i) => removeList.includes(i) === false);
    if (dropdownText.length === 0) {
      dropdownText.push("");
    }
    this.setState({
      selectedCriteria,
      filteredList,
      fromData,
      toData,
      dropdownValue,
      dropdownText
    });
    // if (selectedDataPoint.length === 0 || (selectedCriteria.length === 1 && selectedCriteria[0].year === "")) {

    if (selectedDataPoint.length === 0) {
      this.actAlert(messages[`advanceSearch.importExport.requiredType`]);
    } else if (selectedDataPoint.length !== 0 && selectedCriteria.length === 1 && selectedCriteria[0].year === "" && selectedHS.length === 0) {
      this.actAlert(messages[`advanceSearch.importExport.requiredValueOrHs`]);
    } else {
      const x = selectedDataPoint.length * (selectedCriteria.length + selectedHSCode.length);
      let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
      const y = x + selectedAdvanceSearchingCriteria.length;
      if (y > 10) {
        this.actAlert(messages[`advanceSearch.area.addTermsMax`]);
      } else if (
        selectedHS.length === 0 &&
        selectedCriteria.filter(
          (i) => ((i.from === "" || i.from === undefined) && (i.to === "" || i.to === undefined)) || parseFloat(i.from) > parseFloat(i.to)
        ).length > 0
      ) {
        this.setState({
          isAddTermsError: true
        });
      } else {
        let selectedTerms = [];
        selectedDataPoint.map((dataPoint) => {
          if (
            selectedCriteria[0].year !== "" &&
            ((selectedCriteria[0].from !== "" && selectedCriteria[0].from !== undefined) ||
              (selectedCriteria[0].to !== "" && selectedCriteria[0].to !== undefined))
          ) {
            selectedCriteria.map((criteria) => {
              const z = selectedAdvanceSearchingCriteria.filter(
                (i) =>
                  i.type === "importExport_year" &&
                  i.dataPoint === dataPoint &&
                  i.criteria.year === criteria.year &&
                  i.criteria.from === criteria.from &&
                  i.criteria.to === criteria.to
              );
              if (z.length === 0) {
                selectedTerms.push({
                  dataPoint,
                  criteria,
                  type: "importExport_year"
                });
              }
            });
          }
        });
        selectedDataPoint.map((dataPoint) => {
          if (selectedHS.length) {
            selectedHS.map((hs) => {
              const z = selectedAdvanceSearchingCriteria.filter(
                (i) => i.type === "importExport_hs" && dataPoint === i.dataPoint && i.hs.shortCode === hs.shortCode
              );
              if (z.length === 0) {
                selectedTerms.push({
                  dataPoint,
                  hs,
                  type: "importExport_hs"
                });
              }
            });
          }
        });

        this.props.companyReducer.selectedAdvanceSearchingCriteria = copyArray([...selectedAdvanceSearchingCriteria, ...selectedTerms]);

        // Chỉ để lại 1 hàng thêm chỉ tiêu, đồng thời reset các data
        // + Xóa hết options
        this.setState({
          isAddTermsError: false,
          selectedTerms,
          selectedDataPoint: [],
          selectedCriteria: [],
          selectedHSCode: [],
          selectedHS: [],
          fromData: [""], // list from
          toData: [""],
          dropdownValue: [""],
          dropdownText: [""]
        });
        // + Thêm vào 1 option để về trạng thái ban đầu
        setTimeout(() => {
          this.addCriteria();
        }, 10);
      }
    }
  };

  // xóa điều kiện
  removeTerm = (index) => {
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    this.props.companyReducer.selectedAdvanceSearchingCriteria = selectedAdvanceSearchingCriteria.filter((item, i) => i !== index);
    let selectedTerms = this.state.selectedTerms.filter((item, i) => i !== index);
    this.setState({
      selectedTerms
    });
  };

  // tìm kiếm
  searchTerms = (page) => {
    const { messages } = this.props.intl;
    const { pageSize } = this.state;
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    const areas = [];
    const vsics = [];
    const financials = [];
    const companyTypes = [];
    const legals = [];
    const owners = [];

    // Xuất nhập khẩu
    let importExportTurnover;
    let importExportYearValue = [];
    let importExportHsValue = [];

    if (selectedAdvanceSearchingCriteria.length > 0) {
      this.setState({ isSearch: true });
      selectedAdvanceSearchingCriteria.forEach((e) => {
        if (e.type === "area") {
          areas.push({
            year: e.dataPoint ? e.dataPoint : "",
            provinceId: e.area.provinceidrequest ? e.area.provinceidrequest.toString() : "",
            districtCode: e.area.districtCode ? e.area.districtCode.toString() : "",
            communeId: e.area.communeCode ? e.area.communeCode.toString() : ""
          });
        } else if (e.type === "industry") {
          vsics.push({
            year: e.dataPoint,
            vsicCode: e.industry.code
          });
        } else if (e.type === "companyType") {
          companyTypes.push({
            year: e.dataPoint,
            companyTypeId: e.typeCompany.companyTypeId
          });
        } else if (e.type === "legal") {
          legals.push({
            userId: e.userId
          });
        } else if (e.type === "owner") {
          owners.push({
            id: e.userId,
            ownerShipType: e.ownerShipType
          });
        } else if (e.type === "finance") {
          financials.push({
            year: e.dataPoint,
            financialItemId: e.criteria.criteriaId,
            fromValue: parseFloat(e.criteria.from),
            toValue: parseFloat(e.criteria.to)
          });
        } else if (e.type === "importExport_year" || e.type === "importExport_hs") {
          if (e.type === "importExport_year") {
            importExportYearValue.push({
              type: e.dataPoint,
              year: e.criteria.year,
              from: e.criteria.from ? parseFloat(e.criteria.from) : null,
              to: e.criteria.to ? parseFloat(e.criteria.to) : null
            });
          }
          if (e.type === "importExport_hs") {
            importExportHsValue.push({
              type: e.dataPoint,
              value: e.hs.shortCode
            });
          }
        }
      });

      importExportTurnover = {
        importExportYearValue,
        importExportHsValue
      };

      var body = {
        pageIndex: page,
        pageSize: pageSize,
        areas,
        vsics,
        financials,
        companyTypes,
        legals,
        owners,
        importExportTurnover
      };

      this.props.advanceSearchCompanyByFinance(body);
      this.setState({
        dataSearch: body
      });
    } else {
      this.actAlert(messages[`advanceSearch.no-searching-criteria`]);
    }
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => this.searchTerms(activePage));
  };

  handleDownLoad(dataSearch) {
    const { messages } = this.props.intl;
    this.setState({
      isDownloading: true
    });
    const body = {
      areas: dataSearch.areas,
      financials: dataSearch.financials,
      vsics: dataSearch.vsics,
      companyTypes: dataSearch.companyTypes,
      legals: dataSearch.legals,
      owners: dataSearch.owners,
      importExportTurnover: dataSearch.importExportTurnover
    };
    callApiServer
      .post("Company/CompanyReportAdvancedSearch/export", body)
      .then((res) => {
        if (res && res.data.data && res.data.statusCode === 200) {
          this.processDownloadData(res.data.data);
        } else if (res && res.data.statusCode === 429) {
          this.actAlert(messages[`newWord.overTurnDownload`]);
          this.setState({
            isDownloading: false
          });
        } else if (res && res.data.statusCode === 403) {
          this.actAlert(messages[`newWord.notPermision`]);
          this.setState({
            isDownloading: false
          });
        } else {
          this.actAlert(messages[`newWord.somthingWrong`]);
          this.setState({
            isDownloading: false
          });
        }
      })
      .catch((err) => {
        this.actAlert(messages[`newWord.somthingWrong`]);
        this.setState({
          isDownloading: false
        });
      })
      .finally();
  }

  processDownloadData(urlFile) {
    const dateNow = Date.now();
    const dateNowFomated = moment(dateNow).format("YYMMDD");
    const fileName = `Result_${dateNowFomated}.xlsx`;
    const urlDownload = `/Company/SearchCompany/download-file-export?pathFile=${urlFile}`;
    callApiServer
      .get(urlDownload, {
        responseType: "blob"
      })
      .then((res) => {
        if (res && res.data) {
          importedSaveAs(res.data, fileName);
        }

        this.setState({
          isDownloading: false
        });
      });
  }
  // chọn năm
  handleClick = (value, option, index, listRefDropDown) => {
    let { dropdownValue, dropdownText, selectedCriteria, fromData, toData } = this.state;

    dropdownValue[index] = value;
    dropdownText[index] = value;
    selectedCriteria[index].year = value;
    selectedCriteria[index].text = option.text;
    selectedCriteria[index].from = fromData[index];
    selectedCriteria[index].to = toData[index];

    selectedCriteria[index].unit = "USD";
    this.setState({ selectedCriteria, dropdownValue, dropdownText });
    listRefDropDown[index].clearSearchQuery();
    listRefDropDown[index].close();
  };

  // đóng dropdown
  handleClose = (index) => {
    let { filteredList, criteriaData } = this.state;
    filteredList[index] = criteriaData;
    this.setState({ filteredList });
  };

  // thay đổi ở input
  handleChangeInput = (value, index, field) => {
    let { fromData, toData, selectedCriteria } = this.state;
    if (field === "from") {
      fromData[index] = value;
      selectedCriteria[index].from = value;
    } else if (field === "to") {
      toData[index] = value;
      selectedCriteria[index].to = value;
    }
    this.setState({ selectedCriteria, fromData, toData, isAddTermsError: false });
  };

  render() {
    const { messages } = this.props.intl;
    const { selectedCriteria, importExportType, listHSCode, selectedHS, selectedHSCode, fromData, toData } = this.state;

    const { totalItems, totalPage, loading, companyData, selectedAdvanceSearchingCriteria, advanceSearchLoading } = this.props.companyReducer;
    const { activePage, dataSearch, isDownloading } = this.state;
    const language = localStorage.getItem("currentLanguage");

    let listRefDropDown = [];

    const listHSCodeFormat = [];
    listHSCode.map((item) => {
      listHSCodeFormat.push({
        key: item.shortCode,
        text: `${item.shortCode}`,
        value: item.shortCode,
        label: `- ${language === "vi" ? item.name : item.enName}`
      });
    });
    return (
      <React.Fragment>
        {this.state.alert}
        <div className="tab_area">
          <div className="row equal-height">
            <div className="col-lg-7 col-xl-8 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.selectSearchTerms" />
                </div>
                <div className="mb-4">
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.importExport.dataPoint" />
                  </div>
                  <div className="row">
                    {importExportType &&
                      importExportType.length > 0 &&
                      importExportType.map((prop, key) => {
                        return (
                          <div className={`input-list-Checkbox col-md-3 col-lg-4 col-xl-3`} key={key}>
                            <Checkbox
                              label={language === "en" && prop.enName ? prop.enName : prop.name}
                              checked={this.state.selectedDataPoint.includes(prop.value)}
                              onChange={() => this.handleChangeDataPoint(prop.value)}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div>
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.importExport.value" />
                  </div>
                  {selectedCriteria &&
                    selectedCriteria.length > 0 &&
                    selectedCriteria.map((prop, index) => {
                      return (
                        <div className="area mb-3" key={index}>
                          <div className="row selected-custom-select criteria-section">
                            <div className="col-xl-2 pr-0 mb-2 mb-xl-0 pl-0 ml-2">
                              <Menu compact className="w-100 h-100">
                                <Dropdown
                                  fluid
                                  className="selection"
                                  ref={(el) => (listRefDropDown = [...listRefDropDown, el])}
                                  closeOnChange
                                  onClose={(_e) => this.handleClose(index)}
                                  value={this.state.dropdownValue[index]}
                                  text={this.state.dropdownText[index]}
                                  placeholder={messages["advanceSearch.area.year"]}
                                >
                                  <Dropdown.Menu>
                                    {this.state.filteredList &&
                                      this.state.filteredList[index] &&
                                      this.state.filteredList[index].map((item, key) => {
                                        return (
                                          <div key={key}>
                                            {/* <Dropdown.Header content={language === "en" && item.enText ? item.enName : item.text} /> */}
                                            {item.data.map((option) => {
                                              return (
                                                <Dropdown.Item
                                                  key={option.value}
                                                  value={option.value}
                                                  active={option.value === this.state.dropdownValue[index]}
                                                  onClick={(e, { value }) => this.handleClick(value, option, index, listRefDropDown)}
                                                >
                                                  <span className="text pl-4 text-ellipsis">{option.value}</span>
                                                </Dropdown.Item>
                                              );
                                            })}
                                          </div>
                                        );
                                      })}
                                    {this.state.filteredList && this.state.filteredList[0] && this.state.filteredList[0].length === 0 && (
                                      <div className="message">
                                        <IntlMessages id="search.notfound.text" />
                                      </div>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Menu>
                            </div>
                            <div className="col-xl-4 pr-0 mb-2 ml-2 mb-xl-0 pl-0 group-input-curency">
                              <span className="label-curency">
                                <IntlMessages id="advanceSearch.finance.from" />
                              </span>
                              <CurrencyInput
                                name="input-name"
                                className="input-curency"
                                value={fromData[index]}
                                allowNegativeValue={false}
                                decimalsLimit={2}
                                onValueChange={(value) => this.handleChangeInput(value, index, "from")}
                              />
                              <span className="suffix-curency">USD</span>
                            </div>
                            <div className="col-xl-4 pr-0 mb-2 ml-2 mb-xl-0 pl-0 group-input-curency">
                              <span className="label-curency">
                                <IntlMessages id="advanceSearch.finance.to" />
                              </span>
                              <CurrencyInput
                                name="input-name"
                                className="input-curency"
                                decimalsLimit={2}
                                value={toData[index]}
                                allowNegativeValue={false}
                                onValueChange={(value) => this.handleChangeInput(value, index, "to")}
                              />
                              <span className="suffix-curency">USD</span>
                            </div>
                            <div className="col-12 form-error">
                              {prop.year !== "" &&
                                (((prop.from === "" || prop.from === undefined) && (prop.to === "" || prop.to === undefined)) ||
                                  parseFloat(prop.from) > parseFloat(prop.to)) &&
                                this.state.isAddTermsError && <IntlMessages id="advanceSearch.finance.required" />}
                            </div>
                          </div>
                          {index > 0 && (
                            <span className="material-icons check" onClick={() => this.removeCriteria(index)}>
                              cancel
                            </span>
                          )}
                        </div>
                      );
                    })}
                  <div className="add-area" onClick={() => this.addCriteria()}>
                    <span className="material-icons check mr-1">add_circle</span>
                    <span>
                      <IntlMessages id="advanceSearch.importExport.addCriteria" />
                    </span>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.importExport.majorGoods" />
                  </div>
                  <div className="area mb-3">
                    <div className="selected-custom-select w-100">
                      <Menu compact className="w-100 dropdown-vsic-list">
                        <Dropdown
                          placeholder={messages["advanceSearch.importExport.hsCode"]}
                          fluid
                          multiple
                          search={this.handleSearchListHS}
                          value={selectedHSCode}
                          selection
                          options={listHSCodeFormat}
                          noResultsMessage={messages["search.notfound.text"]}
                          onChange={(e, { value }) => this.handleChangeHSCode(value, listHSCode)}
                        />
                      </Menu>
                    </div>
                  </div>
                </div>
                <div className="mt-5 d-flex justify-content-center">
                  <Button className={`common-button`} onClick={() => this.addTerms()}>
                    <IntlMessages id="advanceSearch.addTerms" />
                    {/* <em className="material-icons">add</em> */}
                  </Button>
                </div>
              </div>
            </div>

            {/* Danh sách các điều kiện tìm kiếm */}
            <div className="col-lg-5 col-xl-4 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.searchTerms" />
                </div>
                <div>
                  {selectedAdvanceSearchingCriteria.length > 0 && (
                    <div className="table-custom ">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <IntlMessages id="table.sttTable" />
                            </th>
                            <th>
                              <IntlMessages id="advanceSearch.term" />
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAdvanceSearchingCriteria.map((prop, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                {prop.type === "area" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.area.area" /> ({prop.dataPoint}):
                                    <span>{` ${prop.area.communeName ? prop.area.communeName + ", " : ""}${
                                      prop.area.districtName ? prop.area.districtName + ", " : ""
                                    }${prop.area.provinceName}`}</span>
                                  </td>
                                )}
                                {prop.type === "industry" && (
                                  <td>
                                    <IntlMessages id="sidebar.Industry" />{" "}
                                    {language === "en" && prop.industry.enName ? prop.industry.enName : prop.industry.name} ({prop.dataPoint}):{" "}
                                    {prop.industry.code}
                                  </td>
                                )}
                                {prop.type === "companyType" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.companyType.companyType" /> :
                                    {language === "en" && prop.typeCompany.enName ? prop.typeCompany.enName : prop.typeCompany.name} ({prop.dataPoint}):{" "}
                                  </td>
                                )}
                                {prop.type === "legal" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.legalRepresentative" /> :{language === "en" && prop.enName ? prop.enName : prop.name}{" "}
                                    <br></br> ID: {prop.idPerson}{" "}
                                  </td>
                                )}
                                {prop.type === "owner" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.owner" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID:{" "}
                                    {prop.showId}{" "}
                                  </td>
                                )}
                                {prop.type === "finance" && (
                                  <td>
                                    {prop.criteria.criteriaText} ({prop.dataPoint}):
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()}  ${formatMoney(
                                            prop.criteria.from.replace(/,/g, "."),
                                            2
                                          )} (${prop.criteria.criteriaUnit}) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${
                                            prop.criteria.criteriaUnit
                                          })`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_year" && (
                                  <td>
                                    {prop.dataPoint === "import" ? (
                                      <IntlMessages id="advanceSearch.importExport.import" />
                                    ) : (
                                      <IntlMessages id="advanceSearch.importExport.export" />
                                    )}
                                    <span> ({prop.criteria.year}):</span>
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()} ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${
                                            prop.criteria.unit
                                          }) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${
                                            prop.criteria.unit
                                          })`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_hs" && (
                                  <td>
                                    {prop.dataPoint === "import" ? (
                                      <IntlMessages id="advanceSearch.importExport.import" />
                                    ) : (
                                      <IntlMessages id="advanceSearch.importExport.export" />
                                    )}
                                    <span>{`: ${prop.hs.shortCode} - ${language === "vi" ? prop.hs.name : prop.hs.enName}`}</span>
                                  </td>
                                )}
                                <td>
                                  <span className="material-icons check" onClick={() => this.removeTerm(index)}>
                                    cancel
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  <div className="mt-5 d-flex justify-content-center">
                    <Button className={`common-button`} onClick={() => this.searchTerms(1)} disabled={loading} isLoading={loading}>
                      <IntlMessages id="filter.buttonSearch" />
                      <em className="material-icons">search</em>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {advanceSearchLoading === true && <LoaderTable isLoaded={!loading} styleCustom={{ height: "500px" }} />}
            {advanceSearchLoading === false && (
              <div>
                {companyData && companyData.length > 0 ? (
                  <div className="card-item mb-4 mt-0 company-info-content">
                    <div className="company-info-content-main w-100">
                      <div className="search-result-heading pb-3">
                        <p className="search-result-title">
                          {`${messages["newWord.amountCompanies"]} (${formatMoney(totalItems)} ${messages["newWord.companies"]})`}
                        </p>
                        {isDownloading ? (
                          <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                        ) : (
                          <div
                            className="btn-export-data text-cyan"
                            onClick={() => {
                              this.handleDownLoad(dataSearch);
                            }}
                          >
                            <em className="material-icons">download</em>
                            <IntlMessages id="newWord.downloadSearchResult" />
                          </div>
                        )}{" "}
                      </div>
                      <div className="search-result-list">
                        <div className="row">
                          {companyData.map((props, index) => {
                            return (
                              <CompanyItem
                                {...this.props}
                                key={index}
                                name={props.companyName}
                                nameEnglish={props.companyEnName}
                                id={props.id}
                                taxNumber={props.companyTax}
                                vsic={props.vsic}
                                address={props.companyAddress}
                                addressEnglish={props.companyEnAddress}
                                phoneNumber={props.companyPhone}
                                fax={props.companyFax}
                                webSite={props.companyWebsite}
                                vsicID={props.vsic}
                                isShowValueTooltip={false}
                                enNetProfitLabel={props.enNetProfitLabel}
                                enNetRevenueLabel={props.enNetRevenueLabel}
                                netProfitLabel={props.netProfitLabel}
                                netRevenueLabel={props.netRevenueLabel}
                                netProfitValue={props.netProfitValue}
                                netRevenueValue={props.netRevenueValue}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="foot-pagination">
                        <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-item">
                    <Findnotfound goHome={"/trang-chu-cr"} isSearch={true} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyReducer: state.companyReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    advanceSearchCompanyByFinance: (body) => {
      dispatch(actAdvanceSearchCompanyByFinance(body));
    },
    // danh sách tiêu chí tài chính
    getFinancialCriteriaList: () => {
      dispatch(actGetFinancialCriteriaList());
    },
    getDataPointList: () => {
      dispatch(actGetDataPointList());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabImportExport));
