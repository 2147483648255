import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as Types from "../actions";
import * as actions from "./actions";
import * as companyCompareService from "../../services/companyCompare.service";
import { totalPage } from "../../utils/function.util";
import { companyPageSize } from "../../constants/pageSize";
import { actNotify } from "./../Fetch/actions";
import { redirectURL } from "../../utils/logoutCommon";
const language = localStorage.getItem("currentLanguage");

function* actGetCompanyCompare(params) {
  const model = params.payload.model;
  var typeIP = params.payload.typeIP;
  const body = {
    dt: model.dt,
    kv: model.kv,
    pageSize: companyPageSize,
    ln: model.ln,
    page: model.page,
    tts: model.tts,
    search: model.txtsearch,
    vdl: model.vdl,
    ss: model.ss,
    minmax: model.minmax
  };
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    var response=null;
    if (typeIP === 2) {
       response = yield call(
        companyCompareService.actSearchCompanyCompareAny,
        body
      );
    } else {
       response = yield call(
        companyCompareService.actSearchCompanyCompare,
        body
      );
    }

    if (response.data.data) {
      var companyData = response.data.data.companies;
      var totalItems = response.data.data.totalItem;
      var pageIndex = response.data.data.pageIndex;
      var totalPages = totalPage(totalItems, companyPageSize);
      yield put(
        actions.getSearchCompanyCompareSuccess(
          companyData,
          totalItems,
          totalPages,
          pageIndex,
          typeIP
        )
      );
    } else if(response.data.statusCode === 203) {
      let notify = {
        message: response.data.message,
        color: "danger",
      };
      yield put(actNotify(notify));
      setTimeout(function() {
        redirectURL();
      }, 4000);
    } else {
      yield put({
        type: Types.GET_LIST_COMPANYCOMPARE_FAIL,
        typeIP
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.GET_LIST_COMPANYCOMPARE_FAIL,
      typeIP
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActSeachCompanyCompare() {
  yield takeEvery(Types.GET_LIST_COMPANYCOMPARE, actGetCompanyCompare);
}
//SS Truc Tiep
function* actRequestLiveCharteCompare(params) {
  let body = {
    arrayRatio: params.body.arrayRatio,
    arrayAccount: params.body.arrayAccount,
    arrCompanyIds: params.body.arrCompanyIds
  };
  try {
    var response = yield call(
      companyCompareService.requestLiveCharteCompare,
      body
    );

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_LIVE_CHART_CP_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHART_CP_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHART_CP_FAIL
    });
  }
}
export function* watchRequestLiveCharteCompare() {
  yield takeEvery(Types.REQUEST_LIVE_CHART_CP, actRequestLiveCharteCompare);
}
//SS Trung Binh

function* requestLiveCharteCompare_TB(params) {
  let body = {
    vsic: params.body.vsicId,
    arrayAccount: params.body.arrayAccount,
    arrayRatio: params.body.arrayRatio,
    arrCompanyIds: params.body.arrCompanyIds,
    arrAllCompanyIds: params.body.arrAllCompanyIds,
    isCheckTBN: params.body.isCheckTBN,
    arrayKv: params.body.arrayKv,
    isCheckAllCompany: params.body.isCheckAllCompany,
    isCheckListCompany: params.body.isCheckCompany,
  };
  try {
    var response = yield call(
      companyCompareService.requestLiveCharteCompare_TB,
      body
    );

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_LIVE_CHART_CP_TB_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHART_CP_TB_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHART_CP_TB_FAIL
    });
  }
}
export function* watchRequestLiveChartCPDNK_TB() {
  yield takeEvery(Types.REQUEST_LIVE_CHART_CP_TB, requestLiveCharteCompare_TB);
}

//SS TGT

function* requestLiveCharteCompare_TGT(params) {
  let body = {
    vsic: params.body.vsicId,
    arrayAccount: params.body.arrayAccount,
    arrCompanyIds: params.body.arrCompanyIds,
    arrAllCompanyIds: params.body.arrAllCompanyIds,
    isCheckTBN: params.body.isCheckTBN,
    arrayKv: params.body.arrayKv,
    isCheckAllCompany: params.body.isCheckAllCompany,
    isCheckListCompany: params.body.isCheckCompany,
  };
  try {
    var response = yield call(
      companyCompareService.requestLiveCharteCompare_TGT,
      body
    );

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_LIVE_CHART_CP_TGT_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHART_CP_TGT_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHART_CP_TGT_FAIL
    });
  }
}
export function* watchRequestLiveChartCPDNK_TGT() {
  yield takeEvery(Types.REQUEST_LIVE_CHART_CP_TGT, requestLiveCharteCompare_TGT);
}
function* actRequestTotalCharteCompare(params) {
  let body = { arrHeso: params.arrHeso, arrKv: params.arrKv };
  try {
    var response = yield call(
      companyCompareService.requestTotalChartCompare,
      body
    );
    if (response.data.data) {
      yield put({
        type: Types.REQUEST_TOTAL_CHART_CP_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_TOTAL_CHART_CP_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_TOTAL_CHART_CP_FAIL
    });
  }
}
export function* watchRequestTotalChartCompare() {
  yield takeEvery(Types.REQUEST_TOTAL_CHART_CP, actRequestTotalCharteCompare);
}
function* actRequestChartListCB(params) {
  let typeID = params.id;
  try {
    var response = yield call(
      companyCompareService.requestTotalChartListCB,
      typeID
    );
    if (response.data.success) {
      yield put({
        type: Types.REQUEST_LIVE_CHARTLIST_CP_SUCCESS,
        data: response.data.data, typeID
      });
    } else {
      yield put({
        type: Types.REQUEST_LIVE_CHARTLIST_CP_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_LIVE_CHARTLIST_CP_FAIL
    });
  }
}
export function* watchRequestListCbChart() {
  yield takeEvery(Types.REQUEST_LIVE_CHARTLIST_CP, actRequestChartListCB);
}
export default function* rootSaga() {
  yield all([
    fork(watchActSeachCompanyCompare),
    fork(watchRequestLiveCharteCompare),
    fork(watchRequestTotalChartCompare),
    fork(watchRequestListCbChart),
    fork(watchRequestLiveChartCPDNK_TGT),
    fork(watchRequestLiveChartCPDNK_TB),
  ]);
}
