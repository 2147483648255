import React,{ Suspense}  from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import IntlMessages from "../../utils/IntlMessages";

class ModalConfidentialityPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: "",
        }
    }
    handleCloseModal = () => {
        this.props.handleCloseForget(false);
    };
    handleHideAlert = () => {
        this.setState({
            alert: "",
        });
    };

    render() {
        const { toggleModal } = this.props;
        const closeBtn = (
            <span onClick={this.handleCloseModal} className="material-icons close-btn">close</span>
        );

        return (
            <React.Fragment>

                <Modal
                    className="common-modal"
                    isOpen={toggleModal}
                    size="lg"
                    centered
                >
                    <ModalHeader
                        close={closeBtn}
                    >
                        <IntlMessages id="footer.confidentiality" />
                    </ModalHeader>

                    <ModalBody>
                        <p className="mb-2 font-medium"><IntlMessages id="footer.confidentiality.text1" /></p>
                        <p className="mb-2"><IntlMessages id="footer.confidentiality.text2" /></p>
                        <p className="mb-2"><IntlMessages id="footer.confidentiality.text3" /></p>
                        <p><IntlMessages id="footer.confidentiality.text4" /></p>
                    </ModalBody>

                </Modal>
            </React.Fragment >
        );
    }
}


const MyComponent = (ModalConfidentialityPage);
export default function ModalConfidentiality(props) {
  return (
    <Suspense fallback="loading">
      <MyComponent {...props} />
    </Suspense>
  );
}