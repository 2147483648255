import axios from "axios";
import BASE_API_URL from "../constants/hostSever";

export const callApiServer = axios.create({
  baseURL: BASE_API_URL,
  timeout: 60000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});
callApiServer.interceptors.request.use(
  (config) => {
    // setIpV4();
    if (config.baseURL === BASE_API_URL && !config.headers.Authorization) {
      const token = localStorage.getItem("access_token");
      if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    if (config.baseURL === BASE_API_URL && !config.headers.Hash) {
      const hash = localStorage.getItem("hash");
      if (hash !== null) {
        config.headers.Hash = hash;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
callApiServer.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response && error.response.status) {
      if (error.response.statusText === "Unauthorized" || error.response.status === 401) {
        try {
          // var pathname = new URL(window.location.href).pathname;
          // // localStorage.removeItem("access_token");
          // if (pathname !== "/dang-nhap") {
          // }
          window.location.href = "/company-search";
        } catch (error) {
          // Todo err
        }
      }
    }
    return Promise.reject(error);
  }
);

export function setAuthorization(token) {
  if (token === null) {
    delete callApiServer.defaults.headers.common["Authorization"];
  } else {
    callApiServer.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

export function removeAuthorization() {
  setAuthorization(null);
}
