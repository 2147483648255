import { callApiServer } from "../utils/callApiServer";
export const actSearchCompanyCompare = (body) => {
  const params = {
    searchText: body.search,
    vdl: body.vdl,
    tts: body.tts,
    ln: body.ln,
    dt: body.dt,
    kv: body.kv,
    pageIndex: body.page,
    pageSize: body.pageSize,
    sort: body.ss,
    minmax: body.minmax
  };
  return callApiServer.get("/CompareBusinesses/SearchCompareBusinesses", { params: params });
};
export const actSearchCompanyCompareAny = (body) => {
  const params = {
    searchText: body.search,
    vdl: body.vdl,
    tts: body.tts,
    ln: body.ln,
    dt: body.dt,
    kv: body.kv,
    pageIndex: body.page,
    pageSize: body.pageSize,
    sort: body.ss,
    minmax: body.minmax
  };
  return callApiServer.get("/CompareBusinesses/SearchCompare_Any_Businesses", { params: params });
};
//SS truc tiep
export const requestLiveCharteCompare = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/CompareBusinesses/Compare_TT_Chart?lang=" + params.lang, body);
};
export const requestLiveCharteCompare_TB = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/CompareBusinesses/TB_CompareChart?lang=" + params.lang, body);
};
export const requestLiveCharteCompare_TGT = (body) => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.post("/CompareBusinesses/TongGiaTri_CompareChart?lang=" + params.lang, body);
};
export const requestTotalChartCompare = (body) => {
  return callApiServer.post("/chartsosanhnhom", body);
};
export const requestTotalChartListCB = (id) => {
  var params = {
    languagues: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/CompareBusinesses/Menu_Compare?id=" + id, { params: params });
};
