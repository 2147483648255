import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { getRequestProvince, getRequestDistrict, getRequestCommune } from "../../../redux/Common/actions";
import { Dropdown, Menu, Checkbox } from "semantic-ui-react";
import Button from "../../../components/CustomButton/CustomButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Table } from "reactstrap";
import { actAdvanceSearchCompanyByArea, actGetDataPointList } from "../../../redux/CompanyInfo/actions";
import { CompanyItem } from "../../../components/CompanyInfo/CompanyChild/CompanyItem";
import Pagination from "../../../components/Pagination/PaginationSimple";
import { formatMoney } from "../../../utils/formatMoney";
import LoaderTable from "../../../components/LoaderTable";
import Findnotfound from "../../../components/Findnotfound";
import { _Demo_, _Basic_, _Standard_, _Premium_ } from "../../../constants/rolesModel";
import { copyArray } from "../../../utils/copyArray";
import { callApiServer } from "../../../utils/callApiServer";
import moment from "moment";
import { Spinner } from "reactstrap";
import { saveAs as importedSaveAs } from "file-saver";
export class TabArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listYear: [],
      selectedDataPoint: [], // giai đoạn dữ liệu
      provinces: [],
      districts: [],
      communes: [],
      selectedArea: [
        // khu vực
        {
          provinceId: "",
          provinceidrequest: "",
          districtId: "",
          communeId: ""
        }
      ],
      provinceIndex: 0,
      districtIndex: 0,
      communeIndex: 0,
      selectedTerms: [], // điều kiện tìm kiếm được thêm
      pageIndex: 1,
      pageSize: 10,
      activePage: 1,
      isSearch: false,
      dataSearch: {},
      isDownloading: false
    };
  }

  componentDidMount() {
    this.props.requestGetListProvince();
    // this.props.getDataPointList();

    this.getListYear();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const language = localStorage.getItem("currentLanguage");
    let { provinceIndex, districtIndex, communeIndex, districts, communes } = this.state;
    let { listDistrict, listCommune } = nextProps.commonReducer;
    if (listDistrict && listDistrict.length && listDistrict[0].key !== "") {
      listDistrict = listDistrict.map((props) => {
        return { key: props.districtsCode, value: props.id, text: language === "en" && props.enName ? props.enName : props.name };
      });
      listDistrict.unshift({
        key: "",
        text: language === "en" ? "District" : "Quận/Huyện",
        value: ""
      });
    }
    if (listCommune && listCommune.length && listCommune[0].key !== "") {
      listCommune = listCommune.map((props) => {
        return { key: props.id, value: props.id, text: language === "en" && props.enName ? props.enName : props.name };
      });
      listCommune.unshift({
        key: "",
        text: language === "en" ? "Commune" : "Phường/Xã",
        value: ""
      });
    }
    if (listDistrict && districtIndex === provinceIndex) {
      districts[districtIndex] = listDistrict;
    }
    if (listCommune && communeIndex === districtIndex && districtIndex === provinceIndex) {
      communes[communeIndex] = listCommune;
    }
    this.setState({
      districts,
      communes
    });
  }

  // List năm
  async getListYear() {
    try {
      const response = await callApiServer.get("/Company/ListYear");
      if (response && response.data && response.data.data) {
        const data = response.data.data;
        this.setState({ listYear: data });
      } else {
        this.setState({
          listYear: []
        });
      }
    } catch (error) {
      this.setState({
        listYear: []
      });
    }
  }

  // chọn giai đoạn dữ liệu
  handleChangeDataPoint = (year) => {
    const index = this.state.selectedDataPoint.indexOf(year);
    if (index < 0) {
      this.state.selectedDataPoint.push(year);
    } else {
      this.state.selectedDataPoint.splice(index, 1);
    }
    this.setState({
      selectedDataPoint: [...this.state.selectedDataPoint]
    });
  };

  handleChangeProvince = (value, index, provinces, prop, provinceidrequest) => {
    const province = provinces.filter((i) => i.value === value)[0];
    let selectedArea = this.state.selectedArea;
    selectedArea[index].provinceId = value;
    selectedArea[index].provinceidrequest = province.provinceidrequest;

    selectedArea[index].provinceCode = province.key;
    selectedArea[index].provinceName = province.text;
    selectedArea[index].districtId = "";
    selectedArea[index].districtCode = "";
    selectedArea[index].districtName = "";
    selectedArea[index].communeId = "";
    selectedArea[index].communeCode = "";
    selectedArea[index].communeName = "";
    this.setState({
      selectedArea,
      provinceIndex: index,
      districtIndex: index,
      communeIndex: index
      // selectedTerms: []
    });
    this.props.requestGetListDistrict(value);
  };

  handleChangeDistrict = (value, index, districts) => {
    const district = districts.filter((i) => i.value === value)[0];
    let selectedArea = this.state.selectedArea;
    selectedArea[index].districtId = value;
    selectedArea[index].districtCode = district.key;
    selectedArea[index].districtName = district.text;
    selectedArea[index].communeId = "";
    selectedArea[index].communeCode = "";
    selectedArea[index].communeName = "";
    this.setState({
      selectedArea,
      districtIndex: index,
      communeIndex: index
      // selectedTerms: []
    });
    this.props.requestGetListCommune(value);
  };

  handleChangeCommnue = (value, index, communes) => {
    const commune = communes.filter((i) => i.value === value)[0];
    let selectedArea = this.state.selectedArea;
    selectedArea[index].communeId = value;
    selectedArea[index].communeCode = commune.key;
    selectedArea[index].communeName = commune.text;
    this.setState({
      selectedArea,
      communeIndex: index
      // selectedTerms: []
    });
  };

  // thêm khu vực
  addArea = () => {
    let { selectedArea, districts, communes } = this.state;
    selectedArea.push({
      provinceId: "",
      districtId: "",
      communeId: ""
    });
    districts.push([]);
    communes.push([]);
    this.setState({
      selectedArea,
      districts,
      communes
    });
  };

  // xóa khu vực
  removeArea = (index) => {
    let selectedArea = this.state.selectedArea.filter((value, i) => i !== index);
    let districts = this.state.districts.filter((value, i) => i !== index);
    let communes = this.state.communes.filter((value, i) => i !== index);
    this.setState({
      selectedArea,
      districts,
      communes
    });
  };

  // thêm điều kiện
  addTerms = () => {
    const { roles } = this.props;
    const { messages } = this.props.intl;
    let { selectedDataPoint, dataSearch } = this.state;
    let removeList = [];
    this.state.selectedArea.forEach((item, i) => {
      if (item.provinceId === "") {
        removeList.push(i);
      }
    });
    // xóa tất cả khu vực trống, giữ lại 1 cái mặc định
    let selectedArea = this.state.selectedArea.filter((item, i) => item.provinceId !== "");

    if (selectedArea.length === 0) {
      selectedArea.push({
        provinceId: "",
        districtId: "",
        communeId: ""
      });
    }
    let districts = this.state.districts.filter((item, i) => removeList.includes(i) === false);
    if (districts.length === 0) {
      districts.push([]);
    }
    let communes = this.state.communes.filter((item, i) => removeList.includes(i) === false);
    if (communes.length === 0) {
      communes.push([]);
    }
    this.setState({
      selectedArea,
      districts,
      communes
    });
    if (selectedDataPoint.length === 0 || (selectedArea.length === 1 && selectedArea[0].provinceId === "")) {
      this.actAlert(messages[`advanceSearch.area.addTermsCondition`]);
    } else {
      const x = selectedDataPoint.length * selectedArea.length;
      let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
      const y = x + selectedAdvanceSearchingCriteria.length;
      if (y > 10) {
        this.actAlert(messages[`advanceSearch.area.addTermsMax`]);
      } else {
        let selectedTerms = [];
        selectedDataPoint.forEach((dataPoint) => {
          selectedArea.forEach((area) => {
            const z = selectedAdvanceSearchingCriteria.filter(
              (i) =>
                i.type === "area" &&
                i.dataPoint === dataPoint &&
                i.area.provinceId === area.provinceId &&
                i.area.districtId === area.districtId &&
                i.area.communeId === area.communeId
            );
            if (z.length === 0) {
              selectedTerms.push({
                dataPoint,
                area,
                type: "area"
              });
            }
          });
        });
        this.props.companyReducer.selectedAdvanceSearchingCriteria = copyArray([...selectedAdvanceSearchingCriteria, ...selectedTerms]);
        this.setState({
          selectedTerms,
          selectedDataPoint: [],
          // Reset điều kiện tìm kiếm khu vực về rỗng
          selectedArea: [
            // khu vực
            {
              provinceId: "",
              provinceidrequest: "",
              districtId: "",
              communeId: ""
            }
          ]
        });
      }
    }
  };

  // xóa điều kiện
  removeTerm = (index) => {
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    this.props.companyReducer.selectedAdvanceSearchingCriteria = selectedAdvanceSearchingCriteria.filter((item, i) => i !== index);
    let selectedTerms = this.state.selectedTerms.filter((item, i) => i !== index);
    this.setState({
      selectedTerms
    });
  };

  // tìm kiếm
  searchTerms = (page) => {
    const { messages } = this.props.intl;
    const { pageSize } = this.state;
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    const areas = [];
    const vsics = [];
    const financials = [];
    const companyTypes = [];
    const legals = [];
    const owners = [];

    // Xuất nhập khẩu
    let importExportTurnover;
    let importExportYearValue = [];
    let importExportHsValue = [];
    if (selectedAdvanceSearchingCriteria.length > 0) {
      this.setState({ isSearch: true });
      selectedAdvanceSearchingCriteria.forEach((e) => {
        if (e.type === "area") {
          areas.push({
            year: e.dataPoint ? e.dataPoint : "",
            provinceId: e.area.provinceidrequest ? e.area.provinceidrequest.toString() : "",
            districtCode: e.area.districtCode ? e.area.districtCode.toString() : "",
            communeId: e.area.communeCode ? e.area.communeCode.toString() : ""
          });
        } else if (e.type === "industry") {
          vsics.push({
            year: e.dataPoint,
            vsicCode: e.industry.code
          });
        } else if (e.type === "companyType") {
          companyTypes.push({
            year: e.dataPoint,
            companyTypeId: e.typeCompany.companyTypeId
          });
        } else if (e.type === "legal") {
          legals.push({
            userId: e.userId
          });
        } else if (e.type === "owner") {
          owners.push({
            id: e.userId,
            ownerShipType: e.ownerShipType
          });
        } else if (e.type === "finance") {
          financials.push({
            year: e.dataPoint,
            financialItemId: e.criteria.criteriaId,
            fromValue: parseFloat(e.criteria.from),
            toValue: parseFloat(e.criteria.to)
          });
        } else if (e.type === "importExport_year" || e.type === "importExport_hs") {
          if (e.type === "importExport_year") {
            importExportYearValue.push({
              type: e.dataPoint,
              year: e.criteria.year,
              from: e.criteria.from ? parseFloat(e.criteria.from) : null,
              to: e.criteria.to ? parseFloat(e.criteria.to) : null
            });
          }
          if (e.type === "importExport_hs") {
            importExportHsValue.push({
              type: e.dataPoint,
              value: e.hs.shortCode
            });
          }
        }
      });
      importExportTurnover = {
        importExportYearValue,
        importExportHsValue
      };

      var body = {
        pageIndex: page,
        pageSize: pageSize,
        areas,
        vsics,
        financials,
        companyTypes,
        legals,
        owners,
        importExportTurnover
      };
      this.props.advanceSearchCompanyByArea(body);
      this.setState({
        dataSearch: body
      });
    } else {
      this.actAlert(messages[`advanceSearch.no-searching-criteria`]);
    }
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => this.searchTerms(activePage));
  };

  handleDownLoad(dataSearch) {
    const { messages } = this.props.intl;
    this.setState({
      isDownloading: true
    });
    const body = {
      areas: dataSearch.areas,
      financials: dataSearch.financials,
      vsics: dataSearch.vsics,
      companyTypes: dataSearch.companyTypes,
      legals: dataSearch.legals,
      owners: dataSearch.owners,
      importExportTurnover: dataSearch.importExportTurnover
    };
    callApiServer
      .post("Company/CompanyReportAdvancedSearch/export", body)
      .then((res) => {
        if (res && res.data.data && res.data.statusCode === 200) {
          this.processDownloadData(res.data.data);
        } else if (res && res.data.statusCode === 429) {
          this.actAlert(messages[`newWord.overTurnDownload`]);
          this.setState({
            isDownloading: false
          });
        } else if (res && res.data.statusCode === 403) {
          this.actAlert(messages[`newWord.notPermision`]);
          this.setState({
            isDownloading: false
          });
        } else {
          this.actAlert(messages[`newWord.somthingWrong`]);
          this.setState({
            isDownloading: false
          });
        }
      })
      .catch((err) => {
        this.actAlert(messages[`newWord.somthingWrong`]);
        this.setState({
          isDownloading: false
        });
      })
      .finally();
  }

  processDownloadData(urlFile) {
    const dateNow = Date.now();
    const dateNowFomated = moment(dateNow).format("YYMMDD");
    const fileName = `Result_${dateNowFomated}.xlsx`;
    const urlDownload = `/Company/SearchCompany/download-file-export?pathFile=${urlFile}`;
    callApiServer
      .get(urlDownload, {
        responseType: "blob"
      })
      .then((res) => {
        if (res && res.data) {
          importedSaveAs(res.data, fileName);
        }

        this.setState({
          isDownloading: false
        });
      });
  }

  render() {
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    const { listProvince } = this.props.commonReducer;
    const provinces = listProvince.map((props) => {
      return {
        key: props.provinceCode,
        value: props.id,
        text: language === "en" && props.enName ? props.enName : props.name,
        provinceidrequest: props.provinceId ? props.provinceId : ""
      };
    });

    provinces.unshift({
      key: "",
      text: language === "en" ? "Province/City" : "Tỉnh/Thành phố",
      value: "",
      provinceidrequest: ""
    });
    const { selectedArea, districts, communes, activePage, isSearch, dataSearch, isDownloading, listYear } = this.state;
    const { totalItems, totalPage, loading, companyData, dataPointList, selectedAdvanceSearchingCriteria, advanceSearchLoading } = this.props.companyReducer;
    return (
      <React.Fragment>
        {this.state.alert}
        <div className="tab_area">
          <div className="row equal-height">
            <div className="col-lg-7 col-xl-8 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.selectSearchTerms" />
                </div>
                <div className="mb-4">
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.area.dataPoint" />
                  </div>
                  <div className="row">
                    {listYear &&
                      listYear.length > 0 &&
                      listYear.map((prop, key) => {
                        return (
                          <div className={`input-list-Checkbox col-md-3 col-lg-4 col-xl-3`} key={key}>
                            <Checkbox
                              label={language === "en" && prop.enName ? prop.enName : prop.name}
                              checked={this.state.selectedDataPoint.includes(prop.value)}
                              onChange={() => this.handleChangeDataPoint(prop.value)}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div>
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.area.area" />
                  </div>
                  {selectedArea &&
                    selectedArea.length > 0 &&
                    selectedArea.map((prop, key) => {
                      return (
                        <div className="area mb-3" key={key}>
                          <div className="row selected-custom-select criteria-section">
                            <div className="col-md-4 pr-0 mb-2 mb-md-0">
                              <Menu compact className="w-100">
                                <Dropdown
                                  placeholder={language === "en" ? "Province/City" : "Tỉnh/Thành phố"}
                                  search
                                  fluid
                                  selection
                                  options={provinces}
                                  value={prop.provinceId}
                                  noResultsMessage={messages["search.notfound.text"]}
                                  onChange={(e, { value, provinceidrequest }) => this.handleChangeProvince(value, key, provinces, prop, provinceidrequest)}
                                ></Dropdown>
                              </Menu>
                            </div>
                            <div className="col-md-4 pr-0 mb-2 mb-md-0">
                              <Menu compact className="w-100">
                                <Dropdown
                                  placeholder={language === "en" ? "District" : "Quận/Huyện"}
                                  search
                                  fluid
                                  selection
                                  options={districts[key] ? districts[key] : []}
                                  value={prop.districtId}
                                  noResultsMessage={messages["search.notfound.text"]}
                                  onChange={(e, { value }) => this.handleChangeDistrict(value, key, districts[key])}
                                />
                              </Menu>
                            </div>
                            <div className="col-md-4 pr-0">
                              <Menu compact className="w-100">
                                <Dropdown
                                  placeholder={language === "en" ? "Ward/Commune" : "Phường/Xã"}
                                  search
                                  fluid
                                  selection
                                  options={communes[key] ? communes[key] : []}
                                  value={prop.communeId}
                                  noResultsMessage={messages["search.notfound.text"]}
                                  onChange={(e, { value }) => this.handleChangeCommnue(value, key, communes[key])}
                                />
                              </Menu>
                            </div>
                          </div>
                          {selectedArea.length > 1 && (
                            <span className="material-icons check" onClick={() => this.removeArea(key)}>
                              cancel
                            </span>
                          )}
                        </div>
                      );
                    })}
                  <div className="add-area" onClick={() => this.addArea()}>
                    <span className="material-icons check mr-1">add_circle</span>
                    <span>
                      <IntlMessages id="advanceSearch.area.add" />
                    </span>
                  </div>
                </div>
                <div className="mt-5 d-flex justify-content-center">
                  <Button className={`common-button`} onClick={() => this.addTerms()}>
                    <IntlMessages id="advanceSearch.addTerms" />
                    {/* <em className="material-icons">add</em> */}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.searchTerms" />
                </div>
                <div>
                  {selectedAdvanceSearchingCriteria.length > 0 && (
                    <div className="table-custom ">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <IntlMessages id="table.sttTable" />
                            </th>
                            <th>
                              <IntlMessages id="advanceSearch.term" />
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAdvanceSearchingCriteria.map((prop, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                {prop.type === "area" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.area.area" /> ({prop.dataPoint}):
                                    <span>{` ${prop.area.communeName ? prop.area.communeName + ", " : ""}${
                                      prop.area.districtName ? prop.area.districtName + ", " : ""
                                    }${prop.area.provinceName}`}</span>
                                  </td>
                                )}
                                {prop.type === "industry" && (
                                  <td>
                                    <IntlMessages id="sidebar.Industry" />{" "}
                                    {language === "en" && prop.industry.enName ? prop.industry.enName : prop.industry.name} ({prop.dataPoint}):{" "}
                                    {prop.industry.code}
                                  </td>
                                )}
                                {prop.type === "companyType" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.companyType.companyType" /> :
                                    {language === "en" && prop.typeCompany.enName ? prop.typeCompany.enName : prop.typeCompany.name} ({prop.dataPoint}):{" "}
                                  </td>
                                )}
                                {prop.type === "legal" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.legalRepresentative" /> :{language === "en" && prop.enName ? prop.enName : prop.name}{" "}
                                    <br></br> ID: {prop.idPerson}{" "}
                                  </td>
                                )}
                                {prop.type === "owner" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.owner" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID:{" "}
                                    {prop.showId}{" "}
                                  </td>
                                )}
                                {prop.type === "finance" && (
                                  <td>
                                    {prop.criteria.criteriaText} ({prop.dataPoint}):
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()}  ${formatMoney(
                                            prop.criteria.from.replace(/,/g, "."),
                                            2
                                          )} (${prop.criteria.criteriaUnit}) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${
                                            prop.criteria.criteriaUnit
                                          })`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_year" && (
                                  <td>
                                    {prop.dataPoint === "import" ? (
                                      <IntlMessages id="advanceSearch.importExport.import" />
                                    ) : (
                                      <IntlMessages id="advanceSearch.importExport.export" />
                                    )}
                                    <span> ({prop.criteria.year}):</span>
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()} ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${
                                            prop.criteria.unit
                                          }) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${
                                            prop.criteria.unit
                                          })`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_hs" && (
                                  <td>
                                    {prop.dataPoint === "import" ? (
                                      <IntlMessages id="advanceSearch.importExport.import" />
                                    ) : (
                                      <IntlMessages id="advanceSearch.importExport.export" />
                                    )}
                                    <span>{`: ${prop.hs.shortCode} - ${language === "vi" ? prop.hs.name : prop.hs.enName}`}</span>
                                  </td>
                                )}
                                <td>
                                  <span className="material-icons check" onClick={() => this.removeTerm(index)}>
                                    cancel
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  <div className="mt-5 d-flex justify-content-center">
                    <Button className={`common-button`} onClick={() => this.searchTerms(1)} disabled={loading} isLoading={loading}>
                      <IntlMessages id="filter.buttonSearch" />
                      <em className="material-icons">search</em>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {advanceSearchLoading === true && <LoaderTable isLoaded={!loading} styleCustom={{ height: "500px" }} />}
            {advanceSearchLoading === false && (
              <div>
                {companyData && companyData.length > 0 ? (
                  <div className="card-item mb-4 mt-0 company-info-content">
                    <div className="company-info-content-main w-100">
                      <div className="search-result-heading pb-3">
                        <p className="search-result-title">
                          {`${messages["newWord.amountCompanies"]} (${formatMoney(totalItems)} ${messages["newWord.companies"]})`}
                        </p>
                        {isDownloading ? (
                          <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                        ) : (
                          <div
                            className="btn-export-data text-cyan"
                            onClick={() => {
                              this.handleDownLoad(dataSearch);
                            }}
                          >
                            <em className="material-icons">download</em>
                            <IntlMessages id="newWord.downloadSearchResult" />
                          </div>
                        )}
                      </div>
                      <div className="search-result-list">
                        <div className="row">
                          {companyData.map((props, index) => {
                            return (
                              <CompanyItem
                                {...this.props}
                                key={index}
                                name={props.companyName}
                                nameEnglish={props.companyEnName}
                                id={props.id}
                                taxNumber={props.companyTax}
                                vsic={props.vsic}
                                address={props.companyAddress}
                                addressEnglish={props.companyEnAddress}
                                phoneNumber={props.companyPhone}
                                fax={props.companyFax}
                                webSite={props.companyWebsite}
                                vsicID={props.vsic}
                                isShowValueTooltip={false}
                                enNetProfitLabel={props.enNetProfitLabel}
                                enNetRevenueLabel={props.enNetRevenueLabel}
                                netProfitLabel={props.netProfitLabel}
                                netRevenueLabel={props.netRevenueLabel}
                                netProfitValue={props.netProfitValue}
                                netRevenueValue={props.netRevenueValue}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="foot-pagination">
                        <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-item">
                    <Findnotfound goHome={"/trang-chu-cr"} isSearch={true} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    commonReducer: state.CommonReducer,
    companyReducer: state.companyReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    requestGetListProvince: () => dispatch(getRequestProvince()),
    requestGetListDistrict: (id) => dispatch(getRequestDistrict(id)),
    requestGetListCommune: (id) => dispatch(getRequestCommune(id)),
    advanceSearchCompanyByArea: (body) => {
      dispatch(actAdvanceSearchCompanyByArea(body));
    },
    getDataPointList: () => {
      dispatch(actGetDataPointList());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabArea));
