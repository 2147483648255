import React, { Component } from "react";
import { Table } from "reactstrap";
import TableOwerLaw from "../../../../components/CompanyInfo/Table/TableOwer/TableOwnerLaw";

import PieChartOwner from "../../../CompanyInfo/Chart/PieChartOwner";
import ModalStructure from "../../../Modals/Owner/ModalStructure";
import ModalLaw from "../../../Modals/Owner/ModalLaw";
import { connect } from "react-redux";
import { actGetOwnerStructorDetail, actGetOwnerLawDetail } from "../../../../redux/actions";
// import Findnotfound from "../../../../components/Findnotfound";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../../utils/IntlMessages";
import { withRouter } from "react-router-dom";
import { _Standard_, _Premium_ } from "../../../../constants/rolesModel";
import NoData from "../../../NoData";
export class TableStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleStructure: false,
      nameOwner: "",
      created_at: "",
      toggleLaw: false,
      type: null
    };
  }
  handlePopupStructure = (id, name, type, isVN, created_at) => {
    // this.handlePopupStructure(props.id, props.nameOwner, props.type, props.isVN, props.created_at)
    if (id !== null && id !== undefined && type !== 3) {
      if (type === 2) {
        type = "";
      }
      this.props.getOwnerStructorDetail({ id, type, isVN });
      this.setState((state) => ({
        toggleStructure: !state.toggleStructure,
        nameOwner: name,
        created_at: created_at,
        type: type
      }));
    }
  };
  handleCloseStructure = (prams) => {
    this.setState({
      toggleStructure: prams
    });
  };

  handlePopupLaw = (e, id) => {
    const { type } = this.state;

    e.preventDefault();
    if (id !== null && id !== undefined) {
      this.props.getOwnerLawDetail(id);
    }
    if (type === 0) {
      this.setState((state) => ({
        toggleLaw: !state.toggleLaw,
        toggleStructure: false
      }));
    } else {
      window.location.href = "/chi-tiet-doanh-nghiep/" + id;
    }
  };
  handleCloseLaw = (prams) => {
    this.setState({
      toggleLaw: prams
    });
  };
  render() {
    const { toggleStructure, nameOwner, created_at, toggleLaw, type } = this.state;
    const { structorsData, roles, dataChartStructure, laws } = this.props;
    const { structorDetail, loadingStructor } = this.props.structorDetailData;
    const { lawDetail, loadingDetailLaw } = this.props.lawDetailData;
    const { messages } = this.props.intl;

    return (
      <div className="">
        {toggleStructure && (
          <ModalStructure
            nameOwner={nameOwner}
            handlePopupLaw={this.handlePopupLaw}
            type={type}
            roles={roles}
            created_at={created_at}
            loadingStructor={loadingStructor}
            structorDetailData={structorDetail}
            toggleStructure={toggleStructure}
            handleCloseStructure={this.handleCloseStructure}
          />
        )}

        <div className="row mb-4">
          <div className="col-12">
            {toggleLaw && (
              <ModalLaw
                lawDetailData={lawDetail}
                title={messages[`newWord.listOwnerpercentage`]}
                toggleLaw={toggleLaw}
                loadingDetailLaw={loadingDetailLaw}
                handleCloseLaw={this.handleCloseLaw}
              />
            )}
            {laws && laws.length > 0 ? <TableOwerLaw lawsData={laws} roles={roles} /> : ""}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className="card-title card-title-line">
              <IntlMessages id="ownership.Ownershipstructure" />
            </div>
            {structorsData && structorsData.length > 0 ? (
              <div className="table-custom">
                <Table responsive>
                  <thead>
                    <tr className="owner-structure-title">
                      <th style={{ width: "70px" }}>
                        <IntlMessages id="ownership.stt" />
                      </th>
                      <th>
                        <IntlMessages id="ownership.Ownershiptype" />
                      </th>
                      <th>
                        <IntlMessages id="ownership.Ownershippercentagex" />
                      </th>
                      <th>
                        <IntlMessages id="ownership.Updateddate" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {structorsData.map((props, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {props.type !== 3 ? (
                              <a
                                href="#"
                                className="text-cyan"
                                onClick={
                                  roles && (roles === _Standard_ || roles === _Premium_)
                                    ? () => this.handlePopupStructure(props.id, props.nameOwner, props.type, props.isVN, props.created_at)
                                    : () => {
                                        return false;
                                      }
                                }
                              >
                                {props.nameOwner}
                              </a>
                            ) : (
                              props.nameOwner
                            )}
                          </td>
                          <td>{props.percent ? props.percent : 0}%</td>
                          <td>{props.created_at ? props.created_at : ""}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoData />
            )}
          </div>

          <div className="col-xl-6">
            {structorsData && structorsData.length > 0 ? (
              <div className="chart-law">
                <div className="cover-pie-chart">
                  <PieChartOwner dataChartStructure={dataChartStructure} />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    structorDetailData: state.companyReducer.owner,
    lawDetailData: state.companyReducer.owner
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOwnerStructorDetail: (model) => {
      dispatch(actGetOwnerStructorDetail(model));
    },
    getOwnerLawDetail: (id) => {
      dispatch(actGetOwnerLawDetail(id));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(TableStructure)));
