import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as macroService from "../../services/macro.service";
import * as Types from "../actions";

// GET-sub_nav
function* actSubNavMacro(actions) {
  var { cbSuccess } = actions.payload;
  try {
    var response = yield call(macroService.actGetListNavMacro);
    if (response.data.data) {
      yield put({
        type: Types.GET_SUB_NAV_MARCRO_SUCCESS,
        data: response.data.data
      });
      if (response.data.data.length > 0 && response.data.data[0] && response.data.data[0].id && response.data.data[0].name) {
        cbSuccess(response.data.data[0].id, response.data.data[0].name)
      }
    }
  } catch (err) {
    yield put({
      type: Types.GET_SUB_NAV_MARCRO_FAIL
    });
  }
}

function* watchRequestSubNavMacro() {
  yield takeEvery(Types.GET_SUB_NAV_MARCRO, actSubNavMacro);
}

// GET-listCheckbox
function* actListCheckboxMacro(action) {
  var { id } = action.payload;
  try {
    var response = yield call(macroService.actGetListCheckboxMacro, id);
    if (response.data.data) {
      yield put({
        type: Types.GET_LIST_CHECKBOX_MARCRO_SUCCESS,
        data: response.data.data
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_LIST_CHECKBOX_MARCRO_FAIL
    });
  }
}

function* watchRequestListCheckboxMacro() {
  yield takeEvery(Types.GET_LIST_CHECKBOX_MARCRO, actListCheckboxMacro);
}
// GET-dataChart
function* actListDataChartMacro(action) {
  var { cateId, macroId, activeTab } = action.payload;
  var body = {
    cateId,
    macroId
  }

  try {
    var response = yield call(macroService.actGetDataChartMacro, body);
    if (response.data.data) {
      yield put({
        type: Types.GET_DATA_CHART_MACRO_SUCCESS,
        activeTab: activeTab,
        data: response.data.data
      });
    }
  } catch (err) {
    yield put({
      type: Types.GET_DATA_CHART_MACRO_FAIL
    });
  }
}

function* watchRequestGetListDataChartMacro() {
  yield takeEvery(Types.GET_DATA_CHART_MACRO, actListDataChartMacro);
}
export default function* rootSaga() {
  yield all([
    fork(watchRequestSubNavMacro),
    fork(watchRequestListCheckboxMacro),
    fork(watchRequestGetListDataChartMacro)
  ]);
}
