import React, { Component } from "react";
import { connect } from "react-redux";
import getUrlParam from "../../../utils/getUrlParam";
import { injectIntl } from "react-intl";
import { actSearchCompanyInfo } from "../../../redux/actions";
import LoaderTable from "../../../components/LoaderTable";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../utils/IntlMessages";
import Swiper from "swiper";
import { formatMoney } from "../../../utils/formatMoney";
import { actAddToCart } from "../../../redux/actions";
import { handleFlyBuyCart } from "../../../assets/js/handleflybuycart.js";

class TabRelatedReport extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      handleFlyBuyCart();

      this.swiper();
    }, 500);
  }

  swiper = () => {
    new Swiper(".swiper-container", {
      grabCursor: true,
      autoplay: {
        delay: 3000
      },
      rewind: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      slidesPerView: 1.25,
      spaceBetween: 25,
      breakpoints: {
        320: {
          slidesPerView: 1.25,
          centeredSlides: true
        },
        992: {
          slidesPerView: 2
        }
      }
    });
  };

  // Add item to cart
  handleBuyIndustry = (data) => {
    let model = {
      id: data.reportId,
      name: data.reportName,
      englishName: data.reportEName,
      requestData: data.reportName,
      price: data.price,
      currency: "VND",
      viracServiceId: 2, // báo cáo ngành
      type: 2,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  denyClick = () => {};

  handleClickBtnBuy(event, data) {
    event.preventDefault();
    if (data) {
      this.handleBuyIndustry(data);
    } else {
      this.denyClick();
    }
  }

  buttonCart = (data) => {
    const stateBtn = this.checkDisabledBtnCart(data);
    return (
      <React.Fragment>
        <button className={"ui basic button add-to-cart p-0 m-0 "} disabled={stateBtn} onClick={(event) => this.handleClickBtnBuy(event, data)}>
          <span className={"cart-icon material-icons text-cyan"}>near_me</span>
        </button>
      </React.Fragment>
    );
  };

  checkDisabledBtnCart = (data) => {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const userId = this.props.accountReducer ? this.props.accountReducer.userAccount.id : "";
    const language = localStorage.getItem("currentLanguage");

    if (!carts) return false;

    if (carts.length > 0) {
      // const cartOfCurrentUser = carts.filter((item) => item.userID === userId);
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;

      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === data.reportId && item.lang === language).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    const language = localStorage.getItem("currentLanguage");
    const { id, vsicRelatedCompany, vsicRelatedReport, loadingRelatedReport } = this.props;

    // Tạo slide
    if (vsicRelatedReport && vsicRelatedReport.length > 0) {
      this.swiper();
    }

    const isLogged = localStorage.getItem("access_token") ? true : false;

    return (
      <React.Fragment>
        <LoaderTable isLoaded={!loadingRelatedReport} styleCustom={{ height: "300px" }} />
        <div className="company_info_views related-report" hidden={loadingRelatedReport} ref={(el) => (this.componentRef = el)}>
          <div className="row equal-height">
            {isLogged === true && (
              <div className="col-xl-6 pb-4">
                <div className="card-item info_view_h_">
                  <div className="card-title card-title-line">
                    <IntlMessages id="ownership.Affiliate" />
                  </div>
                  {vsicRelatedCompany && vsicRelatedCompany.length > 0 ? (
                    <div>
                      {vsicRelatedCompany.map((d, key) => {
                        return (
                          <div className="report-name mb-3" key={key}>
                            <Link className="text-cyan" to={`/chi-tiet-doanh-nghiep/${d.companyId}`}>
                              {language === "en" && d.companyEName ? d.companyEName : d.companyName}
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="">
                      <IntlMessages id="notfound.text" />
                    </p>
                  )}
                </div>
              </div>
            )}

            <div className="col-xl-6 pb-4">
              <div className="card-item info_view_h_">
                <div className="card-title card-title-line">
                  <IntlMessages id="report.industry" />
                </div>
                {vsicRelatedReport && vsicRelatedReport.length > 0 && (
                  <div className="">
                    <div className="swiper-container">
                      <div className="swiper-wrapper">
                        {vsicRelatedReport.map((props, key) => {
                          return (
                            <div className="swiper-slide" key={key}>
                              <Link to={`/chi-tiet-bao-cao-nganh/${props.reportId ? props.reportId : ""}`}>
                                <img className="report-image" src={props.thumbnailUrl} alt={props.reportName} />
                              </Link>
                              <div className="report-name mt-4 mb-3" style={{ height: "40px" }}>
                                <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${props.reportId ? props.reportId : ""}`}>
                                  {language === "en" && props.reportEName ? <span>{props.reportEName}</span> : <span>{props.reportName}</span>}
                                </Link>
                              </div>

                              {/* Tạm ẩn description */}
                              {/* <div className="report-des">
                              {language === "en" && props.enSummary ? <span>{props.enSummary}</span> : <span>{props.summary}</span>}
                            </div> */}

                              {language === "en" && !props.enIsPurchased && (
                                <div className="report-price-container">
                                  {this.buttonCart(props)}
                                  <span className="report-price" style={{ color: "#212529" }}>
                                    {formatMoney(parseInt(props.enPrice, 10))} VND
                                  </span>
                                </div>
                              )}
                              {language === "vi" && !props.isPurchased && (
                                <div className="report-price-container">
                                  {this.buttonCart(props)}
                                  <span className="report-price" style={{ color: "#212529" }}>
                                    {formatMoney(parseInt(props.price, 10))} VND
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="swiper-navigation">
                      <div className="swiper-btn btn-prev swiper-button-prev">
                        <span className="material-icons">chevron_left</span>
                      </div>
                      <div className="swiper-btn btn-next swiper-button-next">
                        <span className="material-icons">chevron_right</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyReducer: state.companyReducer,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // fake
    actSearchCompany: (body) => {
      dispatch(actSearchCompanyInfo(body));
    },
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabRelatedReport));
