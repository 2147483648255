/* Authentication*/
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const REFRESH_CHART = "REFRESH_CHART";
export const REFRESH_CHART_RESET = "REFRESH_CHART_RESET";

/*COMMON*/
export const GET_DATA_PROVINCE = "GET_DATA_PROVINCE";
export const GET_DATA_PROVINCE_SUCCESS = "GET_DATA_PROVINCE_SUCCESS";
export const GET_DATA_PROVINCE_FAIL = "GET_DATA_PROVINCE_FAIL";

export const GET_DATA_DISTRICT = "GET_DATA_DISTRICT";
export const GET_DATA_DISTRICT_SUCCESS = "GET_DATA_DISTRICT_SUCCESS";
export const GET_DATA_DISTRICT_FAIL = "GET_DATA_DISTRICT_FAIL";

export const GET_DATA_COMMUNE = "GET_DATA_COMMUNE";
export const GET_DATA_COMMUNE_SUCCESS = "GET_DATA_COMMUNE_SUCCESS";
export const GET_DATA_COMMUNE_FAIL = "GET_DATA_COMMUNE_FAIL";

/* FETCH */
export const RESET_FETCH_RESOURCE = "RESET_FETCH_RESOURCE";
export const NOTIFY = "NOTIFY";

export const HANDLE_FORGOT_PASSWORD = "HANDLE_FORGOT_PASSWORD";
export const HANDLE_FORGOT_PASSWORD_SUCCESS = "HANDLE_FORGOT_PASSWORD_SUCCESS";
export const HANDLE_FORGOT_PASSWORD_FAIL = "HANDLE_FORGOT_PASSWORD_FAIL";

export const HANDLE_CONFIRM_TOKEN = "HANDLE_CONFIRM_TOKEN";
export const HANDLE_CONFIRM_TOKEN_SUCCESS = "HANDLE_CONFIRM_TOKEN_SUCCESS";
export const HANDLE_CONFIRM_TOKEN_FAIL = "HANDLE_CONFIRM_TOKEN_FAIL";

export const HANDLE_CHANGE_PASSWORD = "HANDLE_CHANGE_PASSWORD";
export const HANDLE_CHANGE_PASSWORD_SUCCESS = "HANDLE_CHANGE_PASSWORD_SUCCESS";
export const HANDLE_CHANGE_PASSWORD_FAIL = "HANDLE_CHANGE_PASSWORD_FAIL";

export const GET_LANG_REQUESTED = "GET_LANG_REQUESTED";
export const GET_LANG_REQUESTED_SUCCESS = "GET_LANG_REQUESTED_SUCCESS";
export const GET_LANG_REQUESTED_FAIL = "GET_LANG_REQUESTED_FAIL";

/*COMPANY INFO*/
export const GET_LIST_COMPANYINFO = "GET_LIST_COMPANYINFO";
export const GET_LIST_COMPANYINFO_SUCCESS = "GET_LIST_COMPANYINFO_SUCCESS";
export const GET_LIST_COMPANYINFO_FAIL = "GET_LIST_COMPANYINFO_FAIL";

export const GET_LIST_COMPANY_AUTOCOMPLETE = "GET_LIST_COMPANY_AUTOCOMPLETE";
export const GET_LIST_COMPANY_AUTOCOMPLETE_SUCCESS = "GET_LIST_COMPANY_AUTOCOMPLETE_SUCCESS";
export const GET_LIST_COMPANY_AUTOCOMPLETE_FAIL = "GET_LIST_COMPANY_AUTOCOMPLETE_FAIL";

export const GET_TOP_REVENUA = "GET_TOP_REVENUA";
export const GET_TOP_REVENUA_SUCCESS = "GET_TOP_REVENUA_SUCCESS";
export const GET_TOP_REVENUA_FAIL = "GET_TOP_REVENUA_FAIL";

export const GET_REPORT_YEAR = "GET_REPORT_YEAR";
export const GET_REPORT_YEAR_SUCCESS = "GET_REPORT_YEAR_SUCCESS";
export const GET_REPORT_YEAR_FAIL = "GET_REPORT_YEAR_FAIL";

export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT";

export const GET_GROWTHRATE_CHART = "GET_GROWTHRATE_CHART";
export const GET_GROWTHRATE_CHART_SUCCESS = "GET_GROWTHRATE_CHART_SUCCESS";
export const GET_GROWTHRATE_CHART_FAIL = "GET_GROWTHRATE_CHART_FAIL";

export const CODE_CHECKBOX_GROWTHRATE = "CODE_CHECKBOX_GROWTHRATE";
export const CODE_CHECKBOX_GROWTHRATE_SUCCESS = "CODE_CHECKBOX_GROWTHRATE_SUCCESS";
export const CODE_CHECKBOX_GROWTHRATE_FAIL = "CODE_CHECKBOX_GROWTHRATE_FAIL";

export const GET_CHART_BY_TYPE = "GET_CHART_BY_TYPE";
export const GET_CHART_BY_TYPE_SUCCESS = "GET_CHART_BY_TYPE_SUCCESS";
export const GET_CHART_BY_TYPE_FAIL = "GET_CHART_BY_TYPE_FAIL";
export const GET_CHART_BY_TYPE_RESET = "GET_CHART_BY_TYPE_RESET";

export const GET_REPORT_DOWNLOAD_FAIL = "GET_REPORT_DOWNLOAD_FAIL";
export const GET_REPORT_DOWNLOAD = "GET_REPORT_DOWNLOAD";
export const GET_REPORT_DOWNLOAD_SUCCESS = "GET_REPORT_DOWNLOAD_SUCCESS";
/*ACCOUNT MANAGEMENT*/

// USER ACCOUNT - Thông tin tài khoản
export const GET_USER_ACCOUNT = "GET_USER_ACCOUNT";
export const GET_USER_ACCOUNT_SUCCESS = "GET_USER_ACCOUNT_SUCCESS";
export const GET_USER_ACCOUNT_FAIL = "GET_USER_ACCOUNT_FAIL";

/*DATA REQUESTED - CART - Yêu cầu dữ liệu */

export const UPDATE_NOTE_ITEM = "UPDATE_NOTE_ITEM";
export const GET_DATA_FROM_LOCALSTORAGE = "GET_DATA_FROM_LOCALSTORAGE";
export const UPDATE_NOTE_DATA_REQUESTED = "UPDATE_NOTE_DATA_REQUESTED";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAIL = "GET_ORDER_FAIL";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAIL = "PAYMENT_FAIL";
export const SEND_DATA_REQUEST = "SEND_DATA_REQUEST";
export const SEND_DATA_REQUEST_FAIL = "SEND_DATA_REQUEST_FAIL";

export const SEND_DATA_REQUEST_MESSAGE = "SEND_DATA_REQUEST_MESSAGE";

export const COMPANY_DNK_SELECTED = "COMPANY_DNK_SELECTED";

// REQUESTED HISTORY - Lịch sử yeu cau

export const GET_HISTORY_REQUESTED = "GET_HISTORY_REQUESTED";
export const GET_HISTORY_REQUESTED_SUCCESS = "GET_HISTORY_REQUESTED_SUCCESS";
export const GET_HISTORY_REQUESTED_FAIL = "GET_HISTORY_REQUESTED_FAIL";

export const GET_HISTORY_REQUESTED_SEARCH = "GET_HISTORY_REQUESTED_SEARCH";
export const GET_HISTORY_REQUESTED_SEARCH_SUCCESS = "GET_HISTORY_REQUESTED_SEARCH_SUCCESS";
export const GET_HISTORY_REQUESTED_SEARCH_FAIL = "GET_HISTORY_REQUESTED_SEARCH_FAIL";

export const GET_HISTORY_ORDER_ATTACHMENT = "GET_HISTORY_ORDER_ATTACHMENT";
export const GET_HISTORY_ORDER_ATTACHMENT_SUCCESS = "GET_HISTORY_ORDER_ATTACHMENT_SUCCESS";
export const GET_HISTORY_ORDER_ATTACHMENT_FAIL = "GET_HISTORY_ORDER_ATTACHMENT_FAIL";

export const GET_HISTORY_ORDER_DOWNLOAD = "GET_HISTORY_ORDER_DOWNLOAD";
export const GET_HISTORY_ORDER_DOWNLOAD_SUCCESS = "GET_HISTORY_ORDER_DOWNLOAD_SUCCESS";
export const GET_HISTORY_ORDER_DOWNLOAD_FAIL = "GET_HISTORY_ORDER_DOWNLOAD_FAIL";
// NOTIFICATIONS - Thông báo
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";

export const CHANGE_STATUS_SEEN_MESSAGE = "CHANGE_STATUS_SEEN_MESSAGE";
// HISTORY ACCESSED - Lịch sử truy cập
export const GET_HISTORY_ACCESSED = "GET_HISTORY_ACCESSED";
export const GET_HISTORY_ACCESSED_SUCCESS = "GET_HISTORY_ACCESSED_SUCCESS";
export const GET_HISTORY_ACCESSED_FAIL = "GET_HISTORY_ACCESSED_FAIL";

// CHANGE PASSWORD - Đổi mật khẩu (MODAL)

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_MESSAGE = "CHANGE_PASSWORD_MESSAGE";

// NEWS - tin tức sự kiện

export const GET_HOT_NEWS = "GET_HOT_NEWS";
export const GET_HOT_NEWS_SUCCESS = "GET_HOT_NEWS_SUCCESS";
export const GET_HOT_NEWS_FAIL = "GET_HOT_NEWS_FAIL";

export const GET_NEWS_BY_ID = "GET_NEWS_BY_ID";
export const GET_NEWS_BY_ID_SUCCESS = "GET_NEWS_BY_ID_SUCCESS";
export const GET_NEWS_BY_ID_FAIL = "GET_NEWS_BY_ID_FAIL";

export const GET_ALL = "GET_ALL";
export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL";

//  COMPANYDETAIL
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL";
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS";
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL";

//OWNER
export const GET_OWNER_LAW = "GET_OWNER_LAW";
export const GET_OWNER_LAW_SUCCESS = "GET_OWNER_LAW_SUCCESS";
export const GET_OWNER_LAW_FAIL = "GET_OWNER_LAW_FAIL";
export const GET_OWNER_LAW_DETAIL = "GET_OWNER_LAW_DETAIL";
export const GET_OWNER_LAW_DETAIL_SUCCESS = "GET_OWNER_LAW_DETAIL_SUCCESS";
export const GET_OWNER_LAW_DETAIL_FAIL = "GET_OWNER_LAW_DETAIL_FAIL";

export const GET_OWNER_STRUCTOR = "GET_OWNER_STRUCTOR";
export const GET_OWNER_STRUCTOR_SUCCESS = "GET_OWNER_STRUCTOR_SUCCESS";
export const GET_OWNER_STRUCTOR_FAIL = "GET_OWNER_STRUCTOR_FAIL";
export const GET_OWNER_STRUCTOR_DETAIL = "GET_OWNER_STRUCTOR_DETAIL";
export const GET_OWNER_STRUCTOR_DETAIL_SUCCESS = "GET_OWNER_STRUCTOR_DETAIL_SUCCESS";
export const GET_OWNER_STRUCTOR_DETAIL_FAIL = "GET_OWNER_STRUCTOR_DETAIL_FAIL";

export const GET_OWNER_COMPANYREF = "GET_OWNER_COMPANYREF";
export const GET_OWNER_COMPANYREF_SUCCESS = "GET_OWNER_COMPANYREF_SUCCESS";
export const GET_OWNER_COMPANYREF_FAIL = "GET_OWNER_COMPANYREF_FAIL";

//SO SANH TRUNG BINH NGANH
export const GET_DATA_NET_PROFIT_COLUMN_CHART = "GET_DATA_NET_PROFIT_COLUMN_CHART";
export const GET_DATA_NET_PROFIT_COLUMN_CHART_SUCCESS = "GET_DATA_NET_PROFIT_COLUMN_CHART_SUCCESS";
export const GET_MENU_DATA_TO_COMPARE = "GET_MENU_DATA_TO_COMPARE";
export const GET_MENU_DATA_TO_COMPARE_SUCCESS = "GET_MENU_DATA_TO_COMPARE_SUCCESS";
export const GET_DATA_FAIL = "GET_DATA_FAIL";
export const REQUEST_DATA_TO_COMPARE = "REQUEST_DATA_TO_COMPARE";
export const REQUEST_DATA_TO_COMPARE_SUCCESS = "REQUEST_DATA_TO_COMPARE_SUCCESS";
export const REQUEST_DATA_TO_COMPARE_FAIL = "REQUEST_DATA_TO_COMPARE_FAIL";
export const GET_DATA_TO_SHOW = "GET_DATA_TO_SHOW";
export const DELETE_CHART_COMPARE_AVERAGE = "DELETE_CHART_COMPARE_AVERAGE";

// FAQ
export const GET_LIST_FAQ = "GET_LIST_FAQ";
export const GET_LIST_FAQ_SUCCESS = "GET_LIST_FAQ_SUCCESS";
export const GET_LIST_FAQ_FAIL = "GET_LIST_FAQ_FAIL";

// heso
export const RESET_FINANCIAL = "RESET_FINANCIAL";

export const GET_FINANCIAL_INIT_SUCCESS = "GET_FINANCIAL_INIT_SUCCESS";
export const REQUEST_FINANCIAL_INIT = "REQUEST_FINANCIAL_INIT";
export const REQUEST_FINANCIAL_INIT_SUCCESS = "REQUEST_FINANCIAL_INIT_SUCCESS";
export const REQUEST_FINANCIAL = "REQUEST_FINANCIAL";
export const REQUEST_FINANCIAL_DELETE_CHART = "REQUEST_FINANCIAL_DELETE_CHART";
export const REQUEST_FINANCIAL_SUCCESS = "REQUEST_FINANCIAL_SUCCESS";
export const REQUEST_FINANCIAL_FAIL = "REQUEST_FINANCIAL_FAIL";
export const REQUEST_FINANCIAL_RESET = "REQUEST_FINANCIAL_RESET";

export const CODE_CHECKBOX_FINANCIAL = "CODE_CHECKBOX_FINANCIAL";
export const CODE_CHECKBOX_FINANCIAL_SUCCESS = "CODE_CHECKBOX_FINANCIAL_SUCCESS";
export const CODE_CHECKBOX_FINANCIAL_FAIL = "CODE_CHECKBOX_FINANCIAL_FAIL";
// chitieu
export const GET_TARGET_INIT_SUCCESS = "GET_TARGET_INIT_SUCCESS";

export const REQUEST_TARGET_INIT = "REQUEST_TARGET_INIT";
export const REQUEST_TARGET_INIT_SUCCESS = "REQUEST_TARGET_INIT_SUCCESS";
export const REQUEST_TARGET = "REQUEST_TARGET";
export const REQUEST_TARGET_SUCCESS = "REQUEST_TARGET_SUCCESS";
export const REQUEST_TARGET_FAIL = "REQUEST_TARGET_FAIL";
export const REQUEST_TARGET_DELETE = "REQUEST_TARGET_DELETE";
export const REQUEST_TARGET_RESET = "REQUEST_TARGET_RESET";

export const CODE_CHECKBOX_TARGET = "CODE_CHECKBOX_TARGET";
export const CODE_CHECKBOX_TARGET_SUCCESS = "CODE_CHECKBOX_TARGET_SUCCESS";
export const CODE_CHECKBOX_TARGET_FAIL = "CODE_CHECKBOX_TARGET_FAIL";

//so sanh - doanh nghiep khac
export const REQUEST_COMPANY_INDUSTRY = "REQUEST_COMPANY_INDUSTRY";
export const REQUEST_COMPANY_INDUSTRY_SUCCESS = "REQUEST_COMPANY_INDUSTRY_SUCCESS";
export const REQUEST_COMPANY_INDUSTRY_FAIL = "REQUEST_COMPANY_INDUSTRY_FAIL";

export const REQUEST_COMPANY_INDUSTRYBK = "REQUEST_COMPANY_INDUSTRYBK";
export const REQUEST_COMPANY_INDUSTRYBK_SUCCESS = "REQUEST_COMPANY_INDUSTRYBK_SUCCESS";
export const REQUEST_COMPANY_INDUSTRYBK_FAIL = "REQUEST_COMPANY_INDUSTRYBK_FAIL";

export const REQUEST_COMPANY_INDUSTRYDNBK = "REQUEST_COMPANY_INDUSTRYDNBK";
export const REQUEST_COMPANY_INDUSTRYDNBK_SUCCESS = "REQUEST_COMPANY_INDUSTRYDNBK_SUCCESS";
export const REQUEST_COMPANY_INDUSTRYDNBK_FAIL = "REQUEST_COMPANY_INDUSTRYDNBK_FAIL";
//ss truc tiep
export const REQUEST_LIVE_CHARTDNK = "REQUEST_LIVE_CHARTDNK";
export const REQUEST_LIVE_CHARTDNK_SUCCESS = "REQUEST_LIVE_CHARTDNK_SUCCESS";
export const REQUEST_LIVE_CHARTDNK_FAIL = "REQUEST_LIVE_CHARTDNK_FAIL";
//ss trung binh
export const REQUEST_LIVE_CHARTDNK_TB = "REQUEST_LIVE_CHARTDNK_TB";
export const REQUEST_LIVE_CHARTDNK_TB_SUCCESS = "REQUEST_LIVE_CHARTDNK_TB_SUCCESS";
export const REQUEST_LIVE_CHARTDNK_TB_FAIL = "REQUEST_LIVE_CHARTDNK_TB_FAIL";
//ss Tong gia tri
export const REQUEST_LIVE_CHARTDNK_TGT = "REQUEST_LIVE_CHARTDNK_TGT";
export const REQUEST_LIVE_CHARTDNK_TGT_SUCCESS = "REQUEST_LIVE_CHARTDNK_TGT_SUCCESS";
export const REQUEST_LIVE_CHARTDNK_TGT_FAIL = "REQUEST_LIVE_CHARTDNK_TGT_FAIL";

//ss trung binh group
export const REQUEST_LIVE_CHARTDNK_TB_GROUP = "REQUEST_LIVE_CHARTDNK_TB_GROUP";
export const REQUEST_LIVE_CHARTDNK_TB_GROUP_SUCCESS = "REQUEST_LIVE_CHARTDNK_TB_GROUP_SUCCESS";
export const REQUEST_LIVE_CHARTDNK_TB_GROUP_FAIL = "REQUEST_LIVE_CHARTDNK_TB_GROUP_FAIL";
export const REQUEST_LIVE_CHART_GROUP_RESET = "REQUEST_LIVE_CHART_GROUP_RESET";
//ss Tong gia tri group
export const REQUEST_LIVE_CHARTDNK_TGT_GROUP = "REQUEST_LIVE_CHARTDNK_TGT_GROUP";
export const REQUEST_LIVE_CHARTDNK_TGT_GROUP_SUCCESS = "REQUEST_LIVE_CHARTDNK_TGT_GROUP_SUCCESS";
export const REQUEST_LIVE_CHARTDNK_TGT_GROUP_FAIL = "REQUEST_LIVE_CHARTDNK_TGT_GROUP_FAIL";

export const REQUEST_LIVE_CHARTDNK_DELETE_GROUP = "REQUEST_LIVE_CHARTDNK_DELETE_GROUP";
export const REQUEST_LIVE_CHARTDNK_RESET_GROUP = "REQUEST_LIVE_CHARTDNK_RESET_GROUP";

export const REQUEST_LIVE_CHARTDNK_DELETE = "REQUEST_LIVE_CHARTDNK_DELETE";
export const REQUEST_LIVE_CHARTDNK_RESET = "REQUEST_LIVE_CHARTDNK_RESET";

export const SAVE_DATA_COMPANYORTHER = "SAVE_DATA_COMPANYORTHER";
export const SAVE_DATA_COMPANYDN = "SAVE_DATA_COMPANYDN";
export const UPDATE_REMEMBER_DATA_COMPANYORTHER = "UPDATE_REMEMBER_DATA_COMPANYORTHER";
//so sanh doanh nghiep
export const GET_LIST_COMPANYCOMPARE = "GET_LIST_COMPANYCOMPARE";
export const GET_LIST_COMPANYCOMPARE_SUCCESS = "GET_LIST_COMPANYCOMPARE_SUCCESS";
export const GET_LIST_COMPANYCOMPARE_FAIL = "GET_LIST_COMPANYCOMPARE_FAIL";

export const GET_LIST_COMPANYCOMPARE_RESET = "GET_LIST_COMPANYCOMPARE_RESET";
export const GET_LIST_COMPANYCOMPARE_UPDATE = "GET_LIST_COMPANYCOMPARE_UPDATE";
export const GET_LIST_COMPANYCOMPAREORTHER_UPDATE = "GET_LIST_COMPANYCOMPAREORTHER_UPDATE";

//SS Truc Tiep
export const REQUEST_LIVE_CHART_CP = "REQUEST_LIVE_CHART_CP";
export const REQUEST_LIVE_CHART_CP_SUCCESS = "REQUEST_LIVE_CHART_CP_SUCCESS";
export const REQUEST_LIVE_CHART_CP_FAIL = "REQUEST_LIVE_CHART_CP_FAIL";

//SS Trung Binh
export const REQUEST_LIVE_CHART_CP_TB = "REQUEST_LIVE_CHART_CP_TB";
export const REQUEST_LIVE_CHART_CP_TB_SUCCESS = "REQUEST_LIVE_CHART_CP_TB_SUCCESS";
export const REQUEST_LIVE_CHART_CP_TB_FAIL = "REQUEST_LIVE_CHART_CP_TB_FAIL";

//SS Tong Gia Tri
export const REQUEST_LIVE_CHART_CP_TGT = "REQUEST_LIVE_CHART_CP_TGT";
export const REQUEST_LIVE_CHART_CP_TGT_SUCCESS = "REQUEST_LIVE_CHART_CP_TGT_SUCCESS";
export const REQUEST_LIVE_CHART_CP_TGT_FAIL = "REQUEST_LIVE_CHART_CP_TGT_FAIL";

export const REQUEST_LIVE_CHART_CP_DELETE = "REQUEST_LIVE_CHART_CP_DELETE";
export const REQUEST_LIVE_CHART_CP_RESET = "REQUEST_LIVE_CHART_CP_RESET";

export const REQUEST_LIVE_CHARTLIST_CP = "REQUEST_LIVE_CHARTLIST_CP";
export const REQUEST_LIVE_CHARTLIST_CP_SUCCESS = "REQUEST_LIVE_CHARTLIST_CP_SUCCESS";
export const REQUEST_LIVE_CHARTLIST_CP_FAIL = "REQUEST_LIVE_CHARTLIST_CP_FAIL";

export const REQUEST_TOTAL_CHART_CP = "REQUEST_TOTAL_CHART_CP";
export const REQUEST_TOTAL_CHART_CP_SUCCESS = "REQUEST_TOTAL_CHART_CP_SUCCESS";
export const REQUEST_TOTAL_CHART_CP_FAIL = "REQUEST_TOTAL_CHART_CP_FAIL";

//THÔNG TIN NGÀNH

//autocomplete
export const SEARCH_AUTOCOMPLETE_INDUSTRY = "SEARCH_AUTOCOMPLETE_INDUSTRY";
export const SEARCH_AUTOCOMPLETE_INDUSTRY_SUCCESS = "SEARCH_AUTOCOMPLETE_INDUSTRY_SUCCESS";
export const SEARCH_AUTOCOMPLETE_INDUSTRY_FAIL = "SEARCH_AUTOCOMPLETE_INDUSTRY_FAIL";
//TỔNG QUAN NGÀNH
export const GET_DATA_CHART_INDUSTRY_INFORMATION = "GET_DATA_CHART_INDUSTRY_INFORMATION";
export const GET_DATA_CHART_INDUSTRY_INFORMATION_SUCCESS = "GET_DATA_CHART_INDUSTRY_INFORMATION_SUCCESS";
export const GET_INDUSTRY_INFORMATION = "GET_INDUSTRY_INFORMATION";
export const GET_INDUSTRY_INFORMATION_SUCCESS = "GET_INDUSTRY_INFORMATION_SUCCESS";
export const GET_INDUSTRY_INFORMATION_FAIL = "GET_INDUSTRY_INFORMATION_FAIL";

export const GET_TOP_VSIC = "GET_TOP_VSIC";
export const GET_TOP_VSIC_SUCCESS = "GET_TOP_VSIC_SUCCESS";
export const GET_TOP_VSIC_FAIL = "GET_TOP_VSIC_FAIL";
//SẢN XUẤT - TIÊU THỤ - TỒN KHO
export const GET_DATA_PRODUCT_TO_COMPARE = "GET_DATA_PRODUCT_TO_COMPARE";
export const GET_DATA_PRODUCT_TO_COMPARE_SUCCESS = "GET_DATA_PRODUCT_TO_COMPARE_SUCCESS";
export const SEND_DATA_TO_COMPARE = "SEND_DATA_TO_COMPARE";
export const DELETE_CHART_INDUSTRY_INFORMATION = "DELETE_CHART_INDUSTRY_INFORMATION";
export const GET_DATA_RETURN_TO_SHOW_SUCCESS = "GET_DATA_RETURN_TO_SHOW_SUCCESS";
export const GET_DATA_CHART_EXPORT_TO_SHOW_SUCCESS = "GET_DATA_CHART_EXPORT_TO_SHOW_SUCCESS";
//CHỈ SỐ KINH TẾ NGÀNH
export const GET_DATA_FINANCIAL_TO_COMPARE = "GET_DATA_FINANCIAL_TO_COMPARE";
export const GET_DATA_FINANCIAL_TO_COMPARE_SUCCESS = "GET_DATA_FINANCIAL_TO_COMPARE_SUCCESS";
export const SEND_DATA_FINANCIAL_TO_COMPARE = "SEND_DATA_FINANCIAL_TO_COMPARE";
export const SEND_DATA_FINANCIAL_TO_COMPARE_RESET = "SEND_DATA_FINANCIAL_TO_COMPARE_RESET";
export const GET_DATA_CHART_FINANCIAL_TO_COMPARE_SUCCESS = "GET_DATA_CHART_FINANCIAL_TO_COMPARE_SUCCESS";
export const DELETE_FINANCIAL_CHART = "DELETE_FINANCIAL_CHART";
//SO SÁNH NGÀNH
export const GET_DATA_COMPARE_INDUSTRY_INFORMATION = "GET_DATA_COMPARE_INDUSTRY_INFORMATION";
export const GET_DATA_COMPARE_INDUSTRY_INFORMATION_SUCCESS = "GET_DATA_COMPARE_INDUSTRY_INFORMATION_SUCCESS";
export const SEND_DATA_COMPARE = "SEND_DATA_COMPARE";
export const GET_DATA_COMPARE_CHART = "GET_DATA_COMPARE_CHART";
export const DELETE_COMPARE_CHART = "DELETE_COMPARE_CHART";

export const REQUEST_CHART_SSNGANH_TB = "REQUEST_CHART_SSNGANH_TB";
export const REQUEST_CHART_SSNGANH_TB_SUCCESS = "REQUEST_CHART_SSNGANH_TB_SUCCESS";
export const REQUEST_CHART_SSNGANH_TB_FAIL = "REQUEST_CHART_SSNGANH_TB_FAIL";

export const REQUEST_CHART_SSNGANH_TGT = "REQUEST_CHART_SSNGANH_TGT";
export const REQUEST_CHART_SSNGANH_TGT_SUCCESS = "REQUEST_CHART_SSNGANH_TGT_SUCCESS";
export const REQUEST_CHART_SSNGANH_TGT_FAIL = "REQUEST_CHART_SSNGANH_TGT_FAIL";

export const REQUEST_CHART_SSNGANH_DELETE = "REQUEST_CHART_SSNGANH_DELETE";
export const REQUEST_CHART_SSNGANH_DELETE_SUCCESS = "REQUEST_CHART_SSNGANH_DELETE_SUCCESS";
export const REQUEST_CHART_SSNGANH_DELETE_FAIL = "REQUEST_CHART_SSNGANH_DELETE_FAIL";

//macro
export const GET_SUB_NAV_MARCRO = "GET_SUB_NAV_MARCRO";
export const GET_SUB_NAV_MARCRO_SUCCESS = "GET_SUB_NAV_MARCRO_SUCCESS";
export const GET_SUB_NAV_MARCRO_FAIL = "GET_SUB_NAV_MARCRO_FAIL";

export const GET_LIST_CHECKBOX_MARCRO = "GET_LIST_CHECKBOX_MARCRO";
export const GET_LIST_CHECKBOX_MARCRO_SUCCESS = "GET_LIST_CHECKBOX_MARCRO_SUCCESS";
export const GET_LIST_CHECKBOX_MARCRO_FAIL = "GET_LIST_CHECKBOX_MARCRO_FAIL";

export const GET_LIST_DATA_CHART = "GET_LIST_DATA_CHART";
export const GET_LIST_DATA_CHART_SUCCESS = "GET_LIST_DATA_CHART_SUCCESS";
export const GET_LIST_DATA_CHART_FAIL = "GET_LIST_DATA_CHART_FAIL";

export const GET_DATA_CHART_MACRO = "GET_DATA_CHART_MACRO";
export const GET_DATA_CHART_MACRO_SUCCESS = "GET_DATA_CHART_MACRO_SUCCESS";
export const GET_DATA_CHART_MACRO_FAIL = "GET_DATA_CHART_MACRO_FAIL";

export const DELETE_DATA_CHART_MACRO = "DELETE_DATA_CHART_MACRO";
//--------------------

//Quan li chart
export const STORAGE_CHART_TARGET = "STORAGE_CHART_TARGET";
export const STORAGE_CHART_FINANCIAL = "STORAGE_CHART_FINANCIAL";
export const STORAGE_CHART_FINANCIAL_RESET = "STORAGE_CHART_FINANCIAL_RESET";
//banner
export const GET_BANNER = "GET_BANNER";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_FAIL = "GET_BANNER_FAIL";
//SAMPLE_REPORT
export const GET_SAMPLE_REPORT = "GET_SAMPLE_REPORT";
export const GET_SAMPLE_REPORT_SUCCESS = "GET_SAMPLE_REPORT_SUCCESS";
export const GET_SAMPLE_REPORT_FAIL = "GET_SAMPLE_REPORT_FAIL";

export const GET_LIST_SELECT_INDUSTRY = "GET_LIST_SELECT_INDUSTRY";
export const GET_LIST_SELECT_INDUSTRY_SUCCESS = "GET_LIST_SELECT_INDUSTRY_SUCCESS";
export const GET_LIST_SELECT_INDUSTRY_FAIL = "GET_LIST_SELECT_INDUSTRY_FAIL";

export const GET_LIST_SELECT_INDUSTRY_YEAR = "GET_LIST_SELECT_INDUSTRY_YEAR";
export const GET_LIST_SELECT_INDUSTRY_YEAR_SUCCESS = "GET_LIST_SELECT_INDUSTRY_YEAR_SUCCESS";
export const GET_LIST_SELECT_INDUSTRY_YEAR_FAIL = "GET_LIST_SELECT_INDUSTRY_YEAR_FAIL";

// LOGOUT
export const HANDLE_CHECKED_LOGOUT = "HANDLE_CHECKED_LOGOUT";
export const HANDLE_CHECKED_LOGOUT_FAIL = "HANDLE_CHECKED_LOGOUT_FAIL";
export const HANDLE_CHECKED_LOGOUT_SUCCESS = "HANDLE_CHECKED_LOGOUT_SUCCESS";
//So sánh trung bình ngành
// export const STORAGE_CHART_COMPARE_AVERAGE = "STORAGE_CHART_COMPARE_AVERAGE";
// export const DELETE_CHART_COMPARE_AVERAGE = "DELETE_CHART_COMPARE_AVERAGE";
//Thông tin ngành
// export const STORAGE_CHART_INDUSTRY_INFORMATION = "STORAGE_CHART_INDUSTRY_INFORMATION";
// export const DELETE_CHART_INDUSTRY_INFORMATION = "DELETE_CHART_INDUSTRY_INFORMATION";

//  download company report
export const DOWNLOAD_COMPANY_REPORT = "DOWNLOAD_COMPANY_REPORT";
export const DOWNLOAD_COMPANY_REPORT_SUCCESS = "DOWNLOAD_COMPANY_REPORT_SUCCESS";
export const DOWNLOAD_COMPANY_REPORT_FAIL = "DOWNLOAD_COMPANY_REPORT_FAIL";

export const GET_COMPANY_FINANCIAL_INFO = "GET_COMPANY_FINANCIAL_INFO";
export const GET_COMPANY_FINANCIAL_INFO_SUCCESS = "GET_COMPANY_FINANCIAL_INFO_SUCCESS";
export const GET_COMPANY_FINANCIAL_INFO_FAIL = "GET_COMPANY_FINANCIAL_INFO_FAIL";

// Báo cáo doanh nghiệp + báo cáo ngành trong Chi tiết doanh nghiệp
export const GET_DATA_RELATED_REPORT_IN_COMPANY = "GET_DATA_RELATED_REPORT_IN_COMPANY";
export const GET_DATA_RELATED_REPORT_IN_COMPANY_SUCCESS = "GET_DATA_RELATED_REPORT_IN_COMPANY_SUCCESS";
export const GET_DATA_RELATED_REPORT_IN_COMPANY_FAIL = "GET_DATA_RELATED_REPORT_IN_COMPANY_FAIL";

// advance search company
export const ADVANCE_SEARCH_COMPANY_BY_AREA = "ADVANCE_SEARCH_COMPANY_BY_AREA";
export const ADVANCE_SEARCH_COMPANY_BY_AREA_SUCCESS = "ADVANCE_SEARCH_COMPANY_BY_AREA_SUCCESS";
export const ADVANCE_SEARCH_COMPANY_BY_AREA_FAIL = "ADVANCE_SEARCH_COMPANY_BY_AREA_FAIL";
export const ADVANCE_SEARCH_COMPANY_BY_INDUSTRY = "ADVANCE_SEARCH_COMPANY_BY_INDUSTRY";
export const ADVANCE_SEARCH_COMPANY_BY_INDUSTRY_SUCCESS = "ADVANCE_SEARCH_COMPANY_BY_INDUSTRY_SUCCESS";
export const ADVANCE_SEARCH_COMPANY_BY_INDUSTRY_FAIL = "ADVANCE_SEARCH_COMPANY_BY_INDUSTRY_FAIL";
export const ADVANCE_SEARCH_COMPANY_BY_FINANCE = "ADVANCE_SEARCH_COMPANY_BY_FINANCE";
export const ADVANCE_SEARCH_COMPANY_BY_FINANCE_SUCCESS = "ADVANCE_SEARCH_COMPANY_BY_FINANCE_SUCCESS";
export const ADVANCE_SEARCH_COMPANY_BY_FINANCE_FAIL = "ADVANCE_SEARCH_COMPANY_BY_FINANCE_FAIL";
export const GET_FINANCIAL_CRITERIA_LIST = "GET_FINANCIAL_CRITERIA_LIST";
export const GET_FINANCIAL_CRITERIA_LIST_SUCCESS = "GET_FINANCIAL_CRITERIA_LIST_SUCCESS";
export const GET_FINANCIAL_CRITERIA_LIST_FAIL = "GET_FINANCIAL_CRITERIA_LIST_FAIL";
export const GET_DATA_POINT_LIST = "GET_DATA_POINT_LIST";
export const GET_DATA_POINT_LIST_SUCCESS = "GET_DATA_POINT_LIST_SUCCESS";
export const GET_DATA_POINT_LIST_FAIL = "GET_DATA_POINT_LIST_FAIL";

// search industry
export const SEARCH_INDUSTRY = "SEARCH_INDUSTRY";
export const SEARCH_INDUSTRY_SUCCESS = "SEARCH_INDUSTRY_SUCCESS";
export const SEARCH_INDUSTRY_FAIL = "SEARCH_INDUSTRY_FAIL";
export const SEARCH_INDUSTRY_REPORT = "SEARCH_INDUSTRY_REPORT";
export const SEARCH_INDUSTRY_REPORT_SUCCESS = "SEARCH_INDUSTRY_REPORT_SUCCESS";
export const SEARCH_INDUSTRY_REPORT_FAIL = "SEARCH_INDUSTRY_REPORT_FAIL";
export const STATISTIC_INDUSTRY = "STATISTIC_INDUSTRY";
export const STATISTIC_INDUSTRY_SUCCESS = "STATISTIC_INDUSTRY_SUCCESS";
export const STATISTIC_INDUSTRY_FAIL = "STATISTIC_INDUSTRY_FAIL";
export const ADVANCE_STATISTIC_INDUSTRY = "ADVANCE_STATISTIC_INDUSTRY";
export const ADVANCE_STATISTIC_INDUSTRY_SUCCESS = "ADVANCE_STATISTIC_INDUSTRY_SUCCESS";
export const ADVANCE_STATISTIC_INDUSTRY_FAIL = "ADVANCE_STATISTIC_INDUSTRY_FAIL";
export const STATISTIC_INDUSTRY_CHART = "STATISTIC_INDUSTRY_CHART";
export const STATISTIC_INDUSTRY_SUCCESS_CHART = "STATISTIC_INDUSTRY_SUCCESS_CHART";
export const STATISTIC_INDUSTRY_FAIL_CHART = "STATISTIC_INDUSTRY_FAIL_CHART";
export const ADVANCE_STATISTIC_INDUSTRY_CHART = "ADVANCE_STATISTIC_INDUSTRY_CHART";
export const ADVANCE_STATISTIC_INDUSTRY_SUCCESS_CHART = "ADVANCE_STATISTIC_INDUSTRY_SUCCESS_CHART";
export const ADVANCE_STATISTIC_INDUSTRY_FAIL_CHART = "ADVANCE_STATISTIC_INDUSTRY_FAIL_CHART";
export const STATISTIC_INDUSTRY_CHART_YEAR = "STATISTIC_INDUSTRY_CHART_YEAR";
export const STATISTIC_INDUSTRY_SUCCESS_CHART_YEAR = "STATISTIC_INDUSTRY_SUCCESS_CHART_YEAR";
export const STATISTIC_INDUSTRY_FAIL_CHART_YEAR = "STATISTIC_INDUSTRY_FAIL_CHART_YEAR";
export const VSIC_LIST = "VSIC_LIST";
export const VSIC_LIST_SUCCESS = "VSIC_LIST_SUCCESS";
export const VSIC_LIST_FAIL = "VSIC_LIST_FAIL";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";
export const INDUSTRY_REPORT_DETAIL = "INDUSTRY_REPORT_DETAIL";
export const INDUSTRY_REPORT_DETAIL_SUCCESS = "INDUSTRY_REPORT_DETAIL_SUCCESS";
export const INDUSTRY_REPORT_DETAIL_FAIL = "INDUSTRY_REPORT_DETAIL_FAIL";
export const INDUSTRY_RELATED_REPORT = "INDUSTRY_RELATED_REPORT";
export const INDUSTRY_RELATED_REPORT_SUCCESS = "INDUSTRY_RELATED_REPORT_SUCCESS";
export const INDUSTRY_RELATED_REPORT_FAIL = "INDUSTRY_RELATED_REPORT_FAIL";

export * from "./AccountManagement/actions";
export * from "./settings/actions";
export * from "./Cart/actions";
export * from "./Authentication/actions";
export * from "./CompanyInfo/actions";
export * from "./News/actions";
export * from "./FAQ/actions";
export * from "./ReducerDataStorage/actions";
export * from "./CompanyCompare/actions";
export * from "./Fetch/actions";
export * from "./Common/actions";
export * from "./IndustryInformation/actions";
export * from "./MacroInfomations/actions";
export * from "./Report/actions";
