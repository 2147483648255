import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Table } from "reactstrap";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import NoData from "../../NoData";
import LoaderTable from "../../LoaderTable";
class ModalLaw extends React.Component {
  handleCloseModal = () => {
    this.props.handleCloseLaw(false);
  };
  render() {
    const { toggleLaw, lawDetailData, loadingDetailLaw, title } = this.props;
    const { messages } = this.props.intl;
    const closeBtn = (
      <span onClick={this.handleCloseModal} className="material-icons close-btn">
        close
      </span>
    );

    return (
      <div>
        <Modal isOpen={toggleLaw} size="lg" centered className="modal_owner_law common-modal">
          <ModalHeader close={closeBtn}>{title ? title : messages[`newWord.titleChartOwner`]}</ModalHeader>
          <LoaderTable isLoaded={!loadingDetailLaw} styleCustom={{ height: "200px" }} />
          {!loadingDetailLaw && (
            <ModalBody style={{ display: "flex", justifyContent: "center" }}>
              {lawDetailData && lawDetailData.length > 0 ? (
                <div className="table-custom" style={{ width: "100%" }}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <IntlMessages id="ownership.stt" />
                        </th>
                        <th>
                          <IntlMessages id="table.businessName" />
                        </th>
                        <th>
                          <IntlMessages id="table.tableMST" />
                        </th>
                        <th>VSIC</th>
                        <th>
                          <IntlMessages id="newWord.ownerPercentage" />
                        </th>
                        <th>
                          <IntlMessages id="ownership.Updateddate" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lawDetailData.length > 0 &&
                        lawDetailData.map((props, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <span>{index + 1}</span>
                              </td>
                              <td>
                                <a className="text-cyan" href={props.id ? `/chi-tiet-doanh-nghiep/${props.id}` : "#a"}>
                                  {props.name ? props.name : ""}
                                </a>
                              </td>
                              <td>
                                <span>{props.mst ? props.mst : ""}</span>
                              </td>
                              <td>
                                <span>{props.vsicCode ? props.vsicCode : ""}</span>
                              </td>
                              <td>
                                <span> {props.shipRate ? props.shipRate + "%" : ""}</span>
                              </td>
                              <td>
                                <span>{props.dateUpdate ? props.dateUpdate : ""}</span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoData />
              )}
            </ModalBody>
          )}
        </Modal>
      </div>
    );
  }
}

export default injectIntl(ModalLaw);
