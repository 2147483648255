import { callApiServer } from "../utils/callApiServer";
//GET - LIST - SAMPLE - REPORT
export const requestGetSampleReport = () => {
  return callApiServer.get("/Report/ListSampleReport");
};

//GET -  INDUSTRY - YEAR
export const requestGetListIndustryYear = () => {
  return callApiServer.get("/Report/GetYear");
};

//GET - LIST - INDUSTRY
export const requestGetListIndustry = () => {
  let params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("Getmenu", { params: params });
};
