import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as reportService from "../../services/report.service";
import * as Types from "../actions";

// GET - SAMPLE - REPORT

function* watchGetSampleReport() {
    yield takeEvery(Types.GET_SAMPLE_REPORT, getSampleReport);
}

function* getSampleReport() {
    try {
        var response = yield call(reportService.requestGetSampleReport);
        if (response.data.data) {
            yield put({
              type: Types.GET_SAMPLE_REPORT_SUCCESS,
              data: response.data.data
            });
          }
    } catch (err) {
        if (response.data.data) {
            yield put({
              type: Types.GET_SAMPLE_REPORT_FAIL,
              data: response.data.data
            });
          }
    }
}
// GET - INDUSTRY - YEAR

function* watchGetIndustryYear() {
    yield takeEvery(Types.GET_LIST_SELECT_INDUSTRY_YEAR, getSelectIndustryYear);
}

function* getSelectIndustryYear() {
    try {
        var response = yield call(reportService.requestGetListIndustryYear);
        if (response.data.data) {
            yield put({
              type: Types.GET_LIST_SELECT_INDUSTRY_YEAR_SUCCESS,
              data: response.data.data
            });
          }
    } catch (err) {
        if (response.data.data) {
            yield put({
              type: Types.GET_LIST_SELECT_INDUSTRY_YEAR_FAIL,
              data: response.data.data
            });
          }
    }
}
// GET - INDUSTRY - REPORT

function* watchGetIndustryReport() {
    yield takeEvery(Types.GET_LIST_SELECT_INDUSTRY, getIndustryReport);
}

function* getIndustryReport() {
    try {
        var response = yield call(reportService.requestGetListIndustry);
        if (response.data.data) {
            yield put({
              type: Types.GET_LIST_SELECT_INDUSTRY_SUCCESS,
              data: response.data.data
            });
          }
    } catch (err) {
        if (response.data.data) {
            yield put({
              type: Types.GET_LIST_SELECT_INDUSTRY_FAIL,
              data: response.data.data
            });
          }
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetSampleReport),
        fork(watchGetIndustryYear),
        fork(watchGetIndustryReport),
    ]);
}
