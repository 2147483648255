import React, { Component } from "react";
import { connect } from "react-redux";
import getUrlParam from "../../utils/getUrlParam";
import { injectIntl } from "react-intl";
import { actAddToCart, actGetIndustryReportDetail, actGetRelatedReportAndCompany } from "../../redux/actions";
import LoaderTable from "../../components/LoaderTable";
import { Link } from "react-router-dom";
import { Nav, NavItem, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../utils/IntlMessages";
import classnames from "classnames";
import IndustryFilter from "../../components/IndustryFilter/IndustryFilter";
import TabIndex from "./Tabs/TabIndex";
import TabRelatedReport from "./Tabs/TapRelatedReport";
import TabChapter from "./Tabs/TapChapter";
import nav_head_lang_vn from "../../assets/images/ic/nav_head_lang_vn.png";
import nav_head_lang_us from "../../assets/images/ic/nav_head_lang.png";
import { formatStringToTime } from "../../utils/formatDate";
import { handleFlyBuyCart } from "../../assets/js/handleflybuycart.js";

class IndustryReportDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: getUrlParam()["tab"] ? getUrlParam()["tab"] : "index",
      id: this.props.match.params.id,
      reportLang: "vi",
      statusButtonBuy: false,
      tabs: []
    };
  }

  componentDidMount() {
    setTimeout(() => {
      handleFlyBuyCart();
    }, 500);
    const { activeTab, id } = this.state;
    // const staticTab = ["index", "related-report"];
    // const dynamictab = [];
    // if (!["index", "related-report", "chapter-I", "chapter-II", "chapter-III", "chapter-IV", "chapter-V"].includes(activeTab)) {
    //   window.location.href = "/chi-tiet-bao-cao-nganh/" + id + "?tab=index";
    // }
    this.props.actGetIndustryReportDetail(id);
    this.props.actGetRelatedReportAndCompany(id);

    // Enable or Disabled Button Buy
    const { dataRequest } = this.props.carts.dataRequired;
    this.checkItemExistInCart(dataRequest);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Kiếm tra báo cáo có bao nhiêu chương/chapter và mở tab Mục lục đó nếu tab trên url không tồn tại
    const { activeTab, id } = this.state;

    if (nextProps.industryInformationReducer) {
      if (nextProps.industryInformationReducer.industryReportDetail) {
        let listTab = [];
        let staticTab = [];
        let dynamicTab = [];
        const listChapterFromApi = nextProps.industryInformationReducer.industryReportDetail.reportContent
          ? nextProps.industryInformationReducer.industryReportDetail.reportContent
          : [];

        const listStaticTab = nextProps.industryInformationReducer.industryReportDetail.reportContentStatic
          ? nextProps.industryInformationReducer.industryReportDetail.reportContentStatic
          : [];
        listChapterFromApi.map((item) => {
          dynamicTab.push(`chapter-${item.chapterNo}`);
        });
        listStaticTab.map((item) => {
          staticTab.push(`${item.value}`);
        });
        listTab = [...dynamicTab, ...staticTab];

        this.setState({ tabs: listTab });
        if (this.state.tabs) {
          if (this.state.tabs.length) {
            if (!listTab.includes(activeTab)) {
              window.location.href = "/chi-tiet-bao-cao-nganh/" + id + "?tab=index";
            }
          }
        }
      }
    }

    // Enable or Disabled Button Buy
    const { dataRequest } = nextProps.carts.dataRequired;
    this.checkItemExistInCart(dataRequest);
  }

  // đổi ngôn ngữ báo cáo
  handleChangeReportLang = (reportLang) => {
    this.setState({ reportLang });
  };

  checkItemExistInCart = (dataInCart) => {
    const { industryReportDetail } = this.props.industryInformationReducer;
    const id = industryReportDetail.reportId;
    const isFound = dataInCart.some((item) => item.id === id && item.lang === localStorage.getItem("currentLanguage"));
    this.setState({
      statusButtonBuy: isFound
    });
    return isFound;
  };

  // thay đổi tab
  changeTab = (tab) => {
    const { messages } = this.props.intl;
    if (this.state.activeTab !== tab) {
      if (parseInt(this.state.setChecked) === -1 && [3, 4, 5].includes(tab)) {
        // nếu set = -1 thì thì không thể xem chỉ tiêu, chỉ số, so sánh DN
        this.setState({
          alert: (
            <SweetAlert
              warning
              style={{ display: "block" }}
              title={messages[`user.Notification`]}
              confirmBtnText={messages[`newWord.oke`]}
              customClass="swal-wide"
              confirmBtnCssClass="confirmBtn swal2-styled"
              onConfirm={() => {
                this._hideAlert();
              }}
            >
              <p>
                <IntlMessages id="newWord.notAvailable" />
              </p>
            </SweetAlert>
          )
        });
      } else {
        this.setState({
          activeTab: tab
        });
      }
    }
  };

  downloadReport = () => {
    const { id } = this.state;
    this.props.downloadIndustryReport(id);
  };

  handleBuyIndustry = (data) => {
    let model = {
      id: data.reportId,
      name: data.reportName,
      englishName: data.reportEName,
      requestData: data.reportName,
      price: data.price,
      currency: data.currency,
      viracServiceId: 2, // báo cáo ngành
      type: 2,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  denyClick = () => {};

  handleClickBtnBuy(event, data) {
    event.preventDefault();
    if (data) {
      this.handleBuyIndustry(data);
    } else {
      this.denyClick();
    }
  }

  buttonCart = (data) => {
    const stateBtn = this.checkDisabledBtnCart(data);
    return (
      <React.Fragment>
        <button className={"ui basic button add-to-cart p-0 m-0 "} disabled={stateBtn} onClick={(event) => this.handleClickBtnBuy(event, data)}>
          <span className={"cart-icon material-icons text-cyan"}>near_me</span>
        </button>
      </React.Fragment>
    );
  };

  checkDisabledBtnCart = (data) => {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");

    const userId = this.props.accountReducer ? this.props.accountReducer.userAccount.id : "";
    if (!carts) return false;

    if (carts.length > 0) {
      // const cartOfCurrentUser = carts.filter((item) => item.userID === userId);
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;

      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === data.reportId && item.lang === language).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  renderView() {
    const { activeTab, id, reportLang, tabs } = this.state;
    const { industryReportDetail, vsicRelatedCompany, vsicRelatedReport, loadingRelatedReport } = this.props.industryInformationReducer;
    const { hotUrl, reportContent } = industryReportDetail;

    if (activeTab === "related-report") {
      return (
        <TabRelatedReport
          vsicRelatedCompany={vsicRelatedCompany}
          vsicRelatedReport={vsicRelatedReport}
          reportLang={reportLang}
          id={id}
          loadingRelatedReport={loadingRelatedReport}
        />
      );
    } else if (activeTab.includes("chapter")) {
      return <TabChapter reportLang={reportLang} chapters={reportContent} activeTab={activeTab} id={id} />;
    } else {
      return <TabIndex reportLang={reportLang} chapter={reportContent} imageUrl={hotUrl} id={id} changeTab={this.changeTab} />;
    }

    // switch (activeTab) {
    //   case "related-report":
    //     return (
    //       <TabRelatedReport
    //         vsicRelatedCompany={vsicRelatedCompany}
    //         vsicRelatedReport={vsicRelatedReport}
    //         reportLang={reportLang}
    //         id={id}
    //         loadingRelatedReport={loadingRelatedReport}
    //       />
    //     );
    //   case "chapter-I":
    //   case "chapter-II":
    //   case "chapter-III":
    //   case "chapter-IV":
    //   case "chapter-V":
    //     return <TabChapter reportLang={reportLang} chapters={reportContent} activeTab={activeTab} id={id} />;
    //   default:
    //     return <TabIndex reportLang={reportLang} chapter={reportContent} imageUrl={hotUrl} id={id} changeTab={this.changeTab} />;
    // }
  }

  render() {
    const { id, reportLang } = this.state;
    const language = localStorage.getItem("currentLanguage");
    const { loadingIndustryReportDetail, industryReportDetail } = this.props.industryInformationReducer;
    const { reportContent } = industryReportDetail;

    return (
      <React.Fragment>
        <div className="company_info_views">
          <div className="industry-filter-results if-ir-home mb-5">
            <div className="card-item search-info-section">
              <IndustryFilter handleSearch={() => {}} {...this.props} />
            </div>
          </div>
          {<LoaderTable isLoaded={!loadingIndustryReportDetail} styleCustom={{ height: "300px" }} />}
          <div className="company_info_views " hidden={loadingIndustryReportDetail} ref={(el) => (this.componentRef = el)}>
            <div
              className="industry-report-detail if-ir-home _views_industry_detail"
              onMouseDown={() => {
                return false;
              }}
            >
              <div className="report-info">
                <div className="report-name">
                  <div>{language === "en" && industryReportDetail.reportEName ? industryReportDetail.reportEName : industryReportDetail.reportName}</div>
                  <div className="download-report">
                    {/* <a href="#" className="text-cyan printOut d-block" onClick={() => this.downloadReport()}>
                      <em className="material-icons">download</em>
                      <IntlMessages id="industry.downloadReport" />
                    </a> */}
                  </div>
                </div>
                <div className="report-des">
                  <div>{language === "en" && industryReportDetail.enSummary ? industryReportDetail.enSummary : industryReportDetail.summary}</div>
                  {language === "en" && !industryReportDetail.enIsPurchased && <div className="buy-button">{this.buttonCart(industryReportDetail)}</div>}
                  {language === "vi" && !industryReportDetail.isPurchased && <div className="buy-button">{this.buttonCart(industryReportDetail)}</div>}
                </div>
                <div className="report-time">
                  <span className="material-icons">event_available</span>
                  <IntlMessages id="detailCompany.Updated" />: {formatStringToTime(industryReportDetail.datePost)}
                </div>
              </div>
              <div className="report-content-container">
                <Nav>
                  <NavItem>
                    <Link
                      className={classnames({
                        active: this.state.activeTab === "index" || !this.state.activeTab,
                        "nav-link": true
                      })}
                      onClick={() => {
                        this.changeTab("index");
                      }}
                      to={`${id}?tab=index`}
                    >
                      <IntlMessages id="industry.report.menu" />
                    </Link>
                  </NavItem>
                  {reportContent && reportContent.length
                    ? reportContent.map((props, key) => {
                        return (
                          <NavItem key={key}>
                            <Link
                              to={`${id}?tab=chapter-${props.chapterNo}`}
                              className={classnames({
                                active: this.state.activeTab === `chapter-${props.chapterNo}`,
                                "nav-link": true
                              })}
                              onClick={() => {
                                this.changeTab(`chapter-${props.chapterNo}`);
                              }}
                            >
                              <IntlMessages id="industry.report.chapter" /> {props.chapterNo}
                            </Link>
                          </NavItem>
                        );
                      })
                    : ""}
                  <NavItem>
                    <Link
                      to={`${id}?tab=related-report`}
                      className={classnames({
                        active: this.state.activeTab === "related-report",
                        "nav-link": true
                      })}
                      onClick={() => {
                        this.changeTab("related-report");
                      }}
                    >
                      <IntlMessages id="industry.statistic.tab.relatedReport" />
                    </Link>
                  </NavItem>
                </Nav>
                <div className="report-language">
                  <div className="mr-3">
                    <IntlMessages id="newWord.selectLangReportTable" />:
                  </div>
                  <UncontrolledDropdown className="custom-dropdown-lang">
                    <DropdownToggle className="lang-btn" color="light" size="sm">
                      {reportLang === "vi" ? (
                        <img src={nav_head_lang_vn} alt="" className="img_fix_width_" />
                      ) : (
                        <img src={nav_head_lang_us} alt="" className="img_fix_width_" />
                      )}
                      <span className="material-icons">expand_more</span>
                    </DropdownToggle>
                    <DropdownMenu className="report-language-menu">
                      <DropdownItem onClick={() => this.handleChangeReportLang("en")} key={"en"}>
                        <img src={nav_head_lang_us} alt="" className="img_fix_width_" /> EN
                      </DropdownItem>
                      <DropdownItem onClick={() => this.handleChangeReportLang("vi")} key={"vi"}>
                        <img src={nav_head_lang_vn} alt="" className="img_fix_width_" /> VI
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div className="company-panel-content">{this.renderView()}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    industryInformationReducer: state.industryInformationReducer,
    carts: state.cartReducer,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actGetIndustryReportDetail: (reportId) => {
      dispatch(actGetIndustryReportDetail(reportId));
    },
    actGetRelatedReportAndCompany: (reportId) => {
      dispatch(actGetRelatedReportAndCompany(reportId));
    },
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IndustryReportDetail));
