import { callApiServer } from "../utils/callApiServer";
export const actGetListNavMacro = () => {
  var params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/MacroInfomations/GetMenu_MacroInfoCategory", { params: params });
};
export const actGetListCheckboxMacro = (id) => {
  var params = {
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/MacroInfomations/GetMenu_MacroInfoCategoryChild?id=" + id, { params: params });
};
export const actGetDataChartMacro = (body) => {
  let modelx = {
    ...body,
    lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
  };
  return callApiServer.get("/MacroInfomations/GetMacroInfos_Chart", { params: modelx });
};
