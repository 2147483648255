import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import getUrlParam from "../../utils/getUrlParam";
import TabArea from "./Tabs/TabArea";
import TabIndustry from "./Tabs/TabIndustry";
import TabCompanyType from "./Tabs/TabCompanyType";
import TabOwnership from "./Tabs/TabOwnership";
import TabFinance from "./Tabs/TabFinance";
import TabImportExport from "./Tabs/TabImportExport";

import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { actGetDataPointList } from "../../redux/actions";
import { _Basic_, _Standard_ } from "../../constants/rolesModel";
import SweetAlert from "react-bootstrap-sweetalert";

export class CompanySearchAdvance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: getUrlParam()["tab"] ? parseInt(getUrlParam()["tab"]) : 1,
      alert: ""
    };
  }

  componentDidMount() {
    document.title = "VIRACE | Company Search Advance";

    this.props.getDataPointList();
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  renderView() {
    const { activeTab } = this.state;
    var activeTabCheck = activeTab;

    switch (activeTabCheck) {
      case 2:
        return <TabIndustry history={this.props.history} {...this.props} />;
      case 3:
        return <TabCompanyType history={this.props.history} {...this.props} />;
      case 4:
        return <TabOwnership history={this.props.history} {...this.props} />;
      case 5:
        return <TabFinance history={this.props.history} {...this.props} />;
      case 6:
        return <TabImportExport history={this.props.history} {...this.props} />;
      default:
        return <TabArea history={this.props.history} {...this.props} />;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.companyReducer) {
      //Kiểm tra nếu user là roles Standard thì không cho tìm kiếm doanh nghiệp
      const { roles } = nextProps;
      const { messages } = nextProps.intl;
      if (roles && roles === _Standard_) {
        this.actAlert(messages[`newWord.upgradeAccount`]);
        setTimeout(() => {
          this.props.history.push("/menu-dieu-huong/");
        }, 3000);
      }
    }
  }
  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };
  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.alert}

        <div className="views_company_detail company_search_advance" ref={(el) => (this.componentRef = el)}>
          <div className="page-title">
            <IntlMessages id="advanceSearch.title" />
          </div>
          <div>
            <Nav>
              <NavItem>
                <Link
                  className={classnames({
                    active: this.state.activeTab === 1 || !this.state.activeTab,
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggle(1);
                  }}
                  to={`?tab=1`}
                >
                  <IntlMessages id="advanceSearch.area" />
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to={`?tab=2`}
                  className={classnames({
                    active: this.state.activeTab === 2,
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggle(2);
                  }}
                >
                  <IntlMessages id="advanceSearch.industry" />
                </Link>
              </NavItem>

              {/* Tạm ẩn 2 tab dưới */}
              <NavItem>
                <Link
                  to={`?tab=3`}
                  className={classnames({
                    active: this.state.activeTab === 3,
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggle(3);
                  }}
                >
                  <IntlMessages id="advanceSearch.companyType" />
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to={`?tab=4`}
                  className={classnames({
                    active: this.state.activeTab === 4,
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggle(4);
                  }}
                >
                  <IntlMessages id="advanceSearch.ownership" />
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to={`?tab=5`}
                  className={classnames({
                    active: this.state.activeTab === 5,
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggle(5);
                  }}
                >
                  <IntlMessages id="advanceSearch.finance" />
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to={`?tab=6`}
                  className={classnames({
                    active: this.state.activeTab === 6,
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggle(6);
                  }}
                >
                  <IntlMessages id="advanceSearch.importExport" />
                </Link>
              </NavItem>
            </Nav>
            <div className="company-panel-content">{this.renderView()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyReducer: state.companyReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDataPointList: () => {
      dispatch(actGetDataPointList());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompanySearchAdvance));
