import React, { Component } from "react";
import { connect } from "react-redux";
import getUrlParam from "../../utils/getUrlParam";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import IntlMessages from "../../utils/IntlMessages";
import classnames from "classnames";
import IndustryFilter from "../../components/IndustryFilter/IndustryFilter";
import TabData from "./Tabs/TabData";
import TabRelatedRatio from "./Tabs/TabRelatedRatio";
// import TabRelatedReport from "./Tabs/TabRelatedReport";
import queryString from "query-string";

class BasicStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: getUrlParam()["tab"] ? parseInt(getUrlParam()["tab"]) : 1,
      isTab: getUrlParam()["tab"] ? true : false,
      loadingInitial: true,
      txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : "",
      filterType: getUrlParam()["filterType"] ? getUrlParam()["filterType"] : "",
      statistic: getUrlParam()["statistic"] ? getUrlParam()["statistic"] : ""
    };
  }

  componentDidMount() {
    document.title = "VIRACE | Basic Statistic";
  }
  // thay đổi tab
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  renderView() {
    const { activeTab, txtsearch, statistic } = this.state;
    switch (activeTab) {
      case 2:
        return <TabRelatedRatio {...this.props} history={this.props.history} />;
      // case 3:
      //     return (
      //         <TabRelatedReport {...this.props} history={this.props.history} />
      //     );
      default:
        return <TabData {...this.props} history={this.props.history} txtsearch={txtsearch} statistic={statistic} />;
    }
  }

  render() {
    const { txtsearch, filterType, statistic } = this.state;
    const roles = this.props.userAccount.roleClient;

    const baseUrl = `/thong-ke-co-ban?q=${txtsearch}&filterType=${filterType}&statistic=${statistic}`;
    return (
      <div>
        <div className="industry-filter-results if-ir-home mb-5">
          <div className="card-item search-info-section">
            <IndustryFilter txtsearch={txtsearch} filterType={filterType} statistic={statistic} handleSearch={() => {}} {...this.props} />
          </div>
        </div>
        <div
          className="industry-report-detail if-ir-home _views_industry_detail"
          onMouseDown={() => {
            return false;
          }}
        >
          <Nav>
            <NavItem>
              <Link
                className={classnames({
                  active: this.state.activeTab === 1 || !this.state.activeTab,
                  "nav-link": true
                })}
                onClick={() => {
                  this.toggle(1);
                }}
                to={`${baseUrl}&tab=1`}
              >
                <IntlMessages id="industry.statistic.tab.data" />
              </Link>
            </NavItem>
            {localStorage.getItem("access_token") && roles && (
              <NavItem>
                <Link
                  to={`${baseUrl}&tab=2`}
                  className={classnames({
                    active: this.state.activeTab === 2,
                    "nav-link": true
                  })}
                  onClick={() => {
                    this.toggle(2);
                  }}
                >
                  <IntlMessages id="industry.statistic.tab.relatedRatio" />
                </Link>
              </NavItem>
            )}

            {/* <NavItem>
                            <Link
                                to={`${baseUrl}&tab=3`}
                                className={classnames({
                                    active: this.state.activeTab === 3,
                                    "nav-link": true,
                                })}
                                onClick={() => {
                                    this.toggle(3);
                                }}
                            >
                                <IntlMessages id="industry.statistic.tab.relatedReport" />
                            </Link>
                        </NavItem> */}
          </Nav>
          <div className="company-panel-content">{this.renderView()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { userAccount: state.accountReducer.userAccount };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BasicStatistic));
