import React, { Component } from "react";
import TableStructure from "../../../components/CompanyInfo/Table/TableOwer/TableStructure";
import TableAffiliates from "../../../components/CompanyInfo/Table/TableOwer/TableAffiliates";
import LoaderTable from "../../../components/LoaderTable";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { actGetOwnerLaw, actGetCompanyRef, actGetOwnerStructor } from "../../../redux/actions";
import { _Basic_, _Standard_, _Premium_ } from "../../../constants/rolesModel";

export class TabOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChartStructure: []
    };
  }
  componentDidMount() {
    const { id } = this.props;
    if (id !== null && id !== undefined) {
      this.props.getOwnerLaw(id);
      this.props.getCompanyRef(id);
      this.props.getOwnerStructor(id);
    }
  }
  formatDataChart = (data) => {
    var flattened =
      data.length > 0 &&
      data.reduce(function(prev, props) {
        return [
          ...prev,
          {
            y: props.percent ? props.percent : 0,
            name: props.nameOwner ? props.nameOwner : ""
          }
        ];
      }, []);
    this.setState({
      dataChartStructure: flattened
    });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { structors } = nextProps.dataOwner;
    if (structors) {
      this.formatDataChart(structors);
    }
    if (this.props.intl.locale !== nextProps.intl.locale) {
      const { id } = this.props;
      if (id !== null && id !== undefined) {
        this.props.getOwnerStructor(id);
        this.props.getOwnerLaw(id);
        this.props.getCompanyRef(id);
      }
    }
  }

  render() {
    const { justView } = this.props;
    const roles = _Premium_;
    const { loadingDT } = this.props.dataReducer;
    const { laws, companyRef, structors, loadingLawTB, loadingREFTB, loadingStructorTB } = this.props.dataOwner;
    const { dataChartStructure } = this.state;
    return (
      <React.Fragment>
        {loadingLawTB && loadingREFTB && loadingStructorTB && loadingDT ? (
          <div ref={(el) => (this.componentRef = el)} className="PDF_assess-growth">
            <div className="company_owner_views">
              <div className="row equal-height">
                <div className="col-xl-6 pb-4">
                  <div className="card-item">
                    <TableStructure laws={laws} structorsData={structors} roles={roles} dataChartStructure={dataChartStructure} />
                  </div>
                </div>
                <div className="col-xl-6 pb-4">
                  <div className="card-item">
                    <TableAffiliates companyRefData={companyRef} roles={roles} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LoaderTable isLoaded={loadingLawTB && loadingREFTB && loadingStructorTB && loadingDT} styleCustom={{ height: "50vh" }} />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataOwner: state.companyReducer.owner,
    dataReducer: state.companyReducer,
    companyDetailReducer: state.companyReducer.companyDetail
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // Đại diện pháp luật
    getOwnerLaw: (id) => {
      dispatch(actGetOwnerLaw(id));
    },
    // Cấu trúc chủ sở hữu
    getOwnerStructor: (id) => {
      dispatch(actGetOwnerStructor(id));
    },
    // DS cty con và liên kết
    getCompanyRef: (id) => {
      dispatch(actGetCompanyRef(id));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabOwner));
