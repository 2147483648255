import React, { Component } from "react";
import CanvasJSReact from "../../../assets/js/canvasjs.react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import addSymbols from "../../../utils/addSymbols.js";
import LoaderTable from "../../LoaderTable";
import NoData from "../../NoData";
import { numberFormat } from "../../../utils/numberFormat";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
class ColumnComparisonChart extends Component {
  toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  };

  render() {
    const { title, enabledToolTip, backgroundColor } = this.props;
    const { revColumnChart, loadingChart } = this.props.dataPoints;
    const { messages } = this.props.intl;
    const options = {
      backgroundColor: backgroundColor !== undefined ? backgroundColor : "#02164d",
      animationEnabled: true,
      exportEnabled: false,
      dataPointMaxHeight: 100,
      dataPointWidth: 40,
      labelFontFamily: "Roboto",
      toolTip: {
        enabled: enabledToolTip,
        shared: true,
        contentFormatter: function(e) {
          var content = "";
          if (e.entries[0].dataPoint.x) {
            content += e.entries[0].dataPoint.x;
            content += "<br/>";
            content += e.entries[0].dataPoint.vsicName;
            content += "<br/>";
          }
          for (var i = 0; i < e.entries.length; i++) {
            // content += "<span style='color: " + e.entries[i].dataSeries.color + ";' >" + e.entries[i].dataSeries.name + "</span>" + ": " + numberFormat(e.entries[i].dataPoint.y) + messages["suffixD"];
            content +=
              "<span style='color: " +
              e.entries[i].dataSeries.color +
              ";' >" +
              e.entries[i].dataSeries.name +
              "</span>" +
              ": " +
              numberFormat(e.entries[i].dataPoint.y);

            content += "<br/>";
          }
          return content;
        }
      },
      axisX: {
        valueFormatString: "####",
        interval: 1,
        labelFontSize: 12,
        labelFontWeight: 400,
        labelFontColor: "#B6C4DE",
        lineColor: "rgba(144, 155, 175, 0.3)"
      },

      title: {
        text: title,
        margin: 20,
        fontSize: 18,
        fontWeight: 400,
        fontColor: "#FFFFFF",
        fontFamily: "Roboto"
      },
      // subtitles:[
      //     {
      //         text: vsicName,
      //         fontWeight: 400,
      //         fontColor: "#FFFFFF",
      //         fontFamily: "Roboto",
      //     }
      // ],
      axisY: {
        titleFontSize: 12,
        titleFontWeight: 400,
        labelFontFamily: "Roboto",
        titleFontColor: "#B6C4DE",
        labelFontColor: "#B6C4DE",
        labelFontSize: 12,
        tickColor: "rgba(144, 155, 175, 0.3)",
        //gridThickness: 1,
        gridColor: "rgba(144, 155, 175, 0.3)",
        includeZero: false,
        lineColor: "rgba(144, 155, 175, 0.3)",
        labelFormatter: (e) => addSymbols(e, CanvasJS, messages)
      },

      axisY2: {
        // lineColor: "#808080",
        labelFontSize: 12,
        labelFontColor: "#808080",
        titleFontColor: "#FFFFFF",
        // valueFormatString: "#,,.",
        // title: (revColumnChart && revColumnChart.netProfitVirac && revColumnChart.netProfitVirac.length > 0) ? messages["newWord.industryRevenueVIRAC"] : null,
        titleFontSize: 12,
        titleFontWeight: 400,
        tickLength: 14,
        lineThickness: 0,
        tickThickness: 0,
        gridThickness: 0,
        titleFontFamily: "Roboto",
        labelFormatter: (e) => addSymbols(e, CanvasJS, messages)
      },

      legend: {
        // reversed: true,
        cursor: "pointer",
        fontColor: "#B6C4DE",
        fontSize: 12,
        itemclick: this.toggleDataSeries,
        fontFamily: "Roboto",
        fontWeight: 400
      },

      data: [
        {
          type: "column",
          showInLegend: true,
          //legendText: "GSO",
          name: "GSO",
          // yValueFormatString: "#,,.",
          color: "#4661EE",
          // toolTipContent: "<span style='\"'color: {color};'\"'>GSO</span>: <strong>{y}</strong>",
          xValueFormatString: "####",
          yValueFormatString: "##,##0.##",
          dataPoints: revColumnChart && revColumnChart.netProfitGSO && revColumnChart.netProfitGSO.length > 0 ? revColumnChart.netProfitGSO : []
        },

        {
          type: "column",
          showInLegend: true,
          // legendText: "VIRAC",
          name: "VIRAC",
          color: "#1BCDD1",
          // toolTipContent: "<span style='\"'color: {color};'\"'>VIRAC</span>: <strong>{y}</strong>",
          xValueFormatString: "####",
          yValueFormatString: "##,##0.##",
          dataPoints: revColumnChart && revColumnChart.netProfitVirac && revColumnChart.netProfitVirac.length > 0 ? revColumnChart.netProfitVirac : []
        }
      ]
    };

    return (
      <div>
        {loadingChart ? (
          <LoaderTable isLoaded={!loadingChart} styleCustom={{ height: "500px" }} />
        ) : Object.keys(revColumnChart).length > 0 ? (
          <div className="column-multiple-chart-wrapper">
            <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
            <div className="cright">
              <IntlMessages id="common.sourceVirac" />
            </div>
          </div>
        ) : (
          <NoData />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataPoints: state.companyReducer
  };
};
export default connect(mapStateToProps, null)(injectIntl(ColumnComparisonChart));
