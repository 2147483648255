import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { actGetCompanyDetail, actDownloadCompanyReport, actResetFinancial } from "../../redux/actions";
import getUrlParam from "../../utils/getUrlParam";
import TabFinancial from "./Tabs/TabFinancial";
import TabRelatedReport from "./Tabs/TabRelatedReport";
import TabInfo from "./Tabs/TabInfo";
import TabOwner from "./Tabs/TabOwner";
import TabTargets from "./Tabs/TabTargets";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import IntlMessages from "../../utils/IntlMessages";
import { subPhone } from "../../utils/func.util";
import LoaderTable from "../../components/LoaderTable";
import { saveAs as importedSaveAs } from "file-saver";
import { isArray } from "lodash";
import TabCompareCompany from "./Tabs/TabCompareCompany";
import { _Basic_, _Standard_, _ForeignAccount_ } from "../../constants/rolesModel";
import { Redirect } from "react-router-dom";

export class CompanyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: getUrlParam()["tab"] ? parseInt(getUrlParam()["tab"]) : 1,
      isTab: getUrlParam()["tab"] ? true : false,
      dropdownOpen: false,
      id: this.props.match.params.id,
      valueFinancialSet: null,
      setChecked: null,
      loadingInitial: true,
      alert: ""
    };
  }
  // thay đổi tab
  toggle = (tab) => {
    const { messages } = this.props.intl;
    if (this.state.activeTab !== tab) {
      if (parseInt(this.state.setChecked) === -1 && [3, 4, 5].includes(tab)) {
        // nếu set = -1 thì thì không thể xem chỉ tiêu, chỉ số, so sánh DN
        this.setState({
          alert: (
            <SweetAlert
              warning
              style={{ display: "block" }}
              title={messages[`user.Notification`]}
              confirmBtnText={messages[`newWord.oke`]}
              customClass="swal-wide"
              confirmBtnCssClass="confirmBtn swal2-styled"
              onConfirm={() => {
                this._hideAlert();
              }}
            >
              <p>
                <IntlMessages id="newWord.notAvailable" />
              </p>
            </SweetAlert>
          )
        });
      } else {
        this.setState({
          activeTab: tab
        });
      }
    }
  };

  handleOnBack = () => {
    this.toggle(this.state.activeTab);
    this.props.history.push(`/chi-tiet-doanh-nghiep/${this.state.id}?tab=${this.state.activeTab}`);
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };
  _hideAlert = () => {
    this.handleOnBack();
    this.setState({
      alert: ""
    });
  };

  toggleSS = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  onMouseEnter = () => {
    this.setState({ dropdownOpen: true });
  };

  onMouseLeave = () => {
    this.setState({ dropdownOpen: false });
  };

  componentDidMount() {
    document.title = "VIRACE | Company Detail";

    const { activeTab, id } = this.state;

    if (![1, 2, 3, 4].includes(activeTab)) {
      window.location.href = "/chi-tiet-doanh-nghiep/" + id + "?tab=1";
    }
    const { state } = this.props.history.location;
    const action = this.props.history.action;
    let typeParams = "Thông tin doanh nghiệp";
    if (state && state.type && action === "PUSH") {
      typeParams = state.type;
    }
    this.props.getCompanyDetail(id, typeParams);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.detail) {
      const { set } = nextProps.detail;
      if (set) {
        this.setState({
          setChecked: set
        });
      }

      if (nextProps.detail.id) {
        this.funcSetLocalCompanySearch(nextProps.detail.id, nextProps.detail.name ? nextProps.detail.name : "");
        this.setState({
          loadingInitial: false
        });

        //Kiểm tra nếu user là roles Basic thì không cho xem chi tiết doanh nghiệp
        // const { roles } = nextProps;
        // const { messages } = nextProps.intl;
        // if (roles && (roles === _Basic_ || roles === _Standard_)) {
        //   this.actAlert(messages[`newWord.upgradeAccount`]);
        //   setTimeout(() => {
        //     this.props.history.push("/menu-dieu-huong/");
        //   }, 3000);
        // }
      }
    }
  }

  // danh sách DN đã xem gần nhất
  funcSetLocalCompanySearch = (id, name) => {
    const idUser = this.props.userAccount.id || null;
    try {
      if (id && name) {
        let model = { id: id, name: name };
        var arr = !localStorage.getItem("aListSuggest") ? [] : JSON.parse(localStorage.getItem("aListSuggest"));
        if (isArray(arr)) {
          const index = arr.findIndex((e) => e.idUser === idUser);
          if (index === -1) {
            arr.unshift({
              idUser: idUser,
              arrSuggest: [model]
            });
          } else {
            let arrSG = arr[index].arrSuggest;
            arrSG.splice(5);
            const indexSG = arrSG.findIndex((e) => e.id === id);
            if (indexSG === -1) {
              arr[index] = {
                ...arr[index],
                idUser: idUser,
                arrSuggest: [model, ...(arr[index].arrSuggest || [])]
              };
            }
          }
        }
        localStorage.setItem("aListSuggest", JSON.stringify(arr));
      }
    } catch (err) {
      localStorage.removeItem("aListSuggest");
    }
  };

  renderWebsite = (param) => {
    if (param) {
      return param.replace("https://", "");
    }
    return "";
  };

  renderView() {
    const { activeTab, id, setChecked } = this.state;
    const { set } = this.props.detail;
    var activeTabCheck = activeTab;
    if (set) {
      let arrSet = [2, 13, 14, 19, 20, 21, 22]; // doanh nghiệp tín dụng
      var isCreditChecked = arrSet.includes(set);
    }
    switch (activeTabCheck) {
      case 1:
        return <TabInfo history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} valueFinancial={setChecked} />;
      case 2:
        return <TabOwner history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} />;
      case 3:
        return <TabTargets history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} />;
      case 4:
        return <TabFinancial history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} />;

      default:
        return <TabInfo history={this.props.history} {...this.props} id={id} checkCreditDN={isCreditChecked} />;
    }
  }
  processDownload() {
    this.props.resetData();
  }

  render() {
    const { id } = this.state;
    const props = this.props;
    const { roles } = this.props;
    const { exportCompanyData } = props.dataReducer;
    const { messages } = this.props.intl;
    if (exportCompanyData) {
      var blob = new Blob([exportCompanyData], { type: "application/pdf" });
      importedSaveAs(blob, messages["companyReportFileName"]);
    }
    return (
      <div className="views_company_detail" ref={(el) => (this.componentRef = el)}>
        {<LoaderTable isLoaded={!this.state.loadingInitial} styleCustom={{ height: "calc(100vh - 200px)" }} />}
        {!this.state.loadingInitial && (
          <div>
            {this.state.alert}
            {props.detail.id && (
              <div className="non-financial-info company-info-content-main">
                <div className="result-item" style={{ border: 0 }}>
                  <div className="vietnamese-name mb-1 d-flex justify-content-between">
                    <span>{props.detail.name ? props.detail.name : ""}</span>
                  </div>
                  <div className="english-name mb-2">{props.detail.englishName ? props.detail.englishName : ""}</div>

                  {props.detail.subTitle && (
                    <div className="address has-icon">
                      {/* <img src={icon1} alt="icon" /> */}
                      <span className="material-icons">domain</span>
                      {props.detail.subTitle}
                    </div>
                  )}
                  <div className="address has-icon">
                    {" "}
                    <span className="material-icons">place</span>
                    {props.detail.address ? props.detail.address : ""}
                  </div>
                  <div className="info-wrap">
                    <ul className="info">
                      <li className="has-icon">
                        <span className="material-icons">tag</span>
                        <IntlMessages id="page.MST" />: {props.detail.taxNumber ? props.detail.taxNumber : "N/A"}
                      </li>
                      <li className="has-icon" style={{ width: "66.66667%" }}>
                        <span className="material-icons">business_center</span>
                        VSIC: {props.detail.vsicName ? " " + props.detail.vsicName + " - " : ""} {props.detail.vsicId ? props.detail.vsicId : ""}
                      </li>
                    </ul>
                  </div>
                  <div className="result-more-content">
                    <ul className="info">
                      <li className="has-icon">
                        <span className="material-icons">phone</span>
                        {props.detail.phoneNumber ? subPhone(props.detail.phoneNumber) : "N/A"}
                      </li>
                      <li className="has-icon">
                        <span className="material-icons">print</span>
                        {props.detail.fax ? props.detail.fax : "N/A"}
                      </li>
                      <li className="has-icon">
                        <span className="material-icons">public</span>
                        {props.detail.webSite ? (
                          <a
                            className="text-cyan"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={props.detail.webSite ? "https://" + this.renderWebsite(props.detail.webSite) : ""}
                          >
                            {props.detail.webSite}
                          </a>
                        ) : (
                          <span>N/A</span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {this.props.match.params.id !== null && this.props.match.params.id !== undefined && (
              <Nav>
                <NavItem>
                  <Link
                    className={classnames({
                      active: this.state.activeTab === 1 || !this.state.activeTab,
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggle(1);
                    }}
                    to={`${id}?tab=1`}
                  >
                    <IntlMessages id="submenu.Businessinformation" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to={`${id}?tab=2`}
                    className={classnames({
                      active: this.state.activeTab === 2,
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggle(2);
                    }}
                  >
                    <IntlMessages id="submenu.Ownership" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to={`${id}?tab=3`}
                    className={classnames({
                      active: this.state.activeTab === 3,
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggle(3);
                    }}
                  >
                    <IntlMessages id="submenu.Accounts" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to={`${id}?tab=4`}
                    className={classnames({
                      active: this.state.activeTab === 4,
                      "nav-link": true
                    })}
                    onClick={() => {
                      this.toggle(4);
                    }}
                  >
                    <IntlMessages id="submenu.Ratios" />
                  </Link>
                </NavItem>
              </Nav>
            )}
            <div className="company-panel-content">{this.renderView()}</div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    detail: state.companyReducer.companyDetail,
    dataReducer: state.companyReducer,
    userAccount: state.accountReducer.userAccount
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // chi tiết DN
    getCompanyDetail: (id, type) => {
      dispatch(actGetCompanyDetail(id, type));
    },
    //resetData
    resetData: () => {
      dispatch(actResetFinancial());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CompanyDetail));
