import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as Types from "../actions";
import * as actions from "./actions";
import * as dataRequestedService from "../../services/dataRequested.service";
import { actNotify } from "../Fetch/actions";

//SEND - DATA - REQUEST
function* watchSendDataRequest() {
  yield takeEvery(Types.SEND_DATA_REQUEST, sendDataRequest);
}

function* sendDataRequest(action) {
  let language = localStorage.getItem("currentLanguage");

  let notify_data_err = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  let notify_data_success = {
    message: language === "en" ? "Submit request successfully!" : "Gửi yêu cầu thành công!",
    color: "success"
  };
  try {
    var { arrSendData, selectedList } = action.payload;

    var response = yield call(dataRequestedService.actSendDataRequestdApi, arrSendData);
    let message = "";

    if (response.data.success) {
      yield put(actNotify(notify_data_success));
      message = response.data.message;
      yield put(actions.getMessageSendRequestData(message, selectedList));
      action.payload.success();
    }
  } catch (err) {
    yield put(actNotify(notify_data_err));
    yield put(actions.actSendRequestDataFail());
  }
}

// mua hàng
function* actCreateOrder(action) {
  let language = localStorage.getItem("currentLanguage");

  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(dataRequestedService.actCreateOrder, action.payload);
    if (response.data.success) {
      yield put({
        type: Types.CREATE_ORDER_SUCCESS
      });
      window.top.location.href = response.data.data;
    } else {
      yield put({
        type: Types.CREATE_ORDER_FAIL
      });
      yield put(
        actNotify({
          message: language === "en" ? "Purchased fail!" : "Tạo đơn hàng không thành công!",
          color: "danger"
        })
      );
    }
  } catch (err) {
    yield put({
      type: Types.CREATE_ORDER_FAIL
    });
    yield put(actNotify(notify_data));
  }
}

function* watchActCreateOrder() {
  yield takeEvery(Types.CREATE_ORDER, actCreateOrder);
}

// lấy thông tin đơn hàng
function* actGetOrder(action) {
  let language = localStorage.getItem("currentLanguage");

  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger"
  };
  try {
    var response = yield call(dataRequestedService.actGetOrder, action.payload);
    const data = response.data.data;
    if (response.data) {
      if (response.data.success) {
        yield put({
          type: Types.GET_ORDER_SUCCESS,
          data
        });
        yield put(
          actNotify({
            message: language === "en" ? "Payment successful!" : "Đơn hàng thanh toán thành công!",
            color: "success"
          })
        );
      } else {
        yield put({
          type: Types.GET_ORDER_FAIL
        });
        yield put(
          actNotify({
            message: language === "en" ? "Unsuccessful payment. Please purchase again!" : "Thanh toán đơn hàng không thành công. Vui lòng thực hiện lại!",
            color: "danger"
          })
        );
      }
    } else {
      yield put({
        type: Types.GET_ORDER_FAIL
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.GET_ORDER_FAIL
    });
    yield put(actNotify(notify_data));
  }
}

function* watchActGetOrder() {
  yield takeEvery(Types.GET_ORDER, actGetOrder);
}

export default function* rootSaga() {
  yield all([fork(watchActCreateOrder), fork(watchActGetOrder), fork(watchSendDataRequest)]);
}
