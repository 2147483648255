import * as Types from "../actions";


export const getSubNavMacro = (cbSuccess) => {
  return {
    type: Types.GET_SUB_NAV_MARCRO,
    payload: {
      cbSuccess
    }
  };
};

export const getListCheckBoxMacro = (id) => {
  return {
    type: Types.GET_LIST_CHECKBOX_MARCRO,
    payload: {
      id
    }
  };
};

//getDataChart

export const getDataChart = (cateId, macroId, activeTab) => {
  return {
    type: Types.GET_DATA_CHART_MACRO,
    payload: {
      cateId, macroId, activeTab
    }
  };
};
export const handleDeleteMacroChart = key => {
  return {
    type: Types.DELETE_DATA_CHART_MACRO,
    payload: {
      key
    }
  };
};