/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Dashboards
05.Pages
06.Applications
  06.01.Chat
  06.02.Survey
  06.03.Todo
07.UI
  07.01.Alerts
  07.02.Badges
  07.03.Buttons
  07.04.Cards
  07.05.Carousel
  07.06.Charts
  07.07.Collapse
  07.08.Dropdowns
  07.09.Editors
  07.10.Forms
  07.11.Form Components
  07.12.Icons
  07.13.Input Groups
  07.14.Jumbotron
  07.15.Modal
  07.16.Navigation
  07.17.Popover & Tooltip
  07.18.Sortable
  07.19.Maps
  07.20.Tables
*/

export default {
  /* 01.General */
  "general.copyright": "Virace React © 2020 All Rights Reserved.",

  /* 01.menu sidebar */
  "menu.home": "Dashboard",
  "menu.cr-home": "CR Home",
  "menu.if-ir-home": "IF-IR Home",
  "menu.companyinfoorigin": "Company Information",
  "menu.companyinfo": "Company",
  "menu.companyinfoplus": "Information",
  "menu.companycomparisonorigin": "Company Comparison",
  "menu.companycomparison": "Company",
  "menu.companycomparisonplus": "Comparison",
  "menu.industryinformationorigin": "Industry Information",
  "menu.industryinformation": "Industry",
  "menu.industryinformationplus": "Information",
  "menu.macroinformationorigin": "Macro Information",
  "menu.macroinformation": "Macro",
  "menu.macroinformationplus": "Information",
  "menu.newevent": "News",
  "menu.neweventdetail": "News details",
  "menu.faq": "FAQs",
  "menu.contact": "Contacts",
  "menu.report": "Report",
  "menu.reportIndustry": "Industry report",
  "menu.companyinformationsearch": "Search Company",
  "menu.industryinformationsearch": "Search Industry",
  "menu.accountsManagement": "User Page",
  "menu.cartDataTitle": "List of data requests",

  "sidebar.info": "Info",
  "sidebar.menu": "Menu",
  "sidebar.Comparison": "Comparison",
  "sidebar.Industry": "Vsic code",
  "sidebar.companyType": "Type of business",

  /*menu submenu*/
  "submenu.Businessinformation": "Business information",
  "submenu.Basicgrowth": "Basic growth review",
  "submenu.Ownership": "Ownership",
  "submenu.Ratios": "Ratios",
  "submenu.Accounts": "Accounts",
  "submenu.Comparisons": "Comparisons",
  "submenu.Industryaverages": "With Industry averages",
  "submenu.Othercompanies": "With other companies",
  "submenu.ExchangeRate": "Exchange rate",
  "submenu.relatedIndustryReports": "Related reports",

  /*02 Thong tin doanh nghiep */
  "page.hTitle": "Company information",
  "page.hAddress": "Address - Phone number - Fax - Website",
  "page.MST": "Tax code",

  //Tabinfo
  "detailCompany.h1Table": "Operational information",
  "detailCompany.MainbusinessShortcut": "Main business activity",
  "detailCompany.Mainbusiness": "Main business activity",
  "detailCompany.Keyproduct": "Key product",
  "detailCompany.Keymarket": "Key market",
  "detailCompany.TaxcodeShortCut": "Tax code",
  "detailCompany.Taxcode": "Tax code",
  "detailCompany.Parentcompany": "Parent company",
  "detailCompany.Accountingperiod": "Accounting period",
  "detailCompany.FinancialstatmentShortCut": "Financial statment set No.",
  "detailCompany.Financialstatment": "Financial statment set No.",
  "detailCompany.FiscalperiodShortCut": "Fiscal period",
  "detailCompany.Fiscalperiod": "Fiscal period",
  "detailCompany.Stocklisting": "Stock listing",
  "detailCompany.NoemployeesShortCut": "No. of employees",
  "detailCompany.Noemployees": "No. of employees",
  "detailCompany.LegalrepresentativeShortCut": "Legal representative",
  "detailCompany.Legalrepresentative": "Legal representative",
  "detailCompany.shareholders": "No. of shareholders",
  "detailCompany.Datarequest": "Data request",
  "detailCompany.ActionInfo": "Operational Information",
  "detailCompany.AreaBusiness": "Area of business activities",
  "detailCompany.NetProfitGrowthRate": "Net profit growth rate",
  "detailCompany.Downloadfinancialreportx": "Download report",

  //TOOLTIP
  "detailCompany.MainBusiness": "Main business",
  "detailCompany.KeyProduct": "Key product",
  "detailCompany.NetrevenueShortcut": "Net Revenue",
  "detailCompany.Netrevenue": "Net revenue",
  "detailCompany.VsicCode": "VSIC Code",

  "detailCompany.NetprofitShortcut": "Net profit",
  "detailCompany.Netprofit": "Net profit",
  "detailCompany.noNetprofitShortcut": "GROSS PROFIT",
  "detailCompany.noNetprofit": "GROSS PROFIT",
  "detailCompany.Totalassets": "Total assets",
  "detailCompany.Netdebts": "Net debts",
  "detailCompany.GoodsSoldShortcut": "Costs of goods sold",
  "detailCompany.GoodsSold": "Costs of goods sold",
  "detailCompany.OwnerequityShortcut": "Owner's equity",
  "detailCompany.Ownerequity": "Owner's equity",
  "detailCompany.Marketcapitalization": "Market capitalization",
  "detailCompany.Outstandingshares": "Outstanding shares",
  "detailCompany.Downloadfinancialreport": "Buy and Download report",
  "detailCompany.Updated": "Updated",
  "detailCompany.Financialreports": "Financial Analysis Report (source: VIRAC)",
  "detailCompany.Choosefiscalyear": "Choose fiscal year",
  "detailCompany.Purchase": "Purchase",
  "detailCompany.Consolidatedfinancial": "Consolidated financial report (listed company)",
  "detailCompany.Downloadfinancialreportbuy": "Business & industry reports",
  "detailCompany.NetProfitMargin": "Net Profit Margin",
  "detailCompany.table1.top5a": "Top 05 Most Revenued Enterprises in the Same Industry",
  "detailCompany.table1.top5b": "05 Same Revenue Enterprises in the Same Industry",
  "detailCompany.table2": "Most Searched Enterprises In The Same Industry In The Last 3 Months",
  "detailCompany.FinancialReportingType": "Financial Reporting Type",
  "detailCompany.notCompare": "Different business type",
  "detailCompany.maxCompare": "Maximum number of businesses compared",
  "detailCompany.minCompare": "Select at least 2 businesses to compare",
  "detailCompany.reportType": "Company Type",
  "detailCompany.minTargetCompare": "Select at least 1 indicator or metric to compare",
  "detailCompany.maxTargetCompare": "Select at most 8 metrics and/or metrics to compare",

  //Danhgiatangtruong
  "growthBasic.Compareaverage": "Compare with industry average",
  "growthBasic.Selectlocation": "Select location",
  "growthBasic.SelectlocationTitle": "Location",
  //chusohuu
  "ownership.Legal": "Legal representative",
  "ownership.Fullname": "Full name",
  "ownership.Position": "Position",
  "ownership.Ownershippercentage": "Ownership percentage",
  "ownership.Updateddate": "Updated date",
  "ownership.stt": "No.",
  "ownership.Ownershipstructure": "Ownership structure",
  "ownership.Ownershiptype": "Ownership type",
  "ownership.Ownershippercentagex": "Ownership percentage",
  "ownership.Privatehold": "Private hold",
  "ownership.EnterpriseName": "Enterprise Name",
  "ownership.Foreignhold": "Foreign hold",
  "ownership.Others": "Others",
  "ownership.Subsidiaryandaffiliate": "Subsidiary and affiliate",
  "ownership.Companyname": "Enterprise name",
  "ownership.Taxcode": "Tax code",
  "ownership.Relationship": "Relationship",
  "ownership.Subsidiary": "Subsidiary",
  "ownership.Affiliate": "Related Enterprises",
  //ratio
  "ratio.h1list": "Ratios",

  //account
  "account.h1list": "Accounts",
  "account.Otheraccounts": "Accounts ",
  //TabCompareAverages
  "averages.h1Accounts": "Accounts",
  "averages.h1Ratios": "Ratios",
  "averages.basicRatios": "Ratios",
  "averages.Otheraccounts": "Accounts ",
  "averages.peerComparison": "Peer comparison",
  "averages.peerComparisonTB": "Peer comparison",
  "averages.titleColumn": "Total net revenue of the industry",
  /*02 Thong tin doanh nghiep */
  "compare.tabSame": "Same industry compare",
  "compare.tabAny": "Any industry compare",
  "compare.txtInput": "Input industry name or VSIC",
  "compare.searchResult": "Search result",
  "compare.businessComparison": "Business comparison",
  "compare.groupComparison": "Group comparison",
  "compare.group1": "Group 1",
  "compare.group2": "Group 2",
  "compare.companiesComparison": "Companies for comparison",
  "compare.businessGroup": "Business group",

  //tructiep
  "comparett.h1Accounts": "Accounts",
  "comparett.h1Ratios": "Ratios",
  "comparett.basicRatios": "Ratios",
  "comparett.Otheraccounts": "Accounts ",
  "comparett.peerComparison": "Peer comparison",

  "comparett.directcomparison": "Direct comparison",
  "comparett.averages": "Averages",
  "comparett.accountTotal": "Account total",
  "comparett.accounts": "Accounts",
  "comparett.ratios": "Ratios",
  "comparett.industryAverage": "Industry average",
  "comparett.IndustryAverageTB": "Industry average",

  /*03 Thông tin ngành */
  //tong quan nganh
  "industry.tab1": "Industry overview",
  "industry.x1input": "Input industry name or VSIC",
  "industry.x1h1": "Industry overview of",
  "industry.x1top": "Top 10  industries with the largest number of companies",
  "industry.x1sutrix": "Company",

  "industry.tab2": "Financial Information",

  "industry.tab4": "Locations comparison",
  //san xuat - tieu thu - ton kho
  "industry.tab3": "Manufacture - Consumption - Inventory",
  "industry.tab3h1ShortCut": "Manufacture, Consumption, Inventory level of a type of product",
  "industry.tab3h1": "Manufacture, Consumption, Inventory level of a type of product",
  "industry.tab3h2ShortCut": "Compare Manufacture, Consumption, Inventory levels of types of products",
  "industry.tab3h2": "Compare Manufacture, Consumption, Inventory levels of types of products",
  "industry.tab3h2sub1": "Products",
  "industry.tab3h2sub2": "Comparison criteria",
  "industry.locationComparison": "Locations comparison",
  "industry.informationOfManufacture": "Information of manufacture, consumption and inventory of",
  /*01 .Common */
  /* 01.1 Filter */
  "filter.vcsh": "Owner's equity (VND)",
  "filter.tts": "Total assets (VND)",
  "filter.lnt": "Net profit (VND)",
  "filter.dt": "Net revenue (VND)",
  "filter.kvx": "Location",
  "Khu Vực": "Location",
  "filter.dsnn": "List of industries (VSIC)",
  "filter.textInput": "Enter search information (company name or tax code or VSIC)",
  "companyFilter.textInput.minLength": "Please search for at least 3 characters",
  "filter.buttonSearch": "Search",
  "filter.Sortby": "Sort by:",
  /*01.2 DNOrderByVDL */
  "filter.vdl1": "< 3 billion ",
  "filter.vdl2": "3 billion - 20 billion ",
  "filter.vdl3": "20 billion - 100 billion ",
  "filter.vdl4": ">100 billion ",
  /*01.3 DNOrderByLN */
  "filter.ln1": "< 300 million",
  "filter.ln2": "300 million - 5 billion",
  "filter.ln3": "5 billion - 20 billion",
  "filter.ln4": "> 30 billion",
  /*01.4 DNOrderByDT */
  "filter.dt1": "< 3 billion/year",
  "filter.dt2": "3 billion - 50 billion",
  "filter.dt3": "50 billion - 200 billion",
  "filter.dt4": "> 300 billion",
  "filter.advanceSearch": "Advanced Search",
  "filter.compact": "Minimized",

  /* 01.1 Filter */
  "pagi.First": "First",
  "pagi.Last": "Last",
  /* 01.3 Table */
  "table.sttTable": "No.",
  "table.businessName": "Business name",
  "table.companyName": "Company name",
  "table.companyList": "Company List",
  "table.tableMST": "Tax code",
  "table.tableDelete": "Delete",
  "table.favoriteCompanies": "Favorite companies",
  "table.sort": "Sort",
  "table.group": "Group",
  "table.UpdateTable": "Update",
  "table.statusx": "Status",

  "table.groupChosse": "Group",
  "table.group1": "Group 1",
  "table.group2": "Group 2",

  "common.Years": "Time period: 3 years",
  "common.Apply": "Apply",
  "common.cancelBtn": "Cancel",
  "common.refresh": "Refresh",
  "common.IndustryAverage": "Compare with industry average",
  "common.IndustryAverageTB": "Compare with industry average",
  "common.Selectlocation": "Select location",
  "common.Selectlocationx": "Select location",
  "common.listProvince": "Locations",
  "common.listProvinceSelected": "Selected locations",
  "common.sort": "Sort",
  "common.largest": "Largest",
  "common.smallest": "Smallest",
  "common.totalValue": "Industry's total value",

  "common.AccounstRatio": "Accounts - Ratios",
  "common.AccounstCredit": "Accounts - Ratios for credit institution",

  "common.target": "Basic Target",
  "common.bcdkt": "Balance sheet",
  "common.bckqhd": "Business income statement",
  "common.bclctt": "Direct cash flow statement",
  "common.bclcttgt": "Indirect cash flow statement",

  "common.sourceVirac": "Source VIRAC",
  "common.successx": "Successful",

  "ratio.Capitalstructure": "Capital structure",
  "ratio.Performanceratios": "Performance ratios",
  "ratio.Liquidityratios": "Liquidity ratios",
  "ratio.Profitabilityratios": "Profitability ratios",
  "ratio.Customizedratios": "Customized ratios",

  /*Quản lý tài khoản */
  "user.Info": "User Information",
  "user.lang": "Language",
  "user.changpass": "Change Password",
  "user.changpassOld": "Input old password",
  "user.changpassNews": "Input new password",
  "user.changpassConfirm": "Confirm new password",
  "user.validatePass": "Password must consist of more than 6 letters and include special keys, regular and capital letters",

  "user.RequestHistory": "Request history",
  "user.DataRequestHistory": "Data request history",
  "user.orderCode": "Order code",
  "user.requirement": "Requirement",
  "user.companyandindustry": "Company/Industry",
  "user.taxandCode": "Tax code/VSIC Industry code",
  "user.DateRequested": "Date requested",
  "user.Status": "Status",
  "user.NoRequest": "No request history",
  "user.listReport": "List reports",

  "user.LogHistory": "Log in history",
  "user.Action": "Action/Active",
  "user.LogActivity": "Log in/Activity/Action Detail",
  "user.Date": "Date",
  "user.Browser": "Web browser",
  "user.langVN": "Vietnamese",
  "user.langEN": "English",

  "user.Notification": "Notification",
  "user.NotificationContent": "Notification Content",
  "user.DateSend": "Date",
  "user.NewsS": "News",
  "user.LogOff": "Log off",
  "user.Confirm": "Confirm",
  "user.LogOffB": "Do you want to log off?",
  "user.Cancel": "Cancel",
  "user.ViewAll": "View all",
  "user.hello": "Hello",
  "user.profile": "My Profile",

  /*Cart */
  "cart.listDataRequest": "List of data request",
  "cart.Request": "Request",
  "cart.Note": "Note",
  "cart.NoteNo": "No request",
  "cart.NoteOrder": "Note for the order",
  "cart.SendOrder": "Send order",
  "cart.FailAlert": "Fail",
  "cart.FailAlertOrder": "Sorry! You are not authorized to set a data request. Please contact Hotline: 0938 677 566",
  "cart.Acceps": "Accept",
  "cart.industryReport.title": "Industry Reports and Statistics",
  "cart.industryReport.report": "Report Name/Statistic",
  "cart.industryReport.price": "Unit price",
  "cart.industryReport.search": "Find more reports",
  "cart.company.title": "Enterprise Information",
  "cart.company.report": "Report Name/Information",
  "cart.company.company": "Enterprise",
  "cart.company.taxCode": "Tax code",
  "cart.company.search": "Find more enterprise informations",
  "cart.customer": "Shoppers",
  "cart.promotionCode": "Discount Code",
  "cart.termPart_1": "I have read and agree to ",
  "cart.termPart_2": " terms and conditions ",
  "cart.termPart_3": "of the website.",
  "cart.orderInfor": "Order information",

  "cart.money": "Provisional",
  "cart.promotion": "Discount",
  "cart.total": "Total",
  "cart.buy": "Purchase",
  "cart.request": "Send request",
  "cart.note": "Only available unit-priced reports can be purchased",
  "cart.discountCodeWrong": "Invalid discount code.",
  "cart.provisionalMoney": "Provisional",
  "cart.discount": "Discount",
  "cart.VAT": "VAT ",
  "cart.totalMoney": "Total",
  "cart.confirmPay": "CONFIRM PURCHASE",
  "cart.cancelPay": "CANCEL PURCHASE",

  /* new */
  "newWord.somthingWrong": "An error occurred. Please try again later",
  "newWord.overTurnDownload": "You exceed the number of downloads in the month. Please contact customer service!",
  "newWord.notPermision": "You are not authorized to perform this function. Please contact customer service!",

  "newWord.chooseOne": "You can only select direct comparison, or compare average",
  "newWord.oke": "OK",
  "newWord.find": "Find",
  "newWord.from": "From",
  "newWord.to": "To",
  "newWord.sufRange": "(Billion VND)",
  "newWord.sufRangeInput": "billion",
  "newWord.Range": "Customized range",
  "newWord.emptyInput": "Input field must not be empty",
  "newWord.validFormat": "Please enter a valid format",
  "newWord.validFormatPassword": "*Invalid Password!",
  "newWord.validFormatPasswordConfirm": "*Confirm password does not match!",
  "newWord.validFormatPasswordleast": "*Passwords must be at least 6 characters !",
  "newWord.registeredMainShortCut": "Registered main business",
  "newWord.registeredMain": "Registered main business",

  "newWord.financialInfo": "Financial information",
  "newWord.incorporatedDateShortCut": "Incorporated Date",
  "newWord.incorporatedDate": "Incorporated Date",
  "newWord.charteredCapital": "Contributed capital",
  "newWord.wait": "Please wait...",
  "newWord.titleChartOwner": "Companies which the legal representative(s) held ownership percentage(s)",
  "newWord.foreign": "Foreign owners",
  "newWord.owner": "Owners",
  "newWord.ownerPercentage": "Ownership percentage",
  "newWord.notAvailable": "The data is currently not available",
  "newWord.chart": "Chart",
  "newWord.performanceReview": "Company performance review",
  "newWord.industryRevenueGSO": "Industry's total revenue - million VND (source: GSO)",
  "newWord.industryRevenueVIRAC": "Industry's total revenue - VND (source: VIRAC)",
  "newWord.companyNameTaxCode": "Input company name, Tax code or VSIC",
  "newWord.companyNoSelected": "No company is selected!",
  "newWord.typeFinancialStatement": "FS Set",
  "newWord.ficalPeriod": "Reporting period type",
  "newWord.typeStatement": "Type of FS",
  "newWord.accountingPeriod": "Accounting period",
  "newWord.notice": "Notice",
  "newWord.alertSelectedAccountRatioTBN": "Select financial account(s) or ratio(s) to make comparison with industry average.",
  "newWord.alertSelectedAccountRatio": "Select financial account(s) or ratio(s) to make comparison.",
  "newWord.alertSelectedxRatio": "Select financial account(s) to make comparison.",
  "newWord.alertSelectedxAccount": "Select financial ratio(s) to make comparison.",
  "newWord.alertSelected8Chart": "The maximum number of charts per page is 8 charts. Please adjust the number of charts to generate other charts.",
  "newWorld.alert8LineRatio": "Select up to 8 ratios",
  "newWord.alert8Target": "Select up to 8 targets",
  "newWord.alertValidateChart":
    "Please adjust the number of items to be compared. Notice: In order to successfully generate a chart, the maximum number of items that could be selected is 6 items.",
  "newWord.compareCompaniesOne": "Select a group of companies to compare.",
  "newWord.compareCompaniesTWo": "Select two groups of companies to compare",
  "newWord.compareCompaniesGroup": "Select group(s) of companies to compare.",
  "newWord.compareCompaniesCompare": "Select companies to compare!",
  "newWord.compareCompaniesCompareGroup": "Select a business to group for comparison!",
  "newWord.compareCompanies": "Select companies with the same industry.",
  "newWord.alertUpdate": "Are you sure that you want to update?",
  "newWord.selectedCompanies": "Total of selected companies in the group(s)",
  "newWord.AllcompanieInGroup": "Total of all companies in the group(s)",
  "newWord.selectedCompaniesAve": "Average(s) of selected companies in the group(s)",
  "newWord.AllcompanieInGroupAve": "Average(s) of all companies in the group(s)",
  "newWord.listCompanies": "List of companies",
  "newWord.companiesname": "Company name",
  "newWord.AlertRemoveChart": "Are you sure that you want to delete the chart(s)?",
  "newWord.AlertRemoveCompaniesList": "Are you sure that you want to take the company(ies) out of the group?",
  "newWord.AlertDataNotAvailable": "The data is currently not available !",
  "newWord.ContactViracInfo": "Please contact VIRAC for further information.",
  "newWord.ChooseComparison": "Please choose the criteria for comparison.",
  "newWord.AskQuestion": "Frequently asked questions",
  "newWord.InputQuestion": "Input question(s)...",
  "newWord.Headquater": "Headquater",
  "newWord.representativeOffice": "Representative office in Ho Chi Minh City",
  "newWord.representativeOfficeSing": "Representative office in Singapore",
  "newWord.researchStock": "Viet Nam Industry research and consultancy joint stock company",
  "newWord.mostRead": "Most read",
  "newWord.dataRequested": "No data is requested.",
  "newWord.pleaseInput": "Please input password.",
  "newWord.confirmationPassMathch": "Password and confirmation password do not match.",
  "newWord.resetPass": "Password changed",
  "newWord.registerSuccess":
    "Sign up successfully. Please check your email to receive the system login password. You will be redirected to the login page after",
  "newWord.registerFail": "Registration failed. Please try again later",

  "newWord.agree": "Agree",

  "newWord.orderSent": "Your order has been sent!",
  "newWord.newsVirac": "VIRACE News",
  "newWord.noteRequest": "Note for request No.",
  "newWord.saveNote": "Save note",
  "newWord.InputContent": "Input Content",
  "newWord.listVSIC": "List of VSIC Industry Code 2019",
  "newWord.IndustrySystem": "Vietnam Economic Industry System",
  "newWord.oldPassIncorrect": "The old password is incorrect!",
  "newWord.deleteNotification": "Delete notification failed. Please reload the page!",
  "newWord.AlertNotification": "No notification ",
  "newWord.NoFinancial": "No financial statements yet!",
  "newWord.SelectEquivalent": "Select one of the equivalent criteria!",
  "newWord.Alert6LineOnChart":
    "The system only represents a maximum of 6 lines on a chart. Please adjust the number of comparison objects such as the number of metrics or criteria",
  "newWord.AlertSelectProduct": "Select a product to compare! ",
  "newWord.Changelanguage": "Change language ?",
  "newWord.IndustryFound": "No industry code has been found!",
  "newWord.companyNotFound": "No company found !",
  "newWord.companyNotFoundVSIC": "Please input the industry name or VSIC to search !",
  "newWord.financialGrowth": "Company financial growth",
  "newWord.listOwnerpercentage": "Companies which the owners held ownership percentage(s)",
  "newWord.notFindRequest": "Could not find requested data history!",
  "newWord.deleteDataRequest": "Are you sure that you want to delete this data request?",
  "newWord.updatePeriod": "Updated",
  "newWord.upgradeAccount": "Please upgrade your account!",
  "newWord.loginAccountRequire": "Please login to use this function!",

  "textChart.unit": "Unit: VND",
  "textChart.unitValue": "Consumption value (Million VND)",

  "newWord.addressHeadquater": "6th floor Anh Minh Building, 36 Hoang Cau Str., Dong Da Dis., Ha Noi",
  "newWord.addressOffice": "4th Floor, Tuan Minh Building, 21 Huynh Tinh Cua, Vo Thi Sau Ward, District 3, Ho Chi Minh City",

  "newWord.logoutSystem": "The system is logout",
  "newWord.downloadReportSample": "Download the sample report",

  "newWord.chooseLang": "Select language",
  "newWord.selectIndustry": "Select Industry",
  "newWord.selectYear": "Select year",
  "newWord.selectTypeReport": "Select a report type",
  "newWord.selectQuarter": "Select quarter",

  "newWord.selectQuarterTable": "Quarter",
  "newWord.selectTypeReportTable": "Report type",
  "newWord.selectLangReportTable": "Language",
  "newWord.selectYearTable": "Year",

  "newWord.QuarterO": "Quarter 1",
  "newWord.QuarterT": "Quarter 2",
  "newWord.QuarterTh": "Quarter 3",
  "newWord.QuarterF": "Quarter 4",

  "newWord.Regular": "Regular",
  "newWord.Credit": "Credit",
  "newWord.Undefined": "Undefined",
  "newWord.Annual": "Annual",
  "newWord.SemiAnnual": "Semi - Annual",
  "newWord.amountCompanies": "Results",
  "newWord.companies": "enterprises",
  "newWord.categoryFilter": "Sector",
  "newWord.yearFilter": "Year",

  "newWord.noteCart": "Input your massage",
  "newWord.downloadSearchResult": "Download search result",
  "newWord.downloadStatisticResult": "Download Statistic result",

  "marcro.exchange.rate.currency": "Foreign Exchange Rates",
  "marcro.exchange.rate.petroleum": "Gasoline price",
  "marcro.exchange.rate.gold": "Gold price",
  "marcro.exchange.rate.price.list": "Price list",
  "marcro.exchange.rate.price.list.date": "Date",

  suffixD: " VND",
  suffixK: " Thousand VND",
  suffixM: " Million VND",
  suffixB: " Billion VND",
  suffixT: " Thousand Billions VND",

  suffixKP: " Thousand VND",
  suffixMP: " Million VND",
  suffixBP: " Billion VND",
  suffixTP: " Thousand Billions VND",

  suffixUSD: " USD",
  suffixKUSD: " Thousand USD",
  suffixMUSD: " Million USD",
  suffixBUSD: " Billion USD",
  suffixTUSD: " Thousand Billions USD",

  // footer
  "footer.confidentiality": "Confidentiality",
  "footer.disclaimer": "Disclaimer",
  "footer.hotline": "Hotline",
  "footer.confidentiality.text1": `CONFIDENTIAL - THIS INFORMATION IS PROVIDED BY VIRAC JSC SUBJECT TO THE TERMS AND CONDITIONS OF YOUR SUBSCRIPTION CONTRACT AND IS NOT TO BE DISCLOSED.`,
  "footer.confidentiality.text2": `Right to use, cite and extract data and information: `,
  "footer.confidentiality.text3": `-	VIRAC agrees to Client/User a non – exclusive and non – transferable right to cite, post, copy, organize, translate data and information, create derivative products from the data and information including market, industry or business analysis reports, notes, compilations, studies or similar products for Client’s internal use only. However, in all cases, Client/User is required to quote the source when using any information, data, image from VIRACE – Platform and VIRACE Consolidate Company Analysis Report, for example "Source: VIRAC" or "Source: VIRACE".`,
  "footer.confidentiality.text4": `-	Client/User is prohibited to use, copy, share, disclose or provide either orally or in writing or any other method, in whole or in part, any data, information, image, picture, content, function, promotion, contract, trademarks, brand, trade names, service marks, data sources, user guide/user manual, or any other hard or soft document of VIRAC, VIRACE – Platform or VIRACE Consolidate Company Analysis Report for purposes other than those internal purposes stated above, including spreading information to the public or any third party through any channel and by any method, and/or creating content for news, reviews, publications, releases, or similar products about VIRACE – Platform, VIRAC, and VIRACE Consolidate Company Analysis Report for internal use or any third party without written consent from VIRAC.`,
  "footer.disclaimer.text1": `Copyright 2020 VIRAC JSC. All rights reserved. `,
  "footer.disclaimer.text2": `All data and information in VIRACE – Platform and in this report has been prepared on the basis of information believed to be reliable at the time of releases. VIRAC JSC makes no representation or warranty regarding the completeness and accuracy of such information. Opinions, estimates and projection expressed in the report represent the current views at the date of publication only. They do not necessarily reflect the opinion of VIRAC JSC and are subject to change without notice.`,
  "footer.disclaimer.text3": `This report is provided, for information purposes only, to institutional investor and retail clients of VIRAC JSC, and does not constitute an offer or solicitation to buy or sell any assets and/or equity discussed herein in any jurisdiction. Clients must make their decisions based upon independent advice subject to their particular financial situation and investment objectives. This report may not be copied, reproduced, published or redistributed by any person for any purpose without the written permission of an authorized representative of VIRAC JSC. Please cite sources when quoting.`,

  // Api link tygia.com
  "Api.Ngoaite":
    "https://tygia.com/api.php?column=1&title=0&chart=0&gold=0&rate=1&ngoaite=usd,jpy,chf,eur,gbp,hkd&expand=1&color=eeffee&titlecolor=111111&bgcolor=&upcolor=00aa00&downcolor=bb0000&textcolor=333333&nganhang=VIETCOM&fontsize=120&ngay=&column=2&locale=en-us",
  "Api.Vang": "https://tygia.com/api.php?column=1&title=0&chart=0&gold=1&rate=0&expand=2&color=284C84&nganhang=VIETCOM&fontsize=120&change=0&locale=en-us",
  "exchange.rate": "Exchange Rate",

  // main menu
  "mainMenu.company1": "company",
  "mainMenu.company2": "analysis ",
  "mainMenu.industry1": "industry",
  "mainMenu.industry2": "analysis ",

  // auth
  "auth.login": "Login ",
  "auth.register": " Register",

  "auth.forgotPass": "Forgot password",
  "auth.changePass": "Change password",
  "auth.remember": "Remember",
  "auth.password": "Password",
  "auth.repassword": "Confirm password",

  "auth.forgotPass.text": "Please enter your registered email for the system to authenticate and issue a new password",
  "auth.forgotPass.send": "Send request",
  "auth.enterEmail": "Enter email",
  "auth.enterPresenter": "References Email",
  "auth.existPresenter": "Referrer email does not exist!",

  "auth.enterPhone": "Enter phone number",
  "auth.enterFirstName": "First name",
  "auth.enterLastName": "Last name",
  "auth.fullName": "Fullname",

  "auth.enterCompany": "Company / Place of work",
  "auth.enterNewPass": "Password",
  "auth.enterNewPassAgain": "Password re-authentication",
  "auth.forgotPass.checkMail": "VIRACE has sent an email. Please access your email to complete the password change.",
  "auth.changePassFail": "Password change failed",
  "auth.haveAcount": "Already have an account?",
  "auth.notHaveAcount": "No account?",
  "auth.wrongPassword": "Password is incorrect.",

  "auth.wrongAccount_p1": "Account ",
  "auth.wrongAccount_p2": " does not exist.",
  "auth.lockedAccount_p1": "Account locked due to wrong password 5 times in a row. Please contact the hotline ",
  "auth.lockedAccount_p2": " or email to ",

  // error
  "error.phoneRequired": "Phone number can not be blank.",
  "error.phoneFormatted": "Invalid Phone number.",
  "error.firstNameFormatted": "Invalid First Name.",
  "error.firstNameRequired": "First Name can not be blank.",
  "error.lastNameRequired": "Last Name can not be blank.",
  "error.lastNameFormatted": "Invalid Last Name.",
  "error.companyFormatted": "Invalid Company/Workplace.",

  "error.emailRequired": "Email can not be blank.",
  "error.emailFormatted": "Invalid email.",
  "error.presenterFormatted": "Invalid References Email.",

  "error.passRequired": "Password can not be blank.",
  "error.passMinLength": "Password must be at least 8 characters",
  "error.passFormatted": "Password must contain upper and lower case, numbers and other characters at the same time and must not contain spaces",
  "error.confirmPassRequired": "Confirm password can not be blank.",
  "error.confirmPassMatch": "Password is incorrect.",
  "error.duplicateEmail": "Email already existed.",
  "error.duplicateEmailWarning_p1": "Email has been used to register an account, you can ",
  "error.duplicateEmailWarning_p2": "login ",
  "error.duplicateEmailWarning_p3": "or ",
  "error.duplicateEmailWarning_p4": "password recovery ",
  "error.duplicateEmailWarning_p5": "to continue using.",
  "error.wrongPresenter": "References Email does not exist.",

  // breadcrumbs
  "breadcrumb.home": "Home",
  "breadcrumb.menu-dieu-huong": "Navigation menu",
  "breadcrumb.page-chu-cr": "CR homepage",
  "breadcrumb.page-chu-if-ir": "IF-IR homepage",
  "breadcrumb.chi-tiet-business-business": "Business details",
  "breadcrumb.ket-qua-tra-cuu-thong-tin-enterprise": "Search results of business information",
  "breadcrumb.tim-kiem-list-sach-business-nghiep": "Search for business listings",
  "breadcrumb.ket-qua-tra-cuu-thong-tin-nganh": "Industry information lookup results",
  "breadcrumb.list-book-bao-cao-nganh": "List of industry reports",
  "breadcrumb.quan-li-tai-khoan": "Account management",
  "breadcrumb.cart": "Report of interest",

  viewMap: "View map",
  goHomePage: "Go to homepage",
  "search.notfound.title": "Warning",
  "search.notfound.text": "Your search did not match any documents. Please try again!",
  "search.notfound.text-1": "Your search did not match any documents.",
  "search.notfound.text-2": "Please try again!",
  "notfound.text": "No match found",

  "company.downloadReport": "Download business report",
  copyright: "Copyright",
  company: "Company",
  selected: "Selected!",

  "report.company": "Company report",
  "report.industry": "Industries Reports",
  "report.CompanySnapshot.title": "Company Snapshot Report",
  "report.CompanySnapshot.content": "Basic information of a company plus some key indicators and operational information. ",
  "report.CompanyExperditious.title": "Company experditious report",
  "report.CompanyExperditious.content":
    "Basic information, together with multi-dimensional view about a company, including financial analysis, ownership structure, import - export information and other ratios.",
  "report.StandardCompany.title": "Standard Company Report",
  "report.StandardCompany.content": "A thorough evaluation of the company's operation, markets, key business risks, prospects and financial health.",
  "report.ComprehensiveCompany.title": "Comprehensive Company Report",
  "report.ComprehensiveCompany.content":
    "The most comprehensive, multi-dimensional view about a company, including key developments, ownership structure, industry analysis, commentary and other data. ",
  "report.template": "Sample file",
  reportFromVirac: "Financial report source Virac",

  // advance search
  "advanceSearch.title": "Search for business listings",
  "advanceSearch.area": "Area",
  "advanceSearch.industry": "Industry",
  "advanceSearch.companyType": "Business type",
  "advanceSearch.ownership": "Property/management",
  "advanceSearch.finance": "Finance",
  "advanceSearch.importExport": "Import/Export",

  "advanceSearch.info": "Category of information",
  "advanceSearch.selectSearchTerms": "Select search terms",
  "advanceSearch.searchTerms": "Search terms",
  "advanceSearch.addTerms": "Add",
  "advanceSearch.area.addTermsCondition": "Invalid area or invalid data point",
  "advanceSearch.area.addTermsMax": "You can only choose up to 10 searching criterias",
  "advanceSearch.area.addTermsMax2": "You can only choose up to 2 searching criterias",
  "advanceSearch.area.addTermsMax5": "You can only choose up to 5 searching criterias",
  "advanceSearch.area.dataPoint": "Data point",
  "advanceSearch.finance.dataPoint": "Fiscal year",
  "advanceSearch.importExport.dataPoint": "Type",
  "advanceSearch.importExport.import": "Import",
  "advanceSearch.importExport.export": "Export",
  "advanceSearch.importExport.value": "Value",
  "advanceSearch.importExport.addCriteria": "Add value",

  "advanceSearch.importExport.majorGoods": "Major goods",
  "advanceSearch.importExport.hsCode": "HS Code",
  "advanceSearch.importExport.requiredType": "Please select at least 01 type!",
  "advanceSearch.importExport.requiredValueOrHs": "Value or Major goods must not be blank.",

  "advanceSearch.area.year": "Year",
  "advanceSearch.area.latestQuater": "Latest Quarter",
  "advanceSearch.area.area": "Area",
  "advanceSearch.area.add": "Add area",
  "advanceSearch.companyType.companyType": "Business Type",
  "advanceSearch.legalRepresentative": "Legal representative",
  "advanceSearch.legalRepresentative.placeHoder": "Enter search information (ID card/Passport/Name of legal representative)",
  "advanceSearch.owner": "Owner",
  "advanceSearch.owner.placeHoder": "Enter search information (ID card/Passport/Tax code/Name of owner)",
  "advanceSearch.province": "Province",
  "advanceSearch.district": "District",
  "advanceSearch.commune": "Commune",
  "advanceSearch.term": "Condition",
  "advanceSearch.downloadCompany": "Download list",
  "advanceSearch.industry.list": "Industry list",
  "advanceSearch.companyTypes.list": "List of business types",

  "advanceSearch.industry.addTermsCondition": "Invalid vsic code or invalid data point",
  "advanceSearch.typeCompany.addTermsCondition": "Invalid business types or invalid data point",

  "advanceSearch.finance.from": "From",
  "advanceSearch.finance.to": "To",
  "advanceSearch.finance.criteria": "Account/Ratio",
  "advanceSearch.finance.addCriteria": "Add account/ratio",
  "advanceSearch.finance.addTermsCondition": "Invalid account/ratio or invalid fiscal year",
  "advanceSearch.finance.required": "Please enter a valid from or to value!",
  "advanceSearch.no-searching-criteria": "No searching criteria",

  "crhome.table1": "Last viewed",
  "crhome.chart1": "Net revenue of top companies in the most visited industries in the last 30 days",
  "crhome.chart2": "Enterprise by the size of the most visited industries in the last 30 days",
  "crhome.chart3": "Enterprise by the size of the most visited industries in 30 days by Virace users",
  "crhome.netRevenue": "Net revenue",
  "crhome.netRevenueGrowthRate": "Net revenue growth rate",
  "crhome.5yRevenueCAGR": "5y - Revenue CAGR",
  "crhome.3yRevenueCAGR": "3y - Revenue CAGR",
  "crhome.vsic": "VSIC code",

  "vsicList.code": "VSIC code",
  "vsicList.industryName": "Vietnamese industry name",
  "vsicList.industryEnName": "English industry name",

  "virac.businessRegistration": "Certificate of business registration: No.0106972372",
  "virac.businessRegistrationBy": "By Hanoi Business Registration Office on August 31, 2015",
  "virac.follow": "Follow VIRAC:",

  companyReportFileName: "Enterprise_report.pdf",

  "industry.filter.all": "All",
  "industry.filter.statistic": "Statistics",
  "industry.filter.report": "Reports",
  "industry.filter.input": "Input search keywords",
  "industry.filter.input.minLength": "Please search for at least 2 characters",
  "industry.view": "Go",
  "industry.statistic.basic": "Basic Statistics",
  "industry.statistic.SO_DOANH_NGHIEP": "Number of enterprise",
  "industry.statistic.SO_LAO_DONG": "Number of labor",
  "industry.statistic.NGUON_VON_BINH_QUAN": "Average capital",
  "industry.statistic.TAI_SAN_CO_DINH_VA_DAU_TU_DAI_HAN_BINH_QUAN": "Average of fixed assets and long-term investment",
  "industry.statistic.DOANH_THU_THUAN": "Net revenue",
  "industry.statistic.LOI_NHUAN_TRUOC_THUE": "Profit before tax",
  "industry.statistic.THUE_VA_CAC_KHOAN_NOP_NGAN_SACH": "Taxes and amounts already paid to the state budget",
  "industry.statistic.advance": "Advanced Statistics",
  "industry.statistic.SAN_XUAT": "Production",
  "industry.statistic.TIEU_THU": "Consumption",
  "industry.statistic.TON_KHO": "Inventory",
  "industry.report.more": "More reports",
  "industry.statistic.tab.data": "Data",
  "industry.statistic.tab.relatedRatio": "Related ratio",
  "industry.statistic.tab.relatedReport": "Related report",
  "industry.statistic.tab.table": "Table",
  "industry.statistic.tab.chart": "Chart",
  "industry.statistic.tab.addVSIC": "Add VSIC",
  "industry.statistic.typeList.relative": "YOY, %",
  "industry.statistic.typeList.absolute": "Values",
  "industry.statistic.typeList.cagr": "CAGR 5 years",
  "industry.statistic.total": "Total",
  "industry.statistic.average": "Average",
  "industry.statistic.relative": "Relative",
  "industry.statistic.table.type": "Statistic",
  vsicId: "VSIC code",
  vsicName: "VSIC name",
  unit: "Unit",
  "industry.statistic.sortAZ": "Sort A-Z",
  "industry.statistic.sortZA": "Sort Z-A",
  "industry.statistic.clearFilter": "Clear filter",
  "industry.statistic.input": "Search...",
  "industry.statistic.add-vsic-max": "Exceeded the number of VSIC codes allowed",
  "industry.statistic.add-product-max": "Exceeded the number of product codes allowed",
  "industry.statistic.product-code": "Product code",
  "industry.statistic.product-des": "Product description",
  "industry.statistic.product": "Product",
  "industry.statistic.chart.vsic-min": "Select at least 01 VSIC code",
  "industry.statistic.chart.vsic-max": "Maxinum 03 VSIC codes allowed",
  "industry.statistic.chart.statistic-min": "Select at least 01 data type",
  "industry.statistic.chart.year-min": "Select at least 01 year",
  "industry.statistic.chart.year-max": "Maxinum 05 years allowed",
  "industry.report.menu": "Index",
  "industry.report.chapter": "Chapter",
  "industry.statistic-advance.chart.vsic-min": "Number of products * number of area at least 1",
  "industry.statistic-advance.chart.vsic-max": "Number of products * number of area up to 3",
  "industry.statistic.chart.product-min": "Select at least 1 product",
  "industry.statistic.chart.area-min": "Select at least 1 area",

  "industry.statistic.tabRatio.GDP": "GDP (Trillion VND)",
  "industry.statistic.tabRatio.Paddy": "Paddy",
  "industry.statistic.tabRatio.INDUSTRIAL_PRODUCTION": "INDEX OF INDUSTRIAL PRODUCTION (%)",
  "industry.statistic.tabRatio.TOTAL_SALES": "TOTAL SALES OF GOODS AND CONSUMER SERVICES REVENUE (Trillion VND)",
  "industry.statistic.tabRatio.TRANSPORTATION": "TRANSPORTATION OF PASSENGERS AND GOODS",
  "industry.statistic.tabRatio.INVESMENT": "INVESMENT (Trillion VND)",

  "industry.downloadReport": "Download",
  "industry.reportNoContent": "Report has no content",
  "cart.no-data": "Your cart is currently empty",
  "cart.no-selected": "Please select an object",
  "cart.is-company": "Only available reports can purchase",
  "advance-search.company.tooltip.value": "Values",
  "industry.report.chapter-not-found": "This chapter does not exist. Please check again."
};
