import React, { Component } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import LoaderTable from "../../../components/LoaderTable";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { connect } from "react-redux";
import AlertWarning from "../../../components/SweetAlert/AlertWarning";
import {
    actRequestedLang
} from "../../../redux/actions";
export class TabContentTabInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleForgetPass: false,
            radio: false
        };
    }

    handlePopupForgetPass = () => {
        this.setState(state => ({
            toggleForgetPass: !state.toggleForgetPass
        }));
    };

    handleCloseForgetPass = prams => {
        this.setState({
            toggleForgetPass: prams
        });
    };

    onChange = (params) => {
        this.actChangeLang(params)
    };
    actChangeLang = (params) => {
        const { messages } = this.props.intl;
        this.setState({
            alert: (
                <AlertWarning
                    message={messages[`newWord.Changelanguage`]}
                    confirmBtnText={messages[`user.Confirm`]}
                    cancelBtnText={messages[`user.Cancel`]}
                    title={messages[`user.Confirm`]}
                    confirmToFunc={() => {
                        this.props.actRequestedLang(params)
                        this.handleHideAlert()
                    }}
                    cancleToFunc={this.handleHideAlert}
                />
            )
        });
    };
    handleHideAlert = () => {
        this.setState({
            alert: ""
        });
    };
    render() {
        const { userAccount, loadingUser } = this.props;
        return (
            <React.Fragment>
                <div className="manament-tabplane-info">
                    {this.state.alert}
                    {loadingUser ? (
                        <LoaderTable
                            styleCustom={{ height: "80vh" }}
                        />
                    ) : (
                        <React.Fragment>
                            <div className="manament-tabplane-info-name">
                                {userAccount.fullName
                                    ? userAccount.fullName
                                    : ""}
                            </div>
                            <div className="padding-content">
                                <div className="manament-tabplane-info-job">
                                    <span className="material-icons">domain</span>
                                    <p>
                                        {userAccount.companyName
                                            ? userAccount.companyName
                                            : ""}
                                    </p>
                                </div>
                                <div className="manament-tabplane-info-phone">
                                    <span className="material-icons">phone</span>
                                    <p>
                                        {userAccount.phoneNumber
                                            ? userAccount.phoneNumber
                                            : ""}
                                    </p>
                                </div>
                                <div className="manament-tabplane-info-mail">
                                    <span className="material-icons">email</span>
                                    <p>
                                        {userAccount.email ? userAccount.email : ""}
                                    </p>
                                </div>
                                <div className="manament-tabplane-info-lang">
                                    <label><IntlMessages id="user.lang" /></label>
                                    <FormGroup check style={{ marginLeft: "20px" }}>
                                        <Label check className="container">
                                            <Input
                                                type="radio"
                                                name="radio"
                                                onChange={() => this.onChange("en")}
                                                checked={userAccount.lang && userAccount.lang === "en" ? true : false}
                                                value={
                                                    userAccount.lang && userAccount.lang
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <IntlMessages id="user.langEN" />
                                            <span className="checkmark"></span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check className="ml-3">
                                        <Label check className="container">
                                            <Input
                                                type="radio"
                                                name="radio"
                                                onChange={() => this.onChange("vi")}
                                                checked={userAccount.lang && userAccount.lang === "vi" ? true : false}
                                                value={
                                                    userAccount.lang && userAccount.lang
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <IntlMessages id="user.langVN" />
                                            <span className="checkmark"></span>
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actRequestedLang: (lang) => {
            dispatch(actRequestedLang(lang));
        },
    };
};
export default connect(
    null,
    mapDispatchToProps
)(injectIntl(TabContentTabInfo));