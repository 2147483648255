var HOST = "";
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(/^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
) {
  // HOST = `https://gateway-api-develop.virace.vn/api/`;
  HOST = `https://gateway-api.virace.vn/api/`;

  //HOST = `https://localhost:44397/api/`;
} else if (window.location.host === "dev-mm.virace.vn") {
  HOST = `https://gateway-api-develop.virace.vn/api/`;
} else if (window.location.host === "mm.virace.vn") {
  HOST = `https://gateway-api.virace.vn/api/`;
} else {
  // HOST = `https://gateway-api-develop.virace.vn/api/`;

  HOST = `https://gateway-api.virace.vn/api/`;
}

export default HOST;
