import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
// import indexRoutes from "./routes/index.jsx";
import { createBrowserHistory } from "history";
import { IntlProvider } from "react-intl";
import AppLocale from "./lang";

import LoginPage from "./views/Pages/LoginPage";
import RegisterPage from "./views/Pages/RegisterPage";

import LogoutPage from "./views/Pages/LogoutPage";
import LayoutApp from "./layout/LayoutApp";
import ForgotPassword from "./views/Pages/ForgotPassword";
import ChangeNewPassword from "./views/Pages/ChangingPassword";
import Contacts from "./views/Contacts/Contacts";
import Faqs from "./views/Faqs/Faqs";
import viLang from "./lang/entries/vi-VN";

// import { getCookie } from "./utils/cookies";
// import ReactGA from "react-ga";
// import ReactGA from "react-ga4";
// let GA_KEY = "";
// if (window.location.host.includes("virace.viracresearch")) {
//   GA_KEY = `G-ENHR4NCQF5`;
// } else {
//   GA_KEY = `G-VWVXD0EW87`;
// }

// ReactGA.initialize(GA_KEY);
const hist = createBrowserHistory();
// hist.listen((location, action) => {
//   ReactGA.send(location.pathname + location.search);
// });
const AuthRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { locale } = this.props;
    const currentAppLocale = locale ? AppLocale[locale] : AppLocale["vi"];
    return (
      <React.Fragment>
        <IntlProvider
          locale={currentAppLocale ? currentAppLocale.locale : viLang.locale}
          messages={currentAppLocale ? currentAppLocale.messages : viLang.messages}
        >
          <React.Fragment>
            <Router history={hist}>
              <Switch>
                {/* <Route path="/dang-nhap" component={LoginPage} />
                <Route path="/dang-ki" component={RegisterPage} />

                <Route path="/dang-xuat" component={LogoutPage} />
                <Route path="/quen-mat-khau" component={ForgotPassword} />
                <Route path="/confirmtoken" component={ChangeNewPassword} /> */}
                {/* <Route path="/faqs" component={Faqs} key="faqs" exact={true} />
                <Route path="/faqs/detail" component={Faqs} key="faqs-detail" exact={true} /> */}
                <Route path="/lien-he" component={Contacts} />
                {/* <Route path="/ket-qua-tra-cuu-thong-tin-doanh-nghiep" component={Contacts} />

                <Route path="/chi-tiet-doanh-nghiep/:id" component={Contacts} /> */}
                <AuthRoute path="/" component={LayoutApp} />
              </Switch>
            </Router>
          </React.Fragment>
        </IntlProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.settings.locale
  };
};

export default connect(mapStateToProps)(App);
