export function numberFormat(amount, decimal = ",", thousands = ".") {
  try {
    return amount
      .toString()
      .replace(thousands, decimal)
      .replace(/\B(?=(\d{3})+(?!\d))/g, thousands);
  } catch (e) {
    // Todo err
  }
}
