import React, { Component } from "react";
import { CompanyItem } from "./CompanyChild/CompanyItem.jsx";
import Pagination from "../../components/Pagination/PaginationSimple";
import { connect } from "react-redux";
import getUrlParam from "../../utils/getUrlParam";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { formatMoney } from "../../utils/formatMoney";
import IntlMessages from "../../utils/IntlMessages";
import { callApiServer } from "../../utils/callApiServer";
import moment from "moment";
import { Spinner } from "reactstrap";
import { saveAs as importedSaveAs } from "file-saver";
import SweetAlert from "react-bootstrap-sweetalert";

export class CompanyInfoContent extends Component {
  state = {
    activePage: getUrlParam()["page"] ? getUrlParam()["page"] : 1,
    lang: "vi",
    // en-US

    isDownloading: false,
    alert: ""
  };
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => this.props.handlePushPaging(activePage));
  };

  actChangeSortMinMax = (e, { name, value }) => {
    this.props.handleSortMinMax(value, name);
  };

  handleDownLoad(txtsearch) {
    const { messages } = this.props.intl;

    this.setState({
      isDownloading: true
    });
    const params = {
      searchText: txtsearch,
      vdl: "",
      tts: "",
      ln: "",
      dt: "",
      kv: "",
      checkSameType: ""
    };
    callApiServer
      .get("/Company/SearchCompany_v2/Export", { params: params })
      .then((res) => {
        if (res && res.data.data && res.data.statusCode === 200) {
          this.processDownloadData(res.data.data);
        } else if (res && res.data.statusCode === 429) {
          this.actAlert(messages[`newWord.overTurnDownload`]);
          this.setState({
            isDownloading: false
          });
        } else if (res && res.data.statusCode === 403) {
          this.actAlert(messages[`newWord.notPermision`]);
          this.setState({
            isDownloading: false
          });
        } else {
          this.actAlert(messages[`newWord.somthingWrong`]);
          this.setState({
            isDownloading: false
          });
        }
      })
      .catch((err) => {
        this.actAlert(messages[`newWord.somthingWrong`]);
        this.setState({
          isDownloading: false
        });
      })
      .finally();
  }

  processDownloadData(urlFile) {
    const dateNow = Date.now();
    const dateNowFomated = moment(dateNow).format("YYMMDD");
    const fileName = `Result_${dateNowFomated}.xlsx`;
    const urlDownload = `/Company/SearchCompany/download-file-export?pathFile=${urlFile}`;
    callApiServer
      .get(urlDownload, {
        responseType: "blob"
      })
      .then((res) => {
        if (res && res.data) {
          importedSaveAs(res.data, fileName);
        }

        this.setState({
          isDownloading: false
        });
      });
  }

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };
  actAlert = (messagex) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages["newWord.notice"]}
          // showCancel
          confirmBtnText={messages["newWord.oke"]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {messagex}
        </SweetAlert>
      )
    });
  };

  render() {
    const { txtsearch } = this.props;
    const {
      totalItems,
      // pageIndex,
      companyData,
      totalPage
    } = this.props.companyReducer;
    const { activePage, isDownloading } = this.state;
    const { messages } = this.props.intl;
    return (
      <React.Fragment>
        {this.state.alert}
        <div className="company-info-content mb-4">
          <div className="company-info-content-main card-item">
            <div className="search-result-heading pb-3">
              <p className="search-result-title">{`${messages["newWord.amountCompanies"]} (${formatMoney(totalItems)} ${messages["newWord.companies"]})`}</p>
              {isDownloading ? (
                <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
              ) : (
                <div
                  className="btn-export-data text-cyan"
                  onClick={() => {
                    this.handleDownLoad(txtsearch);
                  }}
                >
                  <em className="material-icons">download</em>
                  <IntlMessages id="newWord.downloadSearchResult" />
                </div>
              )}
            </div>
            <div className="search-result-list">
              <div className="row">
                {companyData &&
                  companyData.length > 0 &&
                  companyData.map((props, index) => {
                    return (
                      <CompanyItem
                        {...this.props}
                        key={index}
                        name={props.name}
                        nameEnglish={props.nameEnglish}
                        id={props.id}
                        taxNumber={props.taxNumber}
                        vsic={props.vsic}
                        address={props.address}
                        phoneNumber={props.phoneNumber}
                        fax={props.fax}
                        webSite={props.webSite}
                        vsicID={props.vsicId}
                        vsicName={props.vsicName}
                        district={props.district}
                        province={props.province}
                        enNetProfitLabel={props.enNetProfitLabel}
                        enNetRevenueLabel={props.enNetRevenueLabel}
                        netProfitLabel={props.netProfitLabel}
                        netRevenueLabel={props.netRevenueLabel}
                        netProfitValue={props.netProfitValue}
                        netRevenueValue={props.netRevenueValue}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="foot-pagination">
              <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyReducer: state.companyReducer,
    settings: state.settings
  };
};

export default connect(mapStateToProps, null)(injectIntl(withRouter(CompanyInfoContent)));
