import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as industryInformationService from "../../services/industryInformation.service";
import * as Types from "../actions";
import * as actions from "./actions";
import { actNotify } from "../Fetch/actions";
import { redirectURL } from "../../utils/logoutCommon";
const language = localStorage.getItem("currentLanguage");

//TỔNG QUAN NGÀNH
function* watchGetVSICInformation() {
  yield takeEvery(Types.GET_INDUSTRY_INFORMATION, getVSICInformation);
}

function* getVSICInformation(action) {
  try {
    const { model } = action.payload;
    var response = yield call(
      industryInformationService.actGetVSICInformationApi,
      model
    );
    if (response.data.success) {
      const { data } = response.data;
      yield put(actions.getVSICInformationSuccess(data));
    } else if(response.data.statusCode === 203) {
      let notify = {
        message: response.data.message,
        color: "danger",
      };
      yield put(actNotify(notify));
      setTimeout(function() {
        redirectURL();
      }, 4000);
    }
    else {
      yield put(actions.getVSICInformationFail(response.data.message))
    }
  } catch (err) {
    yield put(actions.getVSICInformationFail(err.message));
  }
}

function* watchGetTopVSIC() {
  yield takeEvery(Types.GET_TOP_VSIC, getTopVSIC);
}
//autocomplete nganh
function* getDataAutocomplete(action) {
  try {
    const { model } = action.payload;
    var response = yield call(
      industryInformationService.actAutocomplete,
      model
    );
    if (response.data) {
      const { data } = response.data;
      yield put({
        type: Types.SEARCH_AUTOCOMPLETE_INDUSTRY_SUCCESS,
        data
      });
    }
    else {
      yield put({
        type: Types.SEARCH_AUTOCOMPLETE_INDUSTRY_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.SEARCH_AUTOCOMPLETE_INDUSTRY_FAIL
    });
  }
}

function* watchGetDataAutocomplete() {
  yield takeEvery(Types.SEARCH_AUTOCOMPLETE_INDUSTRY, getDataAutocomplete);
}

function* getTopVSIC() {
  try {
    var response = yield call(industryInformationService.actGetTopVSICApi);
    if (response.data) {
      const { data } = response.data;
      yield put(actions.getTopVSICSuccess(data));
    }
    else {
      yield put(actions.getVSICInformationFail(response.data.message))
    }
  } catch (err) {
    yield put(actions.getVSICInformationFail(err.message));
  }
}

//SẢN XUẤT - TIÊU THỤ - TỒN KHO
function* watchGetDataProduct() {
  yield takeEvery(Types.GET_DATA_PRODUCT_TO_COMPARE, getDataProductToCompare);
}

function* getDataProductToCompare(action) {
  try {
    const { model } = action.payload;
    var response = yield call(
      industryInformationService.actGetVSICInformationApi,
      model
    );
    if (response.data.success) {
      const { data } = response.data;
      yield put(actions.getDataProductToCompareSuccess(data));
    } else if(response.data.statusCode === 203) {
      let notify = {
        message: response.data.message,
        color: "danger",
      };
      yield put(actNotify(notify));
      setTimeout(function() {
        redirectURL();
      }, 4000);
    }
    else {
      yield put(actions.getVSICInformationFail(response.data.message))
    }
  } catch (err) {
    yield put(actions.getVSICInformationFail(err.message));
  }
}

function* watchSendDataProductToCompare() {
  yield takeEvery(Types.SEND_DATA_TO_COMPARE, sendDataToCompare);
}

function* sendDataToCompare(action) {
  try {
    const { model } = action.payload;
    var response = yield call(
      industryInformationService.actSendDataToCompareApi,
      model
    );
    if (response.data) {
      const { data } = response.data;
      yield put(actions.actGetDataChartExportIndustryInformationSuccess(data));
    } else {
      yield put(actions.getVSICInformationFail(response.data.message))
    }
  } catch (err) {
    yield put(actions.getVSICInformationFail(err.message));
  }
}

//CHỈ SỐ KINH TẾ NGÀNH
function* watchGetDataFinancial() {
  yield takeEvery(Types.GET_DATA_FINANCIAL_TO_COMPARE, getDataFinancialToCompare);
}

function* getDataFinancialToCompare(action) {
  try {
    const { model } = action.payload;
    var response = yield call(
      industryInformationService.actGetVSICInformationApi,
      model
    );
    if (response.data.success) {
      const { data } = response.data;
      yield put(actions.getDataFinancialToCompareSuccess(data));
    } else if(response.data.statusCode === 203) {
      let notify = {
        message: response.data.message,
        color: "danger",
      };
      yield put(actNotify(notify));
      setTimeout(function() {
        redirectURL();
      }, 4000);
    }
    else {
      yield put(actions.getVSICInformationFail(response.data.message))
    }
  } catch (err) {
    yield put(actions.getVSICInformationFail(err.message));
  }
}

function* watchSendDataFinancialToCompare() {
  yield takeEvery(Types.SEND_DATA_FINANCIAL_TO_COMPARE, sendDataFinancialToCompare);
}

function* sendDataFinancialToCompare(action) {
  try {
    const { model } = action.payload;
    var response = yield call(
      industryInformationService.actSendDataFinacialToCompareApi,
      model
    );
    if (response.data) {
      const { data } = response.data
      yield put(actions.getDataChartFinancialToCompareSuccess(data));
    }
    else {
      yield put(actions.getVSICInformationFail(response.data.message))
    }
  } catch (err) {
    yield put(actions.getVSICInformationFail(err.message));
  }
}

//SO SÁNH NGÀNH

function* watchGetDataCompare() {
  yield takeEvery(Types.GET_DATA_COMPARE_INDUSTRY_INFORMATION, getDataCompare);
}

function* getDataCompare(action) {
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.actGetVSICInformationApi, model);
    if (response.data.success) {
      const { data } = response.data;
      yield put(actions.getDataCompareSuccess(data));
    } else if(response.data.statusCode === 203) {
      let notify = {
        message: response.data.message,
        color: "danger",
      };
      yield put(actNotify(notify));
      setTimeout(function() {
        redirectURL();
      }, 4000);
    } else {
      yield put(actions.getVSICInformationFail(response.data.message))
    }
  } catch (err) {
    yield put(actions.getVSICInformationFail(err.message));
  }
}
//SS Trung Binh

function* actRequestSSCharte_TB(params) {
  let body = {
    vsicCode: params.body.vsicCode,
    arrHeSo: params.body.arrayRatio,
    arrChiTieu: params.body.arrayAccount,
    isCheck: params.body.isCheckTBN,
    arrKv: params.body.arrayKv
  };
  try {
    var response = yield call(
      industryInformationService.requestSoSanh_Nganh_TB,
      body
    );

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_CHART_SSNGANH_TB_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_CHART_SSNGANH_TB_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_CHART_SSNGANH_TB_FAIL
    });
  }
}
export function* watchRequestSSCharte_TB() {
  yield takeEvery(Types.REQUEST_CHART_SSNGANH_TB, actRequestSSCharte_TB);
}

//SS TGT

function* actRequestSSCharte_TGT(params) {
  let body = {
    vsicCode: params.body.vsicCode,
    arrHeSo: params.body.arrayRatio,
    arrChiTieu: params.body.arrayAccount,
    isCheck: params.body.isCheckTBN,
    arrKv: params.body.arrayKv
  };
  try {
    var response = yield call(
      industryInformationService.requestSoSanh_Nganh_TGT,
      body
    );

    if (response.data.data) {
      yield put({
        type: Types.REQUEST_CHART_SSNGANH_TGT_SUCCESS,
        data: response.data.data
      });
    } else {
      yield put({
        type: Types.REQUEST_CHART_SSNGANH_TGT_FAIL
      });
    }
  } catch (err) {
    yield put({
      type: Types.REQUEST_CHART_SSNGANH_TGT_FAIL
    });
  }
}
export function* watchRequestLiveChartDNK_TGT() {
  yield takeEvery(Types.REQUEST_CHART_SSNGANH_TGT, actRequestSSCharte_TGT);
}

//----------------------------------------------------------------------
// IR-IF
// Tra cứu thông tin ngành
function* actSearchIndustry(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustry, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.SEARCH_INDUSTRY_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.SEARCH_INDUSTRY_FAIL,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.SEARCH_INDUSTRY_FAIL,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActSearchIndustry() {
  yield takeEvery(Types.SEARCH_INDUSTRY, actSearchIndustry);
}

// DS báo cáo ngành
function* actSearchIndustryReport(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryReport, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.SEARCH_INDUSTRY_REPORT_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.SEARCH_INDUSTRY_REPORT_FAIL,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.SEARCH_INDUSTRY_REPORT_FAIL,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActSearchIndustryReport() {
  yield takeEvery(Types.SEARCH_INDUSTRY_REPORT, actSearchIndustryReport);
}

// Thống kê cơ bản - table
function* actStatisticIndustry(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryStatistic, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.STATISTIC_INDUSTRY_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.STATISTIC_INDUSTRY_FAIL,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.STATISTIC_INDUSTRY_FAIL,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActStatisticIndustry() {
  yield takeEvery(Types.STATISTIC_INDUSTRY, actStatisticIndustry);
}

// Thống kê nâng cao - table
function* actAdvanceStatisticIndustry(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryAdvanceStatistic, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.ADVANCE_STATISTIC_INDUSTRY_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.ADVANCE_STATISTIC_INDUSTRY_FAIL,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.ADVANCE_STATISTIC_INDUSTRY_FAIL,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActAdvanceStatisticIndustry() {
  yield takeEvery(Types.ADVANCE_STATISTIC_INDUSTRY, actAdvanceStatisticIndustry);
}

// ds năm để vẽ chart
function* actGetStatisticChartYear() {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    var response = yield call(industryInformationService.actGetStatisticChartYear);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.STATISTIC_INDUSTRY_SUCCESS_CHART_YEAR,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.STATISTIC_INDUSTRY_FAIL_CHART_YEAR,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.STATISTIC_INDUSTRY_FAIL_CHART_YEAR,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetStatisticChartYear() {
  yield takeEvery(Types.STATISTIC_INDUSTRY_CHART_YEAR, actGetStatisticChartYear);
}

// Thống kê cơ bản - chart
function* actStatisticIndustryChart(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryStatisticChart, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.STATISTIC_INDUSTRY_SUCCESS_CHART,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.STATISTIC_INDUSTRY_FAIL_CHART,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.STATISTIC_INDUSTRY_FAIL_CHART,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActStatisticIndustryChart() {
  yield takeEvery(Types.STATISTIC_INDUSTRY_CHART, actStatisticIndustryChart);
}

// Thống kê nâng cao - table
function* actAdvanceStatisticIndustryChart(action) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    const { model } = action.payload;
    var response = yield call(industryInformationService.searchIndustryAdvanceStatisticChart, model);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.ADVANCE_STATISTIC_INDUSTRY_SUCCESS_CHART,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.ADVANCE_STATISTIC_INDUSTRY_FAIL_CHART,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.ADVANCE_STATISTIC_INDUSTRY_FAIL_CHART,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActAdvanceStatisticIndustryChart() {
  yield takeEvery(Types.ADVANCE_STATISTIC_INDUSTRY_CHART, actAdvanceStatisticIndustryChart);
}

// ds ngành vsic
function* actGetVsicList() {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    var response = yield call(industryInformationService.getVsicList);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.VSIC_LIST_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.VSIC_LIST_FAIL,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.VSIC_LIST_FAIL,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetVsicList() {
  yield takeEvery(Types.VSIC_LIST, actGetVsicList);
}

// ds sản phẩm
function* actGetProductList() {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    var response = yield call(industryInformationService.getProductList);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.PRODUCT_LIST_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.PRODUCT_LIST_FAIL,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.PRODUCT_LIST_FAIL,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetProductList() {
  yield takeEvery(Types.PRODUCT_LIST, actGetProductList);
}

// chi tiết bc ngành
function* actGetIndustryReportDetail(body) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    var response = yield call(industryInformationService.getIndustryReportDeyail, body.reportId);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.INDUSTRY_REPORT_DETAIL_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.INDUSTRY_REPORT_DETAIL_FAIL,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.INDUSTRY_REPORT_DETAIL_FAIL,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetIndustryReportDetail() {
  yield takeEvery(Types.INDUSTRY_REPORT_DETAIL, actGetIndustryReportDetail);
}

// bc liên quan - DN liên quan
function* actGetRelatedReportAndCompany(body) {
  var notify_data = {
    message: language === "en" ? "Error! Please try again!" : "Có lỗi xảy ra! Vui lòng thử lại!",
    color: "danger",
  };
  try {
    var response = yield call(industryInformationService.getRelatedReportAndCompany, body.reportId);
    if (response.data.statusCode === 200) {
      yield put({
        type: Types.INDUSTRY_RELATED_REPORT_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: Types.INDUSTRY_RELATED_REPORT_FAIL,
      });
      yield put(actNotify(notify_data));
    }
  } catch (err) {
    yield put({
      type: Types.INDUSTRY_RELATED_REPORT_FAIL,
    });
    yield put(actNotify(notify_data));
  }
}
export function* watchActGetRelatedReportAndCompany() {
  yield takeEvery(Types.INDUSTRY_RELATED_REPORT, actGetRelatedReportAndCompany);
}
//----------------------------------------------------------------------

export default function* rootSaga() {
  yield all([
    fork(watchGetVSICInformation),
    fork(watchGetTopVSIC),
    fork(watchGetDataProduct),
    fork(watchSendDataProductToCompare),
    fork(watchGetDataFinancial),
    fork(watchSendDataFinancialToCompare),
    fork(watchGetDataCompare),
    fork(watchGetDataAutocomplete),
    fork(watchRequestLiveChartDNK_TGT),
    fork(watchRequestSSCharte_TB),

    //----------------------------------------------------------------------
    // IR-IF
    fork(watchActSearchIndustry),
    fork(watchActSearchIndustryReport),
    fork(watchActStatisticIndustry),
    fork(watchActAdvanceStatisticIndustry),
    fork(watchActStatisticIndustryChart),
    fork(watchActAdvanceStatisticIndustryChart),
    fork(watchActGetStatisticChartYear),
    fork(watchActGetVsicList),
    fork(watchActGetProductList),
    fork(watchActGetIndustryReportDetail),
    fork(watchActGetRelatedReportAndCompany)
    //----------------------------------------------------------------------
  ]);
}
