import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { actAdvanceStatisticIndustryChart, actGetStatisticChartYear } from "../../../redux/actions";
import { Dropdown, Menu } from "semantic-ui-react";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import getUrlParam from "../../../utils/getUrlParam";
import { advanceStatisticCriteria } from "../../../constants/industry";
import LoaderTable from "../../../components/LoaderTable";
import { Checkbox } from "semantic-ui-react";
import IntlMessages from "../../../utils/IntlMessages";
import Button from "../../../components/CustomButton/CustomButton";
import { copyArray } from "../../../utils/copyArray";
import ColumnChart from "../../../components/ChartDemo/ColumnChart";
import queryString from "query-string";
import { callApiServer } from "../../../utils/callApiServer";

class TabDataChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: "",
      selectedIndustry: [], // ds sản phẩm được chọn
      selectedArea: [], // ds khu vực được chọn
      txtsearch: getUrlParam()["q"] ? queryString.parse(this.props.location.search).q : "", // từ khóa tìm kiếm
      statistic: getUrlParam()["statistic"] ? getUrlParam()["statistic"].split(",") : [],
      selectedStatistic: [], // loại dữ liệu thống kê
      checkedAllStatistic: false,
      indeterminateStatistic: false,
      selectedDataPoint: [], // giai đoạn dữ liệu
      checkedAllDataPoint: false,
      indeterminateDataPoint: false,
      productAreaError: "",
      dataTypeError: "",
      yearError: ""
    };
  }

  componentDidMount() {
    // this.props.getDataPointList();
    this.getDataPointListAdvance();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dataPointList, statisticDataChart } = this.props.industryInformationReducer;
    // this.setState({ dataPointList });
    // đổi ngôn ngữ thì vẽ lại chart
    if (this.props.intl.locale !== nextProps.intl.locale && statisticDataChart && statisticDataChart.length) {
      this.handleApplyChart();
    }
  }

  async getDataPointListAdvance() {
    const { txtsearch } = this.state;
    try {
      const response = await callApiServer.get(`/IFReportStatistic/VsicStatisticChartGetYear/Advanced?textSearch=${txtsearch}`);
      if (response && response.data && response.data.data) {
        this.setState({
          dataPointList: response.data.data
        });
      } else
        this.setState({
          dataPointList: []
        });
    } catch (error) {
      this.setState({
        dataPointList: []
      });
    }
  }

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  // chọn sản phẩm
  handleChangeIndustry = (value) => {
    this.setState({
      selectedIndustry: value
    });
  };

  // chọn khu vực
  handleChangeArea = (value) => {
    this.setState({
      selectedArea: value
    });
  };

  // check all loại dữ liệu
  handleChangeCheckAllStatistic = () => {
    let { checkedAllStatistic } = this.state;
    const selectedStatistic = copyArray(advanceStatisticCriteria).map((i) => i.id);
    this.setState({
      checkedAllStatistic: !checkedAllStatistic,
      indeterminateStatistic: false,
      selectedStatistic: !checkedAllStatistic ? selectedStatistic : []
    });
  };

  // chọn loại dữ liệu thống kê
  handleChangeStatistic = (value) => {
    const { selectedStatistic } = this.state;
    const index = selectedStatistic.indexOf(value);
    if (index < 0) {
      selectedStatistic.push(value);
    } else {
      selectedStatistic.splice(index, 1);
    }
    this.setState({
      selectedStatistic: [...selectedStatistic],
      checkedAllStatistic: selectedStatistic.length === advanceStatisticCriteria.length,
      indeterminateStatistic: selectedStatistic.length < advanceStatisticCriteria.length && selectedStatistic.length > 0
    });
  };

  // check all giai đoạn dữ liệu
  handleChangeCheckAllDataPoint = () => {
    let { checkedAllDataPoint, dataPointList } = this.state;
    const selectedDataPoint = dataPointList.map((i) => i);
    this.setState({
      checkedAllDataPoint: !checkedAllDataPoint,
      indeterminateDataPoint: false,
      selectedDataPoint: !checkedAllDataPoint ? selectedDataPoint : []
    });
  };

  // chọn giai đoạn dữ liệu
  handleChangeDataPoint = (value) => {
    const { selectedDataPoint, dataPointList } = this.state;
    const index = selectedDataPoint.indexOf(value);
    if (index < 0) {
      selectedDataPoint.push(value);
    } else {
      selectedDataPoint.splice(index, 1);
    }
    this.setState({
      selectedDataPoint: [...selectedDataPoint],
      checkedAllDataPoint: selectedDataPoint.length === dataPointList.length,
      indeterminateDataPoint: selectedDataPoint.length < dataPointList.length && selectedDataPoint.length > 0
    });
  };

  handleApplyChart = () => {
    const { messages } = this.props.intl;
    const { selectedIndustry, selectedStatistic, selectedDataPoint, selectedArea } = this.state;
    const area = selectedArea.length;
    const vsic = selectedIndustry.length;
    const year = selectedDataPoint.length;
    const statistic = selectedStatistic.length;
    let validProductArea = true;
    if (vsic * area === 0) {
      this.setState({
        productAreaError: "industry.statistic-advance.chart.vsic-min"
      });
      validProductArea = false;
    } else if (vsic * area > 3) {
      this.setState({
        productAreaError: "industry.statistic-advance.chart.vsic-max"
      });
      validProductArea = false;
    } else {
      this.setState({
        productAreaError: ""
      });
      validProductArea = true;
    }
    let validDataType = true;
    if (statistic === 0) {
      this.setState({
        dataTypeError: "industry.statistic.chart.statistic-min"
      });
      validDataType = false;
    } else {
      this.setState({
        dataTypeError: ""
      });
      validDataType = true;
    }
    let validYear = true;
    if (year === 0) {
      this.setState({
        yearError: "industry.statistic.chart.year-min"
      });
      validYear = false;
    } else if (year > 5) {
      this.setState({
        yearError: "industry.statistic.chart.year-max"
      });
      validYear = false;
    } else {
      this.setState({
        yearError: ""
      });
      validYear = true;
    }
    if (validProductArea && validDataType && validYear) {
      const body = {
        productCode: selectedIndustry,
        vsicStatisticKind: selectedStatistic,
        areas: selectedArea,
        year: selectedDataPoint,
        lang: localStorage.getItem("currentLanguage")
      };
      this.props.actAdvanceStatisticIndustryChart(body);
    }
  };
  handleSearchList = (options, data) => {
    if (data) {
      return options.filter((item) => item.text.toLowerCase().includes(data.toLowerCase()));
    }
  };

  render() {
    const language = localStorage.getItem("currentLanguage");
    const { messages } = this.props.intl;
    const {
      checkedAllStatistic,
      indeterminateStatistic,
      checkedAllDataPoint,
      indeterminateDataPoint,
      productAreaError,
      dataTypeError,
      yearError,
      dataPointList
    } = this.state;
    const { statisticLoadingChart, statisticDataChart, filterVsicList, filterAreaList } = this.props.industryInformationReducer;

    const renderLabel = (item) => {
      return (
        <div className="container-lalel-item">
          <div className="hyphen"> - </div>
          <div className="container-lalel">
            <div className="main-label">{language === "en" ? item.productEnName : item.productName}</div>
            <div className="sub-label">{language === "en" ? item.productName : item.productEnName}</div>
          </div>
        </div>
      );
    };
    const vsicList = [];
    filterVsicList.map((item) => {
      vsicList.push({
        key: item.productCode,
        text: `${item.productCode}`,
        value: item.productCode,
        label: renderLabel(item)

        // label: language === "en" ? (" - " + item.productEnName) : (" - " + item.productName)
      });
    });
    const statisticList = copyArray(advanceStatisticCriteria);
    const areaList = [];
    if (filterAreaList) {
      filterAreaList.map((item) => {
        areaList.push({
          key: item.provincesId,
          text: item.provincesId,
          value: item.provincesId,
          label: language === "en" ? " - " + item.provinceEnName : " - " + item.provinceName
        });
      });
    }

    return (
      <div>
        <div className="statistic-data-table selected-custom-select card-item mb-4">
          {this.state.alert}
          <div className="row m-0">
            <div className="col-lg-6 col-xl-5 mb-4 mb-xl-0">
              <Menu compact className="w-100 mr-3 dropdown-vsic-list dropdown-custom-advan-statistic">
                <Dropdown
                  placeholder={messages["industry.statistic.product"]}
                  fluid
                  multiple
                  search={this.handleSearchList}
                  selection
                  options={vsicList}
                  noResultsMessage={messages["search.notfound.text"]}
                  onChange={(e, { value }) => this.handleChangeIndustry(value)}
                />
              </Menu>
              {productAreaError && (
                <div className="form-error p-0">
                  <IntlMessages id={productAreaError} />
                </div>
              )}
            </div>
            <div className="col-lg-6 col-xl-3 mb-4 mb-xl-0">
              <Menu compact className="w-100 mr-3 dropdown-vsic-list">
                <Dropdown
                  placeholder={messages["advanceSearch.area.area"]}
                  fluid
                  multiple
                  search={this.handleSearchList}
                  selection
                  options={areaList}
                  noResultsMessage={messages["search.notfound.text"]}
                  onChange={(e, { value }) => this.handleChangeArea(value)}
                />
              </Menu>
            </div>
            <div className="col-sm-6 col-xl-2 mb-4 mb-xl-0">
              <div className="p-0 search-result">
                <div className="mb-3 title">
                  <IntlMessages id="industry.statistic.table.type" />
                </div>
                <div className="input-list-Checkbox">
                  <Checkbox checked={checkedAllStatistic} onChange={() => this.handleChangeCheckAllStatistic()} indeterminate={indeterminateStatistic} />
                  <IntlMessages id="industry.filter.all" />
                </div>
                <div>
                  {statisticList.map((props, index) => {
                    return (
                      <div key={index} className="input-list-Checkbox">
                        <Checkbox checked={this.state.selectedStatistic.includes(props.id)} onChange={() => this.handleChangeStatistic(props.id)} />
                        {language === "en" && props.enText ? props.enText : props.text}
                      </div>
                    );
                  })}
                </div>
              </div>
              {dataTypeError && (
                <div className="form-error p-0">
                  <IntlMessages id={dataTypeError} />
                </div>
              )}
            </div>
            <div className="col-sm-6 col-xl-2 mb-4 mb-xl-0">
              <div className="p-0 search-result">
                <div className="mb-3 title">
                  <IntlMessages id="advanceSearch.area.year" />
                </div>
                <div className="input-list-Checkbox">
                  <Checkbox checked={checkedAllDataPoint} onChange={() => this.handleChangeCheckAllDataPoint()} indeterminate={indeterminateDataPoint} />
                  <IntlMessages id="industry.filter.all" />
                </div>
                <div>
                  {dataPointList &&
                    dataPointList.length > 0 &&
                    dataPointList.map((prop, key) => {
                      return (
                        <div className={`input-list-Checkbox`} key={key}>
                          <Checkbox label={prop} checked={this.state.selectedDataPoint.includes(prop)} onChange={() => this.handleChangeDataPoint(prop)} />
                        </div>
                      );
                    })}
                </div>
              </div>
              {yearError && (
                <div className="form-error p-0">
                  <IntlMessages id={yearError} />
                </div>
              )}
            </div>
          </div>
          <div className="row m-0 mt-4">
            <Button className="aplly-btn common-button" onClick={this.handleApplyChart} disabled={statisticLoadingChart} isLoading={statisticLoadingChart}>
              <IntlMessages id="common.Apply" />
            </Button>
          </div>
        </div>
        {statisticLoadingChart && <LoaderTable isLoaded={!statisticLoadingChart} styleCustom={{ height: "500px" }} />}
        <div className="company_target_views row equal-height" style={statisticLoadingChart ? { display: "none" } : {}}>
          {statisticDataChart &&
            statisticDataChart.length > 0 &&
            statisticDataChart.map((props, key) => {
              return (
                <div className="col-md-6 pb-4" key={key}>
                  <div className="line-chart card-item">
                    <ColumnChart
                      title={props.title}
                      subTitle={messages["unit"] + ": " + props.unit}
                      dataChart={props.dataChart}
                      // enabledToolTip={roles && roles === _Premium_}
                    ></ColumnChart>
                  </div>
                </div>
              );
            })}
        </div>
        {/* {statisticDataChart && statisticDataChart.length === 0 && !statisticLoadingChart && (
                    <div className="card-item mb-4">
                        <NoData />
                    </div>
                )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyReducer: state.companyReducer,
    industryInformationReducer: state.industryInformationReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actAdvanceStatisticIndustryChart: (body) => {
      dispatch(actAdvanceStatisticIndustryChart(body));
    },
    getDataPointList: () => {
      dispatch(actGetStatisticChartYear());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabDataChart));
