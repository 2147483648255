import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import { Dropdown, Menu, Checkbox, Input, Label } from "semantic-ui-react";
import Button from "../../../components/CustomButton/CustomButton";
import SweetAlert from "react-bootstrap-sweetalert";
import { Table } from "reactstrap";
import { actAdvanceSearchCompanyByFinance, actGetDataPointList, actGetFinancialCriteriaList } from "../../../redux/CompanyInfo/actions";
import { CompanyItem } from "../../../components/CompanyInfo/CompanyChild/CompanyItem";
import Pagination from "../../../components/Pagination/PaginationSimple";
import { formatMoney } from "../../../utils/formatMoney";
import LoaderTable from "../../../components/LoaderTable";
import Findnotfound from "../../../components/Findnotfound";
import { _Demo_, _Basic_, _Standard_, _Premium_ } from "../../../constants/rolesModel";
import { copyArray } from "../../../utils/copyArray";
import { callApiServer } from "../../../utils/callApiServer";
import moment from "moment";
import { Spinner } from "reactstrap";

import { saveAs as importedSaveAs } from "file-saver";
import CurrencyInput from "react-currency-input-field";

export class TabFinance extends Component {
  dropdownRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      listYear: [],

      selectedDataPoint: [], // giai đoạn dữ liệu
      selectedCriteria: [
        // tiêu chí
        {
          criteriaId: "",
          from: "",
          to: ""
        }
      ],
      selectedTerms: [], // điều kiện tìm kiếm được thêm
      pageIndex: 1,
      pageSize: 10,
      activePage: 1,
      isSearch: false,
      criteriaData: [], // ds tiêu chí dropdown
      dropdownValue: [""], // giá trị dropdown
      dropdownText: [""], // text hiển thị ở dropdown
      filteredList: [], // list dropdown
      fromData: [""], // list from
      toData: [""], // list to,

      dataSearch: {},
      isDownloading: false
    };
  }

  componentDidMount() {
    this.getListYear();

    this.props.getFinancialCriteriaList();
    // this.props.getDataPointList();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const data = nextProps.companyReducer.companyFinancialSearchList;
    if (data && data.length > 0) {
      data.forEach((e) => {
        e.companyFinancialSearchList = e.companyFinancialSearchListModels.map((props) => {
          return { key: props.financialId, value: props.financialId, text: props.financialName, enName: props.financialEnName, unit: props.unit };
        });
      });
      this.setState({ filteredList: [data], criteriaData: data });
    }
  }

  // List năm
  async getListYear() {
    try {
      const response = await callApiServer.get("/Company/ListYear");
      if (response && response.data && response.data.data) {
        const data = response.data.data;
        this.setState({ listYear: data });
      } else {
        this.setState({
          listYear: []
        });
      }
    } catch (error) {
      this.setState({
        listYear: []
      });
    }
  }

  // list giai đoạn dữ liệu
  handleChangeDataPoint = (year) => {
    const index = this.state.selectedDataPoint.indexOf(year);
    if (index < 0) {
      this.state.selectedDataPoint.push(year);
    } else {
      this.state.selectedDataPoint.splice(index, 1);
    }
    this.setState({
      selectedDataPoint: [...this.state.selectedDataPoint]
    });
  };

  // thêm tiêu chí
  addCriteria = () => {
    let { selectedCriteria, filteredList, fromData, toData, dropdownValue, dropdownText } = this.state;
    selectedCriteria.push({
      criteriaId: "",
      from: "",
      to: ""
    });
    filteredList.push(this.state.criteriaData);
    fromData.push("");
    toData.push("");
    dropdownValue.push("");
    dropdownText.push("");
    this.setState({
      selectedCriteria,
      filteredList,
      fromData,
      toData,
      dropdownValue,
      dropdownText
    });
  };

  // xóa tiêu chí
  removeCriteria = (index) => {
    let { selectedCriteria, filteredList, fromData, toData, dropdownValue, dropdownText } = this.state;
    selectedCriteria = selectedCriteria.filter((value, i) => i !== index);
    filteredList = filteredList.filter((value, i) => i !== index);
    fromData = fromData.filter((value, i) => i !== index);
    toData = toData.filter((value, i) => i !== index);
    dropdownValue = dropdownValue.filter((value, i) => i !== index);
    dropdownText = dropdownText.filter((value, i) => i !== index);
    this.setState({
      selectedCriteria,
      filteredList,
      fromData,
      toData,
      dropdownValue,
      dropdownText
    });
  };

  // thêm điều kiện
  addTerms = () => {
    const { roles } = this.props;
    const { messages } = this.props.intl;
    let { selectedDataPoint, selectedCriteria, filteredList, fromData, toData, dropdownValue, dropdownText } = this.state;
    let removeList = [];
    selectedCriteria.forEach((item, i) => {
      if (item.criteriaId === "") {
        removeList.push(i);
      }
    });

    // xóa tất cả tiêu chí trống, giữ lại 1 cái mặc định
    selectedCriteria = selectedCriteria.filter((item, i) => item.criteriaId !== "");
    if (selectedCriteria.length === 0) {
      selectedCriteria.push({
        criteriaId: "",
        from: "",
        to: ""
      });
    }
    filteredList = filteredList.filter((item, i) => removeList.includes(i) === false);
    if (filteredList.length === 0) {
      filteredList.push(this.state.criteriaData);
    }
    fromData = fromData.filter((item, i) => removeList.includes(i) === false);
    if (fromData.length === 0) {
      fromData.push("");
    }
    toData = toData.filter((item, i) => removeList.includes(i) === false);
    if (toData.length === 0) {
      toData.push("");
    }
    dropdownValue = dropdownValue.filter((item, i) => removeList.includes(i) === false);
    if (dropdownValue.length === 0) {
      dropdownValue.push("");
    }
    dropdownText = dropdownText.filter((item, i) => removeList.includes(i) === false);
    if (dropdownText.length === 0) {
      dropdownText.push("");
    }
    this.setState({
      selectedCriteria,
      filteredList,
      fromData,
      toData,
      dropdownValue,
      dropdownText
    });
    if (selectedDataPoint.length === 0 || (selectedCriteria.length === 1 && selectedCriteria[0].criteriaId === "")) {
      this.actAlert(messages[`advanceSearch.finance.addTermsCondition`]);
    } else {
      const x = selectedDataPoint.length * selectedCriteria.length;
      let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
      const y = x + selectedAdvanceSearchingCriteria.length;
      if (y > 10) {
        this.actAlert(messages[`advanceSearch.area.addTermsMax`]);
      } else if (selectedCriteria.filter((i) => i.from === "" && i.to === "").length > 0) {
        this.setState({
          isAddTermsError: true
        });
      } else {
        let selectedTerms = [];
        selectedDataPoint.forEach((dataPoint) => {
          selectedCriteria.forEach((criteria) => {
            const z = selectedAdvanceSearchingCriteria.filter(
              (i) =>
                i.type === "finance" &&
                i.dataPoint === dataPoint &&
                i.criteria.criteriaId === criteria.criteriaId &&
                i.criteria.from === criteria.from &&
                i.criteria.to === criteria.to
            );
            if (z.length === 0) {
              selectedTerms.push({
                dataPoint,
                criteria,
                type: "finance"
              });
            }
          });
        });
        this.props.companyReducer.selectedAdvanceSearchingCriteria = copyArray([...selectedAdvanceSearchingCriteria, ...selectedTerms]);

        // Chỉ để lại 1 hàng thêm chỉ tiêu, đồng thời reset các data
        // + Xóa hết options
        this.setState({
          isAddTermsError: false,
          selectedTerms,
          selectedDataPoint: [],
          selectedCriteria: [],
          fromData: [""], // list from
          toData: [""],
          dropdownValue: [""],
          dropdownText: [""]
        });
        // + Thêm vào 1 option để về trạng thái ban đầu
        setTimeout(() => {
          this.addCriteria();
        }, 10);
      }
    }
  };

  // xóa điều kiện
  removeTerm = (index) => {
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    this.props.companyReducer.selectedAdvanceSearchingCriteria = selectedAdvanceSearchingCriteria.filter((item, i) => i !== index);
    let selectedTerms = this.state.selectedTerms.filter((item, i) => i !== index);
    this.setState({
      selectedTerms
    });
  };

  // tìm kiếm
  searchTerms = (page) => {
    const { messages } = this.props.intl;
    const { pageSize } = this.state;
    let { selectedAdvanceSearchingCriteria } = this.props.companyReducer;
    const areas = [];
    const vsics = [];
    const financials = [];
    const companyTypes = [];
    const legals = [];
    const owners = [];

    // Xuất nhập khẩu
    let importExportTurnover;
    let importExportYearValue = [];
    let importExportHsValue = [];

    if (selectedAdvanceSearchingCriteria.length > 0) {
      this.setState({ isSearch: true });
      selectedAdvanceSearchingCriteria.forEach((e) => {
        if (e.type === "area") {
          areas.push({
            year: e.dataPoint ? e.dataPoint : "",
            provinceId: e.area.provinceidrequest ? e.area.provinceidrequest.toString() : "",
            districtCode: e.area.districtCode ? e.area.districtCode.toString() : "",
            communeId: e.area.communeCode ? e.area.communeCode.toString() : ""
          });
        } else if (e.type === "industry") {
          vsics.push({
            year: e.dataPoint,
            vsicCode: e.industry.code
          });
        } else if (e.type === "companyType") {
          companyTypes.push({
            year: e.dataPoint,
            companyTypeId: e.typeCompany.companyTypeId
          });
        } else if (e.type === "legal") {
          legals.push({
            userId: e.userId
          });
        } else if (e.type === "owner") {
          owners.push({
            id: e.userId,
            ownerShipType: e.ownerShipType
          });
        } else if (e.type === "finance") {
          financials.push({
            year: e.dataPoint,
            financialItemId: e.criteria.criteriaId,
            fromValue: parseFloat(e.criteria.from),
            toValue: parseFloat(e.criteria.to)
          });
        } else if (e.type === "importExport_year" || e.type === "importExport_hs") {
          if (e.type === "importExport_year") {
            importExportYearValue.push({
              type: e.dataPoint,
              year: e.criteria.year,
              from: e.criteria.from ? parseFloat(e.criteria.from) : null,
              to: e.criteria.to ? parseFloat(e.criteria.to) : null
            });
          }
          if (e.type === "importExport_hs") {
            importExportHsValue.push({
              type: e.dataPoint,
              value: e.hs.shortCode
            });
          }
        }
      });
      importExportTurnover = {
        importExportYearValue,
        importExportHsValue
      };

      var body = {
        pageIndex: page,
        pageSize: pageSize,
        areas,
        vsics,
        financials,
        companyTypes,
        legals,
        owners,
        importExportTurnover
      };
      this.props.advanceSearchCompanyByFinance(body);
      this.setState({
        dataSearch: body
      });
    } else {
      this.actAlert(messages[`advanceSearch.no-searching-criteria`]);
    }
  };

  actAlert = (params) => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={messages[`newWord.notice`]}
          // showCancel
          confirmBtnText={messages[`newWord.oke`]}
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {params}
        </SweetAlert>
      )
    });
  };

  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () => this.searchTerms(activePage));
  };

  handleDownLoad(dataSearch) {
    const { messages } = this.props.intl;
    this.setState({
      isDownloading: true
    });
    const body = {
      areas: dataSearch.areas,
      financials: dataSearch.financials,
      vsics: dataSearch.vsics,
      companyTypes: dataSearch.companyTypes,
      legals: dataSearch.legals,
      owners: dataSearch.owners,
      importExportTurnover: dataSearch.importExportTurnover
    };
    callApiServer
      .post("Company/CompanyReportAdvancedSearch/export", body)
      .then((res) => {
        if (res && res.data.data && res.data.statusCode === 200) {
          this.processDownloadData(res.data.data);
        } else if (res && res.data.statusCode === 429) {
          this.actAlert(messages[`newWord.overTurnDownload`]);
          this.setState({
            isDownloading: false
          });
        } else if (res && res.data.statusCode === 403) {
          this.actAlert(messages[`newWord.notPermision`]);
          this.setState({
            isDownloading: false
          });
        } else {
          this.actAlert(messages[`newWord.somthingWrong`]);
          this.setState({
            isDownloading: false
          });
        }
      })
      .catch((err) => {
        this.actAlert(messages[`newWord.somthingWrong`]);
        this.setState({
          isDownloading: false
        });
      })
      .finally();
  }

  processDownloadData(urlFile) {
    const dateNow = Date.now();
    const dateNowFomated = moment(dateNow).format("YYMMDD");
    const fileName = `Result_${dateNowFomated}.xlsx`;
    const urlDownload = `/Company/SearchCompany/download-file-export?pathFile=${urlFile}`;
    callApiServer
      .get(urlDownload, {
        responseType: "blob"
      })
      .then((res) => {
        if (res && res.data) {
          importedSaveAs(res.data, fileName);
        }

        this.setState({
          isDownloading: false
        });
      });
  }
  // chọn chỉ tiêu/số
  handleClick = (value, option, index, listRefDropDown) => {
    let { dropdownValue, dropdownText, selectedCriteria } = this.state;
    const language = localStorage.getItem("currentLanguage");
    dropdownValue[index] = value;
    dropdownText[index] = language === "en" && option.enName ? option.enName : option.text;
    selectedCriteria[index].criteriaId = value;
    selectedCriteria[index].criteriaText = option.text;
    selectedCriteria[index].criteriaUnit = option.unit;
    this.setState({ selectedCriteria, dropdownValue, dropdownText });
    listRefDropDown[index].clearSearchQuery();
    listRefDropDown[index].close();

    // if (this.dropdownRef.current) {
    //   this.dropdownRef.current.clearSearchQuery(); // xóa search text
    //   this.dropdownRef.current.close(); // đóng dropdown
    // }
  };

  // hàm filter
  searchFn = (_e, searchQuery) => {
    const language = localStorage.getItem("currentLanguage");
    const searchKey = searchQuery.toLowerCase();
    let filteredList = [];
    this.state.criteriaData.forEach((e) => {
      if (
        (language === "vi" && e.financialName.toLowerCase().includes(searchKey)) ||
        (language === "en" && e.financialEnName.toLowerCase().includes(searchKey))
      ) {
        filteredList.push({
          companyFinancialSearchList: e.companyFinancialSearchList,
          financialEnName: e.financialEnName,
          financialId: e.financialId,
          financialName: e.financialName
        });
      } else {
        const x = e.companyFinancialSearchList.filter(
          (item) =>
            !searchQuery ||
            (language === "vi" && item.text.toLowerCase().includes(searchKey)) ||
            (language === "en" && item.enName.toLowerCase().includes(searchKey))
        );
        if (x.length > 0) {
          filteredList.push({
            companyFinancialSearchList: [...x],
            financialEnName: e.financialEnName,
            financialId: e.financialId,
            financialName: e.financialName
          });
        }
      }
    });
    return filteredList;
  };

  // tìm kiếm chỉ tiêu/số
  handleSearchChange = (_e, searchQuery, index) => {
    let { filteredList } = this.state;
    filteredList[index] = this.searchFn(_e, searchQuery);
    this.setState({ filteredList });
  };

  // đóng dropdown
  handleClose = (index) => {
    let { filteredList, criteriaData } = this.state;
    filteredList[index] = criteriaData;
    this.setState({ filteredList });
  };

  // thay đổi ở input
  handleChangeInput = (value, index, field) => {
    let { fromData, toData, selectedCriteria } = this.state;
    if (field === "from") {
      fromData[index] = value;
      selectedCriteria[index].from = value;
    } else if (field === "to") {
      toData[index] = value;
      selectedCriteria[index].to = value;
    }
    this.setState({ selectedCriteria, fromData, toData });
  };

  render() {
    const { messages } = this.props.intl;
    const { selectedCriteria } = this.state;

    const { totalItems, totalPage, loading, companyData, dataPointList, selectedAdvanceSearchingCriteria, advanceSearchLoading } = this.props.companyReducer;
    const { activePage, isSearch, dataSearch, isDownloading, fromData, toData, listYear } = this.state;
    const language = localStorage.getItem("currentLanguage");

    let listRefDropDown = [];

    // selectedCriteria.forEach((e) => {
    //   listRefDropDown.push(React.createRef().current);
    // });
    return (
      <React.Fragment>
        {this.state.alert}
        <div className="tab_area">
          <div className="row equal-height">
            <div className="col-lg-7 col-xl-8 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.selectSearchTerms" />
                </div>
                <div className="mb-4">
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.finance.dataPoint" />
                  </div>
                  <div className="row">
                    {listYear &&
                      listYear.length > 0 &&
                      listYear.map((prop, key) => {
                        return (
                          <div className={`input-list-Checkbox col-md-3 col-lg-4 col-xl-3`} key={key}>
                            <Checkbox
                              label={language === "en" && prop.enName ? prop.enName : prop.name}
                              checked={this.state.selectedDataPoint.includes(prop.value)}
                              onChange={() => this.handleChangeDataPoint(prop.value)}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div>
                  <div className="sub-title">
                    <IntlMessages id="advanceSearch.finance" />
                  </div>
                  {selectedCriteria &&
                    selectedCriteria.length > 0 &&
                    selectedCriteria.map((prop, index) => {
                      return (
                        <div className="area mb-3" key={index}>
                          <div className="row selected-custom-select criteria-section">
                            <div className="col-xl-5 pr-0 mb-2 mb-xl-0 pl-0 ml-2">
                              <Menu compact className="w-100 h-100">
                                <Dropdown
                                  fluid
                                  className="selection"
                                  ref={(el) => (listRefDropDown = [...listRefDropDown, el])}
                                  search={this.searchFn}
                                  closeOnChange
                                  onClose={(_e) => this.handleClose(index)}
                                  onSearchChange={(_e, { searchQuery }) => this.handleSearchChange(_e, searchQuery, index)}
                                  value={this.state.dropdownValue[index]}
                                  text={this.state.dropdownText[index]}
                                  placeholder={messages["advanceSearch.finance.criteria"]}
                                >
                                  <Dropdown.Menu>
                                    {this.state.filteredList &&
                                      this.state.filteredList[index] &&
                                      this.state.filteredList[index].map((item, key) => {
                                        return (
                                          <div key={key}>
                                            <Dropdown.Header content={language === "en" && item.financialEnName ? item.financialEnName : item.financialName} />
                                            {item.companyFinancialSearchList.map((option) => {
                                              return (
                                                <Dropdown.Item
                                                  key={option.value}
                                                  value={option.value}
                                                  active={option.value === this.state.dropdownValue[index]}
                                                  onClick={(e, { value }) => this.handleClick(value, option, index, listRefDropDown)}
                                                >
                                                  <span className="text pl-4 text-ellipsis">
                                                    {language === "en" && option.enName ? option.enName : option.text}
                                                  </span>
                                                </Dropdown.Item>
                                              );
                                            })}
                                          </div>
                                        );
                                      })}
                                    {this.state.filteredList && this.state.filteredList[0] && this.state.filteredList[0].length === 0 && (
                                      <div className="message">
                                        <IntlMessages id="search.notfound.text" />
                                      </div>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Menu>
                            </div>
                            <div className="col-xl-3 pr-0 mb-2 ml-2 mb-xl-0 pl-0 group-input-curency">
                              <span className="label-curency">
                                <IntlMessages id="advanceSearch.finance.from" />
                              </span>
                              <CurrencyInput
                                name="input-name"
                                className="input-curency"
                                decimalsLimit={2}
                                onValueChange={(value) => this.handleChangeInput(value, index, "from")}
                              />
                              <span className="suffix-curency">{prop.criteriaUnit ? prop.criteriaUnit : ""}</span>

                              {/* <Input
                                labelPosition="right"
                                className="w-100 custom-input"
                                ref={(el) => (listRefFrom = [...listRefFrom, el])}
                                onChange={(e, { value }) => this.handleChangeInput(value, index, "from", listRefFrom)}
                              >
                                <Label basic className="criterial-from">
                                  <IntlMessages id="advanceSearch.finance.from" />
                                </Label>
                                <input className="criterial-input" type="number" />
                                <Label className="criterial-unit">{prop.criteriaUnit ? prop.criteriaUnit : ""}</Label>
                              </Input> */}
                            </div>
                            <div className="col-xl-3 pr-0 mb-2 ml-2 mb-xl-0 pl-0 group-input-curency">
                              <span className="label-curency">
                                <IntlMessages id="advanceSearch.finance.to" />
                              </span>
                              <CurrencyInput
                                name="input-name"
                                className="input-curency"
                                decimalsLimit={2}
                                onValueChange={(value) => this.handleChangeInput(value, index, "to")}
                              />
                              <span className="suffix-curency">{prop.criteriaUnit ? prop.criteriaUnit : ""}</span>
                              {/* <Input
                                labelPosition="right"
                                className="w-100 custom-input"
                                ref={(el) => (listRefTo = [...listRefTo, el])}
                                onChange={(e, { value }) => this.handleChangeInput(value, index, "to", listRefTo)}
                              >
                                <Label basic className="criterial-from">
                                  <IntlMessages id="advanceSearch.finance.to" />
                                </Label>
                                <input className="criterial-input" type="number" />
                                <Label className="criterial-unit">{prop.criteriaUnit ? prop.criteriaUnit : ""}</Label>
                              </Input> */}
                            </div>
                            <div className="col-12 form-error">
                              {prop.criteriaId !== "" && prop.from === "" && prop.to === "" && this.state.isAddTermsError && (
                                <IntlMessages id="advanceSearch.finance.required" />
                              )}
                            </div>
                          </div>
                          {index > 0 && (
                            <span className="material-icons check" onClick={() => this.removeCriteria(index)}>
                              cancel
                            </span>
                          )}
                        </div>
                      );
                    })}
                  <div className="add-area" onClick={() => this.addCriteria()}>
                    <span className="material-icons check mr-1">add_circle</span>
                    <span>
                      <IntlMessages id="advanceSearch.finance.addCriteria" />
                    </span>
                  </div>
                </div>
                <div className="mt-5 d-flex justify-content-center">
                  <Button className={`common-button`} onClick={() => this.addTerms()}>
                    <IntlMessages id="advanceSearch.addTerms" />
                    {/* <em className="material-icons">add</em> */}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4 mb-4">
              <div className="card-item">
                <div className="card-title card-title-line">
                  <IntlMessages id="advanceSearch.searchTerms" />
                </div>
                <div>
                  {selectedAdvanceSearchingCriteria.length > 0 && (
                    <div className="table-custom ">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <IntlMessages id="table.sttTable" />
                            </th>
                            <th>
                              <IntlMessages id="advanceSearch.term" />
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAdvanceSearchingCriteria.map((prop, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span>{index + 1}</span>
                                </td>
                                {prop.type === "area" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.area.area" /> ({prop.dataPoint}):
                                    <span>{` ${prop.area.communeName ? prop.area.communeName + ", " : ""}${
                                      prop.area.districtName ? prop.area.districtName + ", " : ""
                                    }${prop.area.provinceName}`}</span>
                                  </td>
                                )}
                                {prop.type === "industry" && (
                                  <td>
                                    <IntlMessages id="sidebar.Industry" />{" "}
                                    {language === "en" && prop.industry.enName ? prop.industry.enName : prop.industry.name} ({prop.dataPoint}):{" "}
                                    {prop.industry.code}
                                  </td>
                                )}
                                {prop.type === "companyType" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.companyType.companyType" /> :
                                    {language === "en" && prop.typeCompany.enName ? prop.typeCompany.enName : prop.typeCompany.name} ({prop.dataPoint}):{" "}
                                  </td>
                                )}
                                {prop.type === "legal" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.legalRepresentative" /> :{language === "en" && prop.enName ? prop.enName : prop.name}{" "}
                                    <br></br> ID: {prop.idPerson}{" "}
                                  </td>
                                )}
                                {prop.type === "owner" && (
                                  <td>
                                    <IntlMessages id="advanceSearch.owner" /> :{language === "en" && prop.enName ? prop.enName : prop.name} <br></br> ID:{" "}
                                    {prop.showId}{" "}
                                  </td>
                                )}
                                {prop.type === "finance" && (
                                  <td>
                                    {prop.criteria.criteriaText} ({prop.dataPoint}):
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()}  ${formatMoney(
                                            prop.criteria.from.replace(/,/g, "."),
                                            2
                                          )} (${prop.criteria.criteriaUnit}) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${
                                            prop.criteria.criteriaUnit
                                          })`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_year" && (
                                  <td>
                                    {prop.dataPoint === "import" ? (
                                      <IntlMessages id="advanceSearch.importExport.import" />
                                    ) : (
                                      <IntlMessages id="advanceSearch.importExport.export" />
                                    )}
                                    <span> ({prop.criteria.year}):</span>
                                    <span>
                                      {prop.criteria.from
                                        ? ` ${messages["advanceSearch.finance.from"].toLowerCase()} ${formatMoney(prop.criteria.from.replace(/,/g, "."), 2)} (${
                                            prop.criteria.unit
                                          }) `
                                        : ""}
                                      {prop.criteria.to
                                        ? ` ${messages["advanceSearch.finance.to"].toLowerCase()} ${formatMoney(prop.criteria.to.replace(/,/g, "."), 2)} (${
                                            prop.criteria.unit
                                          })`
                                        : ""}
                                    </span>
                                  </td>
                                )}
                                {prop.type === "importExport_hs" && (
                                  <td>
                                    {prop.dataPoint === "import" ? (
                                      <IntlMessages id="advanceSearch.importExport.import" />
                                    ) : (
                                      <IntlMessages id="advanceSearch.importExport.export" />
                                    )}
                                    <span>{`: ${prop.hs.shortCode} - ${language === "vi" ? prop.hs.name : prop.hs.enName}`}</span>
                                  </td>
                                )}
                                <td>
                                  <span className="material-icons check" onClick={() => this.removeTerm(index)}>
                                    cancel
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  <div className="mt-5 d-flex justify-content-center">
                    <Button className={`common-button`} onClick={() => this.searchTerms(1)} disabled={loading} isLoading={loading}>
                      <IntlMessages id="filter.buttonSearch" />
                      <em className="material-icons">search</em>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {advanceSearchLoading === true && <LoaderTable isLoaded={!loading} styleCustom={{ height: "500px" }} />}
            {advanceSearchLoading === false && (
              <div>
                {companyData && companyData.length > 0 ? (
                  <div className="card-item mb-4 mt-0 company-info-content">
                    <div className="company-info-content-main w-100">
                      <div className="search-result-heading pb-3">
                        <p className="search-result-title">
                          {`${messages["newWord.amountCompanies"]} (${formatMoney(totalItems)} ${messages["newWord.companies"]})`}
                        </p>
                        {isDownloading ? (
                          <Spinner style={{ borderRightColor: "#3bdbfd" }} variant="success" animation="border" />
                        ) : (
                          <div
                            className="btn-export-data text-cyan"
                            onClick={() => {
                              this.handleDownLoad(dataSearch);
                            }}
                          >
                            <em className="material-icons">download</em>
                            <IntlMessages id="newWord.downloadSearchResult" />
                          </div>
                        )}{" "}
                      </div>
                      <div className="search-result-list">
                        <div className="row">
                          {companyData.map((props, index) => {
                            return (
                              <CompanyItem
                                {...this.props}
                                key={index}
                                name={props.companyName}
                                nameEnglish={props.companyEnName}
                                id={props.id}
                                taxNumber={props.companyTax}
                                vsic={props.vsic}
                                address={props.companyAddress}
                                addressEnglish={props.companyEnAddress}
                                phoneNumber={props.companyPhone}
                                fax={props.companyFax}
                                webSite={props.companyWebsite}
                                vsicID={props.vsic}
                                isShowValueTooltip={false}
                                enNetProfitLabel={props.enNetProfitLabel}
                                enNetRevenueLabel={props.enNetRevenueLabel}
                                netProfitLabel={props.netProfitLabel}
                                netRevenueLabel={props.netRevenueLabel}
                                netProfitValue={props.netProfitValue}
                                netRevenueValue={props.netRevenueValue}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="foot-pagination">
                        <Pagination activePage={activePage} totalPages={totalPage} onPageChange={this.handlePaginationChange} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-item">
                    <Findnotfound goHome={"/trang-chu-cr"} isSearch={true} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyReducer: state.companyReducer
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    advanceSearchCompanyByFinance: (body) => {
      dispatch(actAdvanceSearchCompanyByFinance(body));
    },
    // danh sách tiêu chí tài chính
    getFinancialCriteriaList: () => {
      dispatch(actGetFinancialCriteriaList());
    },
    getDataPointList: () => {
      dispatch(actGetDataPointList());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabFinance));
