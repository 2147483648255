import React, { Component } from "react";
import IntlMessages from "../../../utils/IntlMessages";
import CanvasJSReact from "../../../assets/js/canvasjs.react";
import NoData from "../../../components/NoData";

import { numberFormat } from "../../../utils/numberFormat";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class PieChartImportExport extends Component {
  constructor() {
    super();
    this.state = {
      dataChart: [],
      colorChart: [
        "#4661EE",
        "#EC5657",
        "#1BCDD1",
        "#8FAABB",
        "#B08BEB",
        "#3EA0DD",
        "#F5A52A",
        "#23BFAA",
        "#FAA586",
        "#EB8CC6",
        "#4669UU",
        "#EC1234",
        "#1BCSS7",
        "#8FPTTT",
        "#B08MNB",
        "#3EA0II",
        "#F5A6ZP",
        "#23BFEE",
        "#FAA741",
        "#EB8H47",

        "#4661DD",
        "#EC5478",
        "#1BCEE1",
        "#8FALBL",
        "#B08AAB",
        "#3EM6DD",
        "#F5A78A",
        "#23BDXA",
        "#FAA123",
        "#EB8KK6",
        "#4891EE",
        "#EC5563",
        "#1BCKK1",
        "#8FAABB",
        "#B08BLK",
        "#3EA0PO",
        "#F5A5LO",
        "#23BFSS",
        "#FAA412",
        "#EB8LL9"
      ]
    };
    this.toggleDataSeries = this.toggleDataSeries.bind(this);
  }

  // ẩn hiện legend
  toggleDataSeries(e) {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    this.chart.render();
  }

  render() {
    const { dataChart, backgroundColor, dataActive } = this.props;
    const language = localStorage.getItem("currentLanguage");

    let valueLabel = "";
    let percentLabel = "";
    if (language === "vi") {
      valueLabel = "Giá trị";
      percentLabel = "Tỷ trọng";
    } else {
      valueLabel = "Value";
      percentLabel = "Proportion";
    }

    const options = {
      animationEnabled: false,
      colorSet: "#4661EE",
      backgroundColor: backgroundColor !== undefined ? backgroundColor : "transparent",
      height: 300,
      title: {
        fontFamily: "Roboto",
        fontSize: 20
      },
      indexLabel: {
        fontFamily: "Roboto"
      },
      legend: {
        fontColor: "#B6C4DE",
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: 12,
        cursor: "pointer"
        // itemclick: this.toggleDataSeries,
        // itemWrap: true,
        // markerMargin: 10,
        // itemWidth: 250,
      },
      data: [
        {
          type: "doughnut",
          innerRadius: "75%",
          // legendMarkerType: "square",
          radius: "100%",
          startAngle: 90,
          // legendText: "{name}",
          showInLegend: true,
          // toolTipContent: "{name}: {y}%",
          indexLabelPlacement: "outside",
          indexLabel: "{name}: {y}%",
          indexLabelFontColor: "#B6C4DE",
          indexLabelFontSize: 12,
          xValueFormatString: "####",
          yValueFormatString: "##,##0.##",
          dataPoints: dataActive === "import" ? dataChart.importData : dataChart.exportData
        }
      ],
      toolTip: {
        contentFormatter: function(e) {
          var content = "";
          if (e.entries[0].dataPoint.name) {
            content += e.entries[0].dataPoint.name;
            content += "<br/>";

            if (e.entries[0].dataPoint.otherData) {
              content += `${valueLabel}: ${numberFormat(e.entries[0].dataPoint.otherData)} USD`;
              content += "<br/>";
            }
            if (e.entries[0].dataPoint.y) {
              content += `${percentLabel}: ${e.entries[0].dataPoint.y} %`;
              content += "<br/>";
            }
          }

          return content;
        }
      }
    };

    return (
      <div className="column-multiple-chart-wrapper">
        {options.data[0].dataPoints && options.data[0].dataPoints.length ? (
          <div>
            <CanvasJSChart
              options={options}
              onRef={(ref) => (this.chart = ref)}
              //containerProps={{width: "80%", position: "relative"}}
            />
            <div className="cright">
              <IntlMessages id="common.sourceVirac" />
            </div>
          </div>
        ) : (
          <NoData></NoData>
        )}
      </div>
    );
  }
}

export default PieChartImportExport;
