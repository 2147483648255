import { Formik } from "formik";
import axios from "axios";

import { ConnectedFocusError } from "focus-formik-error";
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../assets/images/logo.svg";
import BG from "../../assets/images/login.png";
import Button from "../../components/CustomButton/CustomButton";
import { actLogin, actLogout } from "../../redux/actions";
import Eye from "../../assets/images/ic/Eye.svg";
import EyeActive from "../../assets/images/ic/EyeActive.svg";
import { setAuthorization } from "../../utils/callApiServer";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
// const { v4: uuidv4 } = require('uuid');
import Header from "../../components/Header/HomeHead";
import IntlMessages from "../../utils/IntlMessages";
import { injectIntl } from "react-intl";
import ModalConfidentiality from "../../components/Footer/ModalConfidentiality";
import ModalCopyright from "../../components/Footer/ModalCopyright";
import ModalDisclaimer from "../../components/Footer/ModalDisclaimer";
import { callApiServer } from "../../utils/callApiServer";
// import AlertWarning from "../../components/SweetAlert/AlertWarning";
// import AlertSuccess from "../../components/SweetAlert/AlertSuccess";
// import AlertError from "../../components/SweetAlert/AlertError";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import Countdown from "react-countdown";
import img from "../../assets/images/logoSaleNoti.png";
import { v4 as uuidv4 } from "uuid";
import * as CryptoJS from "crypto-js";
class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      message: null,
      initialValues: { phoneNumber: "", email: "", password: "" },
      rememberMe: false,
      isShowPassword: false,
      toggleModalConfidentiality: false,
      toggleModalModalDisclaimer: false,
      toggleModalModalCopyright: false,
      loadingSubmit: false,
      dupplicateEmail: false,
      dupplicatePhoneNumber: false,
      wrongPresenter: false
    };
    // this.form = React.createRef();
  }

  componentDidMount() {
    document.title = "VIRACE | Register";

    this.sendLogToServer("Register");
  }

  async sendLogToServer(actionString) {
    let action = actionString;
    let ip = "";
    let location = "";
    let sessionId = uuidv4();
    let timeStamp = Date.now();
    let signature;
    let KEY;
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "[::1]" ||
      window.location.hostname.match(/^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    ) {
      KEY = `npvzhKj/vnvpcxCPZi8zT45TgTjqKYzKfI1y8tJW2f0=`;
    } else if (window.location.host.includes("viracresearch")) {
      KEY = `Yk8WUzFJv3qN3FYpZ9+K5N+i8uxeh/Nk2bqxKYyJ/ck=`;
    } else if (window.location.host.includes("web-develop.virace")) {
      KEY = `npvzhKj/vnvpcxCPZi8zT45TgTjqKYzKfI1y8tJW2f0=`;
    } else {
      KEY = `Yk8WUzFJv3qN3FYpZ9+K5N+i8uxeh/Nk2bqxKYyJ/ck=`;
    }
    const url = "https://geolocation-db.com/json/";

    try {
      const response = await fetch(url);
      const responseLocation = await response.json();
      if (responseLocation) {
        ip = responseLocation.IPv4;
        location = `${responseLocation.state}, ${responseLocation.city}, ${responseLocation.country_code}`;

        let stringOrigin = `Action-${action}Ip-${ip}Location-${location}SessionId-${sessionId}TimeStamp-${timeStamp}`;

        signature = CryptoJS.HmacSHA256(stringOrigin, KEY).toString(CryptoJS.enc.Base64);

        const body = {
          sessionId,
          timeStamp,
          ip,
          location,
          action,
          signature
        };

        callApiServer.post("AuditLog/log", body);
      }
    } catch (error) {
      const dataLocationLocal = JSON.parse(localStorage.getItem("location"));
      if (dataLocationLocal) {
        ip = dataLocationLocal.IPv4;
        location = `${dataLocationLocal.state}, ${dataLocationLocal.city}, ${dataLocationLocal.country_code}`;

        let stringOrigin = `Action-${action}Ip-${ip}Location-${location}SessionId-${sessionId}TimeStamp-${timeStamp}`;

        signature = CryptoJS.HmacSHA256(stringOrigin, KEY).toString(CryptoJS.enc.Base64);

        const body = {
          sessionId,
          timeStamp,
          ip,
          location,
          action,
          signature
        };

        callApiServer.post("AuditLog/log", body);
      }
    }
  }
  _isLoginCheck = (nextProps) => {
    const auth = nextProps.authentication;
    if (auth.isLogin === false) {
      this.setState({
        message: auth.message,
        isLoading: false
      });
    } else if (auth.isLogin === true) {
      this.props.history.push("/menu-dieu-huong");
    }
  };

  showPassword = () => {
    this.setState((state) => ({
      isShowPassword: !state.isShowPassword
    }));
    setTimeout(() => {
      this.setState((state) => ({
        isShowPassword: !state.isShowPassword
      }));
    }, 3000);
  };

  handleModalConfidentiality = () => {
    this.setState((state) => ({
      toggleModalConfidentiality: !state.toggleModalConfidentiality
    }));
  };

  handleModalDisclaimer = () => {
    this.setState((state) => ({
      toggleModalModalDisclaimer: !state.toggleModalModalDisclaimer
    }));
  };

  handleModalCopyright = () => {
    this.setState((state) => ({
      toggleModalModalCopyright: !state.toggleModalModalCopyright
    }));
  };

  handleRegister(values) {
    const { messages } = this.props.intl;

    this.setState({
      loadingSubmit: true
    });
    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      company: values.company,
      email: values.email,
      phoneNumber: values.phoneNumber,
      referenceEmail: values.presenter

      // password: values.password,
      // confirmPassword: values.passConfirm
    };
    try {
      callApiServer
        .post("/Users/register", body)
        .then((res) => {
          if (res.status === 200 && res.statusText === "OK") {
            this.handleShowAlertSuccess();

            // Gửi log sau khi đăng kí thành công
            this.sendLogToServer("SubmitRegister");
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            let listErr = err.response.data;
            for (let item of listErr) {
              if (item.code === "DuplicateUserName") {
                this.setState({
                  dupplicateEmail: true
                });

                // Hiện poppup thông báo email đã tồn tại,
                // người dùng có thể link đến trang đăng nhập hoặc quên mật khẩu
                this.showPopupDuplicateEmail();
              } else if (item.code === "InvalidReferenceUser") {
                this.setState({
                  wrongPresenter: true
                });
              }
            }
          } else {
            this.handleShowAlertError();
          }
        })
        .finally(() => {
          this.setState({
            loadingSubmit: false
          });
        });
    } catch (error) {
      console.log(error);
    }
  }

  handleInputEmail = () => {
    this.setState({
      dupplicateEmail: false
    });
  };
  handleInputPresenter = () => {
    this.setState({
      wrongPresenter: false
    });
  };

  handleShowAlertSuccess = () => {
    const { messages } = this.props.intl;
    const renderer = ({ hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <span>{seconds}</span>;
    };
    this.setState({
      alert: (
        <SweetAlert
          title=""
          type="success"
          style={{ display: "block" }}
          confirmBtnBsStyle="success"
          confirmBtnText={messages[`newWord.agree`]}
          confirmBtnCssClass="confirmBtn swal2-styled"
          onConfirm={() => {
            this.handleHideAlert();
          }}
          timeout={5000}
          beforeUnmount={() => {
            this.handleHideAlert();
            this.props.history.push("/dang-nhap");
          }}
        >
          <div>
            {messages[`newWord.registerSuccess`]} <Countdown date={Date.now() + 5000} renderer={renderer} />
          </div>
        </SweetAlert>
      )
    });
  };
  handleShowAlertError = () => {
    const { messages } = this.props.intl;

    this.setState({
      alert: (
        <SweetAlert
          title=""
          type="error"
          style={{ display: "block" }}
          confirmBtnBsStyle="danger"
          confirmBtnText={messages[`newWord.agree`]}
          confirmBtnCssClass="confirmBtn swal2-styled"
          onConfirm={() => {
            this.handleHideAlert();
          }}
        >
          <div>{messages[`newWord.registerFail`]}</div>
        </SweetAlert>
      )
    });
  };

  showPopupDuplicateEmail = () => {
    const { messages } = this.props.intl;

    this.setState({
      alert: (
        <SweetAlert
          title=""
          type="warning"
          style={{ display: "block" }}
          confirmBtnBsStyle="success"
          confirmBtnText={messages[`newWord.agree`]}
          confirmBtnCssClass="confirmBtn swal2-styled"
          onConfirm={() => {
            this.handleHideAlert();
          }}
        >
          <div>
            <span>
              <IntlMessages id="error.duplicateEmailWarning_p1" />
            </span>

            <span className="text-cyan">
              <Link to="/dang-nhap">
                <IntlMessages id="error.duplicateEmailWarning_p2" />
              </Link>
            </span>
            <span>
              <IntlMessages id="error.duplicateEmailWarning_p3" />
            </span>
            <span className="text-cyan">
              <Link to="/quen-mat-khau">
                <IntlMessages id="error.duplicateEmailWarning_p4" />
              </Link>
            </span>
            <span>
              <IntlMessages id="error.duplicateEmailWarning_p5" />
            </span>
          </div>
        </SweetAlert>
      )
    });
  };

  handleHideAlert = () => {
    this.setState({
      alert: ""
    });
  };
  goToConfirmPage() {
    window.open("http://online.gov.vn/Home/WebDetails/86982?AspxAutoDetectCookieSupport=1", "_blank");
  }

  render() {
    const { loading } = this.props.authentication;
    const { messages } = this.props.intl;
    const loadingSubmit = this.state.loadingSubmit;
    var d = new Date();
    var n = d.getFullYear();
    setAuthorization("");

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const access_token = localStorage.getItem("access_token");

    return (
      <div className="login-page" style={{ backgroundImage: `url(${BG})` }}>
        {this.state.alert}
        <Header history={this.props.history} />
        {this.state.toggleModalConfidentiality && (
          <ModalConfidentiality toggleModal={this.state.toggleModalConfidentiality} handleCloseForget={this.handleModalConfidentiality} />
        )}
        {this.state.toggleModalModalDisclaimer && (
          <ModalDisclaimer toggleModal={this.state.toggleModalModalDisclaimer} handleCloseForget={this.handleModalDisclaimer} />
        )}
        {this.state.toggleModalModalCopyright && (
          <ModalCopyright toggleModal={this.state.toggleModalModalCopyright} handleCloseForget={this.handleModalCopyright} />
        )}
        <div className="login-form-container form-container">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="login-form">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                phoneNumber: "",
                company: "",
                email: "",
                presenter: ""

                // password: "",
                // passConfirm: ""
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                setTimeout(() => {
                  this.handleRegister(values);
                  this.setState({
                    isCheck: true
                  });
                  setSubmitting(false);
                }, 500);
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .matches(/^[\p{L} ]+$/u, <IntlMessages id="error.firstNameFormatted" />)
                  .max(256)
                  .required(<IntlMessages id="error.firstNameRequired" />),
                lastName: Yup.string()
                  .matches(/^[\p{L} ]+$/u, <IntlMessages id="error.lastNameFormatted" />)
                  .max(256)
                  .required(<IntlMessages id="error.lastNameRequired" />),
                company: Yup.string()
                  .matches(/[^!¡?÷?¿+=@#$%&^*(){}|~<>;:,`'"/\\[\]]{1,}$/, <IntlMessages id="error.companyFormatted" />)
                  .max(256),
                phoneNumber: Yup.string()
                  .required(<IntlMessages id="error.phoneRequired" />)
                  .matches(phoneRegExp, <IntlMessages id="error.phoneFormatted" />)
                  .min(10, <IntlMessages id="error.phoneFormatted" />),
                email: Yup.string()
                  .required(<IntlMessages id="error.emailRequired" />)
                  .matches(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.-]{2,}$/, <IntlMessages id="error.emailFormatted" />),
                presenter: Yup.string().matches(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.-]{2,}$/, <IntlMessages id="error.presenterFormatted" />)
                // password: Yup.string()
                //   .required(messages[`error.passRequired`])
                //   .min(8, messages[`error.passMinLength`])
                //   .matches(/^.*((?=.*[!~/*^$&@]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).[^\s]*$/, messages[`error.passFormatted`]),
                // passConfirm: Yup.string()
                //   .required(messages[`error.confirmPassRequired`])
                //   .oneOf([Yup.ref("password"), null], messages[`error.confirmPassMatch`])
              })}
            >
              {(props) => {
                const { values, errors, touched, handleChange, handleSubmit, handleBlur } = props;
                return (
                  <form onSubmit={handleSubmit} onChange={handleChange}>
                    <ConnectedFocusError />
                    <h1>
                      <IntlMessages id="auth.register" />
                    </h1>
                    {/* ____Họ--Tên____ */}
                    <div className="form-input form-icon row">
                      <div className="col-6">
                        <label>
                          <div className="icon">
                            <span className="material-icons">person</span>
                          </div>
                          <input
                            id="lastName"
                            type="text"
                            placeholder={messages[`auth.enterLastName`] + "*"}
                            name="lastName"
                            value={values.lastName}
                            maxLength="256"
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                        {touched.lastName && errors.lastName && <div className="form-error">{errors.lastName}</div>}
                      </div>
                      <div className="col-6">
                        <label>
                          <div className="icon">
                            <span className="material-icons">person</span>
                          </div>
                          <input
                            id="firstName"
                            type="text"
                            placeholder={messages[`auth.enterFirstName`] + "*"}
                            name="firstName"
                            value={values.firstName}
                            maxLength="256"
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </label>
                        {touched.firstName && errors.firstName && <div className="form-error">{errors.firstName}</div>}
                      </div>
                    </div>

                    <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">apartment</span>
                        </div>
                        <input
                          id="company"
                          type="text"
                          placeholder={messages[`auth.enterCompany`]}
                          name="company"
                          value={values.company}
                          maxLength="256"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      {errors.company && <div className="form-error">{errors.company}</div>}
                    </div>
                    {/* ____Số điện thoại____ */}
                    <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">phone_iphone</span>
                        </div>
                        <input
                          id="phoneNumber"
                          type="text"
                          placeholder={messages[`auth.enterPhone`] + "*"}
                          name="phoneNumber"
                          value={values.phoneNumber}
                          maxLength="10"
                          autoComplete="off"
                          ref={(input) => {
                            this.phoneNumber = input;
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      {touched.phoneNumber && errors.phoneNumber && <div className="form-error">{errors.phoneNumber}</div>}
                    </div>

                    {/* _____Email_____ */}
                    <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">email</span>
                        </div>
                        <input
                          id="email"
                          type="search"
                          autoComplete="new-password"
                          placeholder={messages[`auth.enterEmail`] + "*"}
                          value={values.email}
                          maxLength="256"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onInput={this.handleInputEmail}
                        />
                      </label>
                      {touched.email && errors.email && <div className="form-error">{errors.email}</div>}
                      {this.state.dupplicateEmail && <div className="form-error">{messages[`error.duplicateEmail`]}</div>}
                    </div>

                    {/* _____Người giới thiệu_____ */}
                    <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">attach_email</span>
                        </div>
                        <input
                          id="presenter"
                          type="search"
                          autoComplete="new-password"
                          placeholder={messages[`auth.enterPresenter`]}
                          value={values.presenter}
                          maxLength="256"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onInput={this.handleInputPresenter}
                        />
                      </label>
                      {touched.presenter && errors.presenter && <div className="form-error">{errors.presenter}</div>}
                      {this.state.wrongPresenter && <div className="form-error">{messages[`error.wrongPresenter`]}</div>}
                    </div>
                    {/* ______Mật khẩu____ */}
                    {/* <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">lock</span>
                        </div>
                        <input
                          type="password"
                          name="password"
                          maxLength="256"
                          autoComplete="new-password"
                          placeholder={messages[`auth.enterNewPass`] + "*"}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      {touched.password && errors.password && <div className="form-error">{errors.password}</div>}
                    </div> */}
                    {/* _____Xác nhận mật khẩu____ */}
                    {/* <div className="form-input form-icon">
                      <label>
                        <div className="icon">
                          <span className="material-icons">lock</span>
                        </div>
                        <input
                          type="password"
                          name="passConfirm"
                          maxLength="256"
                          autoComplete="off"
                          placeholder={messages[`auth.enterNewPassAgain`] + "*"}
                          value={values.passConfirm}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </label>
                      {touched.passConfirm && errors.passConfirm && <div className="form-error">{errors.passConfirm}</div>}
                    </div> */}
                    <div className="form-submit login-submit">
                      <Button disabled={loadingSubmit} isLoading={loadingSubmit} type="submit" className="common-button w-100">
                        <IntlMessages id="auth.register" />
                      </Button>
                    </div>
                    <div className="mt-3 login-or-register">
                      <IntlMessages id="auth.haveAcount" />

                      <span className="ml-2 action-link">
                        <Link to="/dang-nhap">
                          <IntlMessages id="auth.login" />
                        </Link>
                      </span>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          <div className="copyright">
            <p>
              <a href="#" onClick={() => this.handleModalCopyright()}>
                COPYRIGHT &copy; {n} VIRAC
              </a>
            </p>
            <p className="mt-2">
              <a href="#" onClick={() => this.handleModalConfidentiality()}>
                <IntlMessages id="footer.confidentiality" />
              </a>{" "}
              |{" "}
              <a href="#" onClick={() => this.handleModalDisclaimer()}>
                <IntlMessages id="footer.disclaimer" />
              </a>
            </p>
            <p className="mt-3">
              <span onClick={() => this.goToConfirmPage()} className="img-noti">
                <img src={img} alt="" />
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

RegisterPage.defaultProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RegisterPage));
