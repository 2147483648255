import React, { Component } from "react";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import { debounce } from "lodash";
import {
  actGetHistoryRequested,
  actGetHistoryOrderAttachment,
  actGetHistoryOrderDownload,
} from "../../../redux/AccountManagement/actions";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import LoaderTable from "../../../components/LoaderTable";
import Pagination from "../../../components/Pagination/PaginationSimple";
import { Input } from "reactstrap";
import { Dropdown, Menu, Button } from "semantic-ui-react";
import NoData from "../../../components/NoData";
export class TabContentTabHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      valueFilter: "All",
      txtsearch: "",
      isChooseDownload: true,
      listDataOrderID: [],
      listDataReport: [],
      selectedOrDer: null,
      selectedReport: null,
    };
  }

  activeColorStatus = (status) => {
    if (status === "New") {
      return (
        <td style={{ color: "#0073e6", fontWeight: 500, textAlign: "right" }}>
          {status}
        </td>
      );
    }
    if (status === "Confirming") {
      return (
        <td style={{ color: "#2eb82e", fontWeight: 500, textAlign: "right" }}>
          {status}
        </td>
      );
    }
    if (status === "Proccessing") {
      return (
        <td style={{ color: "#ff6600", fontWeight: 500, textAlign: "right" }}>
          {status}
        </td>
      );
    }
    if (status === "Canceled") {
      return (
        <td style={{ color: "#ff1a1a", fontWeight: 500, textAlign: "right" }}>
          {status}
        </td>
      );
    }
    if (status === "Completed") {
      return (
        <td style={{ color: "#2ecc71", fontWeight: 500, textAlign: "right" }}>
          {status}
        </td>
      );
    }
    if (status === "Pending") {
      return (
        <td style={{ color: "#f39c12", fontWeight: 500, textAlign: "right" }}>
          {status}
        </td>
      );
    }
    if (status === "Approved") {
      return (
        <td style={{ color: "#1abc9c", fontWeight: 500, textAlign: "right" }}>
          {status}
        </td>
      );
    }
  };

  componentDidMount() {
    const { valueFilter, txtsearch } = this.state;
    this.props.handleGetHistoryRequested(1, valueFilter, txtsearch);
  }
  handlePaginationChange = (e, { activePage }) => {
    this.setState(
      {
        page: activePage,
      },
      () => this.handleGetHistoryRequested(activePage)
    );
  };
  handleGetHistoryRequested = (page) => {
    const { valueFilter, txtsearch } = this.state;
    this.props.handleGetHistoryRequested(page, valueFilter, txtsearch);
  };
  changeFilter = (e) => {
    const value = e.target.value;
    const { page, txtsearch } = this.state;
    if (this.state.valueFilter !== value) {
      this.setState({ valueFilter: value }, () =>
        this.props.handleGetHistoryRequested(page, value, txtsearch)
      );
    }
  };
  handleSearchChange = (value) => {
    this.setState({ txtsearch: value }, () => this.requestAutocomplete());
  };
  requestAutocomplete = debounce(() => {
    this.handleGetHistoryRequested(1);
  }, 1000);

  handleDownloadReport = (e) => {
    if (e.target.value !== "") {
      this.setState({
        selectedYearDownload: e.target.value,
        isChooseDownload: false,
      });
    } else {
      this.setState({
        isChooseDownload: true,
      });
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { listOrderID, listDataReport } = nextProps.accountManagement;
    this.setState({
      listDataOrderID: listOrderID,
      listDataReport: listDataReport,
    });
  }
  handleChangeCode = (e, { value }) => {
    this.setState(
      {
        selectedReport: null,
        listDataReport: [],
        selectedOrDer: this.state.listDataOrderID.find(
          (x) => x.value === value
        ),
      },
      () => this.props.actGetHistoryOrderAttachment(value)
    );
  };
  handleChangeReport = (e, { value }) => {
    this.setState({
      selectedReport: this.state.listDataReport.find((x) => x.value === value),
    });
  };
  _handleDownloadReport = () => {
    const { selectedOrDer, selectedReport } = this.state;
    if (selectedOrDer.value && selectedReport.value) {
      this.props.actGetHistoryOrderDownload(
        selectedOrDer.value,
        selectedReport.value
      );
    }
  };
  funcRenderAactions = (data) => {
    switch (data.requestData) {
      case "Báo cáo ngành":
        return <span>{data.major}</span>;
      default:
        return <span>{data.companyName}</span>;
    }
  };
  funcRenderAactionsTax = (data) => {
    switch (data.requestData) {
      case "Báo cáo ngành":
        return <span></span>;
      default:
        return <span>{data.mst}</span>;
    }
  };
  render() {
    const {
      requestedHistory,
      totalPage,
      loading,
      loadingReport,
      loadingDownload,
    } = this.props.accountManagement;
    const { messages } = this.props.intl;
    const {
      valueFilter,
      page,
      listDataOrderID,
      selectedOrDer,
      selectedReport,
      listDataReport,
    } = this.state;
    const optionFilter = [
      { value: "All", text: "All" },
      { value: "New", text: "New" },
      { value: "Pending", text: "Pending" },
      { value: "Canceled", text: "Canceled" },
      { value: "Completed", text: "Completed" },
      { value: "Proccessing", text: "Proccessing" },
      { value: "Confirming", text: "Confirming" },
    ];
    return (
      <div className="manament-tabplane-history">
        <div className="management-title-and-sort-filter d-flex justify-content-between">
          <span>
            <IntlMessages id="user.DataRequestHistory" />
          </span>
        </div>
        <div className="wp_total">
          <div className="sort-filter-actions selected-custom selected-custom-select cutsom_fixed_input">
            <div className="head-top">
              <div className="d-flex justify-content-center align-items-center">
                <span className="titleHistoryAccount">
                  {" "}
                  <IntlMessages id="filter.Sortby" />
                </span>
              </div>
              <Input
                type="text"
                placeholder={messages[`table.businessName`]}
                className="mr-2 "
                value={this.state.txtsearch}
                onChange={(e) => this.handleSearchChange(e.target.value)}
              ></Input>
              <Input
                type="select"
                className="mr-2 "
                value={valueFilter}
                onChange={(e) => this.changeFilter(e)}
              >
                <option value="All" disabled>
                  {messages[`table.statusx`]}
                </option>
                {optionFilter.map((props, index) => {
                  return (
                    <option key={index} value={props.value}>
                      {props.text}
                    </option>
                  );
                })}
              </Input>
            </div>
            <div className="head-bot">
              <Menu compact className="mr-2 ">
                <Dropdown
                  text={
                    selectedOrDer
                      ? selectedOrDer.text
                      : messages[`user.orderCode`]
                  }
                  item
                  loading={loadingReport}
                  onChange={this.handleChangeCode}
                  search
                  options={listDataOrderID}
                ></Dropdown>
              </Menu>
              {listDataReport && listDataReport.length > 0 && (
                <Dropdown
                  text={
                    selectedReport
                      ? selectedReport.text
                      : messages[`user.listReport`]
                  }
                  selection
                  className="mr-2 "
                  onChange={this.handleChangeReport}
                  options={listDataReport}
                ></Dropdown>
              )}
              <Button
                disabled={this.state.selectedReport === null || loadingDownload}
                style={{ height: "38px" }}
                className=" btn-download"
                loading={loadingDownload}
                onClick={this._handleDownloadReport}
              >
                <IntlMessages id="detailCompany.Downloadfinancialreportx" />
              </Button>
            </div>
          </div>
        </div>

        {!loading && requestedHistory && requestedHistory.length > 0 ? (
          <div className="table-custom ">
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <IntlMessages id="table.sttTable" />
                  </th>
                  <th>
                    <IntlMessages id="user.orderCode" />
                  </th>
                  <th>
                    <IntlMessages id="user.requirement" />
                  </th>
                  <th>
                    <IntlMessages id="user.companyandindustry" />
                  </th>
                  <th>
                    <IntlMessages id="user.taxandCode" />
                  </th>
                  {/* // */}
                  <th>
                    <IntlMessages id="newWord.selectQuarterTable" />
                  </th>
                  <th>
                    <IntlMessages id="newWord.selectTypeReportTable" />
                  </th>
                  <th>
                    <IntlMessages id="newWord.selectLangReportTable" />
                  </th>

                  <th>
                    <IntlMessages id="user.Status" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {requestedHistory.map((d, index) => {
                  return (
                    <tr key={index}>
                      <td><span>{((page-1)*10) + index + 1}</span></td>
                      <td>
                        <span>{d.code ? d.code : ""}</span>
                      </td>
                      <td>
                        <span>{d.requestData ? d.requestData : ""}</span>
                      </td>
                      <td>{this.funcRenderAactions(d)}</td>
                      <td>{this.funcRenderAactionsTax(d)}</td>
                      <td className="not_warp_line">
                        <span>{d.quarter ? d.quarter : ""}</span>
                      </td>
                      <td>
                        <span>{d.typeReport ? d.typeReport : ""}</span>
                      </td>
                      <td>
                        <span>{d.language ? d.language : ""}</span>
                      </td>
                      {this.activeColorStatus(d.status ? d.status : "")}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : loading ? (
          <LoaderTable isLoaded={!loading} styleCustom={{ height: "80vh" }} />
        ) : (
          <NoData />
        )}
        {totalPage > 1 && (
          <div
            className="foot-pagination text-right"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              totalPages={totalPage}
              activePage={page}
              onPageChange={this.handlePaginationChange}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountManagement: state.accountReducer.requestedHistoryData,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleGetHistoryRequested: (page, status, searchText) =>
      dispatch(actGetHistoryRequested(page, status, searchText)),
    actGetHistoryOrderAttachment: (orderID) =>
      dispatch(actGetHistoryOrderAttachment(orderID)),
    actGetHistoryOrderDownload: (orderID, name) =>
      dispatch(actGetHistoryOrderDownload(orderID, name)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TabContentTabHistory));
