export const defaultLocale = "vi";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "vi", name: "Vietnamese", direction: "ltr" }
];

export const defaultDirection = "ltr";

export const defaultPageConpanyInfo = {
  //Chỉ số
  tabRatio: [
    {
      set: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16, 17, 18, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
      basic: [["23"], ["31", "34", "35"]],
      standard: [["23"], ["31", "34", "35"]],
      premium: [["22", "23"], ["25", "27"], ["28"], ["31", "32", "34", "35"]],
      integrate_ite: [["22", "23"], ["25", "27"], ["28"], ["31", "32", "34", "35"]]
    },
    {
      set: [2, 13, 14, 19, 20, 21, 22],
      basic: [
        ["20", "21"],
        ["18", "16", "17"]
      ],
      standard: [
        ["20", "21"],
        ["18", "16", "17"]
      ],
      premium: [
        ["20", "21"],
        ["18", "16", "17"]
      ],
      integrate_ite: [
        ["20", "21"],
        ["18", "16", "17"]
      ]
    }
  ],
  // Chỉ tiêu
  tabAccount: [
    {
      set: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16, 17, 18, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
      basic: [
        ["14", "15"],
        ["5", "13"]
      ],
      standard: [
        ["14", "15"],
        ["5", "13"]
      ],
      premium: [
        ["14", "40", "36"],
        ["15", "42", "43", "41", "44"]
      ],
      integrate_ite: [
        ["14", "40", "36"],
        ["15", "42", "43", "41", "44"]
      ]
    },
    {
      set: [2, 13, 14, 19, 20, 21, 22],
      basic: [["1"], ["3"]],
      standard: [
        ["1", "2"],
        ["3", "12"]
      ],
      premium: [
        ["1", "2"],
        ["3", "12"],
        ["59", "60", "61", "63", "66"],
        ["68", "74"]
      ],
      integrate_ite: [
        ["1", "2"],
        ["3", "12"],
        ["59", "60", "61", "63", "66"],
        ["68", "74"]
      ]
    }
  ]
};

export const basicColor = [];

export const DEFAULT_STATISTIC_VSIC = ["0111", "0112", "0113"];
