import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Checkbox, Popup } from "semantic-ui-react";
import Linechart from "../../../components/ChartDemo/Linechart";
import AlertWarning from "../../../components/SweetAlert/AlertWarning";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import LoaderTable from "../../../components/LoaderTable";
import Findnotfound from "../../../components/Findnotfound";
import { Search } from "semantic-ui-react";
import IntlMessages from "../../../utils/IntlMessages";
import formatNumber from "../../../utils/formatNumber";
import { injectIntl } from "react-intl";
import {
  actSearchCompanyInfo, actRequestLiveCharteCompare,
  actRequestLiveChartCompareDelete, actMenuGetDataCompare
} from "../../../redux/actions";
import { Table } from "reactstrap";
import Pagination from "../../../components/Pagination/PaginationSimple";
import Button from "../../../components/CustomButton/CustomButton";
import { _Standard_, _Premium_, _Basic_ } from "../../../constants/rolesModel";
import NoData from "../../../components/NoData";
import ModalListVSIC from "../../../components/CompanyInfo/CompanyChild/ListVSIC";

class TabCompareCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInputChartLiveChiTieu: [],  // ds chỉ tiêu được chọn
      selectedInputChartLiveChiSo: [], // ds chỉ số được chọn
      toggleModalListVSIC: false,
      txtsearch: '',  // từ khóa tìm kiếm
      txtsearchError: '',  // lỗi tìm kiếm
      activePage: 1, // trang hiện tại ở bảng danh sách DN
      selectedCompany: this.props.dataReducer.companyDetail ? [this.props.dataReducer.companyDetail] : [], // danh sách DN được chọn để so sánh
      isMaxCompany: false,  // đạt số lượng DN so sánh tối đa,
      alert: '',
      isSearchSubmitted: false,
      isExpand1: true,
      isExpand2: true,
      isExpand3: true,
      isCompare: false, // khi click so sánh
    };
    this.props.dataReducer.companyData = [];
    this.props.dataCompareReducer.companyCompareChartLive = [];
  }

  componentDidMount() {
    if (this.props.id !== null && this.props.id !== undefined) {
      this.handleGetListCheckBox(this.props.id);
    }
  }

  // list chỉ tiêu
  handleChangeLiveChitieu = nameInput => {
    const index = this.state.selectedInputChartLiveChiTieu.indexOf(nameInput);
    if (index < 0) {
      this.state.selectedInputChartLiveChiTieu.push(nameInput);
    } else {
      this.state.selectedInputChartLiveChiTieu.splice(index, 1);
    }
    this.setState({
      selectedInputChartLiveChiTieu: [...this.state.selectedInputChartLiveChiTieu]
    });
  };

  // list chỉ số
  handleChangeLiveChiSo = nameInput => {
    const index = this.state.selectedInputChartLiveChiSo.indexOf(nameInput);
    if (index < 0) {
      this.state.selectedInputChartLiveChiSo.push(nameInput);
    } else {
      this.state.selectedInputChartLiveChiSo.splice(index, 1);
    }
    this.setState({
      selectedInputChartLiveChiSo: [...this.state.selectedInputChartLiveChiSo]
    });
  };

  // áp dụng để vẽ chart
  handleApplyChart = () => {
    const {
      selectedInputChartLiveChiTieu,
      selectedInputChartLiveChiSo
    } = this.state;
    const { messages } = this.props.intl;
    const totalSelected = [...selectedInputChartLiveChiTieu, ...selectedInputChartLiveChiSo];
    if (
      totalSelected.length === 0
    ) {
      this.actAlert(messages[`detailCompany.minTargetCompare`]);
    } else if (
      totalSelected.length > 8
    ) {
      this.actAlert(messages[`detailCompany.maxTargetCompare`]);
    } else {
      if (this.props.id !== null && this.props.id !== undefined) {
        this.renderRequestModel();
      }
    }
  };

  // call api so sánh trực tiếp
  renderRequestModel = () => {
    const {
      selectedInputChartLiveChiTieu,
      selectedInputChartLiveChiSo,
      selectedCompany,
    } = this.state;
    const selectedCompanyID = [];
    selectedCompany.forEach(e => {
      selectedCompanyID.push(e.id);
    });
    let modelt1 = {
      arrayRatio: selectedInputChartLiveChiSo.length === 0 ? [""] : selectedInputChartLiveChiSo,
      arrayAccount: selectedInputChartLiveChiTieu.length === 0 ? [""] : selectedInputChartLiveChiTieu,
      arrCompanyIds: selectedCompanyID,
    };
    this.props.requestLiveCharteCompareSSTT(modelt1);
  }

  // reset lại tiêu chí so sánh
  handleRefresh = () => {
    this.setState({
      selectedInputChartLiveChiTieu: [],
      selectedInputChartLiveChiSo: [],
    });
  };

  // xóa chart
  handleDeleteChart = prams => {
    this.props.requestLiveChartDNKDelete(prams)
  };

  actDeleteChart = prams => {
    const { messages } = this.props.intl;
    this.setState({
      alert: (
        <AlertWarning
          message={messages[`newWord.AlertRemoveChart`]}
          confirmBtnText={messages[`user.Confirm`]}
          cancelBtnText={messages[`user.Cancel`]}
          title={messages[`user.Confirm`]}
          confirmToFunc={() => {
            this.handleDeleteChart(prams);
            this._hideAlert();
          }}
          cancleToFunc={this._hideAlert}
        />
      )
    });
  };

  // đóng modal thông báo
  _hideAlert = () => {
    this.setState({
      alert: ""
    });
  };

  // hiển thị modal thông báo
  actAlert = message => {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={"Lưu ý"}
          // showCancel
          confirmBtnText="Đồng ý"
          customClass="swal-wide"
          confirmBtnCssClass="confirmBtn swal2-styled"
          cancelBtnCssClass="cancelBBtn swal2-styled"
          onConfirm={() => {
            this._hideAlert();
          }}
        >
          {message}
        </SweetAlert>
      )
    });
  };

  handleGetListCheckBox = (id) => {
    this.props.handleGetMenuDataCompare(id);
  }

  // tìm kiếm
  handleSearchSubmit = () => {
    this.setState({ 
      isSearchSubmitted: true, 
      activePage: 1,
      isExpand1: true,
      isExpand2: true,
      isExpand3: false,
    }, () =>
      this.searchCompany(1)
    );
  };

  searchCompany = (page) => {
    const {
      txtsearch,
    } = this.state;

    if (txtsearch.trim().length < 3) {
      this.setState({
        ...this.state,
        txtsearchError: 'companyFilter.textInput.minLength'
      });
      return;
    }
    this.setState({
      ...this.state,
      txtsearchError: ''
    });

    var rq = {
      page: page,
      q: txtsearch.trim(),
      checkSameType: this.props.id
    };
    this.props.actSearchCompany(rq);
  };

  onKeyDownSearch = (event) => {
    this.setState({
      ...this.state,
      txtsearchError: ''
    });
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      this.handleSearchSubmit();
    }
  };

  handleSearchChange = (e, data) => {
    this.setState({
      ...this.state,
      txtsearch: data.value
    });
  }

  // modal vsic
  handleModalListVSIC = () => {
    this.setState((state) => ({
      toggleModalListVSIC: !state.toggleModalListVSIC,
    }));
  };

  // chi tiết DN
  redirectToCompanyDetail = (id) => {
    if (id) {
      window.location.href = `/chi-tiet-doanh-nghiep/${id}`;
    }
  };

  // chuyển trang
  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage }, () =>
      this.searchCompany(activePage)
    );
  };

  // thêm DN để SS
  addCompany = (maxCompareCompany, item) => {
    const selectedCompany = this.state.selectedCompany;
    if (this.state.selectedCompany.length < maxCompareCompany) {
      selectedCompany.push(item);
      this.setState({ selectedCompany });
      this.props.dataReducer.companyData.forEach(e => {
        if (e.id === item.id) {
          e.isSelected = true;
        }
      });
    } else {
      this.setState({ isMaxCompany: true }, () => {
        const { messages } = this.props.intl;
        this.actAlert(messages[`detailCompany.maxCompare`]);
      });
    }
  };

  // xóa DN đang SS
  removeCompany = (item) => {
    const selectedCompany = this.state.selectedCompany.filter(e => e.id !== item.id);
    this.setState({ selectedCompany });
    this.props.dataReducer.companyData.forEach(e => {
      if (e.id === item.id) {
        e.isSelected = false;
      }
    });
  }

  // đánh dấu các DN trong list tìm kiếm mà có trong list DN SS
  checkedInitial = (list) => {
    list.forEach(e => {
      const x = this.state.selectedCompany.filter(i => i.id === e.id);
      e.isSelected = x[0] ? true : false;
    });
  }

  // so sánh
  handleBusinessComparison = () => {
    const { messages } = this.props.intl;
    if (this.state.selectedCompany.length < 2) {
      this.actAlert(messages[`detailCompany.minCompare`]);
    } else {
      this.setState({
        isCompare: true,
        isExpand1: true,
        isExpand2: false,
        isExpand3: true,
      });
    }
  }

  toggleExpand1 = () => {
    this.setState({
      ...this.state,
      isExpand1: !this.state.isExpand1
    })
  }

  toggleExpand2 = () => {
    this.setState({
      ...this.state,
      isExpand2: !this.state.isExpand2
    })
  }

  toggleExpand3 = () => {
    this.setState({
      ...this.state,
      isExpand3: !this.state.isExpand3
    })
  }

  render() {
    const {
      txtsearch,
      selectedInputChartLiveChiTieu,
      selectedInputChartLiveChiSo
    } = this.state;
    const totalSelected = [...selectedInputChartLiveChiTieu, ...selectedInputChartLiveChiSo];
    const { companyCompareChartLive } = this.props.dataCompareReducer;
    const menu_compare = this.props.dataReducer.model;
    const { loadingMenuCompare } = this.props.dataReducer;
    const loadingChart = this.props.dataCompareReducer.loading;
    this.props.dataReducer.companyData.forEach((element) => {
      if (element.isSelected === undefined) {
        element.isSelected = false;
      }
    });
    this.checkedInitial(this.props.dataReducer.companyData);
    const { loading, totalItems, totalPage, pageIndex, companyData, companyDetail } = this.props.dataReducer;
    const roles = (companyDetail && companyDetail.roleName) ? companyDetail.roleName.replace(/^\w/, (c) => c.toUpperCase()) : '';
    let maxCompareCompany = 2;
    switch (roles) {
      case _Standard_:
        maxCompareCompany = 3;
        break;
      case _Premium_:
        maxCompareCompany = 5;
        break;
      default:
        maxCompareCompany = 2;
        break;
    }
    const { messages } = this.props.intl;
    const language = localStorage.getItem("currentLanguage");
    return (
      <React.Fragment>
        {this.state.alert}
        {this.state.toggleModalListVSIC &&
          <ModalListVSIC
            toggleModal={this.state.toggleModalListVSIC}
            handleCloseForget={this.handleModalListVSIC} />
        }
        {loadingMenuCompare ?
          <LoaderTable
            isLoaded={!loadingMenuCompare}
            styleCustom={{ height: "50vh" }} />
          :
          <div className="company-compare">
            <div className="row equal-height">
              <div className="col-md-5 col-xl-3 pb-4">
                <div className="card-item company-search-result search-info-section">
                  <div className="heading">
                    <div className="section-title pr-5"><IntlMessages id="compare.companiesComparison" /></div>
                    <div className="toogle-icon-container" onClick={this.toggleExpand1}>
                      <span className="material-icons">{this.state.isExpand1 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>
                    </div>
                  </div>
                  <Collapse isOpen={this.state.isExpand1}>
                    {
                      this.state.selectedCompany && this.state.selectedCompany.length > 0 &&
                      <div>
                        {this.state.selectedCompany.map((props, key) => {
                          return (
                            <div key={key} className="selected-company mb-3">
                              <span className="pr-4">{key + 1} - {" "}
                                {
                                  language === 'en' && props.nameEnglish ? <span>{props.nameEnglish}</span> : <span>{props.name}</span>
                                }
                              </span>
                              {
                                this.props.id && props.id && this.props.id !== props.id &&
                                <span className="material-icons check" onClick={() => this.removeCompany(props)}>cancel</span>
                              }
                            </div>
                          );
                        })
                        }
                        <div className="d-flex justify-content-center mt-5">
                          <Button
                            className={`compare-btn common-button`}
                            onClick={() => this.handleBusinessComparison()}
                          // disabled={loadingMenuCompare}
                          // isLoading={loadingMenuCompare}
                          >
                            <IntlMessages id="compare.businessComparison" />
                            {/* <em className="material-icons">compare_arrows</em> */}
                          </Button>
                        </div>
                      </div>
                    }
                  </Collapse>
                </div>
              </div>
              <div className="col-md-7 col-xl-9 pb-4">
                <div>
                  <div className={!this.state.isCompare ? 'card-item h-100' : 'card-item'}>
                    <div className="content-fillter search-info-section p-0">
                      <div className="company-search-bar mb-3">
                        <div className="heading">
                          <div className="section-title pr-3"><IntlMessages id="menu.companyinformationsearch" /></div>
                          <div className="visc-list pr-5">
                            <a href="#" className="text-cyan" onClick={this.handleModalListVSIC}><em className="material-icons">featured_play_list</em><IntlMessages id="filter.dsnn" /></a>
                          </div>
                        </div>
                        <div className="toogle-icon-container" onClick={this.toggleExpand2}>
                          <span className="material-icons">{this.state.isExpand2 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>
                        </div>
                        <div className="ui action input fluid customFilter">
                          <Search
                            className="searchFilter"
                            onKeyDown={this.onKeyDownSearch}
                            onSearchChange={this.handleSearchChange}
                            placeholder={messages["filter.textInput"]}
                            value={txtsearch}
                            open={false}
                          />
                          <Button
                            className={`common-button ml-3 ${loading ? "disabled" : ""
                              }`}
                            onClick={() => this.handleSearchSubmit()}
                            disabled={loading}
                            isLoading={loading}
                          >
                            <IntlMessages id="filter.buttonSearch" />
                            <em className="material-icons">search</em>
                          </Button>
                        </div>
                        {this.state.txtsearchError && <div className="form-error"><IntlMessages id={this.state.txtsearchError} /></div>}
                      </div>
                      <Collapse isOpen={this.state.isExpand2}>
                        {!loading && companyData && companyData.length > 0 &&
                          <div className="heading mt-4 mb-3">
                            <div className="section-title pr-3 fs-16"><IntlMessages id="compare.searchResult" /> ({totalItems} <IntlMessages id="company" />)</div>
                          </div>
                        }
                        <div className="company-search-result">
                          <div className="table-custom">
                            {!loading && companyData && companyData.length > 0 &&
                              <Table responsive>
                                <thead>
                                  <tr>
                                    <th>
                                      <IntlMessages id="table.sttTable" />
                                    </th>
                                    <th>
                                      <IntlMessages id="table.businessName" />
                                    </th>
                                    <th>
                                      <IntlMessages id="table.tableMST" />
                                    </th>
                                    <th>VSIC</th>
                                    <th>
                                      <IntlMessages id="detailCompany.reportType" />
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {companyData.map((props, key) => {
                                    return (
                                      <tr key={key}>
                                        <td>{formatNumber((pageIndex - 1) * 10 + key + 1)}</td>
                                        <td>
                                          <a href="#" className="text-cyan" onClick={() => this.redirectToCompanyDetail(props.id)}>
                                            {
                                              language === 'en' && props.nameEnglish ? <span>{props.nameEnglish}</span> : <span>{props.name}</span>
                                            }
                                          </a>
                                        </td>
                                        <td>{props.taxNumber ? props.taxNumber : ""}</td>
                                        <td>{props.vsicId ? props.vsicId : ""}</td>
                                        <td>
                                          {props.set === "NORMAL" && <IntlMessages id="newWord.Regular" />}
                                          {props.set === "CREDIT" && <IntlMessages id="newWord.Credit" />}
                                          {props.set === null && <IntlMessages id="newWord.Undefined" />}
                                        </td>
                                        <td>
                                          {props.isSelected &&
                                            <Popup
                                              trigger={
                                                <span className="material-icons tooltip-icon check cursor-default">check_circle</span>
                                              }
                                              className="custom-popup"
                                              position={'left center'}
                                            >
                                              <Popup.Content>
                                                <IntlMessages id="selected" />
                                              </Popup.Content>
                                            </Popup>
                                          }
                                          {!props.isSelected && !props.isSameType &&
                                            <Popup
                                              trigger={
                                                <span className="material-icons tooltip-icon cursor-default">add_circle</span>
                                              }
                                              className="custom-popup"
                                              position={'left center'}
                                            >
                                              <Popup.Content>
                                                <IntlMessages id="detailCompany.notCompare" />
                                              </Popup.Content>
                                            </Popup>
                                          }
                                          {!props.isSelected && props.isSameType &&
                                            <span className="material-icons check" onClick={() => this.addCompany(maxCompareCompany, props)}>add_circle</span>
                                          }
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            }
                          </div>
                          {loading &&
                            <LoaderTable
                              isLoaded={!loading}
                              styleCustom={{ height: "200px" }} />
                          }
                          {!loading && companyData && companyData.length > 0 &&
                            <div className="foot-pagination">
                              <Pagination
                                activePage={this.state.activePage}
                                totalPages={totalPage}
                                onPageChange={this.handlePaginationChange}
                              />
                            </div>
                          }
                          {!loading && this.state.isSearchSubmitted && companyData && companyData.length === 0 &&
                            <Findnotfound isSearch={true} />
                          }
                        </div>
                      </Collapse>
                    </div>
                  </div>
                  {this.state.isCompare &&
                    <div className="company_target_views">
                      <div className="card-item mt-4">
                        <div className="card-title card-title-line search-info-section p-0">
                          <div className="heading">
                            <div className="section-title pr-5"><IntlMessages id="compare.businessComparison" /></div>
                          </div>
                          <div className="toogle-icon-container compare" onClick={this.toggleExpand3}>
                            <span className="material-icons">{this.state.isExpand3 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>
                          </div>
                        </div>
                        <Collapse isOpen={this.state.isExpand3}>
                          {/* Chỉ tiêu */}
                          <div className="row-title mb-3 text-uppercase font-bold text-cyan"><IntlMessages id="submenu.Accounts" /></div>
                          <div className={menu_compare.creditAccount &&
                            menu_compare.creditAccount.length > 0 ? 'row mb-3' : ''}>
                            {/* chỉ tiêu tín dụng */}
                            {menu_compare.creditAccount &&
                              menu_compare.creditAccount.length > 0 && (
                                <React.Fragment>
                                  {/* <div className="row-title col-12"><IntlMessages id="common.target" /></div> */}
                                  {
                                    menu_compare.creditAccount.map(
                                      (prop, key) => {
                                        return (
                                          <div className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3" key={key}>
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiTieu.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChitieu(prop.code)}
                                            />
                                          </div>
                                        );
                                      })
                                  }
                                </React.Fragment>
                              )}
                          </div>
                          <div className={menu_compare.account &&
                            menu_compare.account.length > 0 ? 'row mb-3' : ''}>
                            {/* chỉ tiêu thường */}
                            {menu_compare.account &&
                              menu_compare.account.length > 0 && (
                                <React.Fragment>
                                  {/* <div className="row-title col-12"><IntlMessages id="common.bcdkt" /></div> */}
                                  {
                                    menu_compare.account.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiTieu.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChitieu(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className={menu_compare.accountOther &&
                            menu_compare.accountOther.length > 0 ? 'row mb-3' : ''}>
                            {/* Bảng cân đối kế toán */}
                            {menu_compare.accountOther &&
                              menu_compare.accountOther.length > 0 && (
                                <React.Fragment>
                                  <div className="row-title col-12"><IntlMessages id="common.bcdkt" /></div>
                                  {
                                    menu_compare.accountOther.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiTieu.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChitieu(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className={menu_compare.income_Statement &&
                            menu_compare.income_Statement.length > 0 ? 'row mb-3' : ''}>
                            {/* Báo cáo KQ HĐ KD */}
                            {menu_compare.income_Statement &&
                              menu_compare.income_Statement.length > 0 && (
                                <React.Fragment>
                                  <div className="row-title col-12"><IntlMessages id="common.bckqhd" /></div>
                                  {
                                    menu_compare.income_Statement.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiTieu.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChitieu(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className={menu_compare.direct_Cash_Flow_Statement &&
                            menu_compare.direct_Cash_Flow_Statement.length > 0 ? 'row mb-3' : ''}>
                            {/* báo cáo lưu chuyển tiền tệ trực tiếp */}
                            {menu_compare.direct_Cash_Flow_Statement &&
                              menu_compare.direct_Cash_Flow_Statement.length > 0 && (
                                <React.Fragment>
                                  <div className="row-title col-12"><IntlMessages id="common.bckqhd" /></div>
                                  {
                                    menu_compare.direct_Cash_Flow_Statement.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiTieu.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChitieu(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className={menu_compare.indirect_Cash_Flow_Statement &&
                            menu_compare.indirect_Cash_Flow_Statement.length > 0 ? 'row mb-3' : ''}>
                            {/* báo cáo lưu chuyển tiền tệ gián tiếp */}
                            {menu_compare.indirect_Cash_Flow_Statement &&
                              menu_compare.indirect_Cash_Flow_Statement.length > 0 && (
                                <React.Fragment>
                                  <div className="row-title col-12"><IntlMessages id="common.bckqhd" /></div>
                                  {
                                    menu_compare.indirect_Cash_Flow_Statement.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiTieu.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChitieu(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>

                          {/* Chỉ số */}
                          <div className="row-title mb-3 text-uppercase font-bold text-cyan"><IntlMessages id="submenu.Ratios" /></div>
                          <div className={menu_compare.creditRatio &&
                            menu_compare.creditRatio.length > 0 ? 'row mb-3' : ''}>
                            {/* chỉ số tín dụng */}
                            {menu_compare.creditRatio &&
                              menu_compare.creditRatio.length > 0 && (
                                <React.Fragment>
                                  {/* <div className="row-title col-12"><IntlMessages id="common.bckqhd" /></div> */}
                                  {
                                    menu_compare.creditRatio.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiSo.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChiSo(prop.code)}
                                            />
                                          </div>
                                        );
                                      })
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className={menu_compare.ratio &&
                            menu_compare.ratio.length > 0 ? 'row mb-3' : ''}>
                            {/* chỉ số thường */}
                            {menu_compare.ratio &&
                              menu_compare.ratio.length > 0 && (
                                <React.Fragment>
                                  {/* <div className="row-title col-12"><IntlMessages id="common.bclctt" /></div> */}
                                  {
                                    menu_compare.ratio.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiSo.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChiSo(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className={menu_compare.cauTrucVon &&
                            menu_compare.cauTrucVon.length > 0 ? 'row mb-3' : ''}>
                            {/* Cấu trúc vốn */}
                            {menu_compare.cauTrucVon &&
                              menu_compare.cauTrucVon.length > 0 && (
                                <React.Fragment>
                                  <div className="row-title col-12"><IntlMessages id="ratio.Capitalstructure" /></div>
                                  {
                                    menu_compare.cauTrucVon.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiSo.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChiSo(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className={menu_compare.tiSoHieuQuaHoatDong &&
                            menu_compare.tiSoHieuQuaHoatDong.length > 0 ? 'row mb-3' : ''}>
                            {/* Chỉ số hiệu quả hoạt động */}
                            {menu_compare.tiSoHieuQuaHoatDong &&
                              menu_compare.tiSoHieuQuaHoatDong.length > 0 && (
                                <React.Fragment>
                                  <div className="row-title col-12"><IntlMessages id="ratio.Performanceratios" /></div>
                                  {
                                    menu_compare.tiSoHieuQuaHoatDong.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiSo.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChiSo(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className={menu_compare.tiSoThanhToan &&
                            menu_compare.tiSoThanhToan.length > 0 ? 'row mb-3' : ''}>
                            {/* Chỉ số thanh toán */}
                            {menu_compare.tiSoThanhToan &&
                              menu_compare.tiSoThanhToan.length > 0 && (
                                <React.Fragment>
                                  <div className="row-title col-12"><IntlMessages id="ratio.Liquidityratios" /></div>
                                  {
                                    menu_compare.tiSoThanhToan.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiSo.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChiSo(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>
                          <div className={menu_compare.tiSuatLoiNhuan &&
                            menu_compare.tiSuatLoiNhuan.length > 0 ? 'row mb-3' : ''}>
                            {/* Chỉ số lợi nhuận */}
                            {menu_compare.tiSuatLoiNhuan &&
                              menu_compare.tiSuatLoiNhuan.length > 0 && (
                                <React.Fragment>
                                  <div className="row-title col-12"><IntlMessages id="ratio.Profitabilityratios" /></div>
                                  {
                                    menu_compare.tiSuatLoiNhuan.map(
                                      (prop, key) => {
                                        return (
                                          <div
                                            className="input-list-Checkbox col-md-6 col-lg-4 col-xl-3"
                                            key={key}
                                          >
                                            <Checkbox
                                              label={prop.name}
                                              id={prop.code}
                                              checked={this.state.selectedInputChartLiveChiSo.includes(
                                                prop.code
                                              )}
                                              onChange={() => this.handleChangeLiveChiSo(prop.code)}
                                            />
                                          </div>
                                        );
                                      }
                                    )
                                  }
                                </React.Fragment>
                              )
                            }
                          </div>

                          <div className="company-area-head region-submit mt-4">
                            <Button
                              className="refresh-btn common-button-2"
                              onClick={() => this.handleRefresh()}
                              disabled={totalSelected.length === 0}
                            >
                              <IntlMessages id="common.refresh" />
                            </Button>
                            <Button
                              className="aplly-btn common-button ml-3"
                              onClick={this.handleApplyChart}
                              disabled={loadingChart}
                              isLoading={loadingChart}
                            >
                              <IntlMessages id="common.Apply" />
                            </Button>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            {this.state.isCompare &&
              <div className="company_target_views">
                {loadingChart && (
                  <LoaderTable
                    isLoaded={!loadingChart}
                    styleCustom={{ height: "500px" }}
                  />
                )}
                <div
                  className="company-chart row equal-height"
                  style={loadingChart ? { display: "none" } : {}}
                >
                  {companyCompareChartLive &&
                    companyCompareChartLive.length > 0 &&
                    companyCompareChartLive.map((props, key) => {
                      return (
                        <div className="col-md-6 col-lg-4 col-xl-3 pb-4" key={key}>
                          <div className="line-chart card-item">
                            <span className="material-icons close-icon" onClick={() => this.actDeleteChart(key)}>close</span>
                            <Linechart
                              title={props.title}
                              dataChart={props.dataChart}
                              enabledToolTip={
                                roles &&
                                (roles === _Standard_ || roles === _Premium_)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                {!loadingChart && companyCompareChartLive &&
                  companyCompareChartLive.length === 0 && (
                    <div className="card-item mb-4">
                      <NoData />
                    </div>
                  )}
              </div>
            }
          </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    dataCompareReducer: state.companyCompareReducer,
    dataReducer: state.companyReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // tìm kiếm DN
    actSearchCompany: (body) => {
      dispatch(actSearchCompanyInfo(body));
    },
    // lấy list tiêu chí so sánh
    handleGetMenuDataCompare: (id) => dispatch(actMenuGetDataCompare(id)),
    // so sánh trực tiếp (vẽ chart)
    requestLiveCharteCompareSSTT: (body) => {
      dispatch(actRequestLiveCharteCompare(body));
    },
    // xóa chart
    requestLiveChartDNKDelete: (id) => {
      dispatch(actRequestLiveChartCompareDelete(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TabCompareCompany));