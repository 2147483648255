import React, { Component } from "react";
import { connect } from "react-redux";
import { actGetSampleReport, actAddToCart, actGetDataRelatedReportInCompanyDetail } from "../../../redux/actions";
import LoaderTable from "../Loader/LoaderTable";
import { injectIntl } from "react-intl";
import IntlMessages from "../../../utils/IntlMessages";
import Swiper from "swiper";
import { formatMoney } from "../../../utils/formatMoney";
import { handleFlyBuyCart } from "../../../assets/js/handleflybuycart.js";
import axios from "axios";
import fileDownload from "js-file-download";
import BASE_API_URL from "../../../constants/hostSever";
import { Link } from "react-router-dom";
class TabRelatedReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleBuyCompany = (requestData) => {
    const { detail } = this.props;
    let model = {
      id: detail.id,
      name: detail.name,
      englishName: detail.englishName,
      vsicId: detail.vsicId,
      taxNumber: detail.taxNumber,
      requestData: requestData,
      viracServiceId: 1, // dữ liệu doanh nghiệp
      type: 1,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  UNSAFE_componentDidMount() {}

  componentDidMount() {
    setTimeout(() => {
      handleFlyBuyCart();
      this.swiper();
    }, 500);

    this.initData();
  }

  initData() {
    if (this.props.id !== null && this.props.id !== undefined) {
      this.props.getDataRelatedReportInCompanyDetail(this.props.id);
    }
  }

  swiper = () => {
    new Swiper(".swiper-container", {
      grabCursor: true,
      autoplay: {
        delay: 3000
      },
      rewind: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      slidesPerView: 1.25,
      spaceBetween: 25,
      breakpoints: {
        320: {
          slidesPerView: 1.25,
          centeredSlides: true
        },
        992: {
          slidesPerView: 2
        }
      }
    });
  };

  handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob"
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  handleBuyIndustry = (data) => {
    let model = {
      id: data.reportId,
      name: data.reportName,
      englishName: data.reportEName,
      requestData: data.reportName,
      price: data.price,
      currency: "VND",
      viracServiceId: 2, // báo cáo ngành
      type: 2,
      lang: localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : "vi"
    };
    this.props.addToCart(model);
  };

  denyClick = () => {};

  handleClickBtnBuy(event, data) {
    event.preventDefault();
    if (data) {
      this.handleBuyIndustry(data);
    } else {
      this.denyClick();
    }
  }

  buttonCart = (data) => {
    const stateBtn = this.checkDisabledBtnCart(data);
    return (
      <React.Fragment>
        <button className={"ui basic button add-to-cart p-0 m-0 "} disabled={stateBtn} onClick={(event) => this.handleClickBtnBuy(event, data)}>
          <span className={"cart-icon material-icons text-cyan"}>near_me</span>
        </button>
      </React.Fragment>
    );
  };

  checkDisabledBtnCart = (data) => {
    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");

    const userId = this.props.accountReducer ? this.props.accountReducer.userAccount.id : "";
    if (!carts) return false;

    if (carts.length > 0) {
      // const cartOfCurrentUser = carts.filter((item) => item.userID === userId);
      const cartOfCurrentUser = carts;

      if (!cartOfCurrentUser) return false;
      if (!cartOfCurrentUser[0]) return false;

      if (!cartOfCurrentUser[0].dataRequest) return false;
      if (cartOfCurrentUser[0].dataRequest.filter((item) => item.id === data.reportId && item.lang === language).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    const { detail } = this.props;
    const { loadingDT, loadingRelatedData } = this.props.reducer;
    const { vsicReportData } = this.props.reducer.companyRelatedData;
    const { messages } = this.props.intl;

    const carts = JSON.parse(localStorage.getItem("carts"));
    const language = localStorage.getItem("currentLanguage");
    const checkDisabledBtnCart = (requestData) => {
      if (!carts) return false;
      if (
        carts.length > 0 &&
        carts[0].dataRequest &&
        carts[0].dataRequest.filter((i) => i.id === detail.id && i.lang === language && i.name === detail.name && i.requestData === requestData).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    };
    var buttonCartCompany = (requestData) => (
      <React.Fragment>
        <button
          className="ui basic button add-to-cart p-0 m-0"
          disabled={checkDisabledBtnCart(requestData)}
          onClick={detail.id ? () => this.handleBuyCompany(requestData) : () => this.denyClick()}
        >
          {/* <img className="cart-icon" src={ic_btn_fly} alt="" /> */}
          <span className="cart-icon material-icons text-cyan">near_me</span>
        </button>
      </React.Fragment>
    );

    //
    this.swiper();
    return (
      <React.Fragment>
        <LoaderTable isLoaded={!loadingRelatedData} styleCustom={{ height: "300px" }} />
        <div className="company_info_views related-report" hidden={loadingRelatedData} ref={(el) => (this.componentRef = el)}>
          <div className="row equal-height">
            <div className="col-xl-6 pb-4">
              <div className="card-item info_view_h_">
                <div className="card-title card-title-line">
                  <IntlMessages id="report.company" />
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <div className="mb-3 report-name">
                      <IntlMessages id="report.CompanySnapshot.title" />
                    </div>
                    <IntlMessages id="report.CompanySnapshot.content" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {buttonCartCompany(messages["report.CompanySnapshot.title"], null, 1, null)}
                    <a href={BASE_API_URL + "Report/sampleReport?type=CS"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                      <em className="material-icons">download</em>
                      <IntlMessages id="report.template" />
                    </a>
                    {/* <a className="text-cyan" onClick={() => { this.handleDownload(BASE_API_URL + CS_report, 'VIRAC-SAMPLE-CS.pdf') }}><em className="material-icons">download</em><IntlMessages id="report.template" /></a> */}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <div className="mb-3 report-name">
                      <IntlMessages id="report.CompanyExperditious.title" />
                    </div>
                    <IntlMessages id="report.CompanyExperditious.content" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {buttonCartCompany(messages["report.CompanyExperditious.title"], null, 1, null)}
                    <a href={BASE_API_URL + "Report/sampleReport?type=CER"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                      <em className="material-icons">download</em>
                      <IntlMessages id="report.template" />
                    </a>
                    {/* <a className="text-cyan" onClick={() => { this.handleDownload(BASE_API_URL + CS_report, 'VIRAC-SAMPLE-CS.pdf') }}><em className="material-icons">download</em><IntlMessages id="report.template" /></a> */}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <div className="mb-3 report-name">
                      <IntlMessages id="report.StandardCompany.title" />
                    </div>
                    <IntlMessages id="report.StandardCompany.content" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {buttonCartCompany(messages["report.StandardCompany.title"], null, 1, null)}
                    <a href={BASE_API_URL + "Report/sampleReport?type=SCR"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                      <em className="material-icons">download</em>
                      <IntlMessages id="report.template" />
                    </a>
                    {/* <a className="text-cyan" onClick={() => { this.handleDownload('http://www.africau.edu/images/default/sample.pdf', 'VIRAC-SAMPLE-SCR.pdf') }}><em className="material-icons">download</em><IntlMessages id="report.template" /></a> */}
                  </div>
                </div>
                <div className="info_tbody_l_table_item itemx">
                  <div className="info_tbody_l_table_item_l">
                    <div className="mb-3 report-name">
                      <IntlMessages id="report.ComprehensiveCompany.title" />
                    </div>
                    <IntlMessages id="report.ComprehensiveCompany.content" />
                  </div>
                  <div className="info_tbody_l_table_item_r">
                    {buttonCartCompany(messages["report.ComprehensiveCompany.title"], null, 1, null)}
                    <a href={BASE_API_URL + "Report/sampleReport?type=CCR"} target="_blank" rel="noopener noreferrer" className="text-cyan">
                      <em className="material-icons">download</em>
                      <IntlMessages id="report.template" />
                    </a>
                    {/* <a className="text-cyan" onClick={() => { this.handleDownload('http://www.africau.edu/images/default/sample.pdf', 'VIRAC-SAMPLE-CCR.pdf') }}><em className="material-icons">download</em><IntlMessages id="report.template" /></a> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 pb-4">
              <div className="card-item info_view_h_">
                <div className="card-title card-title-line">
                  <IntlMessages id="report.industry" />
                </div>
                {vsicReportData && vsicReportData.length > 0 && (
                  <div className="">
                    <div className="swiper-container">
                      <div className="swiper-wrapper">
                        {vsicReportData.map((props, key) => {
                          return (
                            <div className="swiper-slide" key={key}>
                              <Link to={`/chi-tiet-bao-cao-nganh/${props.reportId ? props.reportId : ""}`}>
                                <img className="report-image" src={props.thumbnailUrl} alt={props.reportName} />
                              </Link>
                              <div className="report-name mt-4 mb-3" style={{ height: "40px" }}>
                                <Link className="text-cyan" to={`/chi-tiet-bao-cao-nganh/${props.reportId ? props.reportId : ""}`}>
                                  {language === "en" && props.reportEName ? <span>{props.reportEName}</span> : <span>{props.reportName}</span>}
                                </Link>
                              </div>

                              {/* Tạm ẩn description */}
                              {/* <div className="report-des">
                                {language === "en" && props.enSummary ? <span>{props.enSummary}</span> : <span>{props.summary}</span>}
                              </div> */}
                              {language === "en" && !props.enIsPurchased && (
                                <div className="report-price-container">
                                  {this.buttonCart(props)}
                                  <span className="report-price">{formatMoney(parseInt(props.enPrice, 10))} VND</span>
                                </div>
                              )}
                              {language === "vi" && !props.isPurchased && (
                                <div className="report-price-container">
                                  {this.buttonCart(props)}
                                  <span className="report-price">{formatMoney(parseInt(props.price, 10))} VND</span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="swiper-navigation">
                      <div className="swiper-btn btn-prev swiper-button-prev">
                        <span className="material-icons">chevron_left</span>
                      </div>
                      <div className="swiper-btn btn-next swiper-button-next">
                        <span className="material-icons">chevron_right</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    carts: state.cartReducer,
    reducer: state.companyReducer,
    detail: state.companyReducer.companyDetail,
    reportReducer: state.reportReducer,
    accountReducer: state.accountReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (model) => {
      dispatch(actAddToCart(model));
    },
    // DS báo cáo DN mẫu
    actGetSampleReport: () => {
      dispatch(actGetSampleReport());
    },

    //Báo cáo doanh nghiệp + báo cáo ngành
    getDataRelatedReportInCompanyDetail: (id) => {
      dispatch(actGetDataRelatedReportInCompanyDetail(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TabRelatedReport));
